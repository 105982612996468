<template>
  <a-tabs v-model:activeKey="activeKey" @change="changeTab">
    <a-tab-pane key="1" tab="搜索商品">
      <genmai-search ref="genmaisearch" />
    </a-tab-pane>
    <a-tab-pane key="2" tab="跟卖库">
      <genmai-list ref="genmailist" />
    </a-tab-pane>
    <a-tab-pane key="3" tab="跟卖任务">
      <genmai-task ref="genmaitask" />
    </a-tab-pane>
    <a-tab-pane key="4" tab="调价规则">
      <genmai-price-rule ref="genmaipricerule" />
    </a-tab-pane>
  </a-tabs>
</template>

<script setup>
import _ from 'underscore';
import { ref, reactive, computed, getCurrentInstance, onActivated, watch, nextTick } from 'vue';
import GenmaiSearch from './search.vue';
import GenmaiList from './list.vue';
import GenmaiTask from './tasklist.vue';
import GenmaiPriceRule from './pricerule.vue';

const genmaisearch = ref(null);
const genmailist = ref(null);
const genmaitask = ref(null);
const genmaipricerule = ref(null);

const activeKey = ref('1');

const changeTab = (tab) => {
  if (tab === '1') {
    console.log(genmaisearch.value);
    nextTick(() => {
      console.log(genmaisearch.value);
      genmaisearch.value?.init();
    });
  }
  if (tab === '2') {
    console.log(genmailist.value);
    nextTick(() => {
      console.log(genmailist.value);
      genmailist.value?.init();
      genmailist.value?.getData();
    })
  }
  if (tab === '3') {
    console.log(genmaitask.value);
    nextTick(() => {
      console.log(genmaitask.value);
      genmaitask.value?.init();
      genmaitask.value?.getData();
    })
  }
  if (tab === '4') {
    console.log(genmaipricerule.value);
    nextTick(() => {
      console.log(genmaipricerule.value);
      genmaipricerule.value?.getData();
    })
  }
}
</script>

<style lang="less" scoped></style>