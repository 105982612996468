<template>
  <a-table size="small" bordered :columns="columns" :data-source="data" :loading="loading" :pagination="false"
    :scroll="{ y: 600 }">
    <template #title>
      <a-space>
        <a-button type="primary" :loading="loading" @click="getData">刷新</a-button>
        <a-input-search v-model:value="searchText" placeholder="CODE/NAME" enter-button="查询" @search="getData"
          allow-clear>
        </a-input-search>
      </a-space>
    </template>
    <template #bodyCell="{ record, column }">
      <template v-if="column.dataIndex === 'code'">
        <a-input v-model:value="record.code" placeholder="承运人代码" style="width: 100%" :disabled="loading" allow-clear>
        </a-input>
      </template>
      <template v-if="column.dataIndex === 'name'">
        <a-input v-model:value="record.name" placeholder="承运人名称" style="width: 100%" :disabled="loading" allow-clear>
        </a-input>
      </template>
      <template v-if="column.dataIndex === 'operation'">
        <a-button type="link" @click="doUpdate(record)" :loading="loading">更新</a-button>
      </template>
    </template>
  </a-table>
</template>

<script setup>
import { message } from "ant-design-vue";
import _ from "underscore";
import { ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from "vue";

const { proxy } = getCurrentInstance();

const loading = ref(false);
const searchText = ref('');
const columns = ref([
  { title: 'ID', dataIndex: '_id' },
  { title: 'CODE', dataIndex: 'code' },
  { title: 'NAME', dataIndex: 'name' },
  { title: 'Operation', dataIndex: 'operation' },
]);
const data = ref([]);

onActivated(() => {
  getData();
});

const getData = () => {
  loading.value = true;
  proxy.$api.doAPI2("/dev/amazon/carrier/list", { searchText: searchText.value }).then((res) => {
    loading.value = false;
    if (res.code === 0) {
      data.value = res.data.list;
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    loading.value = false;
    console.error(err);
  });
}
const doUpdate = (item) => {
  loading.value = true;
  proxy.$api.doAPI2("/dev/amazon/carrier/update", item).then((res) => {
    loading.value = false;
    if (res.code === 0) {
      getData();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    loading.value = false;
    console.error(err);
  });
}

</script>

<style></style>