<template>
  <a-spin :spinning="loading">
    <template v-if="org">
      <a-form ref="formRef" :model="org" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" autocomplete="off"
        class="org-form">
        <a-form-item label="企业编号" name="number">
          <a-tooltip placement="right">
            <template #title> 企业编号 </template>
            <span>{{ org.number }}</span>
          </a-tooltip>
        </a-form-item>
        <a-form-item label="企业名称" name="name" :rules="[{ required: true, message: '请输入企业名称' }]">
          <a-input v-model:value="org.name" :disabled="loading" />
        </a-form-item>
        <a-form-item label="联系人" name="linkman">
          <a-input v-model:value="org.linkman" :disabled="loading" />
        </a-form-item>
        <a-form-item label="联系电话" name="linkphone">
          <a-input v-model:value="org.linkphone" :disabled="loading" />
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 18, offset: 6 }">
          <a-button type="primary" @click="saveOrg" :loading="loading">保存</a-button>
        </a-form-item>
      </a-form>
    </template>
  </a-spin>
</template>

<script>
import { FormOutlined } from "@ant-design/icons-vue";
import { message, Modal } from "ant-design-vue";
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  watch,
  computed,
  onMounted,
  onActivated,
  getCurrentInstance,
  toRaw,
} from "vue";
import _ from "underscore";
export default defineComponent({
  name: "OrgInfo",
  components: { FormOutlined },
  setup() {
    const { proxy } = getCurrentInstance();
    const formRef = ref();
    const state = reactive({
      loading: false,
      org: {},
    });
    onMounted(() => {
    });
    onActivated(() => {
      getOrg();
    });
    const getOrg = () => {
      state.loading = true;
      proxy.$api
        .doAPI("/org/info")
        .then((res) => {
          state.loading = false;
          if (res.code === 0) {
            state.org = res.data.org;
          } else {
            message.error(res.msg);
          }
        })
        .catch((err) => {
          state.loading = false;
          console.error(err);
        });
    };
    const saveOrg = async () => {
      try {
        await formRef.value.validateFields();
        state.loading = true;
        proxy.$api
          .doAPI("/org/save", { org: state.org })
          .then((res) => {
            state.loading = false;
            if (res.code === 0) {
              message.success("保存成功");
              getOrg();
            } else {
              message.error(res.msg);
            }
          })
          .catch((err) => {
            state.loading = false;
            console.error(err);
          });
      } catch (errorInfo) {
        console.log("Failed:", errorInfo);
      }
    };
    return {
      ...toRefs(state),
      formRef,
      getOrg,
      saveOrg,
    };
  },
});
</script>
<style lang="less" scoped>
.org-form {
  max-width: 600px;
}
p {
  margin: 0;
  padding: 0;
}
</style>
