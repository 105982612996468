<template>
  <div class="d-flex align-center mb-2">
    <span style="width:75px;">入库日期：</span>
    <a-range-picker v-model:value="daterange" :locale="locale" value-format="YYYY-MM-DD" />
  </div>
  <div class="d-flex align-center mb-2">
    <span style="width:80px;">查询搜索：</span>
    <a-input-search v-model:value="searchText" placeholder="输入SKU/商品名称查询" enter-button="搜索" allow-clear
      @search="getData" />
  </div>
  <div class="d-flex justify-space-between align-center mb-2">
    <a-button type="danger" @click="showUpload">
      <template #icon>
        <UploadOutlined />
      </template>导入入库单
    </a-button>
    <a-button type="primary">
      <template #icon>
        <DownloadOutlined />
      </template>导出入库单
    </a-button>
  </div>
  <a-table :columns="columns" bordered :data-source="data" :loading="dataLoading" :scroll="{ x: width, y: 600 }"
    :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
    @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" row-key="_id" size="small">
    <template #bodyCell="{ record, index, column }">
      <template v-if="column.dataIndex === 'index'">
        {{ index + 1 }}
      </template>
      <template v-if="column.dataIndex === 'product'">
        {{ record?.product?.name_cn || '-' }}
      </template>
      <template v-if="column.dataIndex === 'warehouse'">
        {{ record?.warehouse?.name || '-' }}
      </template>
      <!-- <template v-if="column.dataIndex === 'state'">
        <a-tag v-if="record.state === 'pending'" color="warning">未入库</a-tag>
        <a-tag v-if="record.state === 'process'" color="processing">正在入库</a-tag>
        <a-tag v-if="record.state === 'success'" color="success">已入库</a-tag>
        <a-tag v-if="record.state === 'fail'" color="error">入库失败</a-tag>
      </template> -->
      <template v-if="column.dataIndex === 'operation'">

      </template>
    </template>
  </a-table>
  <UploadTask ref="uploadTask" />
</template>
<script>
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import {
  UploadOutlined,
  DownloadOutlined,
} from '@ant-design/icons-vue';
import { message } from "ant-design-vue";
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  watch,
  computed,
  onMounted,
  onActivated,
  getCurrentInstance,
} from "vue";
import _ from "underscore";
import UploadTask from '@/components/UploadTask.vue';
const columns = [
  { title: 'SKU', dataIndex: 'sku', width: 120, align: 'center' },
  { title: '商品名称', dataIndex: 'product', width: 150, align: 'center' },
  { title: '仓库', dataIndex: 'warehouse', width: 150, align: 'center' },
  { title: '数量', dataIndex: 'quantity', width: 100, align: 'center' },
  { title: '操作人', dataIndex: 'create_by', width: 100, align: 'center' },
  { title: '操作时间', dataIndex: 'create_time', width: 150, align: 'center' },
  { title: '操作', dataIndex: 'operation', width: 150, align: 'center', fixed: 'right' },
];
export default defineComponent({
  name: 'PurchaseInstock',
  components: {
    UploadOutlined,
    DownloadOutlined,
    UploadTask,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const formRef = ref();
    const uploadTask = ref();
    const state = reactive({
      daterange: ["", ""],
      searchText: "",
      dataLoading: false,
      page: 1,
      limit: 10,
      length: 0,
      count: 0,
      data: [],
      confirming: {},
    });
    const width = computed(() => proxy.$utils.twidth(columns));
    watch(() => state.page, () => getData());
    watch(() => state.limit, () => {
      if (state.page !== 1) state.page = 1;
      else getData();
    });
    onActivated(() => {
      // console.log("onActivated");
      getData();
    });
    const getData = () => {
      state.dataLoading = true;
      proxy.$api.doAPI("/inventory/inhis", { page: state.page, limit: state.limit, daterange: state.daterange, searchText: state.searchText, }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.data = _.map(res.data.list, n => {
            return {
              ...n,
              create_time: proxy.$utils.dateFormat(n.create_time, 'YYYY-MM-DD HH:mm:ss'),
            }
          });
          state.length = res.data.length;
          state.count = res.data.count;
          if (state.length > 0 && state.page > state.length) state.page = 1;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    }
    const showUpload = () => {
      uploadTask.value.showUpload({ title: "模板导入入库单", file: "入库单模板.xlsx", type: "inventory_instorage" });
    }
    return {
      ...toRefs(state),
      locale,
      formRef,
      uploadTask,
      columns,
      width,
      getData,
      showUpload,
    };
  }
});
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

p {
  margin: 0;
  padding: 0;
}
</style>

