<template>
  <a-config-provider :locale="locale">
    <a-layout>
      <a-layout-header class="header">
        <div class="logo"></div>
        <a-menu v-model:selectedKeys="topMenuActiveKeys" theme="dark" mode="horizontal" :style="{ lineHeight: '64px' }">
          <a-menu-item v-for="menu in topMenus" :key="menu.key" @click="redirectTo(menu.url)">
            {{ menu.title }}
          </a-menu-item>
        </a-menu>
        <div class="avatar">
          <a-avatar :size="35">
            <template #icon>
              <UserOutlined />
            </template>
          </a-avatar>
          <span class="loginname"> {{ name || username }} </span>
        </div>
        <div class="setting">
          <a-card title="账号管理">
            <a-card-grid style="width: 50%; text-align: center; cursor: not-allowed" :hoverable="false">
              我的账号
            </a-card-grid>
            <a-card-grid style="width: 50%; text-align: center; cursor: pointer" @click="showReset">
              修改密码
            </a-card-grid>
          </a-card>
          <!-- <a-card title="平台授权">
            <a-card-grid style="width: 25%; text-align: center; cursor: not-allowed" :hoverable="false">
              亚马逊
            </a-card-grid>
            <a-card-grid style="width: 25%; text-align: center; cursor: not-allowed" :hoverable="false">
              Wish
            </a-card-grid>
            <a-card-grid style="width: 25%; text-align: center; cursor: not-allowed" :hoverable="false">
              阿里巴巴
            </a-card-grid>
          </a-card> -->
          <a-card title="系统设置">
            <a-card-grid style="width: 50%; text-align: center; cursor: pointer" @click="showSys">
              系统设置
            </a-card-grid>
            <!-- <a-card-grid style="width: 25%; text-align: center; cursor: not-allowed" :hoverable="false">
              地址管理
            </a-card-grid>
            <a-card-grid style="width: 25%; text-align: center; cursor: not-allowed" :hoverable="false">
              权限管理
            </a-card-grid>
            <a-card-grid style="width: 25%; text-align: center; cursor: not-allowed" :hoverable="false">
              操作日志
            </a-card-grid> -->
          </a-card>
          <a-card title="其他服务">
            <!-- <a-card-grid style="width: 25%; text-align: center; cursor: not-allowed" :hoverable="false">
              操作手册
            </a-card-grid> -->
            <a-card-grid style="width: 50%; text-align: center; cursor: pointer" @click="logout">
              退出登录
            </a-card-grid>
          </a-card>
        </div>
      </a-layout-header>
      <a-layout>
        <a-layout-sider width="200" style="background: #fff" v-if="leftMenus.length > 0">
          <a-menu v-model:selectedKeys="leftMenuActiveKeys" v-model:openKeys="leftMenuOpenKeys" mode="inline"
            :collapsed="true" :style="{ height: '100%', borderRight: 0 }">
            <template v-for="menu in leftMenus">
              <a-sub-menu v-if="menu.children?.length > 0" :key="menu.key">
                <template #title>
                  <span>
                    {{ menu.title }}
                  </span>
                </template>
                <a-menu-item v-for="menusub in menu.children" :key="menusub.key" @click="redirectTo(menusub.url)">
                  {{ menusub.title }}
                </a-menu-item>
              </a-sub-menu>
              <a-menu-item v-else :key="menu.key" @click="redirectTo(menu.url)">
                {{ menu.title }}
              </a-menu-item>
            </template>
          </a-menu>
        </a-layout-sider>
        <a-layout style="padding: 0 24px 24px">
          <a-breadcrumb style="margin: 16px 0">
            <a-breadcrumb-item @click="redirectTo('/')" style="cursor: pointer">

            </a-breadcrumb-item>
            <a-breadcrumb-item v-if="menu2" @click="redirectTo(menu2.url)" style="cursor: pointer">
              {{ menu2.title }}
            </a-breadcrumb-item>
            <a-breadcrumb-item v-if="menu3">
              {{ menu3.title }}
            </a-breadcrumb-item>
          </a-breadcrumb>
          <a-layout-content :style="{ background: '#fff', padding: '24px', margin: 0, minHeight: '580px', }">
            <router-view v-slot="{ Component, route }">
              <keep-alive>
                <component :is="Component" :key="route.name" />
              </keep-alive>
            </router-view>
          </a-layout-content>
        </a-layout>
      </a-layout>
    </a-layout>
    <a-modal v-model:visible="resetModal" title="修改密码" @ok="resetPwd" @cancel="cancelSave" ok-text="确认" cancel-text="取消"
      :confirm-loading="reseting" :cancel-button-props="{ disabled: reseting }" :maskClosable="false"
      :closable="!reseting">
      <a-form ref="formRef" name="resetForm" :model="resetForm" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }"
        autocomplete="off">
        <a-form-item label="新密码" name="password" :rules="password_rule">
          <a-input-password v-model:value="resetForm.password" placeholder="请输入新密码" :disabled="reseting">
            <template #prefix>
              <LockOutlined class="site-form-item-icon" />
            </template>
          </a-input-password>
        </a-form-item>
        <a-form-item label="确认密码" name="password2"
          :rules="[{ required: true, message: '确认密码不能为空' }, { validator: validatePass, trigger: 'change' },]">
          <a-input-password v-model:value="resetForm.password2" placeholder="请确认密码" :disabled="reseting">
            <template #prefix>
              <LockOutlined class="site-form-item-icon" />
            </template>
          </a-input-password>
        </a-form-item>
      </a-form>
    </a-modal>
  </a-config-provider>
</template>
<script setup>
import { UserOutlined, LaptopOutlined, NotificationOutlined, LockOutlined, } from "@ant-design/icons-vue";
import locale from "ant-design-vue/es/locale/zh_CN";
import Cookies from "js-cookie";
import "dayjs/locale/zh-cn";
import { defineComponent, computed, watch, ref, reactive, toRefs, getCurrentInstance, } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { message, Modal } from "ant-design-vue";

const { proxy } = getCurrentInstance();
const formRef = ref();
const store = useStore();
const route = useRoute();
const router = useRouter();

const password_rule = ref(proxy.$constant.password_rule);

const topMenuActiveKeys = ref([]);
const leftMenuActiveKeys = ref([]);
const leftMenuOpenKeys = ref([]);
const leftMenus = ref([]);
const menu2 = ref(null);
const menu3 = ref(null);
const resetForm = ref({});
const resetModal = ref(false);
const reseting = ref(false);

const topMenus = computed(() => store.getters["menu/getTopMenus"]());
const username = computed(() => store.state.user.username);
const name = computed(() => store.state.user.name);

watch(() => route.path, (new_path, old_path) => {
  let { menu1: m1, menu2: m2, menu3: m3 } = store.getters["menu/getMenuChain"](new_path);
  menu2.value = m2;
  menu3.value = m3;
  topMenuActiveKeys.value = m1 ? [m1.key] : [];
  leftMenus.value = store.getters["menu/getLeftMenus"](m1?.key);
  leftMenuOpenKeys.value = leftMenus.value.map((n) => n.key);
  leftMenuActiveKeys.value = [
    ...(m1?.children?.filter((n) => n.url === new_path) || (m1?.url === new_path ? [m1] : [])),
    ...(m2?.children?.filter((n) => n.url === new_path) || (m2?.url === new_path ? [m2] : [])),
  ].map((n) => n.key);
}, { immediate: true });
const redirectTo = (url) => {
  router.push({ path: url }).catch(console.error);
};
const logout = () => {
  localStorage.clear();
  Cookies.remove("ae1688authcode");
  Cookies.remove("ae1688reauth");
  Cookies.remove("zdterp_token");
  router.replace({ path: "/login" }).catch(console.error);
};
const validatePass = async (_rule, value) => {
  if (!value) {
    return Promise.resolve();
  } else if (value !== resetForm.value.password) {
    return Promise.reject("两次输入密码不一致");
  } else {
    return Promise.resolve();
  }
};
const showReset = () => {
  resetForm.value = { password: "", password2: "", };
  resetModal.value = true;
};
const resetPwd = async () => {
  try {
    await formRef.value.validateFields();
    reseting.value = true;
    proxy.$api.doAPI("/resetpwd", { dataForm: resetForm.value, }).then((res) => {
      reseting.value = false;
      if (res.code === 0) {
        resetModal.value = false;
        Modal.success({
          title: "提示", content: "修改成功，重新登录",
          onOk() {
            logout();
          },
        });
      } else {
        message.error(res.msg);
      }
    }).catch((err) => {
      reseting.value = false;
      console.error(err);
    });
  } catch (errorInfo) {
    console.log("Failed:", errorInfo);
  }
};
const cancelSave = () => {
  console.log('cancelSave');
  formRef.value.clearValidate();
  resetModal.value = false;
}
const showSys = () => {

}
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

.logo {
  width: 144px;
  height: 35px;
  margin: 16px 24px 16px 0;
  background-image: url(~@/assets/logo3.png);
  background-position: center center;
  background-size: cover;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  font-weight: bold;
}

.ant-menu-dark.ant-menu-horizontal {
  flex: 1;
}

.site-layout-background {
  background: #fff;
}

.avatar {
  color: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.loginname {
  font-size: 16px;
  margin-left: 5px;
}

.setting {
  display: none;
  box-shadow: 0 0 5px #bbb;
  position: absolute;
  top: 65px;
  right: 0;
  background: #ccc;
  z-index: 10;
}

.setting:hover {
  display: block;
}

.avatar:hover+.setting {
  display: block;
}

p {
  margin: 0;
  padding: 0;
}
</style>
