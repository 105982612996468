<template>
    <div class="header">
        <a-form class="ant-advanced-search-form">
            <a-row :gutter="16">
                <a-col :span="8">
                    <a-form-item label="订单编号">
                        <a-input v-model:value="queryForm.number" placeholder="订单编号/交易单号" allow-clear></a-input>
                    </a-form-item>
                </a-col>
                <a-col :span="8">
                    <a-form-item label="选择店铺">
                        <a-select v-model:value="queryForm.shopid" placeholder="请选择店铺" mode="tags"
                            :options="shops"></a-select>
                    </a-form-item>
                </a-col>
                <a-col :span="8">
                    <a-form-item label="国家地区">
                        <a-select v-model:value="queryForm.country" :options="countrys" placeholder="请选择国家" allow-clear
                            show-search :filter-option="$filters.filterOption">
                        </a-select>
                    </a-form-item>
                </a-col>
                <a-col :span="8">
                    <a-form-item label="付款时间">
                        <a-range-picker v-model:value="queryForm.purchasedate" value-format="YYYY-MM-DD"
                            style="width:100%;" allow-clear />
                    </a-form-item>
                </a-col>
                <a-col :span="8">
                    <a-form-item label="发货时间">
                        <a-range-picker v-model:value="queryForm.deliverydate" value-format="YYYY-MM-DD"
                            style="width:100%;" allow-clear />
                    </a-form-item>
                </a-col>
                <a-col :span="8">
                    <a-button type="primary" @click="getData">查询</a-button>
                </a-col>
            </a-row>
        </a-form>
    </div>
    <div class="content">
        <a-table :columns="columns" :data-source="data" bordered :loading="dateLoading" :scroll="{ x: width }"
            :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
            @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" size="small">
            <template #headerCell="{ column }">
                <template v-if="column.dataIndex === 'orderdate'">
                    <p>付款日期</p>
                    <p>发货日期</p>
                </template>
                <template v-if="column.dataIndex === 'orderid'">
                    <p>订单编号</p>
                    <p>交易单号</p>
                </template>
            </template>
            <template #bodyCell="{ column, record }">
                <template v-if="column.dataIndex === 'orderdate'">
                    <p>{{ record.purchase_time }}</p>
                    <p>{{ record.outbound_time }}</p>
                </template>
                <template v-if="column.dataIndex === 'orderid'">
                    <p>{{ record.orderid }}</p>
                    <p>{{ record.order_number }}</p>
                </template>
                <template v-if="column.dataIndex === 'shop'">
                    <p>{{ record.shop.name }}</p>
                </template>
                <template v-if="column.dataIndex === 'order_currency'">
                    <p>{{ record.channelbind }}</p>
                    <p>{{ record.tracking_number }}</p>
                </template>
                <template v-if="column.dataIndex === 'exchangerate'">
                    <p>{{ record.freight }}</p>
                    <p>{{ record.freight1 }}</p>
                </template>
                <template v-if="column.dataIndex === 'volume'">
                    <p>{{ record.fee_weight1 }}</p>
                    <p>{{ record.fee_weight2 }}</p>
                </template>
                <template v-if="column.dataIndex === 'order_state'">
                    <p v-if="record.order_state === '退件' || record.order_state === '拦截'" style="color: #f76260;">{{
                        record.order_state }}</p>
                </template>
            </template>
            <template #title>
                <div class="theader">
                    <span class="title">物流运费明细</span>
                    <!-- <a-button type="primary">导出物流运费明细</a-button> -->
                </div>
            </template>
        </a-table>
    </div>
</template>
<script>
import { DownOutlined } from '@ant-design/icons-vue';
import { message, Modal } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from 'vue';
import _ from "underscore";
const columns = [
    { title: '付款日期', dataIndex: 'orderdate', width: 120, align: 'center' },
    { title: '店铺', dataIndex: 'shop', width: 150, align: 'center' },
    { title: '订单编号', dataIndex: 'orderid', width: 150, align: 'center' },
    { title: '国家', dataIndex: 'shipping_country', width: 120, align: 'center' },
    { title: '物流渠道/物流单号', dataIndex: 'order_currency', width: 150, align: 'center' },
    { title: '预估运费/真实运费(元)', dataIndex: 'exchangerate', width: 150, align: 'center' },
    { title: '差异(费用/元)', dataIndex: 'dis_freight', width: 120, align: 'center' },
    { title: '自称重/物流称重(克)', dataIndex: 'volume', width: 150, align: 'center' },
    { title: '差异(重量/克)', dataIndex: 'dis_fee_weight', width: 120, align: 'center' },
    { title: '状态', dataIndex: 'order_state', width: 100, align: 'center' },
    // { title: '操作', dataIndex: 'operation', width: 100, align: 'center',fixed:'right' }
];
export default defineComponent({
    name: 'LogisticsSupplierFee',
    components: {
        DownOutlined
    },
    setup() {
        const { proxy } = getCurrentInstance();
        const state = reactive({
            queryForm: {
                number: "",
                shopid: undefined,
                country: null,
                purchasedate: [null, null],
                deliverydate: [null, null],
            },
            page: 1,
            limit: 10,
            data: [],
            length: 0,
            count: 0,
            dateLoading: false,
            shops: [],
            countrys: [],
        });
        const width = computed(() => proxy.$utils.twidth(columns));
        watch(() => state.page, () => getData());
        watch(() => state.limit, () => {
            if (state.page !== 1) state.page = 1;
            else getData();
        });
        onActivated(() => {
            getShops();
        });
        const getShops = () => {
            state.dateLoading = true;
            proxy.$api.doAPI("/product/shops", { platform: "", }).then((res) => {
                state.dateLoading = false;
                if (res.code === 0) {
                    state.shops = res.data.shops;
                    getData();
                } else {
                    message.error(res.msg);
                }
            }).catch((err) => {
                state.dateLoading = false;
                console.error(err);
            });
        }
        const getData = () => {
            state.dateLoading = true;
            proxy.$api.doAPI("/logistics/logisticsfreight", { page: state.page, limit: state.limit, queryForm: state.queryForm, }).then((res) => {
                state.dateLoading = false;
                if (res.code === 0) {
                    state.data = res.data.list;
                    state.length = res.data.length;
                    state.count = res.data.count;
                    if (state.length > 0 && state.page > state.length) state.page = 1;
                    state.countrys = _.map(res.data.countrys || [], n => ({ ...n, label: `${n.code} ${n.name}`, value: n.code }));
                } else {
                    message.error(res.msg);
                }
            }).catch((err) => {
                state.dateLoading = false;
                console.error(err);
            });
        };
        return {
            ...toRefs(state),
            columns,
            width,
            getData,
        }
    }
});
</script>
<style lang="less" scoped>
:deep(.ant-table-thead > tr > th) {
    padding: 8px 8px !important;
}

:deep(.ant-table-tbody > tr > td) {
    padding: 8px 8px !important;
}

.header {
    margin-bottom: 10px;
}

.theader {
    display: flex;
    justify-content: space-between;

    .title {
        font-weight: 600;
    }
}

a {
    text-decoration: underline;
}

p {
    margin: 0;
    padding: 0;
}
</style>