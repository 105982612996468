<template>
  <div class="d-flex align-center mb-2">
    <span>站&emsp;&emsp;点：</span>
    <a-tag v-for="item in sellingcenters" :key="item.value"
      :color="sellingcenterid === item.value ? 'processing' : 'default'" @click="changeSellingCenter(item.value)"
      class="click">{{ item.label }}</a-tag>
  </div>
  <div class="d-flex align-center mb-2">
    <span>菜单类型：</span>
    <a-radio-group v-model:value="isroot" @change="getData">
      <a-radio :value="-1">全部</a-radio>
      <a-radio :value="1">一级菜单</a-radio>
      <a-radio :value="0">子菜单</a-radio>
    </a-radio-group>
  </div>
  <div class="d-flex align-center mb-2">
    <span>搜索内容：</span>
    <a-radio-group v-model:value="searchtype">
      <a-radio :value="1">browseNodeId</a-radio>
    </a-radio-group>
    <a-input-search v-model:value="searchtext" enter-button="Search" style="width: 20%;min-width:300px;" allow-clear
      @search="getData" />
  </div>
  <a-table :columns="columns" :data-source="data" :scroll="{ x: width, y: 600 }" :loading="dataLoading"
    :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
    @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" size="small">
    <template #bodyCell="{ record, column }">
      <template v-if="column.dataIndex === 'browseNodeNameCN'">
        <a href="javascript:;"
          :class="{ 'text-info': record.browseNodeNameCN, 'text-gray': !record.browseNodeNameCN, 'text-error': record.isRoot }"
          @click="editName(record)">
          {{ record.browseNodeNameCN || '【无】' }}
        </a>
        <a-tooltip>
          <template #title>编辑菜单中文</template>
          <EditTwoTone @click="editName(record)" />
        </a-tooltip>
      </template>
      <template v-if="column.dataIndex === 'childNodes'">
        <a-tooltip>
          <template #title>
            {{ record.childNodes.join(',') }}
          </template>
          <p class="in1line">{{ record.childNodes.join(',') }}</p>
        </a-tooltip>
      </template>
      <template v-if="column.dataIndex === 'productTypeDefinitions'">
        <a-tooltip>
          <template #title>
            <div class="productTypeDefinitions">
              {{ record.productTypeDefinitions }}
            </div>
          </template>
          <p class="in1line">{{ record.productTypeDefinitions }}</p>
        </a-tooltip>
      </template>
      <template v-if="column.dataIndex === 'operation'">
        <a-space>

        </a-space>
      </template>
    </template>
  </a-table>
  <a-modal v-model:visible="nameModal" title="编辑菜单中文" @ok="saveName" @cancel="cancelSaveName" ok-text="提交"
    cancel-text="取消" :confirm-loading="saving" :cancel-button-props="{ disabled: saving }" :maskClosable="false"
    :closable="!saving">
    <a-form ref="nameFormRef" name="nameForm" :model="nameForm" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }"
      autocomplete="off">
      <a-form-item label="browseNodeId" name="browseNodeId">
        <a-input v-model:value="nameForm.browseNodeId" disabled />
      </a-form-item>
      <a-form-item label="browseNodeName" name="browseNodeName">
        <a-input v-model:value="nameForm.browseNodeName" disabled />
      </a-form-item>
      <a-form-item label="菜单中文" name="browseNodeNameCN" :rules="[{ required: true, message: '不能为空' }]">
        <a-input v-model:value="nameForm.browseNodeNameCN" placeholder="菜单中文" :disabled="saving" />
      </a-form-item>
      <a-form-item label="一级菜单" name="isRoot" :rules="[{ required: true, message: '不能为空' }]">
        <a-radio-group v-model:value="nameForm.isRoot">
          <a-radio :value="true">是</a-radio>
          <a-radio :value="false">否</a-radio>
        </a-radio-group>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script setup>
import { EditTwoTone } from '@ant-design/icons-vue';
import { message, Modal } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, onUnmounted, getCurrentInstance, } from "vue";
import _ from "underscore";
const columns = [
  { title: 'browse_path_id', dataIndex: 'browse_path_id', width: 120 },
  { title: 'browseNodeId', dataIndex: 'browseNodeId', width: 120 },
  { title: 'browseNodeName', dataIndex: 'browseNodeName', width: 120 },
  { title: '菜单中文', dataIndex: 'browseNodeNameCN', width: 120 },
  { title: '操作', dataIndex: 'operation', width: 150, fixed: 'right' },
];
const { proxy } = getCurrentInstance();
const nameFormRef = ref();
const sellingcenterid = ref('');
const sellingcenters = ref([]);
const searchtype = ref(1);
const searchtext = ref('');
const isroot = ref(-1);
const page = ref(1);
const limit = ref(10);
const data = ref([]);
const length = ref(0);
const count = ref(0);
const dataLoading = ref(false);
const nameForm = ref({});
const nameModal = ref(false);
const saving = ref(false);

const width = computed(() => proxy.$utils.twidth(columns));
watch(() => page.value, () => {
  getData();
});
watch(() => limit.value, () => {
  if (page.value !== 1) page.value = 1;
  else getData();
});
onActivated(() => {
  getData();
});
const getData = () => {
  dataLoading.value = true;
  return new Promise((resolve) => {
    proxy.$api.doAPI2("/dev/amazon/browsetree/list", { sellingcenterid: sellingcenterid.value, searchtype: searchtype.value, searchtext: searchtext.value, isroot: isroot.value, page: page.value, limit: limit.value }).then((res) => {
      dataLoading.value = false;
      if (res.code === 0) {
        data.value = res.data.list;
        length.value = res.data.length;
        count.value = res.data.count;
        if (length.value > 0 && page.value > length.value) page.value = 1;
        sellingcenterid.value = res.data.sellingcenterid;
        sellingcenters.value = res.data.sellingcenters;
      } else {
        message.error(res.msg);
      }
      resolve();
    }).catch((err) => {
      dataLoading.value = false;
      console.error(err);
      resolve();
    });
  });
};
const changeSellingCenter = (value) => {
  sellingcenterid.value = value;
  getData();
};
const editName = (item) => {
  nameForm.value = {
    _id: item._id,
    browseNodeId: item.browseNodeId,
    browseNodeName: item.browseNodeName,
    browseNodeNameCN: item.browseNodeNameCN,
    isRoot: item.isRoot,
  };
  nameModal.value = true;
};
const saveName = async () => {
  try {
    await nameFormRef.value.validateFields();
    saving.value = true;
    proxy.$api.doAPI2("/dev/amazon/browsetree/setnamecn", { nameForm: nameForm.value, }).then((res) => {
      saving.value = false;
      if (res.code === 0) {
        message.success("保存成功");
        nameModal.value = false;
        getData();
      } else {
        message.error(res.msg);
      }
    }).catch((err) => {
      saving.value = false;
      console.error(err);
    });
  } catch (errorInfo) {
    console.log("Failed:", errorInfo);
  }
}
const cancelSaveName = () => {
  nameFormRef.value.clearValidate();
}
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

.productTypeDefinitions {
  max-height: 200px;
  -ms-overflow-style: none;
  overflow: auto;
}

.productTypeDefinitions::-webkit-scrollbar {
  display: none;
}

p {
  margin: 0;
  padding: 0;
}
</style>
