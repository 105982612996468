<template>
  <a-tabs v-model:activeKey="activeKey">
    <a-tab-pane key="1" tab="单位换算">
      <unit-conversion />
    </a-tab-pane>
  </a-tabs>
</template>

<script setup>
import _ from "underscore";
import { ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from "vue";
import UnitConversion from './unit_conversion.vue';
const activeKey = ref('1');

</script>

<style></style>