<template>
  <a-tabs v-model:activeKey="rankname" @change="change_rankname">
    <a-tab-pane v-for="item in Rankings" :key="item.value" :tab="`${item.label}`">
    </a-tab-pane>
    <template #rightExtra>
      <a-space>
        <a-date-picker v-model:value="queryForm.reportDay" :locale="locale" value-format="YYYY-MM-DD"
          style="width:100%;" />
        <a-button type="primary" @click="getData"> 应用 </a-button>
      </a-space>
    </template>
  </a-tabs>
  <a-tabs v-model:activeKey="sort" tab-position="left" :style="{ height: '700px' }" @change="change_sort">
    <a-tab-pane v-for="item in Categorys" :key="item.value" :tab="`${item.label}`">
      <div style=" padding: 20px; height: 700px; overflow:auto">
        <a-card style="height: 700px">
          <a-card-grid style="width: 10%; text-align: center" v-for="item in data" :key="item._id">
            <div style="position: relative;">
              <span class="badge">
                No.{{ item.rank }}
                <!-- <span style="vertical-align: super;font-size: 8px;margin-left: -4px;">
                  <ArrowUpOutlined style="color: green;" />
                </span> -->
                <!-- <span style="vertical-align: sub;font-size: 8px;margin-left: -2px;">
                  <ArrowDownOutlined style="color: red;" />
                </span> -->
              </span>
            </div>
            <div class="text-center" style="width:80px">
              <a-image width="100%" :src="item.image" style="object-fit: contain;">
              </a-image>
            </div>
            <div style="flex:1;">
              <div class="d-flex flex-column">
                <a-tooltip placement="topLeft">
                  <template #title> {{ item.price }} </template>
                  <div class="in1line">
                    {{ item.price }}
                  </div>
                </a-tooltip>
                {{ item.asin }}
                <a-tooltip placement="topLeft">
                  <template #title> {{ item.name }} </template>
                  <div class="in1line">
                    <a :href="`https://www.amazon.com/dp/${item.asin}`" target="_blank">
                      {{ item.name }}
                    </a>
                  </div>
                </a-tooltip>
              </div>
            </div>
          </a-card-grid>
        </a-card>
      </div>
      <!-- <a-table bordered :columns="columns" :data-source="data" :scroll="{ x: width, y: 600 }" :loading="dataLoading"
        :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: false, showTotal: (total) => ``, }"
        @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" size="small">
        <template #bodyCell="{ record, column }">
          <template v-if="column.dataIndex === 'ranktype'">
            {{ GetRankName(record.ranktype) }}
          </template>
          <template v-if="column.dataIndex === 'category'">
            <a-tooltip>
              <template #title> {{ record.category }} </template>
              <div class="in1line"> {{ record.category }} </div>
            </a-tooltip>
          </template>
          <template v-if="column.dataIndex === 'image'">
            <a-image width="100%" height="100px" :src="record.image" style="object-fit: contain;height:100%;">
            </a-image>
          </template>
          <template v-if="column.dataIndex === 'clickedItemName'">
            <div class="d-flex flex-column">
              {{ record.asin }}
              <a-tooltip placement="topLeft">
                <template #title> {{ record.name }} </template>
                <div class="in1line">
                  <a :href="`https://www.amazon.com/dp/${record.asin}`" target="_blank">
                    {{ record.name }}
                  </a>
                </div>
              </a-tooltip>
            </div>
          </template>
          <template v-if="column.dataIndex === 'statistics_time'">
            {{ getDate(record.statistics_time) }}
          </template>
        </template>
      </a-table> -->
    </a-tab-pane>
  </a-tabs>
</template>
<script setup>
import { message, Modal } from "ant-design-vue";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from "vue";
import _ from "underscore";
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import utils from "@/utils";
const columns = [
  { title: '销售排名', dataIndex: 'rank', width: 40 },
  { title: '缩略图', dataIndex: 'image', width: 80 },
  { title: '商品名称', dataIndex: 'clickedItemName', width: 400 },
  { title: '价格', dataIndex: 'price', width: 60 },
  { title: '统计日期', dataIndex: 'statistics_time', width: 60, fixed: 'right' },
];
const { proxy } = getCurrentInstance();
const queryForm = ref({
  rankname: "BestSellers",
  reportPeriod: "DAY",
  sort: 0,
  reportDay: proxy.$utils.moment().format("YYYY-MM-DD"),
});
const reportPeriods = ref([
  { value: "DAY", label: "每日" }
]);
const rankname = ref("BestSellers");
const Rankings = ref([]);
const sort = ref(0);
const Categorys = ref([]);
const data = ref([]);
const dataLoading = ref(false);
onActivated(() => {
  init();
});
const change_rankname = () => {
  queryForm.value.rankname = rankname.value;
  Categorys.value = _.findWhere(Rankings.value, { value: rankname.value })?.Categorys || [];
  getData();
};
const change_sort = () => {
  queryForm.value.sort = sort.value;
  getData();
};
const init = () => {
  proxy.$api.doAPI("/bestseller/rankingslist",).then((res) => {
    if (res.code === 0) {
      Rankings.value = res.data.RankList;
      Categorys.value = res.data.RankList[0].Categorys;
      getData();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    console.error(err);
  });
};
const getData = () => {
  dataLoading.value = true;
  proxy.$api.doAPI("/bestseller/bestlist", { rankname: queryForm.value.rankname, statisticday: queryForm.value.reportDay, category: queryForm.value.sort, searchTerm: queryForm.value.searchTerm }).then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      data.value = res.data.list;
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
};
const getDate = (item) => {
  return utils.dateFormat(item, "YYYY-MM-DD")
};
const GetRankName = (value) => {
  return _.findWhere(Rankings.value, { value })?.label;
};
</script>

<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

p {
  margin: 0;
  padding: 0;
}

.badge {
  position: absolute;
  top: -23px;
  left: -23px;
  width: 70px;
  height: 20px;
  color: #ff7039;
  border-radius: -50%;
  text-align: left;
  line-height: 20px;
  font-size: 16px;
}
</style>
