import _ from 'underscore';
import { message } from "ant-design-vue";
import api from '../../request';
import utils from '../../utils';

const state = {
  type: '',
};
const mutations = {
  set_data(state, payload) {
    let keys = _.keys(payload);
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      if (typeof payload[key] !== 'undefined') state[key] = payload[key];
    }
  },
};
const actions = {
  setData({ commit }, payload) {
    commit('set_data', payload);
  },
};
const getters = {

};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
