<template>
  <a-tabs v-model:activeKey="group" size="large" tab-position="left" type="card" @change="getData">
    <a-tab-pane v-for="item in groups" :key="item" :tab="`${item}组`">
      <a-table size="small" bordered :columns="columns" :data-source="data" :loading="loading" :pagination="false">
        <template #title>
          <a-space>
            <a-button type="primary" :loading="loading" @click="getData">刷新</a-button>
            <a-input-search v-model:value="minutes" type="number">
              <template #enterButton>
                <a-button type="primary" danger :loading="loading" @click="cron">定时</a-button>
              </template>
            </a-input-search>
          </a-space>
        </template>
        <template #bodyCell="{ record, column }">
          <template v-if="column.dataIndex === 'running'">
            <a-tag v-if="record.running" color="#87d068">TRUE</a-tag>
            <a-tag v-else color="#f50">FALSE</a-tag>
          </template>
          <template v-if="column.dataIndex === 'last_time'">
            <a-tag color="#87d068">
              {{ $filters.dateFormat(record.last_time, 'YYYY-MM-DD HH:mm:ss') }}
            </a-tag>
            <a-tag color="green">
              {{ $filters.fromNow(record.last_time) }}
            </a-tag>
          </template>
          <template v-if="column.dataIndex === 'next_time'">
            <template v-if="record.next_time_t > Date.now()">
              <a-tag color="#f50">
                {{ $filters.dateFormat(record.next_time, 'YYYY-MM-DD HH:mm:ss') }}
              </a-tag>
              <a-tag color="red">
                {{ $filters.fromNow(record.next_time) }}
              </a-tag>
            </template>
            <span v-else>-</span>
          </template>
        </template>
      </a-table>
    </a-tab-pane>
  </a-tabs>
</template>

<script setup>
import { message } from "ant-design-vue";
import _ from "underscore";
import { ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from "vue";

const { proxy } = getCurrentInstance();

const groups = ref(["1", "2", "3", "4", "5", "6", "7", "z1", "z2"]);
const group = ref('1');
const minutes = ref(0);
const loading = ref(false);
const columns = ref([
  { title: 'ID', dataIndex: '_id' },
  { title: 'NAME', dataIndex: 'name' },
  { title: 'RUNNING', dataIndex: 'running' },
  { title: 'TIMES', dataIndex: 'times' },
  { title: 'LAST_TIME', dataIndex: 'last_time' },
  { title: 'NEXT_TIME', dataIndex: 'next_time' },
]);
const data = ref([]);

onActivated(() => {
  getData();
});

const getData = () => {
  loading.value = true;
  proxy.$api.doAPI2("/dev/amazon/cron/list", { group: group.value }).then((res) => {
    loading.value = false;
    if (res.code === 0) {
      data.value = res.data.list;
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    loading.value = false;
    console.error(err);
  });
}

const cron = () => {
  loading.value = true;
  proxy.$api.doAPI2("/dev/amazon/cron/change", { group: group.value, minutes: minutes.value }).then((res) => {
    loading.value = false;
    if (res.code === 0) {
      getData();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    loading.value = false;
    console.error(err);
  });
}

</script>

<style></style>