<template>
  <div class="d-flex align-center mb-2">
    <span>类型：</span>
    <a-tag :color="codetype === 0 ? 'processing' : 'default'" @click="changeCodeType(0)" class="click">
      全部
    </a-tag>
    <a-tag v-for="item in codetypes" :key="item" :color="codetype === item ? 'processing' : 'default'"
      @click="changeCodeType(item)" class="click">
      {{ item }}
    </a-tag>
  </div>
  <div class="d-flex justify-space-between align-center mb-2">
    <a-button type="primary" @click="getData">刷新</a-button>
    <a-space>
      <a-button class="btn-success" @click="addGroup">
        新增号码池
      </a-button>
    </a-space>
  </div>
  <a-table :columns="columns" :data-source="data" :scroll="{ x: width, y: 600 }" :loading="dataLoading"
    :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
    @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" row-key="_id" size="small">
    <template #bodyCell="{ record, column }">
      <template v-if="column.dataIndex === 'unused_count'">
        <a href="javascript:;" class="text-h7 text-success">
          {{ record.unused_count }}
        </a>
      </template>
      <template v-if="column.dataIndex === 'total_count'">
        <a href="javascript:;" class="text-h7 text-primary">
          {{ record.total_count }}
        </a>
      </template>
      <template v-if="column.dataIndex === 'operation'">
        <a-space>
          <a href="javascript:;" class="text-success" @click="editCodes(record)">
            管理号码池
          </a>
          <a href="javascript:;" class="text-primary" @click="editGroup(record)">
            编辑
          </a>
          <a-popconfirm title="删除该号码池" @confirm="removeGroup(record._id)">
            <a href="javascript:;" class="text-error">
              删除
            </a>
          </a-popconfirm>
        </a-space>
      </template>
    </template>
  </a-table>
  <a-modal v-model:visible="groupModal" :title="`${groupForm._id === '' ? '新建' : '编辑'}号码池`" @ok="saveGroup"
    @cancel="cancelSaveGroup" ok-text="确定" cancel-text="取消" :confirm-loading="saving"
    :cancel-button-props="{ disabled: saving }" :maskClosable="false" :closable="!saving" width="600px">
    <a-form ref="groupFormRef" name="groupForm" :model="groupForm" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }"
      autocomplete="off">
      <a-form-item label="名称" name="name" :rules="[{ required: true, message: '请填写号码池名称' }]">
        <a-input v-model:value="groupForm.name" :disabled="saving" />
      </a-form-item>
      <a-form-item label="类型" name="type">
        <a-radio-group v-if="groupForm._id === ''" v-model:value="groupForm.type" :options="codetypes" />
        <span v-else>{{ groupForm.type }}</span>
      </a-form-item>
      <a-form-item v-if="groupForm._id === ''" label="号码池" name="codes">
        <a-textarea v-model:value="groupForm.codes" placeholder="请输入号码，可一行一个号码批量添加"
          :auto-size="{ minRows: 6, maxRows: 6 }" />
      </a-form-item>
    </a-form>
    <a-row>
      <a-col :span="4" :offset="4">
        <a-button v-if="groupForm._id === '' && (groupForm.type === 'UPC' || groupForm.type === 'EAN')" type="primary"
          size="middle" @click="autogenCode(groupForm.type)">自动生成</a-button>
      </a-col>
      <a-col>
        <a-input-number v-if="groupForm._id === '' && (groupForm.type === 'UPC' || groupForm.type === 'EAN')"
          id="inputNumber" v-model:value="autogenCodesize" :min="1" style="width: 60px !important" />
      </a-col>
    </a-row>
  </a-modal>
  <a-modal v-model:visible="codeModal" title="管理号码池" @cancel="() => codeModal = false" :maskClosable="false"
    :closable="!saving" width="600px">
    <a-row :gutter="16">
      <a-col :span="8">号码池：{{ codeForm.name }}</a-col>
      <a-col :span="6">类型：{{ codeForm.type }}</a-col>
      <a-col :span="5" class="text-success text-h7">
        未使用：{{ codeForm.unused_codes.length }}
      </a-col>
      <a-col :span="5" class="text-error text-h7">
        已使用：{{ codeForm.used_codes.length }}
      </a-col>
    </a-row>
    <a-tabs v-model:activeKey="codestate">
      <a-tab-pane key="unused" tab="未使用">
        <a-textarea v-model:value="codeForm.unused_codes_text" placeholder="请输入号码，可一行一个号码批量添加"
          :auto-size="{ minRows: 10, maxRows: 10 }" />
      </a-tab-pane>
      <a-tab-pane key="used" tab="已使用">
        <a-textarea v-model:value="codeForm.used_codes_text" :auto-size="{ minRows: 10, maxRows: 10 }" disabled />
      </a-tab-pane>
    </a-tabs>
    <template #footer>
      <div class="d-flex justify-end align-center">
        <a-space>
          <a-button v-if="codestate === 'unused'" class="btn-primary" @click="saveCode">保存</a-button>
          <a-button @click="() => codeModal = false">取消</a-button>
        </a-space>
      </div>
    </template>
  </a-modal>
</template>
<script setup>
import { DownOutlined, SearchOutlined, PlusCircleOutlined, EyeOutlined } from '@ant-design/icons-vue';
import { message, Modal } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from "vue";
import _ from "underscore";
const columns = [
  { title: '号码池名称', dataIndex: 'name', width: 120, },
  { title: '号码池类型', dataIndex: 'type', width: 120, },
  { title: '可用数量', dataIndex: 'unused_count', width: 100, },
  { title: '总数量', dataIndex: 'total_count', width: 100, },
  { title: '更新时间', dataIndex: 'update_time', width: 180, },
  { title: '修改人', dataIndex: 'update_by', width: 100, },
  { title: '操作', dataIndex: 'operation', width: 150 },
];
const { proxy } = getCurrentInstance();
const groupFormRef = ref();
const codetype = ref(0);
const codetypes = ["UPC", "EAN", "ISBN", "GTIN", "其他"];
const page = ref(1);
const limit = ref(10);
const data = ref([]);
const length = ref(0);
const count = ref(0);
const dataLoading = ref(false);
const groupForm = ref({});
const groupModal = ref(false);
const saving = ref(false);
const codeForm = ref({
  unused_codes: [],
  unused_codes_text: "",
  used_codes: [],
  used_codes_text: "",
});
const codestate = ref("unused");
const codeModal = ref(false);
const autogenCodesize = ref(1);

const width = computed(() => proxy.$utils.twidth(columns));
watch(() => page.value, () => {
  getData();
});
watch(() => limit.value, () => {
  if (page.value !== 1) page.value = 1;
  else getData();
});
onActivated(() => {
  getData();
});
const getData = () => {
  dataLoading.value = true;
  return new Promise((resolve) => {
    proxy.$api.doAPI("/listingidentifier/groups", { codetype: codetype.value, page: page.value, limit: limit.value }).then((res) => {
      dataLoading.value = false;
      if (res.code === 0) {
        data.value = _.map(res.data.list, n => {
          return {
            ...n,
            total_count: n.unused_count + n.used_count,
            update_time: proxy.$utils.dateFormat(n.update_time, 'YYYY-MM-DD HH:mm:ss'),
          };
        });
        length.value = res.data.length;
        count.value = res.data.count;
        if (length.value > 0 && page.value > length.value) page.value = 1;
      } else {
        message.error(res.msg);
      }
      resolve();
    }).catch((err) => {
      dataLoading.value = false;
      console.error(err);
      resolve();
    });
  });
};
const addGroup = () => {
  groupForm.value = {
    _id: "", name: "", type: "UPC", codes: ""
  };
  groupModal.value = true;
}
const editGroup = (item) => {
  groupForm.value = {
    _id: item._id,
    name: item.name,
    type: item.type,
  };
  groupModal.value = true;
}
const saveGroup = async () => {
  try {
    await groupFormRef.value.validateFields();
    saving.value = true;
    proxy.$api.doAPI("/listingidentifier/savegroup", { groupForm: groupForm.value }).then((res) => {
      saving.value = false;
      if (res.code === 0) {
        groupModal.value = false;
        getData();
      } else {
        message.error(res.msg);
      }
    }).catch((err) => {
      saving.value = false;
      console.error(err);
    });
  } catch (errorInfo) {
    console.log("Failed:", errorInfo);
    message.error(_.reduce(errorInfo.errorFields, (a, b) => a.concat(b.errors), []).join('；'));
  }
}
const cancelSaveGroup = () => {
  groupFormRef.value.clearValidate();
  groupModal.value = false;
  autogenCodesize.value = 1;
}
const removeGroup = (_id) => {
  dataLoading.value = true;
  proxy.$api.doAPI("/listingidentifier/removegroup", { _id }).then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      getData();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
}
const editCodes = (item) => {
  dataLoading.value = true;
  proxy.$api.doAPI("/listingidentifier/codes", { groupid: item._id }).then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      let unused_codes = _.pluck(_.where(res.data.list, { state: "unused" }), "code");
      let used_codes = _.pluck(_.where(res.data.list, { state: "used" }), "code");
      codeForm.value = {
        groupid: item._id,
        name: item.name,
        type: item.type,
        unused_codes,
        unused_codes_text: unused_codes.join('\n'),
        used_codes,
        used_codes_text: used_codes.join('\n'),
        codes: unused_codes.concat(used_codes),
      };
      codeModal.value = true;
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
}
const saveCode = () => {
  let unused_codes = codeForm.value.unused_codes_text.split('\n');
  let new_codes = _.filter(_.uniq(_.without(unused_codes, ...codeForm.value.unused_codes)), n => !!n);
  console.log('new_codes', new_codes);
  let remove_codes = _.filter(_.uniq(_.without(codeForm.value.unused_codes, ...unused_codes)), n => !!n);
  console.log('remove_codes', remove_codes);
  if (new_codes.length === 0 && remove_codes.length === 0) return;
  dataLoading.value = true;
  proxy.$api.doAPI("/listingidentifier/savecodes", { groupid: codeForm.value.groupid, remove_codes, new_codes }).then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      codeModal.value = false;
      getData();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
}
const changeCodeType = (value) => {
  codetype.value = value;
  getData();
}
//自动生成
const autogenCode = async (type) => {
  try {
    proxy.$api.doAPI("/listingidentifier/autogencodes", { size: autogenCodesize.value, type }).then((res) => {
      if (res.code === 0) {
        let data = res.data;
        groupForm.value.codes = data.join('\n');
      } else {
        message.error(res.msg);
      }
    }).catch((err) => {
      console.error(err);
    });
  } catch (error) {
    console.log("Failed:", error);
    message.error(_.reduce(error.errorFields, (a, b) => a.concat(b.errors), []).join('；'));
  }
};
</script>

<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

p {
  margin: 0;
  padding: 0;
}

.text-warning {
  color: #e6a23c !important;
}
</style>
