<template>
  <router-view v-slot="{ Component, route }">
    <keep-alive>
      <component :key="route.name" :is="Component" />
    </keep-alive>
  </router-view>
</template>
<script setup>

</script>
