<template>
  <div class="header">
    <div class="type">
      所在平台：<a-dropdown>
        <template #overlay>
          <a-menu @click="handleMenuClick">
            <a-menu-item key="1">亚马逊</a-menu-item>
            <a-menu-item key="2">16688</a-menu-item>
          </a-menu>
        </template>
        <a-button>
          亚马逊
          <DownOutlined />
        </a-button>
      </a-dropdown>
    </div>
    <div class="search">
      <span style="width:80px;">查询搜索：</span>
      <a-input-search v-model:value="value" placeholder="商品名称或SKU" enter-button="搜索" @search="onSearch" />
    </div>
    <div class="type">
      匹配状态：
      <a-radio-group v-model:value="matchstate">
        <a-radio-button value="all">全部</a-radio-button>
        <a-radio-button value="0">已匹配</a-radio-button>
        <a-radio-button value="1">未匹配</a-radio-button>
      </a-radio-group>
    </div>
    <div class="op">
      <a-button type="primary">批量申请采购</a-button>
    </div>
  </div>
  <a-table :columns="columns" bordered :data-source="data" :scroll="{ x: width }" size="small">
    <template #bodyCell="{ column, record }">
      <template v-if="column.dataIndex === 'cover'">
        <a-image :width="80" src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png" />
      </template>
      <template v-if="column.dataIndex === 'info'">
        <a href="javascript:;">{{ record.sku }}</a>
        <p>{{ record.name_cn }}</p>
        <p>{{ record.code }}</p>
      </template>
      <template v-if="column.dataIndex === 'neednum'">
        <a-input-number v-model:value="record.neednum" :min="1" :max="100000" />
      </template>
      <template v-if="column.dataIndex === 'OrderID'">
        <a href="javascript:;">{{ record.OrderID }}</a>
      </template>
      <template v-if="column.dataIndex === 'state'">
        <a-tag color="green">已匹配</a-tag>
      </template>
      <template v-if="column.dataIndex === 'operation'">
        <a-space>
          <a href="javascript:;">申请采购</a>
        </a-space>
      </template>
    </template>
  </a-table>
</template>
<script>
import { DownOutlined } from '@ant-design/icons-vue';
import { defineComponent, toRefs, reactive } from 'vue';
import _ from "underscore";
const columns = [
  {
    title: '订单号',
    dataIndex: 'OrderID',
    key: 'OrderID',
    width: 150,
    align: 'center',
  },
  {
    title: '商品图片',
    dataIndex: 'cover',
    key: 'cover',
    width: 120,
    align: 'center',
  },
  {
    title: '商品信息',
    dataIndex: 'info',
    key: 'info',
    width: 180,
  },
  {
    title: '单价(CNY)',
    dataIndex: 'price',
    key: 'price',
    width: 150,
    align: 'center'
  },
  {
    title: '缺货数量',
    dataIndex: 'neednum',
    key: 'neednum',
    width: 150,
    align: 'center'
  },
  {
    title: '状态',
    dataIndex: 'state',
    key: 'state',
    width: 80,
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation',
    width: 120,
    fixed: 'right',
    align: 'center'
  },
];
const data = [];
for (let i = 0; i < 46; i++) {
  data.push({
    key: i,
    cover: `url ${i}`,
    name_cn: '小浣熊加湿器',
    sku: 'kw-weqewiqeqw',
    code: '23444332',
    OrderID: '321321321',
    price: '25.9',
    neednum: 10,
  });
}
export default defineComponent({
  name: 'PurchaseManageMatch',
  components: {
    DownOutlined
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const state = reactive({
      matchstate: 'all',
      data,
    });
    const width = computed(() => proxy.$utils.twidth(columns));
    return {
      ...toRefs(state),
      columns,
      width,
    };
  }
});
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

.header {
  margin-bottom: 10px;

  .search {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }

  .type {
    margin: 10px 0;
  }

  .op {
    display: flex;
    justify-content: space-between;
  }
}

p {
  margin: 0;
  padding: 0;
}
</style>
