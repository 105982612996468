<template>
  <div class="header">
    <a-row :gutter="24">
      <a-col :span="6">
        <a-form-item label="选择店铺">
          <a-select v-model:value="shopid" placeholder="请选择店铺" mode="tags" :options="shops"></a-select>
        </a-form-item>
      </a-col>
      <a-col :span="6">
        <a-form-item label="选择日期">
          <a-range-picker v-model:value="daterange" value-format="YYYY-MM-DD" :allowClear="false" />
        </a-form-item>
      </a-col>
      <a-col :span="6">
        <a-button type="primary" @click="getData">查询</a-button>
      </a-col>
    </a-row>
    <div class="review-header">
      <a-row>
        <a-col :span="4">
          <a-statistic title="客户数" :value="obj.client_num" />
        </a-col>
        <a-col :span="4">
          <a-statistic title="订单数" :value="obj.order_num" />
        </a-col>
        <a-col :span="4">
          <a-statistic title="订单总金额" :precision="2" :value="obj.tot_amounts" />
        </a-col>
        <a-col :span="4">
          <a-statistic title="退款金额" :precision="2" :value="obj.refund_amounts" />
        </a-col>
        <a-col :span="4">
          <a-statistic title="客单价" :precision="2" :value="obj.per_customers" />
        </a-col>
      </a-row>
    </div>
  </div>
  <a-table :columns="columns" :data-source="data" bordered :scroll="{ x: width }" size="small">
    <template #bodyCell="{ column, record }">
      <template v-if="column.dataIndex === 'shop'">
        <p>{{ record.shop?.name || '-' }}</p>
      </template>
    </template>
  </a-table>
</template>
<script>
import { DownOutlined, DownloadOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from 'vue';
import _ from "underscore";
const columns = [
  { title: '店铺', dataIndex: 'name', width: 180, align: 'center', },
  { title: '客户数', dataIndex: 'client_count', width: 120, align: 'center', },
  { title: '订单数', dataIndex: 'order_count', width: 120, align: 'center', },
  { title: '订单总金额', dataIndex: 'tot_amount', width: 150, align: 'center', },
  { title: '退款订单数', dataIndex: 'refund_count', width: 150, align: 'center', },
  { title: '退款金额', dataIndex: 'refund_amount', width: 150, align: 'center', },
  { title: '客单价', dataIndex: 'per_customer', width: 120, align: 'center', },
  { title: '操作', dataIndex: 'action', width: 100, align: 'center', },
];
export default defineComponent({
  name: 'OperationAnalysisShops',//店铺表现
  components: {
    DownOutlined,
    DownloadOutlined
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const state = reactive({
      data: [],
      daterange: [],
      shopid: [],
      shops: [],
      obj: {
        client_num: 0,
        order_num: 0,
        pay_amounts: 0,
        tot_amounts: 0,
        refund_amounts: 0,
        per_customers: 0
      },
    });
    const width = computed(() => proxy.$utils.twidth(columns));
    onMounted(() => {
      let beforeDay = proxy.$utils.moment().subtract(0, "days").format('YYYY-MM-DD')
      let before7Day = proxy.$utils.moment().subtract(6, "days").format('YYYY-MM-DD')
      state.daterange = [];
      state.daterange.push(before7Day);
      state.daterange.push(beforeDay);
      getShops();
    });
    const getShops = () => {
      state.dateLoading = true;
      proxy.$api.doAPI("/product/shops", { platform: "", }).then((res) => {
        state.dateLoading = false;
        if (res.code === 0) {
          state.shops = res.data.shops;
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dateLoading = false;
        console.error(err);
      });
    }
    const getData = () => {
      proxy.$api.doAPI("/finance/dataanalysis/storeper", { daterange: state.daterange, shopid: state.shopid, }).then((res) => {
        if (res.code === 0) {
          state.data = res.data.list;
          state.obj = {
            client_num: res.data.client_num,
            order_num: res.data.order_num,
            pay_amounts: res.data.pay_amounts,
            tot_amounts: res.data.tot_amounts,
            refund_amounts: res.data.refund_amounts,
            per_customers: res.data.per_customers
          }
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        console.error(err);
      });
    };

    const handleChange = (tag, checked) => {
      const { selectedTags } = state;
      const nextSelectedTags = checked
        ? [...selectedTags, tag]
        : selectedTags.filter(t => t !== tag);
      console.log('You are interested in: ', nextSelectedTags);
      state.selectedTags = nextSelectedTags;
    };
    return {
      ...toRefs(state),
      columns,
      width,
      getData,
      handleChange,
    };
  }
});
</script>
<style lang="less" scoped>
.review-header {
  padding: 24px;
}

:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

.header {
  .search {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }

  .op {
    display: flex;
    justify-content: space-between;
  }
}

p {
  margin: 0;
  padding: 0;
}
</style>

