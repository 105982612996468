<template>
  <a-button type="primary" @click="getData">刷新数据</a-button>
  <a-table :columns="columns" bordered :data-source="data" :loading="dataLoading" :scroll="{ x: width, y: height }"
    :pagination="{ showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }" size="small">
    <template #bodyCell="{ record, column }">
      <template v-if="column.dataIndex === 'asin'">
        <a :href="`${record.amazon_product_url}${record.asin}`" target="_blank" class="underline">
          {{ record.asin }}
        </a>
      </template>
      <template v-if="column.dataIndex === 'sellerurl'">
        <a :href="record.sellerurl" target="_blank" class="underline">
          {{ record.sellerId }}
        </a>
      </template>
    </template>
  </a-table>
</template>
<script setup>
import { DownOutlined } from '@ant-design/icons-vue';
import { message } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from "vue";
import _ from "underscore";
const columns = [
  { title: 'ASIN', dataIndex: 'asin', width: 120 },
  { title: '店铺名称', dataIndex: 'shopname', width: 200 },
  { title: '跟卖店铺', dataIndex: 'sellerurl', width: 100 },
  { title: '跟卖时间', dataIndex: 'time', width: 150 },
];
const { proxy } = getCurrentInstance();

const dataLoading = ref(false);
const data = ref([]);
const height = ref(600);

const width = computed(() => proxy.$utils.twidth(columns));

onActivated(() => {
  getData();
});
const getData = () => {
  dataLoading.value = true;
  proxy.$api.doAPI("/product/genmai").then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      data.value = _.map(res.data.list, n => {
        return {
          ...n,
          time: proxy.$utils.dateFormat(n.offer_change_time, 'YYYY-MM-DD HH:mm:ss'),
        }
      });
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
};

</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

p {
  margin: 0;
  padding: 0;
}
</style>
