<template>
  <div class="d-flex align-center mb-2">
    <span style="width:75px;">所在仓库：</span>
    <a-dropdown>
      <template #overlay>
        <a-menu @click="handleMenuClick">
          <a-menu-item key="1">默认仓库</a-menu-item>
          <a-menu-item key="2">二里岗仓库</a-menu-item>
        </a-menu>
      </template>
      <a-button>
        默认仓库
        <DownOutlined />
      </a-button>
    </a-dropdown>
  </div>
  <div class="d-flex align-center mb-2">
    <span style="width:75px;">盘点日期：</span>
    <a-range-picker v-model:value="daterange" :locale="locale" value-format="YYYY-MM-DD" />
  </div>
  <div class="d-flex align-center mb-2">
    <span style="width:80px;">查询搜索：</span>
    <a-input-search v-model:value="value" placeholder="商品名称/编号查询" enter-button="搜索" @search="onSearch" />
  </div>
  <div class="d-flex align-center mb-2">
    <a-button type="primary">导出库存盘点信息</a-button>
  </div>
  <a-table :columns="columns" :data-source="data" :scroll="{ x: width }" size="small">
    <template #bodyCell="{ column, record }">
      <template v-if="column.dataIndex === 'operation'">
        <a-space>
          <a href="javascript:;">编辑</a>
          <a-popconfirm v-if="data.length" title="确定要删除?" @confirm="onDelete(record.key)">
            <a href="javascript:;" class="text-error">删除</a>
          </a-popconfirm>
        </a-space>
      </template>
      <template v-else-if="column.dataIndex === 'mark'">
        <span>
          <a-tag :color="record.state === '0' ? 'green' : 'red'">
            {{ record.mark }}
          </a-tag>
        </span>
      </template>
    </template>
  </a-table>
</template>
<script>
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import { DownOutlined, SearchOutlined, PlusCircleOutlined } from '@ant-design/icons-vue';
import { message, Modal } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from 'vue';
import _ from "underscore";
const columns = [
  { title: '商品名称', dataIndex: 'shopname', width: 100, align: 'center' },
  { title: '商品编码', dataIndex: 'shopcode', width: 100, align: 'center' },
  { title: '仓库', dataIndex: 'store', width: 100, align: 'center' },
  { title: '商品规格', dataIndex: 'shopsize', width: 100, align: 'center' },
  { title: '单位', dataIndex: 'unit', width: 100, align: 'center' },
  { title: '价格', dataIndex: 'price', width: 100, align: 'center' },
  {
    title: '数量',
    children: [
      { title: '显示数', dataIndex: 'count1', key: 'count1', width: 80, align: 'center' },
      { title: '实盘数', dataIndex: 'count2', key: 'count2', width: 80, align: 'center' },
      { title: '差异数', dataIndex: 'count3', key: 'count3', width: 80, align: 'center' }
    ]
  },
  { title: '备注', dataIndex: 'mark', width: 100, align: 'center' },
  { title: '创建时间', dataIndex: 'createtime', width: 150, align: 'center' },
  { title: '操作', dataIndex: 'operation', width: 100, fixed: 'right' },
];
const data = [];
for (let i = 0; i < 5; i++) {
  data.push({
    index: i + 1,
    store: '二里岗仓库',
    shopname: '衣服' + i,
    shopcode: '328828' + i,
    shopsize: '',
    unit: '个',
    price: '89.8',
    count1: '578',
    count2: '577',
    count3: '0',
    mark: '盘亏',
    state: '1',
    createtime: '2022-11-03 10:23:21'
  });
}
export default defineComponent({
  name: 'InventoryManageInventoryage',
  components: {
    DownOutlined,
    SearchOutlined,
    PlusCircleOutlined
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const state = reactive({
      data,
      daterange: ["", ""],
    })
    const width = computed(() => proxy.$utils.twidth(columns));
    return {
      ...toRefs(state),
      locale,
      columns,
      width,
    };
  }
});
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

p {
  margin: 0;
  padding: 0;
}
</style>
