<template>
  <div class="header">
    <div class="search">
      <a-row>
        <a-col>
          <span style="width:75px;">选择站点：</span>
        </a-col>
        <a-col>
          <div>
            <a-tag :color="station === 0 ? 'processing' : 'default'" @click="changeStation(0)" class="click" >全部</a-tag>
            <a-tag v-for="item in stations" :key="item.value" :color="station === item.value ? 'processing' : 'default'" @click="changeStation(item.value)" class="click">{{ item.label }}</a-tag>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="search">
      <a-row>
        <a-col><span style="width:75px;">选择店铺：</span></a-col>
        <a-col :span="22">
          <div>
            <a-select v-model:value="shopid" placeholder="请选择店铺"  mode="tags" :options="shops" style="width: 1000px" @change="(v) => changeShop(v)"></a-select>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="search">
      <span style="width:75px;">查询搜索：</span>
      <a-input-search v-model:value="searchText" placeholder="输入关键词搜索" enter-button="搜索" @search="search" />
    </div>
    <a-tabs v-model:activeKey="activeKey" type="card" @change="handclick">
      <a-tab-pane :key="1" tab="关键词列表">
        <a-table :columns="columns" :data-source="data" :scroll="{ x: width }" :loading="dataLoading"
          :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
          @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" size="small">
          <template #bodyCell="{ record, column }">
            <template v-if="column.dataIndex === 'clickThroughRate'">
              <p>{{record.clickThroughRate}}%</p>
            </template>
            <template v-if="column.dataIndex === 'cost'">
              <p>{{record.countryCode}}${{record.cost}}</p>
            </template>
            <template v-if="column.dataIndex === 'shop'">
              <p>{{record.shop?.name}}</p>
            </template>
            <template v-if="column.dataIndex === 'cpc'">
              <p v-if="record.cpc !== undefined && record.cpc !== '-'">{{record.countryCode}}${{record.cpc}}</p>
            </template>
            <template v-if="column.dataIndex === 'sales'">
              <p v-if="days === -1">{{record.countryCode}}${{record.sales1d}}</p>
              <p v-if="days === 7">{{record.countryCode}}${{record.sales7d}}</p>
              <p v-if="days === 14">{{record.countryCode}}${{record.sales14d}}</p>
              <p v-if="days === 30">{{record.countryCode}}${{record.sales30d}}</p>
            </template>
            <template v-if="column.dataIndex === 'acos'">
              <span v-if="days === -1">{{record.acos1d}}<span v-if="record.acos1d !== '-'">%</span></span>
              <span v-if="days === 7">{{record.acos7d}}<span v-if="record.acos7d !== '-'">%</span></span>
              <span v-if="days === 14">{{record.acos14d}}<span v-if="record.acos14d !== '-'">%</span></span>
              <span v-if="days === 30">{{record.acos30d}}<span v-if="record.acos30d !== '-'">%</span></span>
            </template>
            <template v-if="column.dataIndex === 'purchasesNum'">
              <p v-if="days === -1">{{record.purchases1d}}</p>
              <p v-if="days === 7">{{record.purchases7d}}</p>
              <p v-if="days === 14">{{record.purchases14d}}</p>
              <p v-if="days === 30">{{record.purchases30d}}</p>
            </template>
            <template v-if="column.dataIndex === 'purchasesSameSkuNum'">
              <p v-if="days === -1">{{record.purchasesSameSku1d}}</p>
              <p v-if="days === 7">{{record.purchasesSameSku7d}}</p>
              <p v-if="days === 14">{{record.purchasesSameSku14d}}</p>
              <p v-if="days === 30">{{record.purchasesSameSku30d}}</p>
            </template>
            <template v-if="column.dataIndex === 'conversionRate'">
              <p v-if="days === -1">{{record.conversionRate1d}}%</p>
              <p v-if="days === 7">{{record.conversionRate7d}}%</p>
              <p v-if="days === 14">{{record.conversionRate14d}}%</p>
              <p v-if="days === 30">{{record.conversionRate30d}}%</p>
            </template>
          </template>
        </a-table>
      </a-tab-pane>
      <a-tab-pane :key="2" tab="关键词分析">
        <a-card>
          <a-row>
            <a-col :span="10" class="hitem">
              <div class="chart">
                <apexchart ref="chartOptions" type="bar" width="500" height="500" :options="chartOptions" :series="series"></apexchart>
              </div>
            </a-col>
            <a-col :span="2" class="hitem">
            </a-col>
            <a-col :span="10" class="hitem">
              <div class="chart">
                <a-table :columns="columns1" :data-source="data1" :scroll="{ x: width1, y: 300 }" :loading="dataLoading1"
                :pagination="{ current: page1, pageSize: limit1, total: count1, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
                @change="(pagination) => { page1 = pagination.current; limit1 = pagination.pageSize; }" size="small" />
              </div>
            </a-col>
          </a-row>
        </a-card>
        <a-card>
          <a-row>
            <a-col :span="10" class="hitem">
              <div class="chart">
                <apexchart ref="chartOptions1" type="bar" width="500" height="500" :options="chartOptions1" :series="series1"></apexchart>
              </div>
            </a-col>
            <a-col :span="2" class="hitem">
            </a-col>
            <a-col :span="10" class="hitem">
              <div class="chart">
                <a-table :columns="columns2" :data-source="data1" :scroll="{ x: width2, y: 300  }" :loading="dataLoading2"
                :pagination="{ current: page1, pageSize: limit1, total: count1, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
                @change="(pagination) => { page1 = pagination.current; limit1 = pagination.pageSize; }" size="small" />
              </div>
            </a-col>
          </a-row>
        </a-card>
        <a-card>
          <a-row>
            <a-col :span="10" class="hitem">
              <div class="chart">
                <apexchart ref="chartOptions2" type="bar" width="500" height="500" :options="chartOptions2" :series="series2"></apexchart>
              </div>
            </a-col>
            <a-col :span="2" class="hitem"/>
            <a-col :span="10" class="hitem">
              <div class="chart">
                <a-table :columns="columns3" :data-source="data1" :scroll="{ x: width3, y: 300  }" :loading="dataLoading3"
                :pagination="{ current: page1, pageSize: limit1, total: count1, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
                @change="(pagination) => { page1 = pagination.current; limit1 = pagination.pageSize; }" size="small" />
              </div>
            </a-col>
          </a-row>
        </a-card>
      </a-tab-pane>
      <template #rightExtra>
        <a-radio-group v-model:value="days" @change="getDatas">
          <a-radio-button :value="-1" >昨天</a-radio-button>
          <a-radio-button :value="7"  >近7天</a-radio-button>
          <a-radio-button :value="14" >近14天</a-radio-button>
          <a-radio-button :value="30" >近30天</a-radio-button>
        </a-radio-group>
      </template>
    </a-tabs>
  </div>
</template>
<script>
import { DownOutlined } from '@ant-design/icons-vue';
import { message, Modal } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from 'vue';
import _ from "underscore";
const columns = [
  { title: '关键词', dataIndex: 'keyword', width: 150, align: 'center', },
  { title: '搜索词', dataIndex: 'searchTerm', width: 150, align: 'center', },
  { title: '广告组', dataIndex: 'adgroup', width: 150, align: 'center', },
  { title: '店铺', dataIndex: 'shop', width: 150, align: 'center', },
  { title: '单次点击成本(CPC)', dataIndex: 'cpc', width: 100, align: 'center', },
  {
    title: '金额',
    children: [
      { title: '广告花费', dataIndex: 'cost', key: 'cost', width: 120, align: 'center' },
      { title: '销售额', dataIndex: 'sales', key: 'sales', width: 100, align: 'center' },
      { title: '广告投入产出比(ACoS)', dataIndex: 'acos', key: 'acos', width: 120, align: 'center' }
    ]
  },
  {
    title: '转化',
    children: [
      { title: '曝光量', dataIndex: 'impressions', key: 'impressions', width: 120, align: 'center' },
      { title: '点击量', dataIndex: 'clicks', key: 'freight', width: 100, align: 'center' },
      { title: '订单量', dataIndex: 'purchasesNum', key: 'purchasesNum', width: 120, align: 'center' },
      { title: '销售量', dataIndex: 'purchasesSameSkuNum', key: 'purchasesSameSkuNum', width: 120, align: 'center' },
      { title: '点击率', dataIndex: 'clickThroughRate', key: 'clickThroughRate', width: 120, align: 'center' },
      { title: '订单转化率', dataIndex: 'conversionRate', key: 'conversionRate', width: 120, align: 'center' }
    ]
  },
];
const columns1 = [
    { title: '关键词', dataIndex: 'keyword', width: 150, align: 'center', },
    { title: '曝光量', dataIndex: 'impressions', width: 150, align: 'center', },
    { title: '点击量', dataIndex: 'clicks', width: 150, align: 'center', },
    { title: '点击率', dataIndex: 'clickThroughRate', width: 150, align: 'center', },
];
const columns2 = [
    { title: '关键词', dataIndex: 'keyword', width: 150, align: 'center', },
    { title: '订单量', dataIndex: 'purchasesSameSku', width: 150, align: 'center', },
    { title: '点击量', dataIndex: 'clicks', width: 150, align: 'center', },
    { title: '转化率', dataIndex: 'conversionRate', width: 150, align: 'center', },
];
const columns3 = [
    { title: '关键词', dataIndex: 'keyword', width: 150, align: 'center', },
    { title: '成本', dataIndex: 'cost', width: 150, align: 'center', },
    { title: '销售额', dataIndex: 'sales', width: 150, align: 'center', },
    { title: '投入产出比', dataIndex: 'aCoS', width: 150, align: 'center', },
];

export default defineComponent({
  name: 'ShopsAdvertisingKeyword',
  components: {
    DownOutlined
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const state = reactive({
      dataLoading: false,
      dataLoading1: false,
      dataLoading2: false,
      dataLoading3: false,
      activeKey: 1,
      page: 1,
      limit: 10,
      length: 0,
      count: 0,
      data: [],
      searchText: "",
      shop: 0,
      shopid: undefined,
      shops: [],
      station: 0,
      days: 7,
      stations: [
        { label: "北美", value: "NA", },
        { label: "欧洲", value: "EU" },
        { label: "远东", value: "FE" },
      ],
      profileId: "",
      campaignId: "",
      daterange: [],
      categories:[],
      clickRateData: [],
      clickData: [],
      exposureData: [],
      purchases: [],
      conversionRateData: [],
      aCosCostData: [],
      aCoSSalesData: [],
      aCoSData: [],
      page1: 1,
      limit1: 6,
      length1: 0,
      count1: 0,
      data1: [],
    });
    const width = computed(() => proxy.$utils.twidth(columns));
    const width1 = computed(() => proxy.$utils.twidth(columns1));
    const width2 = computed(() => proxy.$utils.twidth(columns2));
    const width3 = computed(() => proxy.$utils.twidth(columns3));
    onActivated(() => {
      if(JSON.stringify(proxy.$route.params) !== "{}"){
        state.profileId = proxy.$route.params.profileId;
        state.campaignId = proxy.$route.params.campaignId;
        state.shopid = [];
        state.shopid.push(proxy.$route.params.shopid);
      }
      if ( state.activeKey === 1 ) {
        getData();
      } else {
        getData1();
      }
    });
    watch(() => state.page, () => getData());
    watch(() => state.limit, () => {
      if (state.page !== 1) state.page = 1;
      else getData();
    });
    watch(() => state.page1, () => getData1());
    watch(() => state.limit1, () => {
      if (state.page1 !== 1) state.page1 = 1;
      else getData1();
    });
    const handclick = () => {
      if ( state.activeKey === 1 ) {
        getData();
      } else {
        getData1();
      }
    };
    const changeShop = (item) => {
      proxy.$router.push({params: {}});
      state.profileId = "";
      state.campaignId = "";
      state.page = 1;
      state.page1 = 1;
      if ( state.activeKey === 1 ) {
        getData();
      } else {
        getData1();
      }
    };
    const changeStation = (item) => {
      state.station = item;
      proxy.$router.push({params: {}});
      state.profileId = "";
      state.campaignId = "";
      state.page = 1;
      state.page1 = 1;
      if ( state.activeKey === 1 ) {
        getData();
      } else {
        getData1();
      }
    };
    const search = () =>{
      proxy.$router.push({params: {}});
      state.profileId = "";
      state.campaignId = "";
      state.shopid = 0;
      state.page = 1;
      state.page1 = 1;
      if ( state.activeKey === 1 ) {
        getData();
      }else{
        getData1();
      }
    };
    const getDatas = () =>{
      if(state.days === -1){
        let beforeDay = proxy.$utils.moment().subtract(1, "days").format('YYYY-MM-DD');
        state.daterange = [];
        state.daterange.push(beforeDay);
        state.daterange.push(beforeDay);
      }
      if(state.days === 7){
        let beforeDay = proxy.$utils.moment().subtract(1, "days").format('YYYY-MM-DD');
        let before7Day = proxy.$utils.moment().subtract(7, "days").format('YYYY-MM-DD');
        state.daterange = [];
        state.daterange.push(before7Day);
        state.daterange.push(beforeDay);
      }
      if(state.days === 14){
        let beforeDay = proxy.$utils.moment().subtract(1, "days").format('YYYY-MM-DD');
        let before14Day = proxy.$utils.moment().subtract(14, "days").format('YYYY-MM-DD');
        state.daterange = [];
        state.daterange.push(before14Day);
        state.daterange.push(beforeDay);
      }
      if(state.days === 30){
        let beforeDay = proxy.$utils.moment().subtract(1, "days").format('YYYY-MM-DD');
        let before30Day = proxy.$utils.moment().subtract(30, "days").format('YYYY-MM-DD');
        state.daterange = [];
        state.daterange.push(before30Day);
        state.daterange.push(beforeDay);
      }
      if ( state.activeKey === 1 ) {
        getData();
      } else {
        getData1();
      }
    }
    const getData = () => {
      state.dataLoading = true;
      proxy.$api.doAPI("/advertising/keywordlist", {
        page: state.page,
        limit: state.limit,
        shopid: state.shopid,
        station: state.station,
        searchText: state.searchText,
        profileId: state.profileId,
        campaignId: state.campaignId,
      }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.shops = res.data.shops;
          state.length = res.data.length;
          state.count = res.data.count;
          state.data = res.data.list;
          if (state.length > 0 && state.page > state.length) state.page = 1;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        console.error(err);
        state.dataLoading = false;
      });
    };
    const chartOptions = ref({ xaxis: { categories: [], }, });
    const series = ref([
        { name: "点击量", data: [], },
        { name: '曝光量', data: [], },
        { name: '点击率', data: [], },
    ]);
    const chartOptions1 = ref({ xaxis: { categories: [], }, });
    const series1 = ref([
        { name: "订单量", data: [], },
        { name: '点击量', data: [], },
        { name: '转化率', data: [], },
    ]);
    const chartOptions2 = ref({ xaxis: { categories: [], }, });
    const series2 = ref([
        { name: "成本", data: [], },
        { name: '销售额', data: [], },
        { name: '投入产出比', data: [], },
    ]);
    const getData1 =  () => {
      try {
          state.dataLoading1 = true;
          state.dataLoading2 = true;
          state.dataLoading3 = true;
          proxy.$api.doAPI("/advertising/keywordView", {
              page: state.page1,
              limit: state.limit1,
              station: state.station,
              daterange: state.daterange,
              searchText: state.searchText,
              shopid: state.shopid,
          }).then((res) => {
              state.dataLoading1 = false;
              state.dataLoading2 = false;
              state.dataLoading3 = false;
              if (res.code === 0) {
                  state.data1 = res.data.list;
                  state.categories = res.data.categories;
                  state.clickRateData = res.data.clickRateData;
                  state.clickData = res.data.clickData;
                  state.exposureData = res.data.exposureData;
                  state.purchases = res.data.purchases;
                  state.conversionRateData = res.data.conversionRateData;
                  state.aCosCostData = res.data.aCosCostData,
                  state.aCoSSalesData = res.data.aCoSSalesData,
                  state.aCoSData = res.data.aCoSData,
                  state.length1 = res.data.length;
                  state.count1 = res.data.count;
                  chartOptions.value.updateOptions({
                      chart: {
                          id: "vuechart-example",
                          type: "bar",
                          toolbar: { show: true, tools: { download: false, selection: true, zoom: false, zoomin: true, zoomout: true, pan: false } },
                      },
                      legend: {position: "top"},
                      plotOptions: { bar: { horizontal: false } },
                      xaxis:{ categories: state.categories, tickAmount: 3, labels: { rotate: -25, }, },
                      title: { text: "点击率",   align: "center" },
                      yaxis: [
                          { show: true, seriesName: '点击量', title: { text: "点击量" }, labels: { formatter: function(val) { try { return val.toFixed(0); } catch(e){ return val; }}} },
                          { show: true, seriesName: '曝光量', opposite: true, title: { text: "曝光量" }, labels: { formatter: function(val) { try { return val.toFixed(0); } catch(e){ return val; }}} },
                          { show: true, seriesName: '点击率', title: { text: "点击率" }, labels: { formatter: function(val) { try{ return val.toFixed(2)+ "%"; } catch(e){ return val; } } } },
                      ],
                      series: [
                          { name: "点击量", data: state.clickData || [], },
                          { name: '曝光量', data: state.exposureData || [], },
                          { name: '点击率', data: state.clickRateData || [], },
                      ]
                  });
                  chartOptions1.value.updateOptions({
                      chart: {
                          id: "vuechart-example1",
                          type: "bar",
                          toolbar: { show: true, tools: { download: false, selection: true, zoom: false, zoomin: true, zoomout: true, pan: false } },
                      },
                      legend: {position: "top"},
                      plotOptions: { bar: { horizontal: false } },
                      xaxis:{ categories: state.categories, tickAmount: 3, labels: { rotate: -25, }, },
                      title: { text: "转化率",   align: "center" },
                      yaxis: [
                          { show: true, seriesName: '订单量', title: { text: "订单量" }, labels: { formatter: function(val) { try { return val.toFixed(0); } catch(e){ return val; }}} },
                          { show: true, seriesName: '点击量', title: { text: "点击量" }, labels: { formatter: function(val) { try { return val.toFixed(0); } catch(e){ return val; }}}, opposite: true,  },
                          { show: true, seriesName: '转化率', title: { text: "转化率" }, labels: { formatter: function(val) { try{ return val.toFixed(2)+ "%"; } catch(e){ return val; }}} },
                      ],
                      series: [
                          { name: "订单量", data: state.purchases || [], },
                          { name: '点击量', data: state.clickData|| [],  },
                          { name: '转化率', data: state.conversionRateData || [], },
                      ]
                  });
                  chartOptions2.value.updateOptions({
                      chart: {
                          id: "vuechart-example2",
                          type: "bar",
                          toolbar: { show: true, tools: { download: false, selection: true, zoom: false, zoomin: true, zoomout: true, pan: false } },
                      },
                      legend: {position: "top"},
                      plotOptions: { bar: { horizontal: false , columnWidth: '55%', } },
                      xaxis:{ categories: state.categories, tickAmount: 3, labels: { rotate: -25, }, },
                      title: { text: "投入产出比",   align: "center" },
                      yaxis: [
                          { show: true, seriesName: '成本', title: { text: "成本" }, labels: { formatter: function(val) { try { return val.toFixed(0); } catch(e){ return val; }}} },
                          { show: true, seriesName: '销售额', title: { text: "销售额" }, labels: { formatter: function(val) { try { return val.toFixed(0); } catch(e){ return val; }}}, opposite: true,  },
                          { show: true, seriesName: '投入产出比', title: { text: "投入产出比" }, labels: { formatter: function(val) { try{ return val.toFixed(2)+ "%"; } catch(e){ return val; }}} },
                      ],
                      series: [
                          { name: "成本", data: state.aCosCostData || [], },
                          { name: '销售额', data: state.aCoSSalesData || [], },
                          { name: '投入产出比', data: state.aCoSData || [], },
                      ]
                  });

              } else {
                  message.error(res.msg);
              }
          }).catch((err) => {
              console.error(err);
              state.dataLoading1 = false;
              state.dataLoading2 = false;
              state.dataLoading3 = false;
          });
      } catch (errorInfo) {
          console.log(errorInfo);
      }
    };

    const handleChange = (tag, checked) => {
      const { selectedTags } = state;
      const nextSelectedTags = checked
        ? [...selectedTags, tag]
        : selectedTags.filter(t => t !== tag);
      console.log('You are interested in: ', nextSelectedTags);
      state.selectedTags = nextSelectedTags;
    };
    return {
      ...toRefs(state),
      handleChange,
      columns,
      width,
      columns1,
      width1,
      columns2,
      width2,
      columns3,
      width3,
      handclick,
      changeShop,
      changeStation,
      getData,
      search,
      getDatas,
      getData1,
      chartOptions,
      series,
      chartOptions1,
      series1,
      chartOptions2,
      series2,
    };
  }
});
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

.logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-menu-dark.ant-menu-horizontal {
  flex: 1;
}

.site-layout-background {
  background: #fff;
}

.avatar {
  color: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.loginname {
  font-size: 18px;
  margin-left: 5px;
}

.setting {
  display: none;
  box-shadow: 0 0 5px #bbb;
  position: absolute;
  top: 65px;
  right: 0;
  background: #ccc;
  z-index: 10;
}

.setting:hover {
  display: block;
}

.avatar:hover+.setting {
  display: block;
}

.header {
  .search {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }

  .op {
    display: flex;
    justify-content: space-between;
  }
}

p {
  margin: 0;
  padding: 0;
}
</style>
