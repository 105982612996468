<template>
  <a-alert message="目前支持采集的平台：亚马逊、1688；其他站点陆续增加中" type="info" show-icon class="mb-2" />
  <a-tabs v-model:activeKey="activeTab" style="margin-bottom:10px;border-bottom:1px solid #ddd;">
    <a-tab-pane key="1" tab="单品采集">
      <a-form ref="formRef1" name="form1" :model="form1">
        <a-row :gutter="16">
          <a-col :span="20">
            <a-form-item name="urls" :rules="[{ required: true, message: '请输入产品网址' }]">
              <a-textarea v-model:value="form1.urls" placeholder="请填写在线商品链接，可一行一个商品链接批量添加，一次添加的商品链接不要超过100个" :rows="4"
                :disabled="saving" />
            </a-form-item>
          </a-col>
          <a-col :span="4">
            <a-button type="primary" size="large" block :loading="saving" @click="submit1">开始采集</a-button>
            <a-row :gutter="16" class="mt-2">
              <a-col :span="12">
                <a-button block :disabled="saving" @click="clear1">清空</a-button>
              </a-col>
              <a-col :span="12">
                <a-button type="primary" block :disabled="saving" @click="showtask">查看采集日志</a-button>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-form>
    </a-tab-pane>
    <a-tab-pane key="2" tab="关键词采集" force-render>
      <a-form ref="formRef2" name="form2" :model="form2">
        <a-row :gutter="16">
          <a-col :span="10">
            <a-form-item label="关键词" name="keywords" :rules="[{ required: true, message: '请输入关键词' }]">
              <a-textarea v-model:value="form2.keywords" :rows="4" :disabled="saving" />
            </a-form-item>
          </a-col>
          <a-col :span="10">
            <a-form-item label="采集数" name="count" :rules="[{ required: true, message: '请输入采集条数' }]">
              <a-input-number v-model:value="form2.count" :disabled="saving" style="width:100%;" />
            </a-form-item>
            <a-row :gutter="16">
              <a-col :span="12">
                <a-form-item label="价格区间" name="sale_price">
                  <div style="display:flex;align-items:center;">
                    <a-input v-model:value="form2.price1" :disabled="saving" style="margin-right:5px;" /> -
                    <a-input v-model:value="form2.price2" :disabled="saving" style="margin-left:5px;" />
                  </div>
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item label="销量区间" name="sale_quantity">
                  <div style="display:flex;align-items:center;">
                    <a-input v-model:value="form2.quantity1" :disabled="saving" style="margin-right:5px;" /> -
                    <a-input v-model:value="form2.quantity2" :disabled="saving" style="margin-left:5px;" />
                  </div>
                </a-form-item>
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="4">
            <a-button type="primary" size="large" block :loading="saving" @click="submit2">开始采集</a-button>
            <a-row :gutter="16" class="mt-2">
              <a-col :span="12">
                <a-button block :disabled="saving" @click="clear2">清空</a-button>
              </a-col>
              <a-col :span="12">
                <!-- <a-button type="primary" block :disabled="saving" @click="showtask">查看采集日志</a-button> -->
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-form>
    </a-tab-pane>
    <template #rightExtra>
      <a-steps>
        <a-step title="采集数据" />
        <a-step title="认领产品库" />
        <a-step title="编辑产品" />
        <a-step title="发布产品" />
      </a-steps>
    </template>
  </a-tabs>
  <div class="list">
    <div class="d-flex justify-space-between mb-2">
      <a-space>
        <a-button type="primary" @click="getData" :loading="dataLoading">刷新</a-button>
        <a-button type="primary" @click="bulkConfirmClaim">批量认领到产品库</a-button>
        <a-button type="primary" @click="bulkDownloadImages">批量下载图片</a-button>
      </a-space>
      <a-space>
        <a-button type="danger" @click="bulkRemoveListings">批量删除</a-button>
      </a-space>
    </div>
    <a-table bordered :columns="columns" :data-source="data" :scroll="{ x: width }" :loading="dataLoading"
      :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
      @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" row-key="_id"
      :row-selection="{ selectedRowKeys, onChange: (keys) => { selectedRowKeys = keys; } }" size="small">
      <template #bodyCell="{ record, column }">
        <div v-if="column.dataIndex === 'main_image'" class="text-center" style="width:60px;">
          <a-tooltip v-if="record.images[0]" placement="rightBottom" color="#fff"
            :overlayStyle="{ width: '500px', height: '500px' }">
            <template #title>
              <img :src="record.images[0]" style="width:500px;height:500px;object-fit: contain;" />
            </template>
            <div class="image-header" style="border:1px solid #ddd;">
              <img :src="record.images[0]" style="object-fit: contain;padding:2px;" />
            </div>
          </a-tooltip>
          <div v-else class="image-header">
            <img :src="$filters.image_placeholder" style="object-fit: contain;" />
          </div>
        </div>
        <template v-if="column.dataIndex === 'item_name'">
          <p>
            <span>
              <a href="javascript:;" class="text-teal font-weight-bold" style="text-decoration: none;"
                @click="edit(record._id)">
                {{ record.item_sku }}
              </a>
              <CopyOutlined title="复制SPU" class="ml-1" @click="$filters.doCopy(record.item_sku)" />
            </span>
            <a :class="`ml-1 label label-${record.source}`" target="_blank" title="查看在线商品" :href="record.url">
              {{ record.source }}
              <LinkOutlined />
            </a>
          </p>
          <a-tooltip>
            <template #title>
              {{ record.item_name }}
            </template>
            <p class="in3line">
              {{ record.item_name }}
            </p>
          </a-tooltip>
        </template>
        <template v-if="column.dataIndex === 'product_details'">
          <a-tooltip>
            <template #title>
              <p v-for="item in record.product_details" :key="item.label">
                <span>{{ item.label }}: </span>
                <span class="text-info">{{ item.value }}</span>
              </p>
            </template>
            <p class="in3line">
              {{ record._product_details }}
            </p>
          </a-tooltip>
        </template>
        <template v-if="column.dataIndex === 'standard_price'">
          <p>{{ record.standard_price }}</p>
          <p>--</p>
          <p>--</p>
        </template>
        <template v-if="column.dataIndex === 'create_time'">
          <p>{{ record.create_by }}</p>
          <p>{{ record.create_time }}</p>
        </template>
        <template v-if="column.dataIndex === 'operation'">
          <div class="d-flex flex-column align-center">
            <a href="javascript:;" @click="confirmClaim(record._id)"> 认领到产品库 </a>
            <a href="javascript:;" @click="edit(record._id)"> 编辑 </a>
            <a-dropdown>
              <a class="ant-dropdown-link" @click.prevent>
                更多
                <DownOutlined />
              </a>
              <template #overlay>
                <a-menu @click="({ key }) => handleMenuClick(key, record._id)">
                  <a-menu-item key="downloadImages">
                    <a href="javascript:;" class="underline text-info">下载图片</a>
                  </a-menu-item>
                  <a-menu-item key="remove">
                    <a href="javascript:;" class="underline text-error">删除</a>
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </div>
        </template>
      </template>
    </a-table>
  </div>
  <a-modal v-model:visible="taskModal" title="数据采集日志" width="1200px" centered :maskClosable="false" @cancel="hidetask"
    :footer="null">
    <div class="d-flex justify-space-between align-center">
      <a href="javascript:;" @click="getTasks">刷新</a>
      <p>仅保留近3天数据</p>
    </div>
    <a-table bordered :columns="tcolumns" :data-source="tasks" :scroll="{ x: twidth }" :loading="taskLoading"
      :pagination="{ current: tpage, pageSize: tlimit, total: tcount, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
      @change="(pagination) => { tpage = pagination.current; tlimit = pagination.pageSize; }" row-key="_id"
      size="small">
      <template #bodyCell="{ record, column }">
        <template v-if="column.dataIndex === 'platform'">
          {{ getplatformname(record.platform) }}
        </template>
        <template v-if="column.dataIndex === 'url'">
          <a :href="record.url" target="_blank">{{ record.url }}</a>
        </template>
        <div v-if="column.dataIndex === 'main_image'" class="text-center" style="width:50px;">
          <a-tooltip v-if="record.images[0]" placement="rightBottom" color="#fff"
            :overlayStyle="{ width: '500px', height: '500px' }">
            <template #title>
              <img :src="record.images[0]" style="width:500px;height:500px;object-fit:contain;" />
            </template>
            <div class="image-header">
              <img :src="record.images[0]" style="object-fit: contain;" />
            </div>
          </a-tooltip>
          <div v-else class="image-header">
            <img :src="$filters.image_placeholder" style="object-fit: contain;" />
          </div>
        </div>
        <template v-if="column.dataIndex === 'state'">
          <span v-if="record.state === 'success'" class="text-success">
            采集成功
          </span>
          <span v-if="record.state === 'fail'" class="text-error">
            采集失败
          </span>
          <span v-if="record.state === 'pending'" class="text-warning">
            采集中 <loading-outlined />
          </span>
        </template>
        <template v-if="column.dataIndex === 'operation'">
          <a-space>
            <template v-if="env_DEV === 'darwin'">
              <a-space direction="vertical" v-if="record.state !== 'pending'">
                <a-popconfirm title="重新采集" @confirm="resettask(record, 0)">
                  <a href="javascript:;" class="text-error">重新采集</a>
                </a-popconfirm>
                <a href="javascript:;" class="text-error" @click="resettask(record, -1)">手动采集</a>
              </a-space>
            </template>
            <template v-else>
              <a v-if="record.state === 'fail'" href="javascript:;" class="text-error"
                @click="resettask(record, 0)">重试</a>
            </template>
          </a-space>
        </template>
      </template>
    </a-table>
  </a-modal>
  <a-drawer title="支持站点" placement="right" v-model:visible="supportsitedrawer" width="30%">
    <a-row :gutter="16">
      <a-col :span="6" v-for="item in supportsites" :key="item.id">
        <a :href="item.url" target="_blank" :title="item.title">
          <img :src="item.icon" :alt="item.title" style="width:100%;object-fit:cover;">
        </a>
      </a-col>
    </a-row>
  </a-drawer>
  <a-drawer title="上传" placement="right" v-model:visible="uploadDrawer" @close="cancelUpload" width="70%"
    :closable="!uploading" :maskClosable="!uploading">
    <template #extra>
      <a-button class="mr-2" @click="cancelUpload" :disabled="uploading">取消</a-button>
      <a-button type="primary" @click="doUpload" :loading="uploading"
        :disabled="adding || uploading || uploadList.length === 0">
        <template #icon>
          <UploadOutlined />
        </template>
        上传
      </a-button>
    </template>
    <a-space>
      <a-button type="primary" :loading="adding" :disabled="uploading" @click="addFiles">
        <template #icon>
          <PlusOutlined />
        </template>
        点击添加文件
      </a-button>
      <span class="text-error">只能上传 html 文件</span>
    </a-space>
    <input ref="uploadRef" type="file" accept=".html,text/html" multiple style="display:none;" @change="selectHtml" />
    <a-table :columns="ucolumns" :data-source="uploadList" :pagination="false" size="small">
      <template #bodyCell="{ record, column, index }">
        <template v-if="column.dataIndex === 'index'">
          {{ index + 1 }}
        </template>
        <template v-if="column.dataIndex === 'name'">
          {{ record.file.name }}
        </template>
        <template v-if="column.dataIndex === 'region'">
          <a-select v-model:value="record.region" placeholder="请选择" :disabled="uploading" show-search>
            <a-select-option v-for="item in regions" :key="item" :value="item">{{ item }}</a-select-option>
          </a-select>
        </template>
        <template v-if="column.dataIndex === 'asin'">
          <a-input v-model:value="record.asin" :disabled="uploading" style="width:100%;" />
        </template>
        <template v-if="column.dataIndex === 'status'">
          <span v-if="record.status === 'pending'" class="text-info text-h9">等待上传</span>
          <span v-if="record.status === 'uploading'" class="text-orange text-h9">
            正在上传 {{ record.upload_rate }}
          </span>
          <span v-if="record.status === 'success'" class="text-success text-h9">
            上传成功{{ record.upload_msg }}
          </span>
          <a-tooltip v-if="record.status === 'fail'" placement="topLeft">
            <template #title> {{ record.msg }} </template>
            <span class="text-error text-h9 underline">上传失败{{ record.upload_msg }} </span>
          </a-tooltip>
          <a-tooltip v-if="record.status === 'error'" placement="topLeft">
            <template #title> {{ record.msg }} </template>
            <span class="text-error text-h9 underline">无法上传</span>
          </a-tooltip>
        </template>
        <template v-if="column.dataIndex === 'operation'">
          <a-button v-if="record.status === 'fail'" danger type="link" :disabled="uploading"
            @click="uploadFile(index)">重试</a-button>
          <a-button v-if="record.status === 'pending' || record.status === 'error'" danger type="link"
            :disabled="adding" @click="removeFile(index)">移除</a-button>
        </template>
      </template>
    </a-table>
  </a-drawer>
  <a-modal v-model:visible="downloadModal" title="图片下载" :maskClosable="false" @cancel="() => downloadModal = false"
    :footer="null">
    <a-card style="width:100%">
      <template #title>
        <span :class="{ 'text-success': downloadState === 'success', 'text-error': downloadState === 'fail' }">
          {{ downloadState === 'process' ? '图片正在打包中，请稍后...' : '' }}
          {{ downloadState === 'success' ? '图片下载成功！' : '' }}
          {{ downloadState === 'fail' ? '图片下载失败！' : '' }}
        </span>
      </template>
      <div class="d-flex justify-center align-center">
        <div v-if="downloadState === 'process'">
          <div class="loader-bar"></div>
          <div class="mt-2 text-center">下载中请勿关闭提示窗口</div>
        </div>
        <div v-if="downloadState === 'success'">
          <a-button @click="() => downloadModal = false">关闭</a-button>
        </div>
        <div v-if="downloadState === 'fail'">图片下载失败，请稍后重试</div>
      </div>
    </a-card>
  </a-modal>
</template>
<script>
import { LoadingOutlined, DownOutlined, LinkOutlined, CopyOutlined, UploadOutlined, SearchOutlined, PlusOutlined, PlusCircleOutlined } from '@ant-design/icons-vue';
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from "vue";
import _ from "underscore";
import { message, Modal } from "ant-design-vue";
const columns = [
  { title: '缩略图', dataIndex: 'main_image', width: 100, },
  { title: 'SPU编号 / 来源 / 标题', dataIndex: 'item_name', width: 600, },
  // { title: '描述', dataIndex: 'product_details', width: 200, },
  { title: '属性', dataIndex: 'variation', width: 80, },
  { title: '成本/重量(g)/体积', dataIndex: 'standard_price', width: 150, },
  { title: '创建人/时间', dataIndex: 'create_time', width: 150, },
  { title: '操作', dataIndex: 'operation', width: 150, align: 'center', fixed: 'right' },
];
const tcolumns = [
  { title: '平台', dataIndex: 'platform', width: 80, },
  { title: '缩略图', dataIndex: 'main_image', width: 100, },
  { title: '链接', dataIndex: 'url', width: 300, },
  { title: '状态', dataIndex: 'state', width: 100, },
  { title: '时间', dataIndex: 'create_time', width: 150, },
  { title: '操作', dataIndex: 'operation', width: 100, fixed: 'right' },
];
const ucolumns = [
  { title: "序号", dataIndex: "index", width: 50, },
  { title: "文件名", dataIndex: "name", width: 150, },
  { title: "region", dataIndex: "region", width: 100, },
  { title: "asin", dataIndex: "asin", width: 100, },
  { title: "状态", dataIndex: "status", width: 120, },
  { title: "操作", dataIndex: "operation", width: 150, },
];
export default defineComponent({
  name: "ShopsPublishGather",
  components: { LoadingOutlined, DownOutlined, LinkOutlined, CopyOutlined, UploadOutlined, SearchOutlined, PlusOutlined, PlusCircleOutlined },
  setup() {
    const { proxy } = getCurrentInstance();
    const formRef1 = ref();
    const formRef2 = ref();
    const uploadRef = ref();
    const env_DEV = ref(process.env.DEV);
    const state = reactive({
      activeTab: "1",
      form1: { urls: "", },
      form2: {
        keywords: "",
        count: "",
        price1: "",
        price2: "",
        quantity1: "",
        quantity2: "",
      },
      saving: false,
      page: 1,
      limit: 10,
      length: 0,
      count: 0,
      dataLoading: false,
      data: [],
      selectedRowKeys: [],
      supportsites: [],
      supportsitedrawer: false,
      tpage: 1,
      tlimit: 10,
      tlength: 0,
      tcount: 0,
      taskLoading: false,
      tasks: [],
      taskModal: false,
      uploadDrawer: false,
      uploadList: [],
      uploading: false,
      adding: false,
      regions: ["US", "CA", "MX", "BR", "ES", "UK", "FR", "BE", "NL", "DE", "IT", "SE", "PL", "AU", "JP"],
      downloadModal: false,
      downloadState: "",
    });
    const width = computed(() => proxy.$utils.twidth(columns));
    const twidth = computed(() => proxy.$utils.twidth(tcolumns));
    const uwidth = computed(() => proxy.$utils.twidth(ucolumns));
    watch(() => state.page, () => getData());
    watch(() => state.limit, () => {
      if (state.page !== 1) state.page = 1;
      else getData();
    });
    watch(() => state.tpage, () => getTasks());
    watch(() => state.tlimit, () => {
      if (state.tpage !== 1) state.tpage = 1;
      else getTasks();
    });
    onActivated(() => {
      getData();
    })
    const getData = () => {
      state.dataLoading = true;
      proxy.$api.doAPI("/listingcollect/newlist", { page: state.page, limit: state.limit }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.data = _.map(res.data.list, n => {
            return {
              ...n,
              _product_details: _.reduce(n.product_details || [], (a, b) => a + ` ${b.label}:${b.value}`, ''),
              create_time: proxy.$utils.dateFormat(n.create_time, 'YYYY-MM-DD HH:mm:ss'),
            }
          });
          state.length = res.data.length;
          state.count = res.data.count;
          if (state.length > 0 && state.page > state.length) state.page = 1;
          state.supportsites = res.data.supportsites;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    }
    const confirmClaim = (_id) => {
      Modal.confirm({
        title: '提示',
        content: `点击认领到产品库，将会推送产品到产品库，请到产品库完成认领商品或通知运营上架等操作`,
        onOk() {
          doConfirmClaim([_id]);
        },
        onCancel() {
        },
      })
    }
    const edit = (id) => {
      proxy.$utils.downloadBrowser({ href: `/shops/published/gatherform?id=${id}` });
    }
    const downloadImages = (ids) => {
      state.downloadModal = true;
      state.downloadState = "process";
      proxy.$api.doAPI("/listingcollect/downloadimages", { ids }).then((res) => {
        if (res.code === 0) {
          proxy.$utils.downloadImages(res.data.list).then(() => {
            state.downloadState = "success";
          });
        } else {
          message.error(res.msg);
          state.downloadState = "fail";
        }
      }).catch((err) => {
        console.error(err);
        state.downloadState = "fail";
      });
    }
    const removeListings = (ids) => {
      state.dataLoading = true;
      proxy.$api.doAPI("/listingcollect/removelistings", { ids }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    }
    const bulkConfirmClaim = () => {
      if (state.selectedRowKeys.length === 0) return message.error("请先勾选您要认领的产品");
      Modal.confirm({
        title: '提示',
        content: `点击认领到产品库，将会推送产品到产品库，请到产品库完成认领商品或通知运营上架等操作`,
        onOk() {
          doConfirmClaim(state.selectedRowKeys);
        },
        onCancel() {
        },
      })
    }
    const doConfirmClaim = (ids) => {
      state.dataLoading = true;
      proxy.$api.doAPI("/listingcollect/confirmclaim", { ids }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    }
    const bulkDownloadImages = () => {
      if (state.selectedRowKeys.length === 0) return message.error("请先勾选您要下载的产品");
      downloadImages(state.selectedRowKeys);
    }
    const bulkRemoveListings = () => {
      if (state.selectedRowKeys.length === 0) return message.error("请先勾选您要删除的产品");
      removeListings(state.selectedRowKeys);
    }
    const showtask = () => {
      state.taskModal = true;
      getTasks();
    }
    const hidetask = () => {
      state.taskModal = false;
    }
    const getTasks = () => {
      state.taskLoading = true;
      proxy.$api.doAPI("/listingcollect/tasks", { page: state.tpage, limit: state.tlimit }).then((res) => {
        state.taskLoading = false;
        if (res.code === 0) {
          state.tasks = _.map(res.data.list, n => {
            return {
              ...n, create_time: proxy.$utils.dateFormat(n.create_time, 'YYYY-MM-DD HH:mm:ss'),
            }
          });
          state.tlength = res.data.tlength;
          state.tcount = res.data.tcount;
          if (state.tlength > 0 && state.tpage > state.tlength) state.tpage = 1;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.taskLoading = false;
        console.error(err);
      });
    }
    const resettask = (item, type) => {
      if (type >= 0) {
        state.taskLoading = true;
        proxy.$api.doAPI("/listingcollect/resettask", { _id: item._id, type }).then((res) => {
          state.taskLoading = false;
          getTasks();
          if (res.code === 0) {
            getData();
          } else {
            message.error(res.msg);
          }
        }).catch((err) => {
          state.taskLoading = false;
          console.error(err);
        });
      } else {
        showUpload();
      }
    }
    const submit1 = async () => {
      try {
        await formRef1.value.validateFields();
        state.saving = true;
        proxy.$api.doAPI("/listingcollect/save1", { form: state.form1 }).then((res) => {
          state.saving = false;
          if (res.code === 0) {
            if (res.data.unsupporturls.length > 0) {
              message.error(`不支持的商品链接：\n${res.data.unsupporturls.join('\n')}`);
              state.form1 = {
                urls: res.data.unsupporturls.join('\n'),
              };
            } else {
              message.success("提交成功");
              clear1();
            }
          } else {
            message.error(res.msg);
          }
        }).catch((err) => {
          state.saving = false;
          console.error(err);
        });
      } catch (errorInfo) {
        console.log("Failed:", errorInfo);
      }
    }
    const clear1 = () => {
      state.form1 = {
        urls: "",
      };
      formRef1.value.clearValidate();
    }
    const submit2 = async () => {
      try {
        await formRef2.value.validateFields();
        state.saving = true;
        proxy.$api.doAPI("/listingcollect/save2", { form: state.form2 }).then((res) => {
          state.saving = false;
          if (res.code === 0) {
            message.success("提交成功");
            clear2();
          } else {
            message.error(res.msg);
          }
        }).catch((err) => {
          state.saving = false;
          console.error(err);
        });
      } catch (errorInfo) {
        console.log("Failed:", errorInfo);
      }
    }
    const clear2 = () => {
      state.form2 = {
        keywords: "",
        count: "",
        price1: "",
        price2: "",
        quantity1: "",
        quantity2: "",
      };
      formRef2.value.clearValidate();
    }
    const showUpload = () => {
      state.uploadDrawer = true;
      state.uploadList = [];
    }
    const addFiles = async (e) => {
      uploadRef.value.click();
    }
    const removeFile = (index) => {
      state.uploadList.splice(index, 1);
    }
    const selectHtml = async (e) => {
      let files = e.target.files;
      state.adding = true;
      for (let i = 0; i < files.length; i++) {
        let status = "pending";
        await proxy.$utils.sleep(17);
        state.uploadList.unshift({
          _id: proxy.$utils.uuid(),
          file: files[i],
          region: null,
          asin: '',
          status,
        });
      }
      uploadRef.value.value = null;
      state.adding = false;
    }
    const doUpload = async () => {
      if (state.uploadList.length === 0) return;
      for (let i = 0; i < state.uploadList.length; i++) {
        await _doUpload(i);
      }
      console.log('doUpload-finished');
      state.uploading = false;
    }
    const _doUpload = async (i) => {
      let { status, file, region, asin } = state.uploadList[i];
      if (status !== "pending" && status !== "fail") return;
      state.uploading = true;
      const slicefiles = proxy.$utils.sliceFile(file.size);
      const faid = proxy.$utils.uuid();
      let loaded = 0, total = file.size;
      let timestamp = Date.now();
      for (let j = 0; j < slicefiles.length; j++) {
        let slicefile = slicefiles[j];
        const formData = new FormData();
        if (slicefile.chunks > 1) {
          let _file = file.slice(slicefile.from, slicefile.to);
          formData.set("file", _file);
        } else {
          formData.set("file", file);
        }
        formData.set("faid", faid);
        formData.set("chunk", slicefile.chunk);
        formData.set("chunks", slicefile.chunks);
        formData.set("originalname", file.name);
        formData.set("region", region);
        formData.set("asin", asin);
        state.uploadList[i].status = 'uploading';
        try {
          let res = await proxy.$api.doUpload("/listingcollect/plupload", formData, function onUploadProgress(e) {
            console.log('onUploadProgress', e);
            if (e.lengthComputable) {
              loaded += e.loaded;
              let upload_rate = Math.round((loaded * 100) / total);
              if (upload_rate > 100) upload_rate = 100;
              state.uploadList[i].upload_rate = upload_rate + '%';
              if (loaded >= total) {
                timestamp = Date.now() - timestamp;
                state.uploadList[i].upload_msg = `，用时：${timestamp.toFixed(0)}ms`;
              }
            }
          });
          if (res.code === 0) {
            if (slicefile.chunk + 1 === slicefile.chunks) {
              if (res.data?.url) {
                state.uploadList[i].status = 'success';
                state.uploadList[i].url = res.data.url;
              } else {
                state.uploadList[i].status = 'fail';
                state.uploadList[i].msg = '系统异常';
              }
            }
          } else {
            message.error(res.msg);
            state.uploadList[i].status = 'fail';
            state.uploadList[i].msg = res.msg;
            break;
          }
        } catch (e) {
          console.error(e);
          message.error(e.message);
          state.uploadList[i].status = 'fail';
          state.uploadList[i].msg = e.message;
          break;
        }
      }
    }
    const uploadFile = async (i) => {
      await _doUpload(i);
      state.uploading = false;
    }
    const cancelUpload = () => {
      state.uploadDrawer = false;
      state.uploadList = [];
    }
    const handleMenuClick = (key, id) => {
      console.log('handleMenuClick', key, id);
      switch (key) {
        case "downloadImages": //下载图片
          downloadImages([id]);
          break;
        case "remove": //删除
          Modal.confirm({
            title: '提示',
            content: `确认要删除该产品吗？`,
            onOk() {
              removeListings([id])
            },
            onCancel() {
            },
          })
          break;
      }
    }
    const showsupportsite = () => {
      state.supportsitedrawer = true;
    }
    const getplatformname = (platform) => {
      return _.findWhere(state.supportsites, { id: platform })?.title;
    }
    return {
      ...toRefs(state),
      env_DEV,
      formRef1, formRef2, uploadRef,
      columns, tcolumns, ucolumns,
      width, twidth, uwidth,
      getData, confirmClaim, edit, downloadImages, removeListings,
      bulkConfirmClaim, bulkDownloadImages, bulkRemoveListings,
      showtask, hidetask, getTasks, resettask,
      submit1, clear1,
      submit2, clear2,
      showUpload, addFiles, removeFile, selectHtml,
      doUpload, uploadFile, cancelUpload,
      handleMenuClick,
      showsupportsite,
      getplatformname,
    }
  }
});
</script>
<style lang="less" scoped>
p {
  margin: 0;
  padding: 0;
}

.loader-bar {
  width: 300px;
  height: 20px;
  margin: 0 auto;
  background: linear-gradient(-45deg, #4183d7 25%, #52b3d9 0, #52b3d9 50%, #4183d7 0, #4183d7 75%, #52b3d9 0, #52b3d9);
  background-size: 20px 20px;
  box-shadow: inset 0 10px 0 hsla(0, 0%, 100%, .2), 0 0 0 5px rgba(0, 0, 0, .2);
  border-radius: 15px;
  animation: moveBar 1.5s linear infinite;
}

@keyframes moveBar {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 20px 20px;
  }
}
</style>
