<template>
  <div class="d-flex align-center mb-2">
    <span style="width:90px;">供应商类型：</span>
    <a-radio-group v-model:value="platform">
      <a-radio-button value="ALL"> 全部 </a-radio-button>
      <a-radio-button v-for="item in platforms" :key="item.value" :value="item.value"> {{ item.label }}
      </a-radio-button>
    </a-radio-group>
  </div>
  <div class="d-flex align-center mb-2">
    <span style="width:80px;">查询搜索：</span>
    <a-input-search v-model:value="searchText" placeholder="输入名称查询" enter-button="搜索" allow-clear @search="getData" />
  </div>
  <div class="d-flex justify-space-between align-center mb-2">
    <a-space>
      <a-button type="primary" @click="getData">刷新数据</a-button>
    </a-space>
    <a-space>
      <!-- <a-dropdown>
        <template #overlay>
          <a-menu @click="handleExportClick">
            <a-menu-item-group title="导入">
              <a-menu-item key="bulkimport">模板导入供应商</a-menu-item>
            </a-menu-item-group>
            <a-menu-item-group title="导出">
              <a-menu-item key="exportselected">导出勾选供应商</a-menu-item>
              <a-menu-item key="exportall">导出全部供应商</a-menu-item>
            </a-menu-item-group>
          </a-menu>
        </template>
<a-button type="primary">
  导入导出
  <DownOutlined />
</a-button>
</a-dropdown> -->
      <a-button type="danger" @click="addSupplier">
        <template #icon>
          <PlusCircleOutlined />
        </template>新增供应商
      </a-button>
    </a-space>
  </div>
  <a-table :columns="columns" bordered :data-source="data" :loading="dataLoading" :scroll="{ x: width, y: 600 }"
    :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
    @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" row-key="_id"
    :row-selection="{ selectedRowKeys, onChange: (keys) => { selectedRowKeys = keys; } }" size="small">
    <template #bodyCell="{ record, column }">
      <template v-if="column.dataIndex === 'url'">
        <a v-if="record.url" :href="record.url" target="_blank">
          {{ record.name }}
          <LinkOutlined />
        </a>
        <p v-else>
          <a href="javascript:;" class="text-gray link" @click="editSupplierUrl(record)"> 【暂未匹配】 </a>
          <a-tooltip>
            <template #title>编辑供应商链接</template>
            <EditTwoTone @click="editSupplierUrl(record)" />
          </a-tooltip>
          <SearchOutlined class="text-info" @click="searchSupplier(record)" />
        </p>
      </template>
      <template v-if="column.dataIndex === 'linkman'">
        <p>{{ record.linkman || '--' }}</p>
        <p>{{ record.linkphone || record.linkmobile || '--' }}</p>
      </template>
      <template v-if="column.dataIndex === 'address'">
        <p>{{ record.address || '--' }}</p>
      </template>
      <template v-if="column.dataIndex === 'operation'">
        <a-space>
          <a href="javascript:;" @click="editSupplier(record)">编辑</a>
          <a-popconfirm title="确定要删除供应商?" @confirm="deleteSupplier(record._id)">
            <a href="javascript:;" class="text-error">删除</a>
          </a-popconfirm>
        </a-space>
      </template>
    </template>
  </a-table>
  <a-modal v-model:visible="supplierModal" :title="`${supplierForm._id !== '' ? '编辑' : '新增'}供应商`" @ok="saveSupplier"
    @cancel="cancelSave" ok-text="确认" cancel-text="取消" :confirm-loading="saving"
    :cancel-button-props="{ disabled: saving }" :maskClosable="false" :closable="!saving">
    <a-form ref="formRef" name="supplierForm" :model="supplierForm" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }"
      autocomplete="off">
      <a-form-item label="供应商类型" name="platform" :rules="[{ required: true, message: '供应商类型不能为空' }]">
        <a-select v-model:value="supplierForm.platform" :options="platforms" placeholder="请选择供应商类型"
          :disabled="supplierForm._id !== '' || saving" show-search :filter-option="$filters.filterOption">
        </a-select>
      </a-form-item>
      <a-form-item label="供应商名称" name="name" :rules="[{ required: true, message: '供应商名称不能为空' }]">
        <a-input v-model:value="supplierForm.name" placeholder="请输入供应商名称" :disabled="saving" />
      </a-form-item>
      <a-form-item label="供应商链接" name="url">
        <a-input v-model:value="supplierForm.url" placeholder="请输入供应商链接" :disabled="saving" />
      </a-form-item>
      <a-form-item label="联系人" name="linkman">
        <a-input v-model:value="supplierForm.linkman" :disabled="saving" />
      </a-form-item>
      <a-form-item label="联系电话" name="linkphone">
        <a-input v-model:value="supplierForm.linkphone" :disabled="saving" />
      </a-form-item>
      <a-form-item label="联系手机" name="linkmobile">
        <a-input v-model:value="supplierForm.linkmobile" :disabled="saving" />
      </a-form-item>
      <a-form-item label="地址" name="address">
        <a-textarea v-model:value="supplierForm.address" placeholder="地址" :auto-size="{ minRows: 2, maxRows: 2 }"
          :disabled="saving" />
      </a-form-item>
      <a-form-item label="备注" name="remark">
        <a-textarea v-model:value="supplierForm.remark" placeholder="备注" :auto-size="{ minRows: 2, maxRows: 2 }"
          :disabled="saving" />
      </a-form-item>
    </a-form>
  </a-modal>
  <a-modal v-model:visible="supplierUrlModal" :title="`编辑供应商链接【供应商:${supplierUrlForm.name}】`" @ok="saveSupplierUrl"
    @cancel="cancelSaveUrl" ok-text="确认" cancel-text="取消" :confirm-loading="saving"
    :cancel-button-props="{ disabled: saving }" :maskClosable="false" :closable="!saving">
    <a-form ref="formUrlRef" name="supplierUrlForm" :model="supplierUrlForm" :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }" autocomplete="off">
      <a-form-item label="供应商链接" name="url">
        <a-input v-model:value="supplierUrlForm.url" placeholder="请输入供应商链接" :disabled="saving" />
      </a-form-item>
    </a-form>
  </a-modal>
  <UploadTask ref="uploadTask" />
</template>
<script setup>
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import { DownOutlined, SearchOutlined, PlusCircleOutlined, UploadOutlined, LinkOutlined, EditTwoTone } from '@ant-design/icons-vue';
import { message } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from "vue";
import _ from "underscore";
import UploadTask from '@/components/UploadTask.vue';
const columns = [
  { title: '供应商类型', dataIndex: 'platform', width: 100, },
  { title: '供应商名称', dataIndex: 'name', width: 150, },
  { title: '供应商链接', dataIndex: 'url', width: 150, },
  { title: '联系人/联系电话', dataIndex: 'linkman', width: 150, },
  { title: '地址', dataIndex: 'address', width: 150, },
  { title: '备注', dataIndex: 'remark', width: 100, },
  { title: '创建时间', dataIndex: 'create_time', width: 150, },
  { title: '操作', dataIndex: 'operation', width: 150, fixed: 'right' },
];
const { proxy } = getCurrentInstance();
const formRef = ref();
const formUrlRef = ref();
const uploadTask = ref();
const searchText = ref('');
const platform = ref('ALL');
const platforms = ref([]);
const dataLoading = ref(false);
const page = ref(1);
const limit = ref(10);
const length = ref(0);
const count = ref(0);
const data = ref([]);
const saving = ref(false);
const supplierModal = ref(false);
const supplierForm = ref({});
const supplierUrlModal = ref(false);
const supplierUrlForm = ref({});


const width = computed(() => proxy.$utils.twidth(columns));
watch(() => page.value, () => getData());
watch(() => limit.value, () => {
  if (page.value !== 1) page.value = 1;
  else getData();
});
watch(() => platform.value, () => {
  if (page.value !== 1) page.value = 1;
  else getData();
});
onActivated(() => {
  // console.log("onActivated");
  getData();
});
const getData = () => {
  dataLoading.value = true;
  proxy.$api.doAPI("/productsupplier/list", { page: page.value, limit: limit.value, platform: platform.value, searchText: searchText.value }).then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      data.value = _.map(res.data.list, n => {
        return {
          ...n, create_time: proxy.$utils.dateFormat(n.create_time, 'YYYY-MM-DD HH:mm:ss'),
        }
      });
      length.value = res.data.length;
      count.value = res.data.count;
      if (length.value > 0 && page.value > length.value) page.value = 1;
      platforms.value = res.data.platforms;
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
};
const addSupplier = () => {
  supplierForm.value = { _id: "", platform: "1688", name: "", url: "", linkman: "", linkphone: "", linkmobile: "", address: "", remark: "", };
  supplierModal.value = true;
}
const editSupplier = (item) => {
  supplierForm.value = {
    _id: item._id,
    platform: item.platform,
    name: item.name,
    url: item.url,
    linkman: item.linkman,
    linkphone: item.linkphone,
    linkmobile: item.linkmobile,
    address: item.address,
    remark: item.remark,
  };
  supplierModal.value = true;
}
const saveSupplier = async () => {
  try {
    await formRef.value.validateFields();
    saving.value = true;
    proxy.$api.doAPI("/productsupplier/save", { supplierForm: supplierForm.value, }).then((res) => {
      saving.value = false;
      if (res.code === 0) {
        message.success("保存成功");
        supplierModal.value = false;
        getData();
      } else {
        message.error(res.msg);
      }
    }).catch((err) => {
      saving.value = false;
      console.error(err);
    });
  } catch (errorInfo) {
    console.log("Failed:", errorInfo);
  }
}
const cancelSave = () => {
  console.log('cancelSave');
  formRef.value.clearValidate();
}
const deleteSupplier = (_id) => {
  dataLoading.value = true;
  proxy.$api.doAPI("/productsupplier/remove", { _id }).then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      message.success("已删除");
      getData();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
}
const editSupplierUrl = (item) => {
  supplierUrlForm.value = { _id: item._id, name: item.name, url: item.url, };
  supplierUrlModal.value = true;
}
const saveSupplierUrl = async () => {
  try {
    await formUrlRef.value.validateFields();
    saving.value = true;
    proxy.$api.doAPI("/productsupplier/saveurl", { supplierUrlForm: supplierUrlForm.value, }).then((res) => {
      saving.value = false;
      if (res.code === 0) {
        message.success("保存成功");
        supplierUrlModal.value = false;
        getData();
      } else {
        message.error(res.msg);
      }
    }).catch((err) => {
      saving.value = false;
      console.error(err);
    });
  } catch (errorInfo) {
    console.log("Failed:", errorInfo);
  }
}
const cancelSaveUrl = () => {
  console.log('cancelSaveUrl');
  formUrlRef.value.clearValidate();
}
const searchSupplier = (item) => {
  let url = `https://s.1688.com/company/company_search.htm?keywords=${proxy.$utils.urlencode.encode(item.name, 'gbk')}`;
  proxy.$utils.downloadBrowser({ href: url });
}
const handleExportClick = ({ key }) => {
  console.log('handleExportClick', key);
  switch (key) {
    case "bulkimport": uploadTask.value.showUpload({ title: "模板导入供应商", file: "供应商上传模板.xlsx", type: "import_createsupplier" }); break;
    case "exportselected": break;
    case "exportall": break;
  }
}

</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

p {
  margin: 0;
  padding: 0;
}
</style>
