import _ from 'underscore';
import { message } from "ant-design-vue";
import api from '../../request';
import utils from '../../utils';

const state = {
  total_size: 0,
  used_size: 0,
};
const mutations = {
  set_data(state, payload) {
    state.total_size = payload.total_size;
    state.used_size = payload.used_size;
  },
};
const actions = {
  setData({ commit }, payload) {
    commit('set_data', payload);
  },
  getSpaceUsage({ commit }) {
    api.doAPI("/assets/space_usage").then((res) => {
      if (res.code === 0) {
        commit('set_data', {
          total_size: res.data.total,
          used_size: res.data.used,
        });
      }
    }).catch((err) => {
      console.error(err);
    });
  },
};
const getters = {

};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
