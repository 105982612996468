<template>
  <div class="header">
    <div class="d-flex align-center mb-2">
      <span style="width:75px;">查询搜索：</span>
      <a-input-search v-model:value="searchText" placeholder="关键字搜索" enter-button="搜索" @search="getData" />
    </div>
    <div class="d-flex align-center mb-2">
      <span style="width:90px;">来源店铺：</span>
      <div class="d-flex flex-wrap align-center">
        <a-tag :color="shopid === 0 ? 'processing' : 'default'" @click="changeShop(0)" class="click">全部</a-tag>
        <a-tag v-for="item in shops" :key="item._id" :color="shopid === item._id ? 'processing' : 'default'"
          @click="changeShop(item._id)" class="click">{{ item.name }}
        </a-tag>
      </div>
    </div>
    <div class="d-flex align-center mb-2">
      <span style="width: 75px">消息时间：</span>
      <a-range-picker v-model:value="daterange" value-format="YYYY-MM-DD" @change="getData" />
    </div>
    <div class="d-flex align-center mb-2">
      <span style="width:75px;">消息状态：</span>
      <a-tag :color="replystate === 'waiting' ? 'orange' : 'default'" @click="changeReplyState('waiting')"
        class="click">未回复</a-tag>
      <a-tag :color="replystate === 'success' ? 'success' : 'default'" @click="changeReplyState('success')"
        class="click">已回复</a-tag>
      <a-tag :color="replystate === 'fail' ? 'error' : 'default'" @click="changeReplyState('fail')"
        class="click">发送失败</a-tag>
      <a-tag :color="replystate === 'delete' ? 'error' : 'default'" @click="changeReplyState('delete')"
        class="click">已删除</a-tag>
    </div>
  </div>
  <div class="d-flex justify-space-between align-center mb-2">
    <a-space>
      <a-button type="primary" @click="markReply" :disabled="selectedRowKeys.length === 0" :loading="dataLoading">
        标记为已回复
      </a-button>
      <a-button type="danger" @click="bulkReply" :disabled="selectedRowKeys.length === 0" :loading="dataLoading">
        批量回复
      </a-button>
    </a-space>
    <a-space>
      <a-button type="danger" @click="bulkDelete" :disabled="selectedRowKeys.length === 0" :loading="dataLoading">
        批量删除
      </a-button>
    </a-space>
  </div>
  <a-table :columns="columns" bordered :data-source="data" :loading="dataLoading" :scroll="{ x: width, y: 600 }"
    :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
    @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" row-key="_id"
    :row-selection="{ selectedRowKeys, onChange: (keys) => { selectedRowKeys = keys; } }" size="small">
    <template #bodyCell="{ record, column }">
      <template v-if="column.dataIndex === 'from'">
        <p class="click underline" @click="showMessageBox(record._id)">
          {{ record.from.name }}
        </p>
        <p v-if="record.from.address" class="text-gray">
          &lt;{{ record.from.address }}&gt;
        </p>
      </template>

      <template v-if="column.dataIndex === 'subject'">
        <p class="text-gray click" @click="showMessageBox(record._id)">
          {{ record.sfrom }}：<span class="text-black underline">{{ record.stitle }}</span>
        </p>
        <p class="text-gray"> {{ record.fromNow }} </p>
      </template>

      <template v-if="column.dataIndex === 'shop'">
        <p>
          {{ record.shop.name }} {{ record.shop.region }}
        </p>
        <p v-if="record.shopmailbox">
          {{ record.shopmailbox.username }}@{{ record.shopmailbox.domain }}
        </p>
      </template>

      <template v-if="column.dataIndex === 'replystate'">
        <a-tag v-if="record.replystate === 'waiting'" class="click" color="orange" @click="showMessageBox(record._id)">
          未回复
        </a-tag>
        <a-tag v-if="record.replystate === 'success'" color="success"> 已回复 </a-tag>
        <a-tag v-if="record.replystate === 'fail'" color="error"> 发送失败 </a-tag>
        <a-tag v-if="record.replystate === 'delete'" color="error"> 已删除 </a-tag>
      </template>
    </template>
  </a-table>
  <a-drawer placement="right" v-model:visible="messageDrawer" width="80%" :closable="!messageProcessing"
    :maskClosable="!messageProcessing">

    <template #title>
      <div class="d-flex align-center">
        <span>Walmart Marketplace Notifications</span>
        <a-tag class="ml-2" color="default">Walmart</a-tag>
        <a-tag class="ml-2" color="default">
          <span>账号：{{ message.shop.name }} {{ message.shop.region }}</span>
        </a-tag>
      </div>
    </template>

    <template #extra>
      <a-button v-if="message.replystate === 'waiting'" type="primary" :loading="messageProcessing"
        @click="markReply(message._id)">
        标记已回复
      </a-button>
    </template>
    <a-row :gutter="16">
      <a-col :span="14" class="d-flex flex-column" style="height:calc(100vh - 65px)">
        <div style="background-color:#eee;flex:1;">
          <div class="pb-1 text-gray" style="border-bottom:1px solid #ddd;width:100%;padding:10px 20px;">
            {{ message.subject }}
          </div>
          <div style="overflow-y:auto;padding:10px;height:calc(100vh - 305px);">
            <div class="d-flex mt-2">
              <a-avatar :size="40" :style="{ backgroundColor: '#C3D571', verticalAlign: 'middle' }">
                Wa
              </a-avatar>
              <div class="d-flex flex-column ml-2" style="width:80%;">
                <div class="pa-2 bg-white" v-html="message.html || message.plainHtml || message.plain">
                </div>
                <div class="mt-1 text-gray">
                  Walmart Marketplace Notifications, {{ message.fromNow }}
                </div>
              </div>
            </div>
            <div v-for="item in replyMessages" :key="item._id" class="d-flex justify-end mt-2">
              <div class="d-flex flex-column mr-2" style="width:80%;">
                <div class="pa-2 bg-white" v-html="item.html || item.plain">
                </div>
                <div class="mt-1 text-gray">
                  Walmart Marketplace Notifications, {{ item.fromNow }}
                </div>
              </div>
              <a-avatar :size="40" :style="{ backgroundColor: '#3C2A8E', verticalAlign: 'middle' }">
                我
              </a-avatar>
            </div>
          </div>
        </div>
        <div class="bg-white" style="height:200px;position:relative;">
          <a-textarea v-model:value="replyContent" :auto-size="{ minRows: 9, maxRows: 9 }" placeholder="请输入"
            style="width:100%;" />
          <div style="width:100%;position:absolute;bottom:0;left:0;padding:10px 10px;">
            <div class="d-flex justify-end align-center" style="width:100%;">
              <a-space>

              </a-space>
              <a-space>
                <a-button style="color:#fff;background-color:#77BA4B;" @click="doReply" :loading="messageProcessing">
                  <svg-icon iconClass="send" :style="{ 'width': '15px', 'height': '15px' }" />
                  <span style="margin-left:8px;">发送</span>
                </a-button>
              </a-space>
            </div>
          </div>
        </div>
      </a-col>
      <a-col :span="10">

      </a-col>
    </a-row>
  </a-drawer>
</template>

<script>
import { DownOutlined, SearchOutlined, PlusCircleOutlined, } from '@ant-design/icons-vue';
import { message } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, nextTick, } from "vue";
import _ from "underscore";
const columns = [
  { title: '客户ID', dataIndex: 'from', width: 100, },
  { title: '最新消息', dataIndex: 'subject', width: 200, },
  { title: '平台帐号', dataIndex: 'shop', width: 120, },
  { title: 'SKU', dataIndex: 'SKU', width: 100, },
  { title: '最新时间', dataIndex: 'date', width: 120, },
  { title: '状态', dataIndex: 'replystate', width: 100, },
];
export default defineComponent({
  name: 'WalmartBuyerMessage',
  components: { DownOutlined, SearchOutlined, PlusCircleOutlined, },
  setup() {
    const { proxy } = getCurrentInstance();
    const formRef = ref();
    const state = reactive({
      searchText: "",
      shopid: 0,
      dataLoading: false,
      page: 1,
      limit: 20,
      length: 0,
      count: 0,
      data: [],
      shops: [],
      replystate: 'waiting',
      selectedRowKeys: [],
      message: null,
      messageDrawer: false,
      messageProcessing: false,
      replyContent: "",
      replyMessages: [],
    });
    const width = computed(() => proxy.$utils.twidth(columns));
    watch(() => state.page, () => getData());
    watch(() => state.limit, () => {
      refresh();
    });
    onActivated(() => {
      // console.log("onActivated");
      getData();
    });
    const refresh = () => {
      if (state.page !== 1) state.page = 1;
      else getData();
    }
    const getData = () => {
      state.selectedRowKeys = [];
      state.dataLoading = true;
      proxy.$api.doAPI("/mailbox/list", { page: state.page, limit: state.limit, shopid: state.shopid, replystate: state.replystate }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.data = _.map(res.data.list, n => {
            return {
              ...n,
            };
          });
          state.length = res.data.length;
          state.count = res.data.count;
          if (state.length > 0 && state.page > state.length) state.page = 1;
          state.shops = res.data.shops;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    };
    const changeShop = (value) => {
      state.shopid = value;
      refresh();
    };
    const changeReplyState = (value) => {
      state.replystate = value;
      refresh();
    };
    const showMessageBox = (_id) => {
      console.log('showMessageBox', _id);
      state.dataLoading = true;
      proxy.$api.doAPI("/mailbox/content", { _id }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.message = res.data.message;
          state.replyMessages = res.data.replyMessages;
          state.replyContent = "";
          state.messageDrawer = true;
          nextTick(() => {
            document.getElementsByClassName('ant-drawer-body')[0].style = 'padding:0;overflow:hidden;'
          })
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    }
    const doReply = () => {
      if (!state.replyContent) return;
      state.messageProcessing = true;
      proxy.$api.doAPI("/mailbox/reply", { _id: state.message._id, content: state.replyContent }).then((res) => {
        state.messageProcessing = false;
        if (res.code === 0) {
          state.replyContent = "";
          state.replyMessages = res.data.replyMessages;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.messageProcessing = false;
        console.error(err);
      });
    }
    ///标记已回复
    const markReply = (id) => {
      let _id = [];
      if (id) {
        _id.push(id);
      } else {
        _id = state.selectedRowKeys;
      }
      proxy.$api.doAPI("/mailbox/markers", { _id: state.selectedRowKeys }).then((res) => {
        if (res.code === 0) {
          refresh();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        console.error(err);
      });
    };
    ///批量回复
    const bulkReply = (item) => {

    };
    ///批量删除
    const bulkDelete = () => {
      proxy.$api.doAPI("/mailbox/bulkdelete", { _id: state.selectedRowKeys }).then((res) => {
        if (res.code === 0) {
          refresh();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        console.error(err);
      });
    };
    return {
      ...toRefs(state),
      formRef,
      columns,
      width,
      getData,
      changeShop,
      changeReplyState,
      showMessageBox,
      doReply,
      markReply,
      bulkReply,
      bulkDelete,
    };
  }
});
</script>

<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

:deep(.ant-row) {
  align-items: center;
}

:deep(.ant-drawer-body) {
  padding: 0 !important;
  overflow: hidden !important;
}

.header {
  .search {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }

  .op {
    display: flex;
    justify-content: space-between;
  }
}

p {
  margin: 0;
  padding: 0;
}
</style>