<template>
  <div class="d-flex align-center mb-2">
    <span style="width:80px;">查询搜索：</span>
    <a-input-search v-model:value="searchText" placeholder="输入侵权词查询" enter-button="搜索" allow-clear @search="getData" />
  </div>
  <div class="d-flex justify-space-between align-center mb-2">
    <a-space>
      <a-button type="danger" @click="deleteTortWords(selectedRowKeys)">批量删除</a-button>
    </a-space>
    <a-space>
      <a-button type="primary" @click="addTortWords">
        <template #icon>
          <PlusCircleOutlined />
        </template>新增侵权词
      </a-button>
    </a-space>
  </div>
  <a-table :columns="columns" bordered :data-source="data" :loading="dataLoading" :scroll="{ x: width, y: 600 }"
    :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
    @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" row-key="_id"
    :row-selection="{ selectedRowKeys, onChange: (keys) => { selectedRowKeys = keys; } }" size="small">
    <template #bodyCell="{ index, record, column }">
      <template v-if="column.dataIndex === 'index'">
        {{ index + 1 }}
      </template>
      <template v-if="column.dataIndex === 'operation'">
        <a-space>
          <template v-if="record.editable">
            <a-button type="link" @click="editTortWord(record)">编辑</a-button>
            <a-button type="link" @click="deleteTortWords([record._id])" style="color: red;">删除</a-button>
          </template>
          <template v-else>
            <a-button disabled type="link">系统词库</a-button>
          </template>
        </a-space>
      </template>
    </template>
  </a-table>
  <a-modal v-model:visible="tortwordModal" :title="`${tortwordForm._id !== '' ? '编辑' : '新增'}侵权词`" @ok="saveTortWord"
    @cancel="cancelSave" ok-text="确认" cancel-text="取消" :confirm-loading="saving"
    :cancel-button-props="{ disabled: saving }" :maskClosable="false" :closable="!saving">
    <a-form ref="formRef" name="tortwordForm" layout="vertical" :model="tortwordForm" :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }" autocomplete="off">
      <a-input v-if="tortwordForm._id" v-model:value="tortwordForm.content" :disabled="saving" />
      <template v-else>
        <p>请输入侵权词，可一行一个地址批量添加 (支持Excel复制粘贴)</p>
        <a-textarea v-model:value="tortwordForm.content" :auto-size="{ minRows: 5, maxRows: 7 }" :disabled="saving" />
      </template>
    </a-form>
  </a-modal>
</template>
<script setup>
import { DownOutlined, SearchOutlined, PlusCircleOutlined, } from '@ant-design/icons-vue';
import { message } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from "vue";
import _ from "underscore";
const columns = [
  { title: '序号', dataIndex: 'index', width: 80, },
  { title: '侵权词', dataIndex: 'content', width: 300, },
  { title: '操作', dataIndex: 'operation', width: 150, fixed: 'right' },
];
const { proxy } = getCurrentInstance();
const formRef = ref();
const searchText = ref("");
const dataLoading = ref(false);
const page = ref(1);
const limit = ref(100);
const length = ref(0);
const count = ref(0);
const data = ref([]);
const selectedRowKeys = ref([]);
const saving = ref(false);
const tortwordModal = ref(false);
const tortwordForm = ref({});

const width = computed(() => proxy.$utils.twidth(columns));
watch(() => page.value, () => getData());
watch(() => limit.value, () => {
  if (page.value !== 1) page.value = 1;
  else getData();
});
onActivated(() => {
  getData();
});
const getData = () => {
  selectedRowKeys.value = [];
  dataLoading.value = true;
  proxy.$api.doAPI("/tortword/list", { page: page.value, limit: limit.value, searchText: searchText.value }).then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      data.value = _.map(res.data.list, n => {
        return {
          ...n, create_time: proxy.$utils.dateFormat(n.create_time, 'YYYY-MM-DD HH:mm:ss'),
        }
      });
      length.value = res.data.length;
      count.value = res.data.count;
      if (length.value > 0 && page.value > length.value) page.value = 1;
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
};
const addTortWords = () => {
  tortwordForm.value = { _id: "", content: "", };
  tortwordModal.value = true;
}
const editTortWord = (item) => {
  tortwordForm.value = { _id: item._id, content: item.content };
  tortwordModal.value = true;
}
const saveTortWord = async () => {
  try {
    await formRef.value.validateFields();
    saving.value = true;
    proxy.$api.doAPI("/tortword/save", { tortwordForm: tortwordForm.value }).then((res) => {
      saving.value = false;
      if (res.code === 0) {
        tortwordModal.value = false;
        getData();
      } else {
        message.error(res.msg);
      }
    }).catch((err) => {
      saving.value = false;
      console.error(err);
    });
  } catch (errorInfo) {
    console.log("Failed:", errorInfo);
  }
}
const cancelSave = () => {
  console.log('cancelSave');
  formRef.value.clearValidate();
}
///批量删除
const deleteTortWords = (ids) => {
  dataLoading.value = true;
  proxy.$api.doAPI("/tortword/remove", { ids }).then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      getData();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
}
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

p {
  margin: 0;
  padding: 0;
}
</style>
