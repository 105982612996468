import _ from "underscore";
import axios from "axios";
import printJS from "print-js";
import html2pdf from 'html2pdf.js';
import utils from "./utils";

function checkDevice() {
  let PORT = getPrintPort();
  let url = `http://localhost:${PORT}`;
  return new Promise((resolve) => {
    axios.get(url).then(res => {
      if (res.status === 200) {
        resolve(true);
      } else {
        resolve(false);
      }
    }).catch(err => {
      resolve(false);
    });
  });
}
function printBlob(blob, count = 1) {
  let PORT = getPrintPort();
  let PrintName = getDefaultPrinter();
  blob.arrayBuffer().then(buffer => {
    let url = `http://localhost:${PORT}/print?_t=${Date.now()}&PrintNum=${count}`;
    if (PrintName) url += `&PrintName=${PrintName}`;
    axios.post(url, arrayBufferToBase64(buffer)).then(res => {

    }).catch(err => {
      let pdf_url = URL.createObjectURL(blob)
      printJS({ printable: pdf_url, type: "pdf", showModal: true });
      utils.saveBlob(blob, `${Date.now()}.pdf`);
    });
  })
}
function printUrl(pdf_url, count = 1) {
  let PORT = getPrintPort();
  let PrintName = getDefaultPrinter();
  utils.getBlob(pdf_url).then((blob) => {
    blob.arrayBuffer().then(buffer => {
      let url = `http://localhost:${PORT}/print?_t=${Date.now()}&PrintNum=${count}`;
      if (PrintName) url += `&PrintName=${PrintName}`;
      axios.post(url, arrayBufferToBase64(buffer)).then(res => {

      }).catch(err => {
        printJS({ printable: pdf_url, type: "pdf", showModal: true });
        utils.download(pdf_url, `${Date.now()}.pdf`);
      });
    })
  }).catch(() => {
    printJS({ printable: pdf_url, type: "pdf", showModal: true });
    utils.download(pdf_url, `${Date.now()}.pdf`);
  });
}
function printHtml(html, count = 1) {
  let PORT = getPrintPort();
  let PrintName = getDefaultPrinter();
  const options = {
    margin: 5,
    filename: `${Date.now()}.pdf`,
    image: { type: 'png' },
    html2canvas: { scale: 3, useCORS: true },
    jsPDF: {
      orientation: 'p',
      unit: 'mm',
      format: [98, 98],
      compress: true
    }
  };
  html2pdf().set(options).from(html, 'string').outputPdf("blob").then(pdf => {
    console.log(pdf);
    pdf.arrayBuffer().then(buffer => {
      let url = `http://localhost:${PORT}/print?_t=${Date.now()}&PrintNum=${count}`;
      if (PrintName) url += `&PrintName=${PrintName}`;
      let base64 = arrayBufferToBase64(buffer);
      axios.post(url, base64).then(res => {

      }).catch(err => {
        utils.saveBlob(pdf, options.filename);
        printJS({ printable: base64, type: 'pdf', base64: true })
      });
    })
  })
}
function getPrinters() {
  let PORT = getPrintPort();
  return new Promise((resolve) => {
    axios.get(`http://localhost:${PORT}/printlist?_t=${Date.now()}`).then(res => {
      console.log('getPrinters', res, res.status, res.data);
      if (res.status === 200) {
        console.log('getPrinters', res.data);
        if (res.data.Status == 0) {
          resolve(res.data.PrintList.split(','));
        } else {
          resolve([]);
        }
      } else {
        resolve([]);
      }
    }).catch(err => {
      resolve([]);
    });
  })
}
function getWeight() {
  let PORT = getPrintPort();
  return new Promise((resolve) => {
    axios.get(`http://localhost:${PORT}/weight?_t=${Date.now()}`).then(res => {
      console.log('getWeight', res, res.status, res.data);
      if (res.status === 200) {
        console.log('getWeight', res.data);
        if (res.data.Status == 0) {
          resolve(res.data.Weight || 0);
        } else {
          resolve(0);
        }
      } else {
        resolve(0);
      }
    }).catch(err => {
      console.error(err);
      resolve(0);
    });
  })
}
function setPrintPort(port) {
  localStorage.setItem("PRINT_PORT", port);
}
function getPrintPort() {
  return localStorage.getItem("PRINT_PORT") || "19003";
}
function setPrinterList(printerlist) {
  localStorage.setItem("PRINTER_LIST", printerlist);
}
function getPrinterList() {
  return localStorage.getItem("PRINTER_LIST");
}
function setDefaultPrinter(printer) {
  localStorage.setItem("DEFAULT_PRINTER", printer);
}
function getDefaultPrinter() {
  return localStorage.getItem("DEFAULT_PRINTER") || "";
}
function arrayBufferToBase64(buffer) {
  var binary = '';
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}
function base64ToArrayBuffer(base64) {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}

export {
  checkDevice,
  printBlob,
  printUrl,
  printHtml,
  getPrinters,
  getWeight,
  getPrintPort,
  setPrintPort,
  getPrinterList,
  getDefaultPrinter,
  setDefaultPrinter,
}