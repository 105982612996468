<template>
  <a-table bordered :columns="columns" :data-source="data" :scroll="{ x: width, y: 600 }" :loading="dataLoading"
    :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: false, showTotal: (total) => ``, }"
    @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" size="small">
    <template #title>
      <a-button class="btn-error" @click="showCombines">
        下载报表
      </a-button>
    </template>
  </a-table>
  <a-modal v-model:visible="combineModal" title="搜索词报告" width="960px" :footer="null">
    <a-table bordered :columns="ccolumns" :data-source="combines" :loading="showing" size="small">
      <template #title>
        <a-space>
          <a-button type="primary" @click="showCombines"> 刷新 </a-button>
        </a-space>
      </template>
      <template #bodyCell="{ record, column }">
        <template v-if="column.dataIndex === 'downloadstate'">
          <a-tag v-if="record.downloadstate === 'pending'" color="default">排队中</a-tag>
          <a-tag v-if="record.downloadstate === 'processing'" color="processing">正在处理</a-tag>
          <a-tag v-if="record.downloadstate === 'success'" color="success">成功</a-tag>
          <a-tag v-if="record.downloadstate === 'fail'" color="error">失败</a-tag>
        </template>
        <template v-if="column.dataIndex === 'operation'">
          <a-space>
            <a-button v-if="record.downloadstate === 'success' && record.remoteurl" type="link"
              @click="downloadReport(record)" :loading="downloading">
              {{ record.originalname }}
              <DownloadOutlined />
            </a-button>
          </a-space>
        </template>
      </template>
    </a-table>
  </a-modal>
</template>
<script setup>
import { DownloadOutlined } from '@ant-design/icons-vue';
import { message } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from "vue";
import _ from "underscore";
const columns = [
  { title: '搜索词', dataIndex: 'searchTerm', width: 200, fixed: 'left' },
  { title: '搜索频率排名1', dataIndex: 'rank1', width: 100 },
  { title: '报告日期1', dataIndex: 'day1', width: 120 },
  { title: '点击量最高的类别1', dataIndex: 'productType1', width: 150 },
  { title: '搜索频率排名2', dataIndex: 'rank2', width: 100 },
  { title: '报告日期2', dataIndex: 'day2', width: 120 },
  { title: '点击量最高的类别2', dataIndex: 'productType2', width: 150 },
  { title: '搜索频率排名3', dataIndex: 'rank3', width: 100 },
  { title: '报告日期3', dataIndex: 'day3', width: 120 },
  { title: '点击量最高的类别3', dataIndex: 'productType3', width: 150 },
  { title: '搜索频率排名4', dataIndex: 'rank4', width: 100 },
  { title: '报告日期4', dataIndex: 'day4', width: 120 },
  { title: '点击量最高的类别4', dataIndex: 'productType4', width: 150 },
  { title: '搜索频率排名5', dataIndex: 'rank5', width: 100 },
  { title: '报告日期5', dataIndex: 'day5', width: 120 },
  { title: '点击量最高的类别5', dataIndex: 'productType5', width: 150 },
  { title: '搜索频率排名6', dataIndex: 'rank6', width: 100 },
  { title: '报告日期6', dataIndex: 'day6', width: 120 },
  { title: '点击量最高的类别6', dataIndex: 'productType6', width: 150 },
  { title: '搜索频率排名7', dataIndex: 'rank7', width: 100 },
  { title: '报告日期7', dataIndex: 'day7', width: 120 },
  { title: '点击量最高的类别7', dataIndex: 'productType7', width: 150 },
];
const ccolumns = [
  { title: '开始日期', dataIndex: 'start', width: 100, },
  { title: '结束日期', dataIndex: 'end', width: 100, },
  { title: '状态', dataIndex: 'downloadstate', width: 100, },
  { title: '操作', dataIndex: 'operation', width: 300 },
];
const { proxy } = getCurrentInstance();

const page = ref(1);
const limit = ref(25);
const data = ref([]);
const length = ref(0);
const count = ref(0);
const dataLoading = ref(false);
const showing = ref(false);
const combines = ref([]);
const combineModal = ref(false);
const downloading = ref(false);

const width = computed(() => proxy.$utils.twidth(columns));
watch(() => page.value, () => {
  getData();
});
watch(() => limit.value, () => {
  if (page.value !== 1) page.value = 1;
  else getData();
});
onActivated(() => {
  getData();
});
const getData = () => {
  dataLoading.value = true;
  proxy.$api.doAPI("/searchterm/combineresults", { page: page.value, limit: limit.value }).then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      data.value = res.data.list;
      length.value = res.data.length;
      count.value = res.data.count;
      if (length.value > 0 && page.value > length.value) page.value = 1;
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
};
const showCombines = () => {
  showing.value = true;
  proxy.$api.doAPI("/searchterm/combines").then((res) => {
    showing.value = false;
    if (res.code === 0) {
      combines.value = _.map(res.data.list, n => {
        return {
          ...n,
          downloadstate: n.downloadstate || 'pending',
        }
      });
      combineModal.value = true;
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    showing.value = false;
    console.error(err);
  });
}
const downloadReport = ({ remoteurl, originalname }) => {
  downloading.value = true;
  proxy.$utils.download(remoteurl, originalname).then(() => {
    downloading.value = false;
  });
}
</script>

<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

p {
  margin: 0;
  padding: 0;
}

.text-warning {
  color: #e6a23c !important;
}
</style>
