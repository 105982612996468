<template>
  <div class="d-flex">
    <a-row style="width:100%;">
      <a-col :span="6" style="padding-right: 20px;">
        <a-card title="图片库规则" style="width: 100%;">
          <div class="secion capacity">
            <div class="title">容量使用（已用/总共）</div>
            <div class="ct">
              <a-progress :percent="percent" :show-info="false" />
              <div class="info">
                <div class="num">
                  <span style="color:green;font-weight: 700;">{{ totalSize }}MB</span> /
                  <span> {{ usedSize }}MB</span>
                </div>
                <div class="percent">{{ percent }}%</div>
              </div>
            </div>
          </div>
          <div class="secion">
            <div class="title">图片总数量</div>
            <div class="ct">
              <p>图片总数量 <span style="color:green;font-weight: 700;">{{ total_count }}</span></p>
            </div>
          </div>
          <div class="secion">
            <div class="title">图片空间路径</div>
            <div class="ct">
              <p style="word-wrap:break-word;word-break:break-all;">https://static.dfsupplychain.com/uploads/</p>
            </div>
          </div>
          <div class="secion">
            <div class="title">图片限制</div>
            <div class="ct">
              图片文件最大<span style="color:red;font-weight: 700;">2MB</span>，图片文件格式：<span
                style="color:red;font-weight: 700;">jpg/tiff/gif</span>
            </div>
          </div>
        </a-card>
      </a-col>
      <a-col :span="18" style="padding: 10px; border:1px solid #eee;">
        <div class="d-flex align-center mb-2">
          <a-input-search v-model:value="queryForm.name" placeholder="输入图片文件关键字查询" enter-button="搜索" allow-clear
            @search="getData" />
        </div>
        <div class="d-flex align-center mb-2">
          <span>类型：</span>
          <a-radio-group v-model:value="queryForm.type" @change="changeType">
            <a-radio-button value="common">通用</a-radio-button>
            <a-radio-button value="shop">店铺专属</a-radio-button>
          </a-radio-group>
          <a-select v-if="queryForm.type === 'shop'" v-model:value="queryForm.shopid" :options="shops"
            placeholder="选择店铺" style="width:200px;" allow-clear @change="changeShop"></a-select>
        </div>
        <div class="d-flex justify-space-between  mb-2">
          <a-space>
            <a-button type="primary" @click="copyImages">批量生成店铺专属图片</a-button>
            <a-button type="primary" @click="downloadImages">批量下载图片链接</a-button>
            <a-button type="danger" @click="removeImages">批量删除</a-button>
          </a-space>
          <a-button type="primary" @click="showUpload">
            <template #icon>
              <PlusCircleOutlined />
            </template> 上传图片
          </a-button>
        </div>
        <a-table bordered :columns="columns" :data-source="data" :scroll="{ x: width }" :loading="dataLoading"
          :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
          @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" row-key="_id"
          :row-selection="{ selectedRowKeys, onChange: (keys) => { selectedRowKeys = keys; } }" size="small">
          <template #headerCell="{ column }">
            <template v-if="column.dataIndex === 'name_url'">
              <p>文件名</p>
              <p>地址</p>
            </template>
            <template v-if="column.dataIndex === 'size'">
              <p>文件大小</p>
              <p>尺寸(PX)</p>
            </template>
          </template>
          <template #bodyCell="{ record, column }">
            <div v-if="column.dataIndex === 'remoteurl'" class="text-center" style="width:50px;">
              <a-tooltip v-if="record.remoteurl" placement="rightBottom" color="#fff"
                :overlayStyle="{ width: '500px', height: '500px' }">
                <template #title>
                  <img :src="record.remoteurl" style="width:500px;height:500px;object-fit: contain;" />
                </template>
                <div class="image-header">
                  <img :src="record.remoteurl" style="object-fit: contain;" />
                </div>
              </a-tooltip>
              <div v-else class="image-header">
                <img :src="$filters.image_placeholder" style="object-fit: contain;" />
              </div>
            </div>
            <template v-if="column.dataIndex === 'name_url'">
              <p style="color: #00b6ad!important; font-weight: 700;">{{ record.originalname }}</p>
              <a-tooltip>
                <template #title>
                  <a href="javascript:;" class="text-white" @click="$filters.doCopy(record.remoteurl)">复制链接</a>
                </template>
                <p style="word-wrap:break-word;word-break:break-all;">{{ record.remoteurl }}</p>
              </a-tooltip>
            </template>
            <template v-if="column.dataIndex === 'size'">
              <p>{{ record.size_ }}</p>
              <p>{{ record.width }}x{{ record.height }}</p>
            </template>
            <template v-if="column.dataIndex === 'shopid'">
              {{ getshopname(record.shopid) }}
            </template>
            <template v-if="column.dataIndex === 'operation'">
              <div class="d-flex flex-column align-center">
                <a href="javascript:;" @click="copyImage(record._id)"> 复制专属图片 </a>
                <a-dropdown>
                  <a class="ant-dropdown-link" @click.prevent>
                    更多
                    <DownOutlined />
                  </a>
                  <template #overlay>
                    <a-menu>
                      <a-menu-item>
                        <a href="javascript:;" class="underline text-info" @click="updateDimension(record._id)">更新尺寸</a>
                      </a-menu-item>
                      <a-menu-item>
                        <a-popconfirm title="删除图片" @confirm="removeImage(record._id)">
                          <a href="javascript:;" class="underline text-error">删除</a>
                        </a-popconfirm>
                      </a-menu-item>
                    </a-menu>
                  </template>
                </a-dropdown>
              </div>
            </template>
          </template>
        </a-table>
      </a-col>
    </a-row>
  </div>
  <a-drawer title="上传图片" placement="right" v-model:visible="uploadDrawer" @close="cancelUpload" width="70%"
    :closable="!uploading" :maskClosable="!uploading">
    <template #extra>
      <a-button class="mr-2" @click="cancelUpload" :disabled="uploading">取消</a-button>
      <a-button type="primary" @click="doUpload" :loading="uploading"
        :disabled="adding || uploading || files.length === 0">
        <template #icon>
          <UploadOutlined />
        </template>
        上传
      </a-button>
    </template>
    <a-space>
      <a-button type="primary" :loading="adding" :disabled="uploading" @click="addFiles">
        <template #icon>
          <PlusOutlined />
        </template>
        点击添加图片
      </a-button>
      <span class="text-error">只能上传 jpg/tiff/gif 文件，且不超过2MB</span>
    </a-space>
    <input ref="uploadRef" type="file" :accept="accept_file_formats" multiple="multiple" style="display: none"
      @change="selectImage" />
    <a-table :columns="ucolumns" :data-source="files" :pagination="false" size="small">
      <template #bodyCell="{ record, column, index }">
        <template v-if="column.dataIndex === 'index'">
          {{ index + 1 }}
        </template>
        <template v-if="column.dataIndex === 'name'">
          {{ record.file.name }}
        </template>
        <template v-if="column.dataIndex === 'type'">
          {{ record.file.type }}
        </template>
        <template v-if="column.dataIndex === 'size'">
          {{ $filters.sizeToText(record.file.size) }}
        </template>
        <template v-if="column.dataIndex === 'url'">
          <img v-if="record.url" :src="record.url" width="80" height="80" style="object-fit: contain;" />
          <img v-else :src="$filters.image_placeholder" width="80" height="80" style="object-fit: contain;" />
        </template>
        <template v-if="column.dataIndex === 'status'">
          <span v-if="record.status === 'pending'" class="text-info text-h9">等待上传</span>
          <span v-if="record.status === 'uploading'" class="text-orange text-h9">
            正在上传 {{ record.upload_rate }}
          </span>
          <span v-if="record.status === 'success'" class="text-success text-h9">
            上传成功{{ record.upload_msg }}
          </span>
          <a-tooltip v-if="record.status === 'fail'" placement="topLeft">
            <template #title> {{ record.msg }} </template>
            <span class="text-error text-h9 underline">上传失败{{ record.upload_msg }} </span>
          </a-tooltip>
          <a-tooltip v-if="record.status === 'error'" placement="topLeft">
            <template #title> {{ record.msg }} </template>
            <span class="text-error text-h9 underline">无法上传</span>
          </a-tooltip>
        </template>
        <template v-if="column.dataIndex === 'operation'">
          <a-button v-if="record.status === 'fail'" danger type="link" :disabled="uploading"
            @click="uploadFile(index)">重试</a-button>
          <a-button v-if="record.status === 'error'" danger type="link" :disabled="adding"
            @click="removeFile(index)">移除</a-button>
        </template>
      </template>
    </a-table>
  </a-drawer>
  <a-drawer title="复制店铺专属图片" placement="right" v-model:visible="copyDrawer" @close="() => { copyDrawer = false }"
    width="40%" :closable="!copying" :maskClosable="!copying">
    <template #extra>
      <a-button class="mr-2" @click="() => { copyDrawer = false }" :disabled="copying">取消</a-button>
      <a-button type="primary" @click="doCopyImage" :loading="copying" :disabled="copying">
        复制
      </a-button>
    </template>
    <a-card title="勾选店铺（为勾选店铺生成专属图片链接）" style="width: 100%；">
      <div>
        <a-checkbox v-model:checked="checkAll" :indeterminate="indeterminate" @change="toggleCheckAll">
          全选
        </a-checkbox>
      </div>
      <a-divider style="margin:10px;" />
      <a-checkbox v-for="shop in shops" :key="shop.value" v-model:checked="check_shops[shop.value]">
        {{ shop.label }}
      </a-checkbox>
    </a-card>
  </a-drawer>
</template>
<script>
import { DownOutlined, UploadOutlined, SearchOutlined, PlusOutlined, PlusCircleOutlined } from "@ant-design/icons-vue";
import { Modal, message } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from "vue";
import _ from "underscore";
const ucolumns = [
  { title: "序号", dataIndex: "index", width: 50, },
  { title: "预览", dataIndex: "url", width: 120, },
  { title: "文件名", dataIndex: "name", width: 150, },
  { title: "类型", dataIndex: "type", width: 120, },
  { title: "大小", dataIndex: "size", width: 120, },
  { title: "状态", dataIndex: "status", width: 120, },
  { title: "操作", dataIndex: "operation", width: 150, },
];
export default defineComponent({
  name: "PictureList",
  components: { DownOutlined, UploadOutlined, SearchOutlined, PlusOutlined, PlusCircleOutlined, },
  setup() {
    const { proxy } = getCurrentInstance();
    const uploadRef = ref();
    const env_DEV = ref(process.env.DEV);
    const state = reactive({
      queryForm: {
        name: "",
        type: "common",
        shopid: undefined,
      },
      data: [],
      page: 1,
      limit: 10,
      length: 0,
      count: 0,
      total_size: 0,
      used_size: 0,
      total_count: 0,
      adding: false,
      uploading: false,
      uploadDrawer: false,
      files: [],
      selectedRowKeys: [],
      copyids: [],
      copyDrawer: false,
      copying: false,
      shops: [],
      checkAll: false,
      indeterminate: false,
      check_shops: {},
      accept_file_formats: [
        '.bmp', '.jpg', '.png', '.tif', '.tiff', '.gif',
        'image/bmp', 'image/jpeg', 'image/jpg', 'image/png', 'image/tiff', 'image/gif',
      ].join(',')
    });
    const columns = computed(() => {
      let list = [];
      if (state.queryForm.type === 'common') {
        list.push(
          { title: "缩略图", dataIndex: "remoteurl", width: 80, },
          { title: "文件名/地址", dataIndex: "name_url", width: 300, },
          { title: "文件大小/尺寸(PX)", dataIndex: "size", width: 100, },
        );
      }
      if (state.queryForm.type === 'shop') {
        list.push(
          { title: "缩略图", dataIndex: "remoteurl", width: 80, },
          { title: "文件名/地址", dataIndex: "name_url", width: 300, },
          { title: "文件大小/尺寸(PX)", dataIndex: "size", width: 100, },
          { title: "店铺", dataIndex: "shopid", width: 120, }
        );
      }
      list.push(
        { title: "上传时间", dataIndex: "create_time", width: 100, },
        { title: "操作", dataIndex: "operation", width: 100, align: 'center', fixed: 'right' },
      );
      return list;
    })
    const width = computed(() => proxy.$utils.twidth(columns.value));
    const uwidth = computed(() => proxy.$utils.twidth(ucolumns));
    const totalSize = computed(() => proxy.$utils.d((state.total_size) / 1024 / 1024).toFixed(2));
    const usedSize = computed(() => {
      let v = proxy.$utils.d((state.used_size) / 1024 / 1024).toFixed(2);
      if (Number(v) > Number(totalSize.value)) v = totalSize.value;
      if (isNaN(v)) v = 0;
      return v;
    });
    const leftSize = computed(() => {
      let v = proxy.$utils.d(totalSize.value).minus(usedSize.value).toFixed(2);
      if (Number(v) < 0) v = 0;
      if (isNaN(v)) v = 0;
      return v;
    });
    const percent = computed(() => {
      let v = proxy.$utils.d(usedSize.value).dividedBy(totalSize.value).times(100).toFixed(2);
      if (Number(v) > 100) v = 100;
      if (isNaN(v)) v = 0;
      return v;
    })
    watch(() => state.check_shops, (shops) => {
      let checkAll = true, indeterminate = false;
      for (let i = 0; i < state.shops.length; i++) {
        if (shops[state.shops[i].value]) {
          indeterminate = true;
        } else {
          checkAll = false;
        }
      }
      state.checkAll = checkAll;
      state.indeterminate = indeterminate && !checkAll;
    }, { deep: true });
    watch(() => state.page, () => {
      getData();
    });
    watch(() => state.limit, () => {
      if (state.page !== 1) state.page = 1;
      else getData();
    });
    onActivated(() => {
      getData();
    });
    const getData = () => {
      state.selectedRowKeys = [];
      const hide = message.loading('正在加载', 0);
      proxy.$api.doAPI("/assets/space_images", { page: state.page, limit: state.limit, ...state.queryForm, }).then((res) => {
        hide();
        if (res.code === 0) {
          state.data = _.map(res.data.list, n => {
            return {
              ...n,
              size_: proxy.$utils.sizeToText(n.size),
              create_time: proxy.$utils.dateFormat(n.create_time, 'YYYY-MM-DD HH:mm:ss'),
            };
          });
          state.length = res.data.length;
          state.count = res.data.count;
          if (state.length > 0 && state.page > state.length) state.page = 1;
          state.total_size = res.data.total;
          state.total_count = res.data.totalcount;
          state.used_size = res.data.used;
          state.shops = res.data.shops;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        hide();
        console.error(err);
      });
    };
    const copyImage = (_id) => {
      _copyImages([_id]);
    }
    const copyImages = () => {
      if (state.selectedRowKeys.length === 0) return message.error('请选择要复制的图片');
      _copyImages(state.selectedRowKeys);
    }
    const _copyImages = (ids) => {
      const hide = message.loading('正在处理', 0);
      proxy.$api.doAPI("/assets/copypre").then((res) => {
        hide();
        if (res.code === 0) {
          state.shops = res.data.shops;
          state.check_shops = {};
          state.copyids = ids;
          state.copyDrawer = true;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        hide();
        console.error(err);
      });
    }
    const doCopyImage = () => {
      let shopids = _.filter(_.keys(state.check_shops), n => state.check_shops[n]);
      if (shopids.length === 0) return message.error('未选择店铺');
      state.copying = true;
      proxy.$api.doAPI("/assets/copy", { ids: state.copyids, shopids }).then((res) => {
        state.copying = false;
        if (res.code === 0) {
          state.copyDrawer = false;
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.copying = false;
        console.error(err);
      });
    }
    const removeImage = (_id) => {
      _removeImages([_id]);
    }
    const removeImages = () => {
      if (state.selectedRowKeys.length === 0) return message.error('请选择要删除的图片');
      Modal.confirm({
        title: '提示',
        content: `删除图片`,
        onOk() {
          _removeImages(state.selectedRowKeys);
        },
        onCancel() {
        },
      })
    }
    const _removeImages = (ids) => {
      const hide = message.loading('正在删除', 0);
      proxy.$api.doAPI("/assets/remove", { ids }).then((res) => {
        hide();
        if (res.code === 0) {
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        hide();
        console.error(err);
      });
    }
    const downloadImages = () => {
      if (state.selectedRowKeys.length === 0) return message.error('请选择要下载的图片');
      let columns = [{ header: '文件名', key: 'name', width: 50 }], list = [];
      let { type } = state.queryForm;
      if (type === 'common') columns.push({ header: '链接', key: 'url', width: 100 });
      if (type === 'shop') columns.push({ header: '链接', key: 'url', width: 100 }, { header: '店铺', key: 'shop', width: 20 });
      for (let i = 0; i < state.selectedRowKeys.length; i++) {
        let item = _.findWhere(state.data, { _id: state.selectedRowKeys[i] });
        if (type === 'common') list.push({ name: item.originalname, url: item.remoteurl });
        if (type === 'shop') list.push({ name: item.originalname, url: item.remoteurl, shop: getshopname(item.shopid) });
      }
      const hide = message.loading("正在下载", 0);
      proxy.$utils.saveListAsExcel(columns, list).then(() => {
        hide();
        message.success("下载成功");
      }).catch(e => {
        message.error("下载失败");
        console.error(e);
        hide();
      });
    }
    const updateDimension = (_id) => {
      const hide = message.loading('正在处理', 0);
      proxy.$api.doAPI("/assets/update_dimension", { _id }).then((res) => {
        hide();
        if (res.code === 0) {
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        console.error(err);
        hide();
      });
    }
    const showUpload = () => {
      state.files = [];
      state.uploadDrawer = true;
    }
    const addFiles = () => {
      uploadRef.value.click();
    }
    const removeFile = (index) => {
      state.files.splice(index, 1);
    }
    const checkfile = (file) => {
      const validType = state.accept_file_formats.indexOf(file.type) > -1;
      const validSize = file.size / 1024 / 1024 < 2;
      if (!validType) {
        return { code: 1, msg: '只能上传jpg/tiff/gif 文件' };
      } else if (!validSize) {
        return { code: 1, msg: '图片不能超过2MB' };
      }
      return { code: 0 };
    }
    const selectImage = async (e) => {
      let files = e.target.files;
      if (state.files.length + files.length > 100) {
        uploadRef.value.value = null;
        return message.error(`不能超过100张图片`);
      }
      state.adding = true;
      for (let i = 0; i < files.length; i++) {
        let { code, msg } = checkfile(files[i]);
        let status = "pending";
        if (code === 1) status = "error";
        let url = await new Promise((resolve) => {
          let reader = new FileReader();
          reader.addEventListener('load', function () {
            resolve(reader.result);
          }, false);
          reader.readAsDataURL(files[i]);
        });
        await proxy.$utils.sleep(17);
        state.files.unshift({
          _id: proxy.$utils.uuid(), url,
          file: files[i], status, msg,
        });
      }
      uploadRef.value.value = null;
      state.adding = false;
    }
    const doUpload = async () => {
      if (state.files.length === 0) return;
      for (let i = 0; i < state.files.length; i++) {
        await _doUpload(i);
      }
      console.log('doUpload-finished');
      state.uploading = false;
    }
    const _doUpload = async (i) => {
      let { status, file } = state.files[i];
      if (status !== "pending" && status !== "fail") return;
      state.uploading = true;
      const slicefiles = proxy.$utils.sliceFile(file.size);
      const faid = proxy.$utils.uuid();
      let loaded = 0, total = file.size;
      let timestamp = Date.now();
      for (let j = 0; j < slicefiles.length; j++) {
        let slicefile = slicefiles[j];
        const formData = new FormData();
        if (slicefile.chunks > 1) {
          let _file = file.slice(slicefile.from, slicefile.to);
          formData.set("file", _file);
        } else {
          formData.set("file", file);
        }
        formData.set("faid", faid);
        formData.set("chunk", slicefile.chunk);
        formData.set("chunks", slicefile.chunks);
        formData.set("originalname", file.name);
        formData.set("type", "product_image");
        state.files[i].status = 'uploading';
        try {
          let res = await proxy.$api.doUpload("/assets/plupload", formData, function onUploadProgress(e) {
            console.log('onUploadProgress', e);
            if (e.lengthComputable) {
              loaded += e.loaded;
              let upload_rate = Math.round((loaded * 100) / total);
              if (upload_rate > 100) upload_rate = 100;
              state.files[i].upload_rate = upload_rate + '%';
              if (loaded >= total) {
                timestamp = Date.now() - timestamp;
                state.files[i].upload_msg = `，用时：${timestamp.toFixed(0)}ms`;
              }
            }
          });
          if (res.code === 0) {
            if (slicefile.chunk + 1 === slicefile.chunks) {
              if (res.data?.url) {
                state.files[i].status = 'success';
                state.files[i].url = res.data.url;
              } else {
                state.files[i].status = 'fail';
                state.files[i].msg = '系统异常';
              }
            }
          } else {
            message.error(res.msg);
            state.files[i].status = 'fail';
            state.files[i].msg = res.msg;
            break;
          }
        } catch (e) {
          console.error(e);
          message.error(e.message);
          state.files[i].status = 'fail';
          state.files[i].msg = e.message;
          break;
        }
      }
    }
    const uploadFile = async (i) => {
      await _doUpload(i);
      state.uploading = false;
    }
    const cancelUpload = () => {
      state.uploadDrawer = false;
      state.files = [];
    }
    const toggleCheckAll = (e) => {
      console.log('toggleCheckAll');
      let checked = e.target.checked;
      if (checked) checkAllShops();
      else uncheckAllShops();
    }
    const checkAllShops = () => {
      console.log('checkAllShops');
      let check_shops = {};
      for (let i = 0; i < state.shops.length; i++) {
        check_shops[state.shops[i].value] = true;
      }
      state.check_shops = check_shops;
    }
    const uncheckAllShops = () => {
      console.log('uncheckAllShops');
      state.check_shops = {};
    }
    const changeType = (e) => {
      console.log('changeType', e);
      state.queryForm.shopid = undefined;
      getData();
    }
    const changeShop = (v) => {
      console.log('changeShop', v);
      getData();
    }
    const getshopname = (shopid) => {
      return _.findWhere(state.shops, { value: shopid })?.label || '';
    }
    return {
      ...toRefs(state),
      env_DEV,
      uploadRef,
      columns,
      ucolumns,
      width,
      uwidth,
      totalSize,
      usedSize,
      leftSize,
      percent,
      getData,
      copyImages,
      copyImage,
      doCopyImage,
      removeImage,
      removeImages,
      downloadImages,
      updateDimension,
      showUpload,
      addFiles,
      removeFile,
      selectImage,
      doUpload,
      uploadFile,
      cancelUpload,
      toggleCheckAll,
      changeType,
      changeShop,
      getshopname,
    };
  },
});
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

:deep(.ant-table-tbody > tr > td) {
  padding: 8px 8px !important;
}

:deep(.ant-card-head) {
  padding: 0 8px;
}

:deep(.ant-card-body) {
  padding: 8px;
}

:deep(.ant-image) {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.secion {
  padding: 20px 0;
  border-bottom: 1px solid #eee;

  .title {
    font-size: 15px;
  }
}

.capacity {
  .info {
    display: flex;
    justify-content: space-between;
  }
}

:deep(a) {
  text-decoration: underline;
}

p {
  margin: 0 !important;
  padding: 0 !important;
}
</style>
