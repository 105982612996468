<template>
    <a-form :model="formBox">
        <a-row>
            <a-col :span="6">
                <a-form-item label="箱子长度">
                <a-input class="demo-input" v-model:value="Length" :defaultValue="0" />&nbsp;cm
                </a-form-item>
            </a-col>
            <a-col :span="6">
                <a-form-item label="箱子宽度">
                <a-input class="demo-input" v-model:value="Width" :defaultValue="0" />&nbsp;cm
                </a-form-item>
            </a-col>
            <a-col :span="6">
                <a-form-item label="箱子高度">
                <a-input class="demo-input" v-model:value="Height" :defaultValue="0" />&nbsp;cm
                </a-form-item>
            </a-col>
            <a-col :span="4">
                <a-form-item label="箱子毛重">
                <a-input class="demo-input" v-model:value="Weight" :defaultValue="0" />&nbsp;cm
                </a-form-item>
            </a-col>
        </a-row>
    </a-form>
</template>
<script>
import { DownOutlined, SearchOutlined, PlusCircleOutlined, QuestionCircleFilled } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import {
defineComponent,
ref,
reactive,
toRefs,
watch,
computed,
onMounted,
onActivated,
getCurrentInstance,
} from "vue";
import _ from "underscore";
import utils from "@/utils";
export default defineComponent({
    name: 'boxchild',
    components: {
      DownOutlined,
      SearchOutlined,
      PlusCircleOutlined,
      QuestionCircleFilled,
    },
    setup() {
        const { proxy } = getCurrentInstance();
        const formRef = ref();
        const state = reactive({
            boxData: {},
            index: '1',
            formBox: {},
        });
        onMounted(() => {
        });
        const guid = () =>{
            return utils.uuid();
        };
        /**
            * 提供给父组件拿取表单值
            * @method getData
        */
        const getData = () =>{
            return state.formBox;
            // 如果你需要基于这个index来标记哪一个数据
            // const result = {}
            // const formData = this.form.getFieldsValue() || []
            // Object.keys(formData).forEach(item => {
            //   result[`${item}${this.index}`] = formData[item]
            // })
            // return result
            // return this.form.getFieldsValue()
        };
        /**
            * 提供给父组件设置表单值
            * @method setFormData
        */
        const setFormData = (data) =>{
            if (data && typeof data === 'object' && data instanceof Object) {
                state.formBox = data;
                // 如果你想更谨慎些，你可以这样
                // const fields = Object.keys(this.form.getFieldsValue())
                // const formData = {}
                // Object.keys(data).forEach(it => {
                //   if (fields.includes(it)) {
                //     formData[it] = data[it]
                //   }
                // })
                // this.form.setFieldsValue(formData)
            }
        };
        return {
            ...toRefs(state),
            formRef,
            guid,
            getData,
            setFormData,
        };
    }
  });
</script>
<style lang="less" scoped>
</style>
  
  