<template>
  <div class="d-flex align-center mb-2">
    <span style="width:80px;">查询搜索：</span>
    <a-input-search v-model:value="searchText" placeholder="输入ID/账号/手机号/姓名查询" enter-button="搜索" allow-clear
      @search="getData" />
  </div>
  <div class="d-flex justify-space-between align-center mb-2">
    <a-button type="primary" @click="getData">刷新数据</a-button>
    <a-button type="primary" @click="addStaff">
      <template #icon>
        <PlusCircleOutlined />
      </template>新增账号
    </a-button>
  </div>
  <a-table :columns="columns" :data-source="data" :scroll="{ x: width }" :loading="dataLoading"
    :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
    @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" size="small">
    <template #bodyCell="{ record, column }">
      <template v-if="column.dataIndex === 'initpwd'">
        {{ (record.initpwd && record.initpwd !== null) ? record.initpwd : '已修改' }}
      </template>
      <template v-if="column.dataIndex === 'role'">
        {{ getrolename(record) }}
      </template>
      <template v-if="column.dataIndex === 'state'">
        <span v-if="record.enabled" class="text-success">启用</span>
        <span v-else class="text-error">禁用</span>
      </template>
      <template v-if="column.dataIndex === 'operation'">
        <a-space>
          <a href="javascript:;" @click="editStaff(record)">编辑</a>
          <!-- <a-popconfirm title="确定要删除?" @confirm="onDelete(record.key)">
            <a href="javascript:;" class="text-error">删除</a>
          </a-popconfirm> -->
        </a-space>
      </template>
    </template>
  </a-table>
  <a-modal v-model:visible="staffModal" :title="`${staffForm._id !== '' ? '编辑' : '新增'}员工账号`" @ok="saveStaff"
    @cancel="cancelSave" ok-text="确认" cancel-text="取消" :confirm-loading="saving"
    :cancel-button-props="{ disabled: saving }" :maskClosable="false" :closable="!saving">
    <a-form ref="formRef" name="staffForm" :model="staffForm" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }"
      autocomplete="off">
      <a-form-item v-if="staffForm._id !== ''" label="ID">
        <a-input v-model:value="staffForm.number" disabled>
        </a-input>
      </a-form-item>
      <a-form-item label="账号" name="username" :rules="staffForm._id === '' ? username_rule : []">
        <a-input v-model:value="staffForm.username" placeholder="请输入账号" :disabled="staffForm._id !== '' || saving">
        </a-input>
      </a-form-item>
      <a-form-item label="密码" name="password">
        <a-input v-model:value="staffForm.password" :placeholder="staffForm._id === '' ? '请输入密码' : '密码留空则不修改'"
          :disabled="saving">
        </a-input>
      </a-form-item>
      <a-form-item label="姓名" name="name">
        <a-input v-model:value="staffForm.name" placeholder="请输入姓名" :disabled="saving">
        </a-input>
      </a-form-item>
      <a-form-item label="手机号" name="mobile">
        <a-input-group compact>
          <a-select v-model:value="staffForm.mobile_area_code" :disabled="saving" style="width: 30%">
            <a-select-option value="+86">+86</a-select-option>
          </a-select>
          <a-input v-model:value="staffForm.mobile" placeholder="手机号码" type="number" :maxlength="11" :disabled="saving"
            style="width: 70%">
          </a-input>
        </a-input-group>
      </a-form-item>
      <a-form-item label="角色" name="role" :rules="[{ required: true, message: '角色不能为空' }]">
        <a-select v-model:value="staffForm.role" :options="roles" :disabled="saving" show-search
          :filter-option="$filters.filterOption">
        </a-select>
      </a-form-item>
      <a-form-item label="状态" name="enabled">
        <a-checkbox v-model:checked="staffForm.enabled">
          <span v-if="staffForm.enabled" class="text-success">启用</span>
          <span v-else class="text-error">禁用</span>
        </a-checkbox>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script setup>
import { DownOutlined, SearchOutlined, PlusCircleOutlined } from '@ant-design/icons-vue';
import { message } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from "vue";
import _ from "underscore";
const columns = [
  { title: '员工ID', dataIndex: 'number', width: 120, },
  { title: '账号', dataIndex: 'username', width: 120, },
  { title: '默认密码', dataIndex: 'initpwd', width: 120, },
  { title: '手机号', dataIndex: 'mobile', width: 150, },
  { title: '姓名', dataIndex: 'name', width: 120, },
  { title: '角色', dataIndex: 'role', width: 150, },
  { title: '状态', dataIndex: 'state', width: 100, },
  { title: '创建时间', dataIndex: 'create_time', width: 120, },
  { title: '操作', dataIndex: 'operation', width: 150, fixed: 'right' },
];

const { proxy } = getCurrentInstance();
const formRef = ref();

const searchText = ref("");
const page = ref(1);
const limit = ref(10);
const data = ref([]);
const length = ref(0);
const count = ref(0);
const dataLoading = ref(false);
const roles = ref([]);
const saving = ref(false);
const staffModal = ref(false);
const staffForm = ref({});

const width = computed(() => proxy.$utils.twidth(columns));
watch(() => page.value, () => getData());
watch(() => limit.value, () => {
  if (page.value !== 1) page.value = 1;
  else getData();
});
onActivated(() => {
  getData();
});
const getData = () => {
  dataLoading.value = true;
  proxy.$api.doAPI("/account/stafflist", { page: page.value, limit: limit.value, searchText: searchText.value, }).then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      data.value = _.map(res.data.list, n => {
        return {
          ...n,
          create_time: proxy.$utils.dateFormat(n.create_time, 'YYYY-MM-DD HH:mm:ss'),
        };
      });
      length.value = res.data.length;
      count.value = res.data.count;
      if (length.value > 0 && page.value > length.value) page.value = 1;
      roles.value = _.map(res.data.roles, n => ({ ...n, label: n.name, value: n._id }));
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
};
const addStaff = () => {
  staffForm.value = {
    _id: "",
    username: "",
    password: "",
    name: "",
    mobile_area_code: "+86",
    mobile: "",
    role: "",
    enabled: true,
  };
  staffModal.value = true;
}
const editStaff = (item) => {
  staffForm.value = {
    _id: item._id,
    number: item.number,
    username: item.username,
    password: "",
    name: item.name,
    mobile_area_code: item.mobile_area_code,
    mobile: item.mobile,
    role: item.role,
    enabled: item.enabled,
  };
  staffModal.value = true;
}
const saveStaff = async () => {
  try {
    await formRef.value.validateFields();
    saving.value = true;
    proxy.$api.doAPI("/account/savestaff", { dataForm: staffForm.value, }).then((res) => {
      saving.value = false;
      if (res.code === 0) {
        message.success("保存成功");
        staffModal.value = false;
        getData();
      } else {
        message.error(res.msg);
      }
    }).catch((err) => {
      saving.value = false;
      console.error(err);
    });
  } catch (errorInfo) {
    console.log("Failed:", errorInfo);
  }
}
const cancelSave = () => {
  console.log('cancelSave');
  formRef.value.clearValidate();
}
const getrolename = (item) => {
  return _.findWhere(roles.value, { value: item.role })?.label;
}
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

p {
  margin: 0;
  padding: 0;
}
</style>
