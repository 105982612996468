<template>
  <a-modal v-model:visible="modal" :title="title" :maskClosable="false" :closable="!uploading" width="1000px"
    @cancel="() => modal = false">
    <div class="d-flex justify-space-between mb-2">
      <a-space>
        <a-button type="primary" @click="getTasks">刷新数据</a-button>
      </a-space>
      <a-space>
        <a-upload :before-upload="beforeUpload" :disabled="uploading" :showUploadList="false">
          <a-button type="danger" :disabled="uploading">
            <UploadOutlined />
            上传文件
          </a-button>
        </a-upload>
      </a-space>
    </div>
    <a-table :columns="columns" :data-source="tasks" :scroll="{ x: width }" :loading="loading"
      :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
      @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" size="small">
      <template #bodyCell="{ record, index, column }">
        <template v-if="column.dataIndex === 'index'">
          {{ index + 1 }}
        </template>
        <template v-if="column.dataIndex === 'file'">
          <a href="javascript:;" @click="downloadfile(record)">{{ record.originalname }}</a>
        </template>
        <template v-if="column.dataIndex === 'state'">
          <a-tag v-if="record.state === 0" color="processing">正在处理</a-tag>
          <template v-if="record.state === 1">
            <a-tag v-if="record.result.fail > 0" color="error">异常</a-tag>
            <a-tag v-else color="success">成功</a-tag>
          </template>
          <a-tag v-if="record.state === -1" color="error">失败</a-tag>
        </template>
        <template v-if="column.dataIndex === 'result'">
          <template v-if="record.state === 1">
            <a-tooltip title="总数" color="blue">
              <a href="javascript:;" class="text-info">{{ record.result.count }}</a>
            </a-tooltip>
            /
            <a-tooltip title="成功" color="green">
              <a href="javascript:;" class="text-success">{{ record.result.success }}</a>
            </a-tooltip>
            /
            <a-tooltip title="异常" color="red">
              <a href="javascript:;" class="text-error" @click="showErrors(record)">{{ record.result.fail }}</a>
            </a-tooltip>
          </template>
          <template v-if="record.state === -1">
            <span class="text-error"> {{ record.result }} </span>
          </template>
        </template>
        <template v-if="column.dataIndex === 'operation'">
          <a-space>
            <a v-if="record.state === -1 || (record.result && record.result.fail === record.result.count)"
              href="javascript:;" @click="resetTask(record._id)">重试</a>
          </a-space>
        </template>
      </template>
    </a-table>
    <template #footer>
      <div class="d-flex justify-space-between align-center">
        <a :href="`${publicPath}template/${file}`" target="_blank">
          {{ file }}
        </a>
        <a-button @click="() => modal = false" :disabled="uploading">关闭</a-button>
      </div>
    </template>
  </a-modal>
  <a-drawer title="导入失败结果" placement="right" v-model:visible="drawer" width="30%">
    <a-list size="small" bordered :data-source="errors"
      :pagination="{ pageSize: 20, hideOnSinglePage: true, showSizeChanger: false, }">
      <template #renderItem="{ item }">
        <a-list-item>{{ item }}</a-list-item>
      </template>
    </a-list>
  </a-drawer>
</template>
<script>
import { UploadOutlined, } from '@ant-design/icons-vue';
import { message } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from "vue";
import _ from "underscore";
const columns = [
  { title: '序号', dataIndex: 'index', width: 60, },
  { title: '文件', dataIndex: 'file', width: 200, },
  { title: '状态', dataIndex: 'state', width: 100, },
  { title: '结果', dataIndex: 'result', width: 200, },
  { title: '时间', dataIndex: 'create_time', width: 150, },
  { title: '操作', dataIndex: 'operation', width: 100, },
];
export default defineComponent({
  name: 'UploadTask',
  components: { UploadOutlined, },
  setup() {
    const { proxy } = getCurrentInstance();
    const publicPath = ref(process.env.BASE_URL);
    const state = reactive({
      title: '',
      file: '',
      type: '',
      modal: false,
      uploading: false,
      loading: false,
      page: 1,
      limit: 10,
      length: 0,
      count: 0,
      tasks: [],
      drawer: false,
      errors: [],
    });
    const width = computed(() => proxy.$utils.twidth(columns));
    watch(() => state.page, () => getTasks());
    watch(() => state.limit, () => {
      if (state.page !== 1) state.page = 1;
      else getTasks();
    });
    const showUpload = ({ title, file, type }) => {
      state.title = title;
      state.file = file;
      state.type = type;
      state.modal = true;
      getTasks();
    }
    const getTasks = () => {
      state.loading = true;
      proxy.$api.doAPI("/uploadtask/list", { page: state.page, limit: state.limit, type: state.type }).then((res) => {
        state.loading = false;
        if (res.code === 0) {
          state.tasks = _.map(res.data.list, n => {
            return {
              ...n, create_time: proxy.$utils.dateFormat(n.create_time, 'YYYY-MM-DD HH:mm:ss'),
            }
          });
          state.length = res.data.length;
          state.count = res.data.count;
          if (state.length > 0 && state.page > state.length) state.page = 1;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.loading = false;
        console.error(err);
      });
    }
    const resetTask = (_id) => {
      state.loading = true;
      proxy.$api.doAPI("/uploadtask/reset", { _id }).then((res) => {
        state.loading = false;
        if (res.code === 0) {
          getTasks();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.loading = false;
        console.error(err);
      });
    }
    const showErrors = (task) => {
      if (task.result.fail > 0) {
        state.errors = task.result.errors;
        state.drawer = true;
      }
    }
    const downloadfile = (record) => {
      proxy.$utils.download(record.remoteurl, record.originalname);
    }
    const beforeUpload = file => {
      console.log('beforeUpload');
      let checkresult = checkfile(file);
      if (checkresult.code != 0) return message.error(checkresult.msg);
      doUploadExcel(file);
    };
    const checkfile = (file) => {
      const isXLSX = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file.type === "application/vnd.ms-excel";
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isXLSX) {
        return { code: 1, msg: "只允许上传XLSX文件" };
      } else if (!isLt5M) {
        return { code: 1, msg: "单个文件不能超过5MB" };
      }
      return { code: 0 };
    }
    const doUploadExcel = async (file) => {
      state.uploading = true;
      const slicefiles = proxy.$utils.sliceFile(file.size);
      const faid = proxy.$utils.uuid();
      for (let i = 0; i < slicefiles.length; i++) {
        let slicefile = slicefiles[i];
        const formData = new FormData();
        if (slicefile.chunks > 1) {
          let _file = file.slice(slicefile.from, slicefile.to);
          formData.set("file", _file);
        } else {
          formData.set("file", file);
        }
        formData.set("faid", faid);
        formData.set("chunk", slicefile.chunk);
        formData.set("chunks", slicefile.chunks);
        formData.set("originalname", file.name);
        formData.set("type", state.type);
        try {
          let res = await proxy.$api.doUpload("/uploadtask/plupload", formData);
          if (res.code === 0) {
            if (slicefile.chunk + 1 === slicefile.chunks) {
              getTasks();
            }
          } else {
            message.error(res.msg);
            break;
          }
        } catch (e) {
          console.error(e);
          message.error(e.message);
          break;
        }
      }
      console.log('doUploadExcel-finished');
      state.uploading = false;
    }
    return {
      ...toRefs(state),
      publicPath,
      columns,
      width,
      showUpload,
      getTasks,
      resetTask,
      showErrors,
      downloadfile,
      beforeUpload,
    };
  }
});
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

p {
  margin: 0;
  padding: 0;
}
</style>

