<template>
  <a-tabs v-model:activeKey="activeTab">
    <a-tab-pane key="1" tab="竞品采集">
      <a-row :gutter="16">
        <a-col :span="24">
          <a-textarea v-model:value="url" placeholder="请填写竞品链接，每次一个竞品链接" :rows="6" :disabled="saving" />
        </a-col>
      </a-row>
      <div class="sureSubmit">
        <a-space>
          <a-button type="primary" :loading="saving" @click="submit">开始采集</a-button>
          <a-button :disabled="saving" @click="clear">清空</a-button>
        </a-space>
      </div>
    </a-tab-pane>
  </a-tabs>
  <div class="list">
    <div class="d-flex justify-space-between mb-2">
      <a-space>
        <a-button type="primary" @click="getData">刷新数据</a-button>
      </a-space>
    </div>
    <a-tabs v-model:activeKey="claimTab" @change="getData">
      <a-tab-pane key="1" :tab="`全部(${counts})`"></a-tab-pane>
      <a-tab-pane key="2" :tab="`未保存(${unsave_count})`"></a-tab-pane>
      <a-tab-pane key="3" :tab="`已保存(${save_count})`"></a-tab-pane>
    </a-tabs>
    <a-table bordered :columns="columns" :data-source="data" :scroll="{ x: width }" :loading="dataLoading"
      :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
      @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" row-key="_id"
      :row-selection="{ selectedRowKeys, onChange: (keys) => { selectedRowKeys = keys; }, }" size="small">
      <template #bodyCell="{ record, column }">
        <div v-if="column.dataIndex === 'main_image_url'" class="text-center" style="width: 85px">
          <a-tooltip v-if="record.img_url[0]" placement="rightBottom" color="#fff"
            :overlayStyle="{ width: '500px', height: '500px' }">
            <template #title>
              <img :src="record.img_url[0]" style="width:500px;height:500px;object-fit: contain;" />
            </template>
            <div class="image-header">
              <img :src="record.img_url[0]" style="object-fit: contain;" />
            </div>
          </a-tooltip>
          <div v-else class="image-header">
            <img :src="$filters.image_placeholder" style="object-fit: contain;" />
          </div>
          <p>
            ASIN：<a target="_blank" :href="record.url">{{ record.asin }}</a>
          </p>
        </div>
        <template v-if="column.dataIndex === 'title'">
          <a-tooltip>
            <template #title>
              {{ record.title }}
            </template>
            <p class="in3line">
              {{ record.title }}
            </p>
          </a-tooltip>
        </template>
        <template v-if="column.dataIndex === 'marks'">
          <p>{{ record.product_description?.marks }}</p>
        </template>
        <template v-if="column.dataIndex === 'evaluation'">
          <p>{{ record.product_description?.evaluation }}</p>
        </template>
        <template v-if="column.dataIndex === 'date_first_available'">
          <p>{{ record.product_description?.date_first_available }}</p>
        </template>
        <template v-if="column.dataIndex === 'is_save'">
          <a-tag color="success" v-if="record.is_save === true">已保存</a-tag>
        </template>
        <template v-if="column.dataIndex === 'operation'">
          <div class="d-flex flex-column">
            <a href="javascript:;" @click="edit(record._id)"> 详情 </a>
            <!-- <a href="javascript:;" @click="edit(record._id)"> 保存 </a> -->
          </div>
        </template>
      </template>
    </a-table>
  </div>
</template>
<script>
import { LoadingOutlined, DownOutlined } from "@ant-design/icons-vue";
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  watch,
  computed,
  onMounted,
  onActivated,
  getCurrentInstance,
} from "vue";
import _ from "underscore";
import { message, Modal } from "ant-design-vue";
const columns = [
  { title: "缩略图", dataIndex: "main_image_url", width: 100 },
  { title: "关键词", dataIndex: "keyword", width: 150 },
  { title: "所属类目", dataIndex: "category", width: 150 },
  { title: "标题", dataIndex: "title", width: 300 },
  { title: "评分", dataIndex: "marks", width: 60 },
  { title: "评论量", dataIndex: "evaluation", width: 60 },
  { title: "售价", dataIndex: "unit_price", width: 60 },
  { title: "上架时间", dataIndex: "date_first_available", width: 100 },
  { title: "创建时间", dataIndex: "create_time", width: 130 },
  { title: "是否保存", dataIndex: "is_save", width: 100 },
  { title: "操作", dataIndex: "operation", width: 100, fixed: "right" },
];
export default defineComponent({
  name: "ShopsAdvertisingCompetitorsCollect",
  components: { LoadingOutlined, DownOutlined },
  setup() {
    const { proxy } = getCurrentInstance();
    const state = reactive({
      activeTab: "1",
      selectedRowKeys: [],
      claimTab: "2",
      saving: false,
      page: 1,
      limit: 10,
      length: 0,
      count: 0,
      url: "",
      counts: 0,
      unsave_count: 0,
      save_count: 0,

      dataLoading: false,
      data: [],
    });
    const width = computed(() => proxy.$utils.twidth(columns));
    watch(
      () => state.page,
      () => getData()
    );
    watch(
      () => state.limit,
      () => {
        if (state.page !== 1) state.page = 1;
        else getData();
      }
    );
    onActivated(() => {
      getData();
    });
    const getData = () => {
      state.dataLoading = true;
      proxy.$api
        .doAPI("/advertising/competitive_list", {
          page: state.page,
          limit: state.limit,
          claimTab: state.claimTab,
        })
        .then((res) => {
          state.dataLoading = false;
          if (res.code === 0) {
            state.data = res.data.list;
            state.length = res.data.length;
            state.count = res.data.count;
            if (state.length > 0 && state.page > state.length) state.page = 1;
            state.counts = res.data.counts;
            state.save_count = res.data.save_count;
            state.unsave_count = res.data.counts - res.data.save_count;
          } else {
            message.error(res.msg);
          }
        })
        .catch((err) => {
          state.dataLoading = false;
          console.error(err);
        });
    };
    const submit = () => {
      try {
        if (state.url.trim() === "") return "请输入竞品网址";
        state.saving = true;
        proxy.$api
          .doAPI("/advertising/competitive_collect", { url: state.url })
          .then((res) => {
            state.saving = false;
            if (res.code === 0) {
              getData();
              state.url = "";
            } else {
              message.error(res.msg);
            }
          })
          .catch((err) => {
            state.saving = false;
            console.error(err);
          });
      } catch (errorInfo) {
        state.saving = false;
        console.log("Failed:", errorInfo);
      }
    };
    const clear = () => {
      state.url = "";
    };
    const edit = (id) => {
      proxy.$utils.downloadBrowser({ href: `/shops/advertising/competitors_form?id=${id}` });
    };
    return {
      ...toRefs(state),
      columns,
      width,
      getData,
      submit,
      clear,
      edit,
    };
  },
});
</script>
<style lang="less" scoped>
:deep(.ant-row.ant-form-item) {
  text-align: right;
}

p {
  margin: 0;
  padding: 0;
}

.sureSubmit {
  height: 39px;
  text-align: right;
  margin: 10px 0px 0px 0px;
}
</style>
