<template>
  <div class="header">
    <div class="search">
      <a-row>
        <a-col>
          <span style="width:75px;">站点：</span>
        </a-col>
        <a-col>
          <div>
            <a-tag :color="station === 0 ? 'processing' : 'default'" @click="changeStation(0)" class="click">全部</a-tag>
            <a-tag v-for="item in stations" :key="item.value" :color="station === item.value ? 'processing' : 'default'"
              @click="changeStation(item.value)" class="click">{{ item.label }}</a-tag>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="search">
      <a-row>
        <a-col>
          <span style="width: 75px">时间：</span>
        </a-col>
        <a-col>
          <div>
            <a-range-picker v-model:value="daterange" value-format="YYYY-MM-DD" @change="getData" />
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="search">
      <a-row>
        <a-col><span style="width:75px;">店铺：</span></a-col>
        <a-col :span="23">
          <div>
            <a-select v-model:value="shopid" placeholder="请选择店铺" mode="tags" :options="shops" style="width: 1000px"
              @change="(v) => changeShop(v)"></a-select>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
  <div class="divtop">
    <a-space>
      <div class="fitem">
        <div class="divpl">
          <p class="divp">广告销售额($)</p>
        </div>
        <div class="divpr">
          <p class="divsp">{{ saleSum }}</p>
        </div>
      </div>
      <div class="fitem">
        <div class="divpl">
          <p class="divp">广告费($)</p>
        </div>
        <div class="divpr">
          <span class="divsp">{{ costSum }}</span>
        </div>
      </div>
      <div class="fitem">
        <div class="divpl">
          <p class="divp">点击量($)</p>
        </div>
        <div class="divpr">
          <span class="divsp">{{ clickSum }}</span>
        </div>
      </div>
      <div class="fitem">
        <div class="divpl">
          <p class="divp">订单量</p>
        </div>
        <div class="divpr">
          <span class="divsp">{{ orderCount }}</span>
        </div>
      </div>
      <div class="fitem">
        <div class="divpl">
          <p class="divp">曝光量</p>
        </div>
        <div class="divpr">
          <span class="divsp">{{ exposureCount }}</span>
        </div>
      </div>
      <div class="fitem">
        <div class="divpl">
          <p class="divp">ACOS</p>
        </div>
        <div class="divpr">
          <span class="divsp">{{ acosRate }}%</span>
        </div>
      </div>
      <div class="fitem">
        <!-- 单次点击成本 -->
        <div class="divpl">
          <p class="divp">CPC($)</p>
        </div>
        <div class="divpr">
          <span class="divsp">{{ cpcRate }}</span>
        </div>
      </div>
      <div class="fitem">
        <!-- 单次点击成本 -->
        <div class="divpl">
          <p class="divp">ROAS</p>
        </div>
        <div class="divpr">
          <span class="divsp">{{ roas }}</span>
        </div>
      </div>
    </a-space>
  </div>
  <a-tabs v-model:activeKey="activeKey" @change="handclickStationAndshop">
    <a-tab-pane :key="1" tab="按站点">
      <a-table :columns="columns" :data-source="dataStationAndShop" :scroll="{ x: width }" :loading="dataStationing"
        :pagination="{ pageSize: 10 }">
        <template #headerCell="{ column }">
          <template v-if="column.dataIndex === 'acosRate'">
            <span>ACOS</span>&nbsp;
            <a-tooltip placement="bottomLeft">
              <template #title>
                <span>ACOS = 花费额/销售额</span>
              </template>
              <InfoCircleOutlined />
            </a-tooltip>
          </template>
          <template v-if="column.dataIndex === 'conversionRate'">
            <span>转化率</span>&nbsp;
            <a-tooltip placement="bottomLeft">
              <template #title>
                <span>转化率 = 订单量/点击量</span>
              </template>
              <InfoCircleOutlined />
            </a-tooltip>
          </template>
        </template>
        <template #bodyCell="{ record, column }">
          <template v-if="column.dataIndex === 'cost'">
            <p>{{ record.currencyCode }}${{ record.cost }}</p>
          </template>
          <template v-if="column.dataIndex === 'sales'">
            <p>{{ record.currencyCode }}${{ record.sales }}</p>
          </template>
        </template>
      </a-table>
    </a-tab-pane>
    <a-tab-pane :key="2" tab="按店铺">
      <a-table :columns="columnss" :data-source="dataStationAndShop" :scroll="{ x: widths }" :loading="dataShop">
        <template #headerCell="{ column }">
          <template v-if="column.dataIndex === 'acosRate'">
            <span>ACOS</span>&nbsp;
            <a-tooltip placement="bottomLeft">
              <template #title>
                <span>ACOS = 花费额/销售额</span>
              </template>
              <InfoCircleOutlined />
            </a-tooltip>
          </template>
          <template v-if="column.dataIndex === 'conversionRate'">
            <span>转化率</span>&nbsp;
            <a-tooltip placement="bottomLeft">
              <template #title>
                <span>转化率 = 订单量/点击量</span>
              </template>
              <InfoCircleOutlined />
            </a-tooltip>
          </template>
        </template>
        <template #bodyCell="{ record, column }">
          <template v-if="column.dataIndex === 'shop'">
            <p>{{ record.shop?.name }}</p>
          </template>
          <template v-if="column.dataIndex === 'cost'">
            <p>{{ record.currencyCode }}${{ record.cost }}</p>
          </template>
          <template v-if="column.dataIndex === 'sales'">
            <p>{{ record.currencyCode }}${{ record.sales }}</p>
          </template>
        </template>
      </a-table>
    </a-tab-pane>
  </a-tabs>
  <div class="divbottom">
    <a-row>
      <a-col :span="12">
        <div style="margin: 10px 10px 10px 10px">
          <a-card title="广告销售额Top5">
            <template #extra>
              <a-radio-group v-model:value="mode" style="margin: 0 0 0 0">
                <a-radio-button :value="1">店铺</a-radio-button>
                <a-radio-button :value="2">商品</a-radio-button>
              </a-radio-group>
            </template>
            <a-table :columns="columns1" :data-source="mode === 1 ? dataShopSales : dataProSales"
              :scroll="{ x: width1 }" :loading="dataSaleCosting" :pagination="false">
              <template #headerCell="{ column }">
                <template v-if="mode === 2 && column.dataIndex === 'obj'">
                  <span>商品</span>
                </template>
              </template>
              <template #bodyCell="{ record, column }">
                <template v-if="column.dataIndex === 'obj' && mode === 1">
                  <p>{{ record.obj?.name }}</p>
                </template>
                <template v-if="column.dataIndex === 'obj' && mode === 2">
                  <a-row>
                    <a-col :span="4">
                      <div style="
                          display: flex;
                          justify-content: center;
                          align-items: center;
                          width: 55px;
                          height: 55px;
                        ">
                        <a-image :src="record.obj?.product?.cover || record.obj?.image_url
                          " width="40px" height="40px" style="object-fit: contain; height: 100%" :preview="!!record.obj?.product?.cover ||
                            !!record.obj?.image_url
                            ">
                          <template #placeholder>
                            <a-image :src="$filters.image_placeholder" width="40px" height="40px" :preview="false" />
                          </template>
                        </a-image>
                      </div>
                    </a-col>
                    <a-col :offset="1">
                      <div>
                        <p class="pclass">ASIN：{{ record.obj?.asin }}</p>
                        <p class="pclass">
                          SKU：{{ record.obj?.product?.sku || record.obj?.sku }}
                        </p>
                        <p class="pclass">
                          名称：{{ record.obj?.product?.name_cn }}
                        </p>
                      </div>
                    </a-col>
                  </a-row>
                </template>
                <template v-if="column.dataIndex === 'cost'">
                  <p>{{ record.currencyCode }}${{ record.cost }}</p>
                </template>
                <template v-if="column.dataIndex === 'sales'">
                  <p>{{ record.currencyCode }}${{ record.sales }}</p>
                </template>
              </template>
            </a-table>
          </a-card>
        </div>
      </a-col>
      <a-col :span="12">
        <div style="margin: 10px 10px 10px 10px">
          <a-card title="广告活动效果Top5">
            <template #extra>
              <a-radio-group v-model:value="mode1" style="margin: 0 0 0 0">
                <a-radio-button :value="1">最佳</a-radio-button>
                <a-radio-button :value="2">最差</a-radio-button>
              </a-radio-group>
            </template>
            <a-table :columns="columns2" :data-source="mode1 === 1 ? dataAdvBest : dataAdvWorst" :scroll="{ x: width2 }"
              :loading="dataAdving" :pagination="false">
              <template #bodyCell="{ record, column }">
                <template v-if="column.dataIndex === 'advName'">
                  <p>{{ record.campaign?.name }}</p>
                </template>
                <template v-if="column.dataIndex === 'shop'">
                  <p>{{ record.shop?.name }}</p>
                </template>
                <template v-if="column.dataIndex === 'acosRate'">
                  <p>{{ record.acosRate }}%</p>
                </template>
                <template v-if="column.dataIndex === 'cost'">
                  <p>{{ record.currencyCode }}${{ record.cost }}</p>
                </template>
                <template v-if="column.dataIndex === 'sales'">
                  <p>{{ record.currencyCode }}${{ record.sales }}</p>
                </template>
              </template>
            </a-table>
          </a-card>
        </div>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="12">
        <div style="margin: 10px 10px 10px 10px">
          <a-card title="广告花费Top5">
            <template #extra>
              <a-radio-group v-model:value="mode2" style="margin: 0 0 0 0">
                <a-radio-button :value="1">店铺</a-radio-button>
                <a-radio-button :value="2">商品</a-radio-button>
              </a-radio-group>
            </template>
            <a-table :columns="columns1" :data-source="mode2 === 1 ? dataShopCost : dataProCost" :scroll="{ x: width1 }"
              :loading="dataSaleCosting" :pagination="false">
              <template #headerCell="{ column }">
                <template v-if="mode2 === 'product' && column.dataIndex === 'obj'">
                  <span>商品</span>
                </template>
              </template>
              <template #bodyCell="{ record, column }">
                <template v-if="column.dataIndex === 'obj' && mode2 === 1">
                  <p>{{ record.obj?.name }}</p>
                </template>
                <template v-if="column.dataIndex === 'obj' && mode2 === 2">
                  <a-row>
                    <a-col :span="4">
                      <div style="
                          display: flex;
                          justify-content: center;
                          align-items: center;
                          width: 55px;
                          height: 55px;
                        ">
                        <a-image :src="record.obj?.product?.cover || record.obj?.image_url
                          " width="40px" height="40px" style="object-fit: contain; height: 100%" :preview="!!record.obj?.product?.cover ||
                            !!record.obj?.image_url
                            ">
                          <template #placeholder>
                            <a-image :src="$filters.image_placeholder" width="40px" height="40px" :preview="false" />
                          </template>
                        </a-image>
                      </div>
                    </a-col>
                    <a-col :offset="1">
                      <div>
                        <p class="pclass">ASIN：{{ record.obj?.asin }}</p>
                        <p class="pclass">
                          SKU：{{ record.obj?.product?.sku || record.obj?.sku }}
                        </p>
                        <p class="pclass">
                          名称：{{ record.obj?.product?.name_cn }}
                        </p>
                      </div>
                    </a-col>
                  </a-row>
                </template>
                <template v-if="column.dataIndex === 'cost'">
                  <p>{{ record.currencyCode }}${{ record.cost }}</p>
                </template>
                <template v-if="column.dataIndex === 'sales'">
                  <p>{{ record.currencyCode }}${{ record.sales }}</p>
                </template>
              </template>
            </a-table>
          </a-card>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import {
  DownOutlined,
  QuestionCircleFilled,
  InfoCircleOutlined,
} from "@ant-design/icons-vue";
import { message, Modal } from "ant-design-vue";
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  watch,
  computed,
  onMounted,
  onActivated,
  getCurrentInstance,
} from "vue";
import _ from "underscore";
const columns = [
  { title: "站点", dataIndex: "station", width: 150, align: "center" },
  { title: "广告费", dataIndex: "cost", width: 100, align: "center" },
  { title: "销售额", dataIndex: "sales", width: 100, align: "center" },
  { title: "点击量", dataIndex: "clicks", width: 100, align: "center" },
  { title: "ACOS", dataIndex: "acosRate", width: 100, align: "center" },
  { title: "曝光量", dataIndex: "impressions", width: 100, align: "center" },
  { title: "订单量", dataIndex: "orders", width: 100, align: "center" },
  { title: "转化率", dataIndex: "conversionRate", width: 100, align: "center" },
];
const columnss = [
  { title: "店铺", dataIndex: "shop", width: 150, align: "center" },
  { title: "广告费", dataIndex: "cost", width: 100, align: "center" },
  { title: "销售额", dataIndex: "sales", width: 100, align: "center" },
  { title: "点击量", dataIndex: "clicks", width: 100, align: "center" },
  { title: "ACOS", dataIndex: "acosRate", width: 100, align: "center" },
  { title: "曝光量", dataIndex: "impressions", width: 100, align: "center" },
  { title: "订单量", dataIndex: "orders", width: 100, align: "center" },
  { title: "转化率", dataIndex: "conversionRate", width: 100, align: "center" },
];
const columns1 = [
  { title: "店铺", dataIndex: "obj", width: 200, align: "center" },
  { title: "广告费", dataIndex: "cost", width: 100, align: "center" },
  { title: "销售额", dataIndex: "sales", width: 100, align: "center" },
];
const columns2 = [
  { title: "广告活动", dataIndex: "advName", width: 200, align: "center" },
  { title: "店铺", dataIndex: "shop", width: 200, align: "center" },
  { title: "ACOS", dataIndex: "acosRate", width: 100, align: "center" },
  { title: "广告费", dataIndex: "cost", width: 100, align: "center" },
  { title: "销售额", dataIndex: "sales", width: 100, align: "center" },
];

export default defineComponent({
  name: "ShopsAdvertisingAdPreview",
  components: {
    DownOutlined,
    QuestionCircleFilled,
    InfoCircleOutlined,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const state = reactive({
      stations: [
        { label: "北美", value: "NA" },
        { label: "欧洲", value: "EU" },
        { label: "远东", value: "FE" },
      ],
      dataStationing: false,
      dataShoping: false,
      dataSaleCosting: false,
      dataAdving: false,
      activeKey: 1,
      shopid: [],
      shop: 0,
      station: 0,
      dataStationAndShop: [],
      dataShopSales: [],
      dataShopCost: [],
      dataProSales: [],
      dataProCost: [],
      dataAdvBest: [],
      dataAdvWorst: [],
      shops: [],
      daterange: [],
      mode: 1,
      mode1: 1,
      mode2: 1,
      saleSum: "",
      costSum: "",
      clickSum: "",
      orderCount: "",
      exposureCount: "",
      acosRate: "",
      roas: "",
      cpcRate: "",
    });
    const width = computed(() => proxy.$utils.twidth(columns));
    const widths = computed(() => proxy.$utils.twidth(columnss));
    const width1 = computed(() => proxy.$utils.twidth(columns1));
    const width2 = computed(() => proxy.$utils.twidth(columns2));
    onActivated(() => {
      let beforeDay = proxy.$utils.moment().subtract(1, "days").format('YYYY-MM-DD');
      let before7Day = proxy.$utils.moment().subtract(7, "days").format('YYYY-MM-DD');
      state.daterange = [];
      state.daterange.push(before7Day);
      state.daterange.push(beforeDay);
      getShops();
    });
    const changeShop = (item) => {
      state.shopid = item;
      state.shop = _.findWhere(state.shops, { value: item });
      getData();
    };
    const changeStation = (station) => {
      state.station = station;
      getData();
    };
    const handclickStationAndshop = () => {
      getStationAndshop();
    };
    const getStationAndshop = () => {
      state.dataStationing = true;
      state.dataShoping = true;
      proxy.$api
        .doAPI("/advertising/stationAndshop", {
          station: state.station,
          shop: state.shop,
          daterange: state.daterange,
          type: state.activeKey,
        })
        .then((res) => {
          state.dataStationing = false;
          state.dataShoping = false;
          if (res.code === 0) {
            state.dataStationAndShop = res.data.list;
          } else {
            message.error(res.msg);
          }
        })
        .catch((err) => {
          console.error(err);
          state.dataStationing = false;
          state.dataShoping = false;
        });
    };
    const getSalesTop5 = () => {
      state.dataSaleCosting = true;
      proxy.$api
        .doAPI("/advertising/saleTop5", {
          station: state.station,
          shop: state.shop,
          daterange: state.daterange,
          type: state.mode,
        })
        .then((res) => {
          state.dataSaleCosting = false;
          if (res.code === 0) {
            state.dataSale = res.data.list;
            state.dataShopSales = res.data.listShopSales;
            state.dataShopCost = res.data.listShopCost;
            state.dataProSales = res.data.listProSales;
            state.dataProCost = res.data.listProCost;
          } else {
            message.error(res.msg);
          }
        })
        .catch((err) => {
          console.error(err);
          state.dataSaleCosting = false;
        });
    };
    const getAdvTop5 = () => {
      state.dataAdving = true;
      proxy.$api
        .doAPI("/advertising/advNameTop5", {
          station: state.station,
          shop: state.shop,
          daterange: state.daterange,
        })
        .then((res) => {
          state.dataAdving = false;
          if (res.code === 0) {
            state.dataAdvBest = res.data.listBest;
            state.dataAdvWorst = res.data.listWorst;
          } else {
            message.error(res.msg);
          }
        })
        .catch((err) => {
          console.error(err);
          state.dataAdving = false;
        });
    };
    const getShops = () => {
      proxy.$api.doAPI("/product/shops", { platform: "" }).then((res) => {
        if (res.code === 0) {
          state.shops = res.data.shops;
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        console.error(err);
      });
    };
    const getData = () => {
      proxy.$api
        .doAPI("/advertising/statislist", {
          station: state.station,
          shop: state.shop,
          daterange: state.daterange,
        })
        .then((res) => {
          if (res.code === 0) {
            // state.shops = [
            //   ..._.map(res.data.shops || [], (n) => ({
            //     ...n,
            //     label: n.name,
            //     value: n._id,
            //   })),
            // ];
            state.saleSum = res.data.saleSum;
            state.costSum = res.data.costSum;
            state.clickSum = res.data.clickSum;
            state.orderCount = res.data.orderCount;
            state.exposureCount = res.data.exposureCount;
            state.acosRate = res.data.acosRate;
            state.roas = res.data.roas;
            state.cpcRate = res.data.cpcRate;
          } else {
            message.error(res.msg);
          }
        })
        .catch((err) => {
          console.error(err);
        });
      getStationAndshop();
      getSalesTop5();
      getAdvTop5();
    };
    const handleChange = (tag, checked) => {
      const { selectedTags } = state;
      const nextSelectedTags = checked
        ? [...selectedTags, tag]
        : selectedTags.filter((t) => t !== tag);
      console.log("You are interested in: ", nextSelectedTags);
      state.selectedTags = nextSelectedTags;
    };
    return {
      ...toRefs(state),
      columns,
      width,
      columnss,
      widths,
      columns1,
      width1,
      columns2,
      width2,
      changeShop,
      changeStation,
      getData,
      handleChange,
      handclickStationAndshop,
      getSalesTop5,
    };
  },
});
</script>
<style lang="less" scoped>
.fitem {
  background-color: #f5f5f5;
  padding: 1em;
  border-radius: 5px;
  height: 60px;
  width: 210px;
}

.divtop {
  border: 2px solid #f5f5f5;
  padding: 1em;
  height: 90px;
  border-radius: 5px;
  display: flex;
  flex: 1;
  overflow-y: auto;
}

.divbottom {
  margin: 10px 0;
  border: 2px solid #f5f5f5;
  padding: 1em;
  border-radius: 5px;
  flex: 1;
}

.divpl {
  float: left;
  height: 40px;
}

.divpr {
  float: right;
  height: 40px;
}

.divsp {
  font-weight: bold;
  font-size: 18px;
  margin: 0;
  padding: 0;
}

.divp {
  color: #666666;
  margin: 0;
  padding: 0;
  font-size: 15px;
}

.header {
  .search {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }

  .op {
    display: flex;
    justify-content: space-between;
  }
}

p {
  margin: 0;
  padding: 0;
  font-size: 15px;
}

.pclass {
  margin: 0;
  padding: 0;
  text-align: left;
  font-size: 12px;
}

.thumb-class {
  width: 50px;
  height: 50px;
}
</style>
