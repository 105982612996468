<template>
  <div class="header">
    <a-row :gutter="24">
      <a-col :span="6">
        <a-form-item label="选择店铺">
          <a-select v-model:value="shopid" placeholder="请选择店铺" mode="tags" :options="shops"></a-select>
        </a-form-item>
      </a-col>
      <a-col :span="6">
        <a-form-item label="选择日期">
          <a-range-picker v-model:value="daterange" value-format="YYYY-MM-DD" :allowClear="false" />
        </a-form-item>
      </a-col>
      <a-col :span="6">
        <a-button type="primary" @click="getData">查询</a-button>
      </a-col>
    </a-row>
    <div class="review-header">
      <a-row>
        <a-col :span="4">
          <a-statistic title="SKU数" :value="obj.sku_num" />
        </a-col>
        <a-col :span="4">
          <a-statistic title="销售数" :value="obj.sell_counts" />
        </a-col>
        <a-col :span="4">
          <a-statistic title="总订单数" :value="obj.order_counts" />
        </a-col>
        <a-col :span="4">
          <a-statistic title="销售额" :precision="2" :value="obj.amount" />
        </a-col>
        <a-col :span="4">
          <a-statistic title="退款订数" :value="obj.refund_counts" />
        </a-col>
        <a-col :span="4">
          <a-statistic title="退款金额" :precision="2" :value="obj.refund_amounts" />
        </a-col>
      </a-row>
    </div>
  </div>
  <a-table :columns="columns" :data-source="data" bordered :scroll="{ x: width, y: 600 }" size="small">
    <template #bodyCell="{ column, record }">
      <template v-if="column.dataIndex === 'pic'">
        <a-image :src="record.prod?.cover" width="80px" height="80px" style="object-fit: contain;height:100%;"
          :preview="!!record.prod?.cover">
          <template #placeholder>
            <a-image :src="record.prod?.cover" width="80px" height="80px" :preview="false" />
          </template>
        </a-image>
      </template>
      <template v-if="column.dataIndex === 'info'">
        <p>{{ record.prod?.sku || '-' }}</p>
        <p>{{ record.prod?.name_cn || '-' }}</p>
      </template>
    </template>
  </a-table>
</template>
<script>
import { DownOutlined, DownloadOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from 'vue';
import _ from "underscore";
const columns = [
  { title: '图片', dataIndex: 'pic', width: 100, align: 'center', },
  { title: '产品信息', dataIndex: 'info', width: 150, align: 'center', },
  { title: '店铺', dataIndex: 'shop_name', width: 150, align: 'center', },
  { title: '销售数量', dataIndex: 'sell_count', width: 150, align: 'center', },
  { title: '销售额', dataIndex: 'tot_amount', width: 150, align: 'center', },
  { title: '退款订单数', dataIndex: 'refund_count', width: 150, align: 'center', },
  { title: '退款金额', dataIndex: 'refund_amount', width: 150, align: 'center', },
  { title: '操作', dataIndex: 'action', width: 100, align: 'center', },
];
export default defineComponent({
  name: 'OperationAnalysisSales',//产品表现
  components: {
    DownOutlined,
    DownloadOutlined
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const state = reactive({
      data: [],
      tex: '',
      firdaterange: [],
      daterange: [],
      shopid: [],
      shops: [],
      obj: {
        sku_num: 0,
        sell_counts: 0,
        order_counts: 0,
        amount: 0,
        refund_counts: 0,
        refund_amounts: 0.00
      },
    });
    const width = computed(() => proxy.$utils.twidth(columns));
    onMounted(() => {
      let beforeDay = proxy.$utils.moment().subtract(0, "days").format('YYYY-MM-DD')
      let before7Day = proxy.$utils.moment().subtract(6, "days").format('YYYY-MM-DD')
      state.daterange = [];
      state.daterange.push(before7Day);
      state.daterange.push(beforeDay);
      getShops();
    });
    const getShops = () => {
      state.dateLoading = true;
      proxy.$api.doAPI("/product/shops", { platform: "", }).then((res) => {
        state.dateLoading = false;
        if (res.code === 0) {
          state.shops = res.data.shops;
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dateLoading = false;
        console.error(err);
      });
    }
    const getData = () => {
      proxy.$api.doAPI("/finance/dataanalysis/productexp", { daterange: state.daterange, shopid: state.shopid, }).then((res) => {
        if (res.code === 0) {
          state.data = res.data.list;
          state.obj = {
            sku_num: res.data.sku_num,
            sell_counts: res.data.sell_counts,
            order_counts: res.data.order_counts,
            amount: res.data.amount,
            refund_counts: res.data.refund_counts,
            refund_amounts: res.data.refund_amounts
          },
            // state.shops = [{ label: "全部店铺", value: "" }, ..._.map(res.data.own_shops || [], n => ({ ...n, label: n.name, value: n._id }))];
            state.firdaterange = [];
        } else {
          message.error(res.msg);
          state.firdaterange = [];
        }
      }).catch((err) => {
        console.error(err);
        state.firdaterange = [];
      });
    };
    const handleChange = (tag, checked) => {
      const { selectedTags } = state;
      const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter(t => t !== tag);
      console.log('You are interested in: ', nextSelectedTags);
      state.selectedTags = nextSelectedTags;
    };
    return {
      ...toRefs(state),
      columns,
      width,
      getData,
      handleChange,
    };
  }
});
</script>
<style lang="less" scoped>
.review-header {
  padding: 24px;
}

:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

.header {
  .search {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }

  .op {
    display: flex;
    justify-content: space-between;
  }
}

p {
  margin: 0;
  padding: 0;
}
</style>

