<template>
  <a-card size="small">
    <template #title>
      <a-row :gutter="16">
        <a-col :span="4">
          <a-form-item label="站点" style="margin-bottom:unset;">
            <a-select v-model:value="searchForm.region" :options="sellingcenters" :disabled="loading"
              style="width: 100%;" />
          </a-form-item>
        </a-col>
        <a-col :span="20">
          <a-form-item label="ASIN" style="margin-bottom:unset;">
            <a-input-search v-model:value="searchForm.asin" placeholder="ASIN" enter-button="搜索" @search="doSearch"
              :loading="loading" :disabled="loading" />
          </a-form-item>
        </a-col>
      </a-row>
    </template>
    <a-table size="small" bordered :columns="columns" :data-source="listings" :loading="loading" :pagination="false">
      <template #bodyCell="{ record, column }">
        <template v-if="column.dataIndex === 'image_url'">
          <a-tooltip v-if="record.image_url" color="white" placement="rightBottom">
            <template #title>
              <a-image :src="record.image_url" width="480px" height="480px" style="object-fit: contain;height:100%;"
                :preview="false">
                <template #placeholder>
                  <a-image :src="$filters.image_placeholder" width="480px" height="480px" :preview="false" />
                </template>
              </a-image>
            </template>
            <a href="javascript:;">
              <a-image :src="record.image_url" width="80px" height="80px" style="object-fit: contain;height:100%;"
                :preview="false">
                <template #placeholder>
                  <a-image :src="$filters.image_placeholder" width="80px" height="80px" :preview="false" />
                </template>
              </a-image>
            </a>
          </a-tooltip>
          <a-image v-else :src="$filters.image_placeholder" width="80px" height="80px"
            style="object-fit: contain;height:100%;" :preview="false">
            <template #placeholder>
              <a-image :src="$filters.image_placeholder" width="80px" height="80px" :preview="false" />
            </template>
          </a-image>
        </template>
        <template v-if="column.dataIndex === 'product_type'">
          <p>{{ record.product_type }}</p>
          <p>{{ record.productType }}</p>
        </template>
        <template v-if="column.dataIndex === 'asin'">
          <a :href="record.amazon_product_url" target="_blank">
            <span :class="`fi fi-${record.region.toLowerCase()}`"></span> {{ record.asin }}
          </a>
        </template>
        <template v-if="column.dataIndex === 'operation'">
          <a v-if="record.genmai_added" href="javascript:;" class="text-gray">
            已添加跟卖库
          </a>
          <a v-else href="javascript:;" class="text-error" @click="addGenmai(record)">
            添加到跟卖库
          </a>
        </template>
      </template>
    </a-table>
  </a-card>
</template>

<script setup>
import _ from 'underscore';
import { ref, reactive, computed, getCurrentInstance, onActivated, watch } from 'vue';
import { message } from 'ant-design-vue';

const columns = ref([
  { title: '图片', dataIndex: 'image_url', width: 100, },
  { title: '标题', dataIndex: 'title', width: 200, },
  { title: '类目', dataIndex: 'product_type', width: 120, },
  { title: 'ASIN', dataIndex: 'asin', width: 150, },
  { title: '操作', dataIndex: 'operation', width: 100, },
]);
const { proxy } = getCurrentInstance();

const loading = ref(false);
const sellingcenters = ref([]);
const searchForm = ref({ region: "US", asin: "", });
const listings = ref([]);

const init = () => {
  loading.value = true;
  proxy.$api.doAPI2("/dev/amazon/genmai/init").then((res) => {
    loading.value = false;
    if (res.code === 0) {
      sellingcenters.value = res.data.sellingcenters;
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    loading.value = false;
    console.error(err);
  });
}
const doSearch = () => {
  if (!searchForm.value.region) return message.error("站点不能为空");
  if (!searchForm.value.asin) return message.error("ASIN不能为空");
  loading.value = true;
  proxy.$api.doAPI2("/dev/amazon/genmai/search", searchForm.value).then((res) => {
    loading.value = false;
    if (res.code === 0) {
      listings.value = [res.data.listing];
      if (res.data.listing) {
        searchForm.value.region = res.data.listing.region;
      }
    } else {
      message.error(res.msg);
      listings.value = [];
    }
  }).catch((err) => {
    loading.value = false;
    listings.value = [];
    console.error(err);
  });
}
const addGenmai = (item) => {
  loading.value = true;
  proxy.$api.doAPI2("/dev/amazon/genmai/add", item).then((res) => {
    loading.value = false;
    if (res.code === 0) {
      doSearch();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    loading.value = false;
    console.error(err);
  });
}
defineExpose({ init });
onActivated(() => {
  init();
});
</script>

<style lang="less" scoped>
@import "/node_modules/flag-icons/css/flag-icons.min.css";

p {
  margin: 0;
  padding: 0;
}
</style>