<template>
  <div class="header">
    <div class="search">
      <a-row>
        <a-col>
          <span style="width:75px;">选择站点：</span>
        </a-col>
        <a-col>
          <div>
            <a-tag :color="station === 0 ? 'processing' : 'default'" @click="changeStation(0)" class="click" >全部</a-tag>
            <a-tag v-for="item in stations" :key="item.value" :color="station === item.value ? 'processing' : 'default'" @click="changeStation(item.value)" class="click">{{ item.label }}</a-tag>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="search">
      <a-row>
        <a-col>
          <span style="width:75px;">活动状态：</span>
        </a-col>
        <a-col>
          <div>
            <a-tag :color="states === '0' ? 'processing' : 'default'" @click="changeStates('0')" class="click">全部</a-tag>
            <a-tag :color="states === '1' ? 'processing' : 'default'" @click="changeStates('1')" class="click">进行中</a-tag>
            <a-tag :color="states === '2' ? 'processing' : 'default'" @click="changeStates('2')" class="click">超预算</a-tag>
            <a-tag :color="states === '3' ? 'processing' : 'default'" @click="changeStates('3')" class="click">预设中</a-tag>
            <a-tag :color="states === '4' ? 'processing' : 'default'" @click="changeStates('4')" class="click">未完成</a-tag>
            <a-tag :color="states === '5' ? 'processing' : 'default'" @click="changeStates('5')" class="click">已暂停</a-tag>
            <a-tag :color="states === '6' ? 'processing' : 'default'" @click="changeStates('6')" class="click">已结束</a-tag>
            <a-tag :color="states === '7' ? 'processing' : 'default'" @click="changeStates('7')" class="click">异常</a-tag>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="search">
      <a-row>
        <a-col><span style="width:75px;">选择店铺：</span></a-col>
        <a-col :span="22">
          <div>
            <a-select v-model:value="shopid" placeholder="请选择店铺" mode="tags" :options="shops" style="width: 1000px" @change="(v) => changeShop(v)"></a-select>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="search">
      <span style="width:75px;">查询搜索：</span>
      <a-input-search v-model:value="searchText" placeholder="输入名称搜索" enter-button="搜索" @search="searchData" />
    </div>
    <a-tabs v-model:activeKey="activeKey" type="card" @change="handclick">
      <a-tab-pane :key="1" tab="广告列表">
        <a-table :columns="columns" :data-source="data" :scroll="{ x: width }" :loading="dataLoading"
          :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
          @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" size="small">
          <template #bodyCell="{ record, column }">
            <template v-if="column.dataIndex === 'state'">
              <p v-if="record.state === 'ARCHIVED'" style="font-size: 12px; color: #67C23A;">已结束</p>
              <p v-if="record.state === 'PAUSED'" style="font-size: 12px; color: #67C23A;">已暂停</p>
              <p v-if="record.state === 'ENABLED' && record.budget >= record.suggestedBudget" style="font-size: 12px; color: #67C23A;">进行中</p>
              <p v-if="record.state === 'ENABLED' && record.budget < record.suggestedBudget" style="font-size: 12px; color: #FFA500;">超预算</p>
            </template>
            <template v-if="column.dataIndex === 'keyword'">
              <a-tooltip  v-if="record.keywords.length > 0 ">
                <template #title>
                  <p v-for="(keyword,index) in record.keywords" :key="index">{{keyword}}</p>
                </template>
                <div style="width:150px;height:50px; overflow:hidden;">
                  <p v-for="(keyword,index) in record.keywords" :key="index">{{keyword}}</p>
                </div>
              </a-tooltip>
              <p v-if="record.keywords.length === 0 ">-</p>
            </template>
            <template v-if="column.dataIndex === 'clickThroughRate'">
              <p>{{record.clickThroughRate}}%</p>
            </template>
            <template v-if="column.dataIndex === 'cost'">
              <a-tag v-if="record.cost !== undefined && record.cost !== null && record.cost !== '-'" >
                <p style="font-size: 16px;">{{record.countryCode}}${{record.cost}}</p>
              </a-tag>
              <p v-if="record.cost === '-'">-</p>
            </template>
            <template v-if="column.dataIndex === 'budget'">
              <a-tag>
                <p style="font-size: 16px;">{{record.countryCode}}${{record.budget}}</p>
              </a-tag><br/>
              <p v-if="record.budgetType === 'DAILY'">每天</p>
              <p v-else>总计</p>
            </template>
            <template v-if="column.dataIndex === 'suggestedBudget'">
              <p style="font-size: 16px;">{{record.countryCode}}${{record.suggestedBudget}}</p>
              <br/>
            </template>
            <template v-if="column.dataIndex === 'measureSell'">
              <a-tooltip v-if="record.measureSellLower !== ''">
                <template #title>
                  <p>{{record.countryCode}}${{record.measureSellLower}} - {{record.countryCode}}${{record.measureSellUpper}}</p>
                </template>
                <div style="width:150px;height:50px; overflow:hidden; ">
                  <p>{{record.countryCode}}${{record.measureSellLower}} - {{record.countryCode}}${{record.measureSellUpper}}</p>
                </div>
              </a-tooltip>
              <p v-if="record.measureSellLower === ''">-</p>
            </template>
            <template v-if="column.dataIndex === 'measureShow'">
              <p>{{record.measureShowLower}} - {{record.measureShowUpper}}</p>
            </template>
            <template v-if="column.dataIndex === 'measureTap'">
              <p>{{record.measureTapLower}} - {{record.measureTapUpper}}</p>
            </template>
            <template v-if="column.dataIndex === 'type'">
              <p>商品推广</p>
              <p v-if="record.targetingType === 'AUTO'" style="color: #c5c0c0">自动投放</p>
              <p v-if="record.targetingType === 'MANUAL'" style="color: #c5c0c0">手动投放</p>
            </template>
            <template v-if="column.dataIndex === 'shop'">
              <p>{{record.shop?.name}}</p>
            </template>
            <template v-if="column.dataIndex === 'costPerClick'">
              <p v-if="record.costPerClick !== undefined && record.costPerClick !== '-'">{{record.countryCode}}${{record.costPerClick}}</p>
            </template>
            <template v-if="column.dataIndex === 'percentTimeInBudget'">
              <div style="width: 20px" v-if="record.percentTimeInBudget !== ''">
                <a-progress :steps="5" :percent="record.percentTimeInBudget" size="small"
                :format="percent => percent == 100.00 ? '100%' : `${percent}%`"/>
              </div>
              <p v-if="record.percentTimeInBudget === ''">-</p>
            </template>
            <template v-if="column.dataIndex === 'operation'">
              <a href="javascript:;" v-if="record.keywords.length > 0 " @click="pageJump(record)">关键词</a>
            </template>
          </template>
        </a-table>
      </a-tab-pane>
      <a-tab-pane :key="2" tab="广告分析">
        <a-card>
          <a-row>
            <a-col :span="10" class="hitem">
              <div class="chart">
                <apexchart ref="chartOptions" type="bar" width="500" height="500" :options="chartOptions" :series="series"></apexchart>
              </div>
            </a-col>
            <a-col :span="2" class="hitem">
            </a-col>
            <a-col :span="10" class="hitem">
              <div class="chart">
                <a-table :columns="columns1" :data-source="data1" :scroll="{ x: width1 }" :loading="dataLoading" :pagination="{ pageSize: 6, }" size="small" />
              </div>
            </a-col>
          </a-row>
        </a-card>
        <a-card>
          <a-row>
            <a-col :span="10" class="hitem">
              <div class="chart">
                <apexchart ref="chartOptions1" type="bar" width="500" height="500" :options="chartOptions1" :series="series1"></apexchart>
              </div>
            </a-col>
            <a-col :span="2" class="hitem">
            </a-col>
            <a-col :span="10" class="hitem">
              <div class="chart">
                <a-table :columns="columns2" :data-source="data1" :scroll="{ x: width2 }" :loading="dataLoading1" :pagination="{ pageSize: 6, }" size="small" />
              </div>
            </a-col>
          </a-row>
        </a-card>
        <a-card>
          <a-row>
            <a-col :span="10" class="hitem">
              <div class="chart">
                <apexchart ref="chartOptions2" type="bar" width="500" height="500" :options="chartOptions2" :series="series2"></apexchart>
              </div>
            </a-col>
            <a-col :span="2" class="hitem"/>
            <a-col :span="10" class="hitem">
              <div class="chart">
                <a-table :columns="columns3" :data-source="data1" :scroll="{ x: width3 }" :loading="dataLoading2" :pagination="{ pageSize: 6, }" size="small" />
              </div>
            </a-col>
          </a-row>
        </a-card>
      </a-tab-pane>
      <template #rightExtra v-if="activeKey == '2'">
        <a-radio-group v-model:value="days" @change="getDatas">
          <a-radio-button value="-1">昨天</a-radio-button>
          <a-radio-button value="7">近7天</a-radio-button>
          <a-radio-button value="14">近14天</a-radio-button>
          <a-radio-button value="30">近30天</a-radio-button>
          <!-- <a-radio-button value="90">近3个月</a-radio-button> -->
        </a-radio-group>
      </template>
    </a-tabs>
  </div>
</template>
<script>
import { DownOutlined } from '@ant-design/icons-vue';
import { message, Modal } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from 'vue';
import _ from "underscore";
const columns = [
  { title: '广告组', dataIndex: 'adGroupName', fixed: 'left', width: 200, align: 'center', },
  { title: '广告活动', dataIndex: 'name', fixed: 'left', width: 150, align: 'center', },
  { title: '店铺', dataIndex: 'shop', fixed: 'left', width: 150, align: 'center', },
  { title: '状态', dataIndex: 'state', width: 100, align: 'center', },
  { title: '点击率', dataIndex: 'clickThroughRate', width: 100, align: 'center', },
  { title: '点击量', dataIndex: 'clicks', width: 100, align: 'center', },
  { title: '展现量', dataIndex: 'impressions', width: 100, align: 'center', },
  { title: '总成本', dataIndex: 'cost', width: 150, align: 'center', },
  { title: '单次点击成本(CPC)', dataIndex: 'costPerClick', width: 100, align: 'center', },
  { title: '平均预算内活跃时间', dataIndex: 'percentTimeInBudget', width: 100, align: 'center', },
  { title: '预算', dataIndex: 'budget', width: 100, align: 'center', },
  { title: '建议预算', dataIndex: 'suggestedBudget', width: 100, align: 'center', },
  { title: '预计错过的销售', dataIndex: 'measureSell', width: 150, align: 'center', },
  { title: '预计错过的展示', dataIndex: 'measureShow', width: 150, align: 'center', },
  { title: '预计错过的点击', dataIndex: 'measureTap', width: 150, align: 'center', },
  { title: '开始日期', dataIndex: 'stateDate', width: 150, align: 'center', },
  { title: '类型', dataIndex: 'type', width: 150, align: 'center', },
  { title: '关键词', dataIndex: 'keyword', width: 150, align: 'center', },
  { title: '操作', dataIndex: 'operation', fixed: 'right', width: 90, align: 'center' },
];
const columns1 = [
    { title: '广告活动', dataIndex: 'advName', width: 150, align: 'center', },
    { title: '曝光量', dataIndex: 'impressions', width: 150, align: 'center', },
    { title: '点击量', dataIndex: 'clicks', width: 150, align: 'center', },
    { title: '点击率', dataIndex: 'clickThroughRate', width: 150, align: 'center', },
];
const columns2 = [
    { title: '广告活动', dataIndex: 'advName', width: 150, align: 'center', },
    { title: '订单量', dataIndex: 'orders', width: 150, align: 'center', },
    { title: '点击量', dataIndex: 'clicks', width: 150, align: 'center', },
    { title: '转化率', dataIndex: 'conversionRate', width: 150, align: 'center', },
];
const columns3 = [
    { title: '广告活动', dataIndex: 'advName', width: 150, align: 'center', },
    { title: '成本', dataIndex: 'cost', width: 150, align: 'center', },
    { title: '销售额', dataIndex: 'sales', width: 150, align: 'center', },
    { title: '投入产出比', dataIndex: 'aCoS', width: 150, align: 'center', },
];
export default defineComponent({
  name: 'ShopsAdvertisingList',
  components: {
    DownOutlined
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const state = reactive({
      dataLoading: false,
      dataLoading1: false,
      dataLoading2: false,
      dataLoading3: false,
      activeKey: 1,
      page: 1,
      limit: 10,
      length: 0,
      count: 0,
      data: [],
      searchText: "",
      shopid: [],
      shops: [],
      shop: 0,
      station: 0,
      stations: [
        { label: "北美", value: "NA", },
        { label: "欧洲", value: "EU" },
        { label: "远东", value: "FE" },
      ],
      states: "1",
      categories:[],
      clickRateData: [],
      clickData: [],
      exposureData: [],
      purchases: [],
      conversionRateData: [],
      aCosCostData: [],
      aCoSSalesData: [],
      aCoSData: [],
      advs: [],
      data1: [],
      daterange: [],
      days: "7",
    });
    const width = computed(() => proxy.$tils.twidth(columns));
    const width1 = computed(() => proxy.$utils.twidth(columns1));
    const width2 = computed(() => proxy.$utils.twidth(columns2));
    const width3 = computed(() => proxy.$utils.twidth(columns3));
    const handclick = () => {
      state.data = [];
      if(state.activeKey === 1){
        getData();
      }else{
        getData1();
      }
    };
    onActivated(() => {
      getData();
    });
    watch(() => state.page, () => getData());
    watch(() => state.limit, () => {
      if (state.page !== 1) state.page = 1;
      else getData();
    });
    const changeShop = (item) => {
      // state.shopid = item;
      // state.shop = _.findWhere(state.shops, { value: state.shopid });
      if(state.activeKey === 1){
        getData();
      }else{
        getData1();
      }
    }
    const changeStation = (item) => {
      state.station = item;
      if(state.activeKey === 1){
        getData();
      }else{
        getData1();
      }
    }
    const changeStates = (item) =>{
      state.states = item;
      getData();
    };
    const searchData = () =>{
      if(state.activeKey === 1){
        getData();
      }else{
        getData1();
      }
    };
    const getDatas = () =>{
      if(state.days === "-1"){
        let beforeDay = proxy.$utils.moment().subtract(1, "days").format('YYYY-MM-DD');
        state.daterange = [];
        state.daterange.push(beforeDay);
        state.daterange.push(beforeDay);
      }
      if(state.days === "7"){
        let beforeDay = proxy.$utils.moment().subtract(1, "days").format('YYYY-MM-DD');
        let before7Day = proxy.$utils.moment().subtract(7, "days").format('YYYY-MM-DD');
        state.daterange = [];
        state.daterange.push(before7Day);
        state.daterange.push(beforeDay);
      }
      if(state.days === "14"){
        let beforeDay = proxy.$utils.moment().subtract(1, "days").format('YYYY-MM-DD');
        let before14Day = proxy.$utils.moment().subtract(14, "days").format('YYYY-MM-DD');
        state.daterange = [];
        state.daterange.push(before14Day);
        state.daterange.push(beforeDay);
      }
      if(state.days === "30"){
        let beforeDay = proxy.$utils.moment().subtract(1, "days").format('YYYY-MM-DD');
        let before30Day = proxy.$utils.moment().subtract(30, "days").format('YYYY-MM-DD');
        state.daterange = [];
        state.daterange.push(before30Day);
        state.daterange.push(beforeDay);
      }
      if(state.days === "90"){
        let beforeDay = proxy.$utils.moment().subtract(1, "days").format('YYYY-MM-DD');
        let before90Day = proxy.$utils.moment().subtract(90, "days").format('YYYY-MM-DD');
        state.daterange = [];
        state.daterange.push(before90Day);
        state.daterange.push(beforeDay);
      }
      if(state.activeKey === 1){
        getData();
      }else{
        getData1();
      }
    }
    const pageJump = (item) =>{
      proxy.$router.push({
        name: "shops_advertising_keyword",
        params: {
          profileId: item.profileId,
          campaignId: item.campaignId,
          shopid: item.shop?._id || undefined,
        }
      });
    }
    const getData = () => {
      state.dataLoading = true;
      proxy.$api.doAPI("/advertising/list", {
        page: state.page,
        limit: state.limit,
        shopid: state.shopid,
        station: state.station,
        states: state.states,
        searchText: state.searchText,
      }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.shops = res.data.shops;
          state.length = res.data.length;
          state.count = res.data.count;
          state.data = res.data.list;
          if (state.length > 0 && state.page > state.length) state.page = 1;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        console.error(err);
        state.dataLoading = false;
      });
    };
    const chartOptions = ref({ xaxis: { categories: [], }, });
    const series = ref([
        { name: "点击量", data: [], },
        { name: '曝光量', data: [], },
        { name: '点击率', data: [], },
    ]);
    const chartOptions1 = ref({ xaxis: { categories: [], }, });
    const series1 = ref([
        { name: "订单量", data: [], },
        { name: '点击量', data: [], },
        { name: '转化率', data: [], },
    ]);
    const chartOptions2 = ref({ xaxis: { categories: [], }, });
    const series2 = ref([
        { name: "成本", data: [], },
        { name: '销售额', data: [], },
        { name: '投入产出比', data: [], },
    ]);
    const getData1 =  () => {
      try {
          state.dataLoading1 = true;
          state.dataLoading2 = true;
          state.dataLoading3 = true;
          proxy.$api.doAPI("/advertising/advView", {
              station: state.station,
              daterange: state.daterange,
              states: state.states,
              searchText: state.searchText,
              shop: state.shop,
          }).then((res) => {
              state.dataLoading1 = false;
              state.dataLoading2 = false;
              state.dataLoading3 = false;
              if (res.code === 0) {
                  state.data1 = res.data.list;
                  state.categories = res.data.categories;
                  state.clickRateData = res.data.clickRateData;
                  state.clickData = res.data.clickData;
                  state.exposureData = res.data.exposureData;
                  state.purchases = res.data.purchases;
                  state.conversionRateData = res.data.conversionRateData;
                  state.aCosCostData = res.data.aCosCostData,
                  state.aCoSSalesData = res.data.aCoSSalesData,
                  state.aCoSData = res.data.aCoSData,
                  chartOptions.value.updateOptions({
                      chart: {
                          id: "vuechart-example",
                          type: "bar",
                          toolbar: { show: true, tools: { download: false, selection: true, zoom: false, zoomin: true, zoomout: true, pan: false } },
                      },
                      legend: {position: "top"},
                      plotOptions: { bar: { horizontal: false } },
                      xaxis:{ categories: state.categories.slice(0,10), labels: { rotate: -35, }, },
                      title: { text: "点击率",   align: "center" },
                      yaxis: [
                          { show: true, seriesName: '点击量', title: { text: "点击量" }, labels: { formatter: function(val) { try { return val.toFixed(0); } catch(e){ return val; }}} },
                          { show: true, seriesName: '曝光量', opposite: true, title: { text: "曝光量" }, labels: { formatter: function(val) { try { return val.toFixed(0); } catch(e){ return val; }}} },
                          { show: true, seriesName: '点击率', title: { text: "点击率" }, labels: { formatter: function(val) { try{ return val.toFixed(2)+ "%"; } catch(e){ return val; } } } },
                      ],
                      series: [
                          { name: "点击量", data: state.clickData.slice(0,10) || [], },
                          { name: '曝光量', data: state.exposureData.slice(0,10) || [], },
                          { name: '点击率', data: state.clickRateData.slice(0,10) || [], },
                      ]
                  });
                  chartOptions1.value.updateOptions({
                      chart: {
                          id: "vuechart-example1",
                          type: "bar",
                          toolbar: { show: true, tools: { download: false, selection: true, zoom: false, zoomin: true, zoomout: true, pan: false } },
                      },
                      legend: {position: "top"},
                      plotOptions: { bar: { horizontal: false } },
                      xaxis:{ categories: state.categories.slice(0,10), labels: { rotate: -35, }, },
                      title: { text: "转化率",   align: "center" },
                      yaxis: [
                          { show: true, seriesName: '订单量', title: { text: "订单量" }, labels: { formatter: function(val) { try { return val.toFixed(0); } catch(e){ return val; }}} },
                          { show: true, seriesName: '点击量', title: { text: "点击量" }, labels: { formatter: function(val) { try { return val.toFixed(0); } catch(e){ return val; }}}, opposite: true,  },
                          { show: true, seriesName: '转化率', title: { text: "转化率" }, labels: { formatter: function(val) { try{ return val.toFixed(2)+ "%"; } catch(e){ return val; }}} },
                      ],
                      series: [
                          { name: "订单量", data: state.purchases.slice(0,10) || [], },
                          { name: '点击量', data: state.clickData.slice(0,10) || [],  },
                          { name: '转化率', data: state.conversionRateData.slice(0,10) || [], },
                      ]
                  });
                  chartOptions2.value.updateOptions({
                      chart: {
                          id: "vuechart-example2",
                          type: "bar",
                          toolbar: { show: true, tools: { download: false, selection: true, zoom: false, zoomin: true, zoomout: true, pan: false } },
                      },
                      legend: {position: "top"},
                      plotOptions: { bar: { horizontal: false , columnWidth: '55%', } },
                      xaxis:{ categories: state.categories.slice(0,10), labels: { rotate: -35, }, },
                      title: { text: "投入产出比",   align: "center" },
                      yaxis: [
                          { show: true, seriesName: '成本', title: { text: "成本" }, labels: { formatter: function(val) { try { return val.toFixed(0); } catch(e){ return val; }}} },
                          { show: true, seriesName: '销售额', title: { text: "销售额" }, labels: { formatter: function(val) { try { return val.toFixed(0); } catch(e){ return val; }}}, opposite: true,  },
                          { show: true, seriesName: '投入产出比', title: { text: "投入产出比" }, labels: { formatter: function(val) { try{ return val.toFixed(2)+ "%"; } catch(e){ return val; }}} },
                      ],
                      series: [
                          { name: "成本", data: state.aCosCostData.slice(0,10) || [], },
                          { name: '销售额', data: state.aCoSSalesData.slice(0,10) || [], },
                          { name: '投入产出比', data: state.aCoSData.slice(0,10) || [], },
                      ]
                  });

              } else {
                  message.error(res.msg);
              }
          }).catch((err) => {
              console.error(err);
              state.dataLoading1 = false;
              state.dataLoading2 = false;
              state.dataLoading3 = false;
          });
      } catch (errorInfo) {
          console.log(errorInfo);
      }
    };
    const handleChange = (tag, checked) => {
      const { selectedTags } = state;
      const nextSelectedTags = checked
        ? [...selectedTags, tag]
        : selectedTags.filter(t => t !== tag);
      console.log('You are interested in: ', nextSelectedTags);
      state.selectedTags = nextSelectedTags;
    };
    return {
      ...toRefs(state),
      columns,
      width,
      columns1,
      width1,
      columns2,
      width2,
      columns3,
      width3,
      handclick,
      changeShop,
      changeStation,
      changeStates,
      pageJump,
      getData,
      handleChange,
      getData1,
      chartOptions,
      series,
      chartOptions1,
      series1,
      chartOptions2,
      series2,
      getDatas,
      searchData,
    };
  }
});
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

.header {
  .search {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }

  .op {
    display: flex;
    justify-content: space-between;
  }
}

p {
  margin: 0;
  padding: 0;
}
</style>
