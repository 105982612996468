<template>
  <div class="header">
    <a-form class="ant-advanced-search-form">
      <a-row :gutter="16">
        <a-col :span="8">
          <a-form-item label="订单编号">
            <a-input v-model:value="queryForm.number" placeholder="订单编号/交易单号" allow-clear></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="选择店铺">
            <a-select v-model:value="queryForm.shopid" placeholder="请选择店铺" mode="tags" :options="shops"></a-select>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="国家地区">
            <a-select v-model:value="queryForm.country" :options="countrys" placeholder="请选择" allow-clear show-search
              :filter-option="$filters.filterOption">
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="付款时间">
            <a-range-picker v-model:value="queryForm.purchasedate" value-format="YYYY-MM-DD" style="width:100%;"
              allow-clear />
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-form-item label="发货时间">
            <a-range-picker v-model:value="queryForm.deliverydate" value-format="YYYY-MM-DD" style="width:100%;"
              allow-clear />
          </a-form-item>
        </a-col>
        <a-col :span="8">
          <a-space>
            <a-radio-group v-model:value="queryForm.status" :options="statusOptions" @change="getData" />
            <a-button type="primary" @click="getData">查询</a-button>
          </a-space>
        </a-col>
      </a-row>
    </a-form>
  </div>
  <div class="content">
    <a-table :columns="columns" :data-source="data" bordered :loading="dateLoading" :scroll="{ x: width }"
      :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
      @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" size="small">
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'shop'">
          <p>{{ record.shop?.name || '-' }}</p>
        </template>
        <template v-if="column.dataIndex === 'amount'">
          <span>{{ record.amount }}</span><span>({{ record.order_currency }})</span>
        </template>
        <template v-if="column.dataIndex === 'currency'">
          <span>{{ record.currency?.exchange_rate || '-' }}</span>
        </template>
        <template v-if="column.dataIndex === 'refunded_freight'">
          <span v-if="record.refunded_freight > 0" class="text-error"> -{{ record.refunded_freight }} </span>
          <span v-else>0</span>
        </template>
      </template>
    </a-table>
  </div>
</template>
<script>
import { DownOutlined } from '@ant-design/icons-vue';
import { message, Modal } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from 'vue';
import _ from "underscore";
const columns = [
  { title: '订单编号', dataIndex: 'orderid', width: 120, align: 'center', fixed: 'left', },
  { title: '交易号', dataIndex: 'order_number', width: 155, align: 'center', fixed: 'left', },
  { title: '店铺', dataIndex: 'shop', width: 150, align: 'center', fixed: 'left', },
  { title: '国家', dataIndex: 'shipping_country', width: 80, align: 'center' },
  { title: '付款日期', dataIndex: 'purchase_time', width: 120, align: 'center' },
  { title: '发货日期', dataIndex: 'outbound_time', width: 120, align: 'center' },
  { title: '原始金额', dataIndex: 'amount', width: 120, align: 'center' },
  { title: '汇率', dataIndex: 'currency', width: 80, align: 'center' },
  { title: '销量', dataIndex: 'total_quantity', width: 80, align: 'center' },
  {
    title: '收入(元)',
    children: [
      { title: '应收货款', dataIndex: 'order_amount', width: 80, align: 'center' },
      { title: '其他收入', dataIndex: 'other_freight', width: 80, align: 'center' },
      { title: '合计', dataIndex: 'shouru_sum', width: 80, align: 'center' }
    ]
  },
  {
    title: '支出(元)',
    children: [
      { title: '商品成本', dataIndex: 'product_cost', key: 'product_cost', width: 80, align: 'center' },
      { title: '运费', dataIndex: 'freight', width: 80, align: 'center' },
      { title: '平台费', dataIndex: 'platform_fee', key: 'platform_fee', width: 80, align: 'center' },
      { title: 'FBA费用', dataIndex: 'fba_fee', key: 'fba_fee', width: 80, align: 'center' },
      { title: '其他费用', dataIndex: 'disburse_other_fee', key: 'disburse_other_fee', width: 80, align: 'center' },
      { title: '合计', dataIndex: 'zhichu_sum', key: 'zhichu_sum', width: 80, align: 'center' }
    ]
  },
  { title: '退款金额(元)', dataIndex: 'refunded_freight', width: 120, align: 'center' },
  { title: '商品种类', dataIndex: 'goodstype', width: 120, align: 'center' },
  { title: '毛利(元)', dataIndex: 'maoli', width: 100, align: 'center' },
  { title: '毛利率%', dataIndex: 'maoli_lv', width: 100, align: 'center' }
];
export default defineComponent({
  name: 'FinanceIncome',
  components: {
    DownOutlined
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const state = reactive({
      queryForm: {
        number: "",
        shopid: undefined,
        country: null,
        purchasedate: [null, null],
        deliverydate: [null, null],
        status: 'ALL',
      },
      statusOptions: [
        { label: '全部', value: 'ALL', },
        { label: '正常', value: 'normal', },
        { label: '已退款', value: 'refund', },
      ],
      page: 1,
      limit: 10,
      data: [],
      length: 0,
      count: 0,
      dateLoading: false,
      shops: [],
      countrys: [],
    });
    const width = computed(() => proxy.$utils.twidth(columns));
    watch(() => state.page, () => getData());
    watch(() => state.limit, () => { if (state.page !== 1) state.page = 1; else getData(); });
    watch(() => state.queryForm.number, () => { if (state.page !== 1) state.page = 1 });
    onMounted(() => {
      getShops();
    });
    const getShops = () => {
      state.dateLoading = true;
      proxy.$api.doAPI("/product/shops", { platform: "", }).then((res) => {
        state.dateLoading = false;
        if (res.code === 0) {
          state.shops = res.data.shops;
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dateLoading = false;
        console.error(err);
      });
    }
    const getData = () => {
      state.dateLoading = true;
      proxy.$api.doAPI("/finance/salesreport/recedisdetail", { page: state.page, limit: state.limit, queryForm: state.queryForm, }).then((res) => {
        state.dateLoading = false;
        if (res.code === 0) {
          state.data = res.data.list;
          state.length = res.data.length;
          state.count = res.data.count;
          if (state.length > 0 && state.page > state.length) state.page = 1;
          state.countrys = _.map(res.data.countrys || [], n => ({ ...n, label: `${n.code} ${n.name}`, value: n._id }));
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dateLoading = false;
        console.error(err);
      });
    };
    return {
      ...toRefs(state),
      columns,
      width,
      getData,
    }
  }
});
</script>
<style lang="less" scoped>
:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

:deep(.ant-table-tbody > tr > td) {
  padding: 8px 8px !important;
}

.header {
  margin-bottom: 10px;
}

.theader {
  display: flex;
  justify-content: space-between;

  .title {
    font-weight: 600;
  }
}

a {
  text-decoration: underline;
}

p {
  margin: 0;
  padding: 0;
}
</style>