<template>
  <div class="d-flex align-center mb-2">
    <span style="width:95px;">店&emsp;&emsp;铺：</span>
    <div class="d-flex flex-wrap">
      <a-tag :color="shopid === 0 ? 'blue' : 'default'" @click="changeShop(0)" class="click">全部</a-tag>
      <a-tag v-for="item in shops" :key="item.value" :color="shopid === item.value ? 'processing' : 'default'"
        @click="changeShop(item.value)" class="click">{{ item.label }}</a-tag>
    </div>
  </div>
  <div class="d-flex align-center mb-2">
    <span>库存状态：</span>
    <a-tag :color="inventorystate === 0 ? 'processing' : 'default'" @click="changeInventoryState(0)"
      class="click">全部</a-tag>
    <a-tag :color="inventorystate === 1 ? 'success' : 'default'" @click="changeInventoryState(1)"
      class="click">有库存</a-tag>
    <a-tag :color="inventorystate === 2 ? 'error' : 'default'" @click="changeInventoryState(2)"
      class="click">无库存</a-tag>
  </div>
  <div class="d-flex align-center mb-2">
    <span>商品状态：</span>
    <a-tag :color="status === '' ? 'processing' : 'default'" @click="changeStatus('')" class="click">所有</a-tag>
    <a-tag :color="status === 'Active' ? 'success' : 'default'" @click="changeStatus('Active')" class="click">在售</a-tag>
    <a-tag :color="status === 'Inactive' ? 'warning' : 'default'" @click="changeStatus('Inactive')"
      class="click">非在售</a-tag>
    <a-tag :color="status === 'Incomplete' ? 'error' : 'default'" @click="changeStatus('Incomplete')"
      class="click">不完整</a-tag>
  </div>
  <div class="d-flex align-center mb-2">
    <span>拉取状态：</span>
    <a-tag :color="pull_status === '' ? 'processing' : 'default'" @click="changePullStatus('')" class="click">全部</a-tag>
    <a-tag :color="pull_status === 'pending' ? 'warning' : 'default'" @click="changePullStatus('pending')"
      class="click">未拉取</a-tag>
    <a-tag :color="pull_status === 'SUCCESS' ? 'success' : 'default'" @click="changePullStatus('SUCCESS')"
      class="click">已拉取</a-tag>
    <a-tag :color="pull_status === 'ERROR' ? 'error' : 'default'" @click="changePullStatus('ERROR')"
      class="click">拉取失败</a-tag>
  </div>
  <div class="d-flex align-center mb-2">
    <span>搜索内容：</span>
    <a-radio-group v-model:value="searchtype">
      <a-radio :value="1">SKU</a-radio>
      <a-radio :value="2">ASIN</a-radio>
    </a-radio-group>
    <a-input-search v-model:value="searchtext" enter-button="搜索" style="width: 20%;min-width:300px;" allow-clear
      @search="getData" />
  </div>
  <div class="d-flex justify-space-between align-center mb-2">
    <a-button type="primary" @click="getData">Refresh List</a-button>
    <a-button type="danger" @click="bulkPull" :disabled="selectedRowKeys.length === 0" :loading="bulkPulling">
      Bulk Pull
    </a-button>
  </div>
  <a-table :columns="columns" :data-source="data" :scroll="{ x: width, y: 600 }" :loading="dataLoading"
    :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
    @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" row-key="_id"
    :row-selection="{ selectedRowKeys, onChange: (keys) => selectedRowKeys = keys }" size="small">
    <template #bodyCell="{ record, column }">
      <template v-if="column.dataIndex === 'shop'">
        {{ getshopname(record) }}
      </template>
      <template v-if="column.dataIndex === 'image_url'">
        <a-tooltip v-if="record.image_url" color="white" placement="rightBottom">
          <template #title>
            <a-image :src="record.image_url" width="480px" height="480px" style="object-fit: contain;height:100%;"
              :preview="false">
              <template #placeholder>
                <a-image :src="$filters.image_placeholder" width="480px" height="480px" :preview="false" />
              </template>
            </a-image>
          </template>
          <a href="javascript:;">
            <a-image :src="record.image_url" width="80px" height="80px" style="object-fit: contain;height:100%;"
              :preview="false">
              <template #placeholder>
                <a-image :src="$filters.image_placeholder" width="80px" height="80px" :preview="false" />
              </template>
            </a-image>
          </a>
        </a-tooltip>
        <a-image v-else :src="$filters.image_placeholder" width="80px" height="80px"
          style="object-fit: contain;height:100%;" :preview="false">
          <template #placeholder>
            <a-image :src="$filters.image_placeholder" width="80px" height="80px" :preview="false" />
          </template>
        </a-image>
      </template>
      <template v-if="column.dataIndex === 'asin'">
        <template v-if="record.parent_asin">
          <p>父SKU: {{ record.parent_asin }}</p>
          <p>子SKU: {{ record.asin }}</p>
        </template>
        <p v-else>{{ record.asin }}</p>
      </template>
      <template v-if="column.dataIndex === 'name'">
        <a-tooltip>
          <template #title> {{ record.name }} </template>
          <p class="in1line">{{ record.name }}</p>
        </a-tooltip>
      </template>
      <template v-if="column.dataIndex === 'status'">
        <p v-if="record.status === 'Inactive'" class="text-error">{{ record.status }}</p>
        <p v-else-if="record.status === 'Active'" class="text-success">{{ record.status }}</p>
        <p v-else class="text-warning">{{ record.status }}</p>
      </template>
      <template v-if="column.dataIndex === 'pull_status'">
        <a-tooltip v-if="record.pull_status === 'ERROR'">
          <template #title> {{ record.error_message }} </template>
          <a href="javascript:;" class="text-error underline">{{ record.pull_status }}</a>
        </a-tooltip>
        <p v-else-if="record.pull_status === 'SUCCESS'" class="text-success">{{ record.pull_status }}</p>
        <p v-else class="text-warning">{{ record.pull_status }}</p>
      </template>
      <template v-if="column.dataIndex === 'operation'">
        <div class="d-flex flex-column">
          <a href="javascript:;" @click="pullListingItem(record._id)">
            Pull
          </a>
        </div>
      </template>
    </template>
  </a-table>
</template>
<script setup>
import { DownOutlined, SearchOutlined, PlusCircleOutlined, EyeOutlined } from '@ant-design/icons-vue';
import { message, Modal } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from "vue";
import _ from "underscore";
const columns = [
  { title: 'Shop', dataIndex: 'shop', width: 120, },
  { title: 'Image', dataIndex: 'image_url', width: 120, },
  { title: 'SKU', dataIndex: 'seller_sku', width: 180, },
  { title: 'ASIN', dataIndex: 'asin', width: 180, },
  { title: 'Name', dataIndex: 'name', width: 120, },
  { title: 'Price', dataIndex: 'price', width: 100, },
  { title: 'Quantity', dataIndex: 'quantity', width: 100, },
  { title: 'Status', dataIndex: 'status', width: 100, },
  { title: 'PullStatus', dataIndex: 'pull_status', width: 100, },
  { title: 'PullTime', dataIndex: 'pull_time', width: 120, },
  { title: 'CreateTime', dataIndex: 'create_time', width: 120, },
  { title: 'Operation', dataIndex: 'operation', width: 150, fixed: 'right' },
];
const { proxy } = getCurrentInstance();
const shopid = ref(0);
const inventorystate = ref(0);
const status = ref('');
const pull_status = ref('');
const searchtype = ref(1);
const searchtext = ref('');
const page = ref(1);
const limit = ref(10);
const data = ref([]);
const length = ref(0);
const count = ref(0);
const dataLoading = ref(false);
const shops = ref([]);
const selectedRowKeys = ref([]);
const bulkPulling = ref(false);

const width = computed(() => proxy.$utils.twidth(columns));
watch(() => page.value, () => {
  getData();
});
watch(() => limit.value, () => {
  if (page.value !== 1) page.value = 1;
  else getData();
});
onActivated(() => {
  getData();
});
const getData = () => {
  selectedRowKeys.value = [];
  dataLoading.value = true;
  return new Promise((resolve) => {
    proxy.$api.doAPI2("/dev/amazon/listing/list", { shopid: shopid.value, inventorystate: inventorystate.value, status: status.value, pull_status: pull_status.value, searchtype: searchtype.value, searchtext: searchtext.value, page: page.value, limit: limit.value }).then((res) => {
      dataLoading.value = false;
      if (res.code === 0) {
        data.value = _.map(res.data.list, n => {
          return {
            ...n,
            create_time: proxy.$utils.dateFormat(n.create_time, 'YYYY-MM-DD HH:mm:ss'),
            pull_time: proxy.$utils.dateFormat(n.pull_time, 'YYYY-MM-DD HH:mm:ss'),
          };
        });
        length.value = res.data.length;
        count.value = res.data.count;
        if (length.value > 0 && page.value > length.value) page.value = 1;
        shops.value = res.data.shops;
      } else {
        message.error(res.msg);
      }
      resolve();
    }).catch((err) => {
      dataLoading.value = false;
      console.error(err);
      resolve();
    });
  });
};
const pullListingItem = (_id, bulk = false, msg = '') => {
  dataLoading.value = true;
  return new Promise((resolve) => {
    proxy.$api.doAPI2("/dev/amazon/listing/pull_item", { _id }).then((res) => {
      dataLoading.value = false;
      if (!bulk) getData();
      if (res.code === 0) {
        message.success(`Pull success ${msg}`);
      } else {
        message.error(res.msg);
      }
      resolve();
    }).catch((err) => {
      dataLoading.value = false;
      console.error(err);
      resolve();
    })
  });
}
const bulkPull = async () => {
  bulkPulling.value = true;
  let ids = _.map(selectedRowKeys.value, n => n + "");
  for (let i = 0; i < ids.length; i++) {
    await pullListingItem(ids[i], true, `${i + 1}/${ids.length}`);
    if (i % 10 === 0 || i === ids.length - 1) await getData();
  }
  bulkPulling.value = false;
  getData();
}
const changeShop = (value) => {
  shopid.value = value;
  getData();
}
const changeInventoryState = (value) => {
  inventorystate.value = value;
  getData();
}
const changeStatus = (value) => {
  status.value = value;
  getData();
}
const changePullStatus = (value) => {
  pull_status.value = value;
  getData();
}
const getshopname = (item) => {
  return _.findWhere(shops.value, { value: item.shopid })?.label;
}
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

p {
  margin: 0;
  padding: 0;
}
</style>
