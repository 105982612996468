<template>
  <div class="d-flex justify-space-between align-center mb-2">
    <a-button type="primary" @click="getData" :loading="dataLoading">刷新数据</a-button>
    <a-button type="primary" @click="addPermission" :loading="loading">
      <template #icon>
        <PlusCircleOutlined />
      </template>新增权限
    </a-button>
  </div>
  <a-table :columns="columns" :data-source="data" :loading="dataLoading" :scroll="{ y: 600 }" size="small">
    <template #bodyCell="{ record, column }">
      <template v-if="column.dataIndex === 'list'">
        <div class="d-flex flex-column justify-center align-left">
          <span v-for="(item, index) in record.list" :key="index">
            {{ item.title }} { {{ item.url }} }
          </span>
        </div>
      </template>
      <template v-if="column.dataIndex === 'enabled'">
        <a-switch v-model:checked="record.enabled" checked-children="启用" un-checked-children="禁用"
          @change="(checked) => changePermission(checked, record)" :loading="switching[record._id]" />
      </template>
      <template v-if="column.dataIndex === 'operation'">
        <a-space>
          <a href="javascript:;" @click="editPermission(record)">编辑</a>
        </a-space>
      </template>
    </template>
  </a-table>
  <a-modal v-model:visible="permissionModal" :title="`${permissionForm._id !== '' ? '编辑' : '新增'}角色`"
    @ok="savePermission" ok-text="确认" cancel-text="取消" :confirm-loading="saving"
    :cancel-button-props="{ disabled: saving }" :maskClosable="false" :closable="!saving" width="750px">
    <a-form ref="permissionRef" name="permissionForm" :model="permissionForm" autocomplete="off" layout="vertical">
      <a-form-item label="权限组" name="title" :rules="[{ required: true, message: '请输入权限组' }]">
        <a-input v-model:value="permissionForm.title" :disabled="saving" />
      </a-form-item>
      <a-form-item label="权限值" name="list">
        <a-table :columns="columns2" :data-source="permissionForm.list" :scroll="{ y: 300 }" :pagination="false"
          size="small">
          <template #title>
            <div class="d-flex align-center mb-2">
              <a-input v-model:value="addvalue.title" placeholder="权限名" :disabled="saving" />
              <a-input v-model:value="addvalue.url" placeholder="权限值" :disabled="saving" class="ml-2" />
              <a href="javascript:;" @click="add" style="width:70px;" class="text-success ml-2">添加</a>
            </div>
          </template>
          <template #bodyCell="{ index, record, column }">
            <template v-if="column.dataIndex === 'title'">
              <a-input v-if="editing[record.index]" v-model:value="editvalue.title" :disabled="saving" />
              <span v-else> {{ record.title }} </span>
            </template>
            <template v-if="column.dataIndex === 'index'">
              <div class="d-flex flex-column align-center">
                <a-button style="width:20px;height:20px;padding:0;" :disabled="index === 0" @click="moveUp({ index })">
                  <template #icon>
                    <UpOutlined />
                  </template>
                </a-button>
                {{ index + 1 }}
                <a-button style="width:20px;height:20px;padding:0;" :disabled="index === permissionForm.list.length - 1"
                  @click="moveDown({ index })">
                  <template #icon>
                    <DownOutlined />
                  </template>
                </a-button>
              </div>
            </template>
            <template v-if="column.dataIndex === 'url'">
              <a-input v-if="editing[record.index]" v-model:value="editvalue.url" :disabled="saving" />
              <span v-else> {{ record.url }} </span>
            </template>
            <template v-if="column.dataIndex === 'operation'">
              <a-space>
                <div v-if="editing[record.index]">
                  <a href="javascript:;" @click="save(record)" class="text-success">保存</a>
                  <a href="javascript:;" @click="cancelsave(record)" class="text-error ml-1">取消</a>
                </div>
                <div v-else>
                  <a href="javascript:;" @click="edit(record)">编辑</a>
                  <a href="javascript:;" @click="remove(record)" class="text-error ml-1">删除</a>
                </div>
              </a-space>
            </template>
          </template>
        </a-table>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script setup>
import { UpOutlined, DownOutlined, SearchOutlined, PlusCircleOutlined } from '@ant-design/icons-vue';
import { message } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, toRaw, } from "vue";
import _ from "underscore";
const columns = [
  { title: '序号', dataIndex: 'index', width: 80, },
  { title: '权限组', dataIndex: 'title', width: 100, },
  { title: '权限列表', dataIndex: 'list', },
  { title: '状态', dataIndex: 'enabled', width: 120, },
  { title: '创建时间', dataIndex: 'create_time', width: 120, },
  { title: '操作', dataIndex: 'operation', width: 120, fixed: 'right' },
];
const columns2 = [
  { title: '排序', dataIndex: 'index', width: 100, },
  { title: '权限名', dataIndex: 'title', width: 150, },
  { title: '权限值', dataIndex: 'url', width: 300, },
  { title: '操作', dataIndex: 'operation', width: 100, fixed: 'right' },
]
const { proxy } = getCurrentInstance();
const permissionRef = ref();

const data = ref([])
const dataLoading = ref(false)
const saving = ref(false)
const permissionForm = ref({ list: [] })
const permissionModal = ref(false)
const loading = ref(false)
const editing = ref({})
const editvalue = ref({})
const addvalue = ref({ title: "", url: "", })
const switching = ref({})

onActivated(() => {
  getData();
});
const getData = () => {
  dataLoading.value = true;
  proxy.$api.doAPI("/account/permissionlist").then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      data.value = _.map(res.data.list, n => {
        return {
          ...n,
          create_time: proxy.$utils.dateFormat(n.create_time, 'YYYY-MM-DD HH:mm:ss'),
        };
      });
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
};
const addPermission = () => {
  permissionForm.value = { _id: "", title: "", list: [], };
  permissionModal.value = true;
}
const editPermission = (item) => {
  permissionForm.value = {
    _id: item._id,
    title: item.title,
    list: _.map(item.list, (n, i) => _.extend({ index: i }, n)),
  };
  addvalue.value = { title: "", url: "" };
  permissionModal.value = true;
}
const savePermission = async () => {
  try {
    await permissionRef.value.validateFields();
    saving.value = true;
    proxy.$api.doAPI("/account/savepermission", { dataForm: permissionForm.value, }).then((res) => {
      saving.value = false;
      if (res.code === 0) {
        message.success("保存成功");
        permissionModal.value = false;
        getData();
      } else {
        message.error(res.msg);
      }
    }).catch((err) => {
      saving.value = false;
      console.error(err);
    });
  } catch (errorInfo) {
    console.log("Failed:", errorInfo);
  }
}
const changePermission = (enabled, item) => {
  switching.value[item._id] = true;
  proxy.$api.doAPI("/account/changepermission", { enabled, _id: item._id }).then((res) => {
    switching.value[item._id] = false;
    if (res.code === 0) {
    } else {
      message.error(res.msg);
    }
    getData();
  }).catch((err) => {
    switching.value[item._id] = false;
    console.error(err);
    getData();
  });
}
const add = () => {
  if (!addvalue.value.title) return message.error("权限名不能为空");
  if (!addvalue.value.url) return message.error("权限值不能为空");
  let list = [];
  for (let i = 0; i < permissionForm.value.list.length; i++) {
    let { index, title, url } = permissionForm.value.list[i];
    list.push({ index, title, url });
  }
  list.push({
    index: permissionForm.value.list.length,
    title: addvalue.value.title,
    url: addvalue.value.url,
  });
  permissionForm.value.list = list;
  message.success("添加成功");
  addvalue.value = { title: "", url: "" };
}
const edit = (item) => {
  editing.value = {};
  editvalue.value = {
    index: item.index,
    title: item.title,
    url: item.url,
  };
  editing.value[item.index] = true;
}
const save = (item) => {
  let list = [];
  for (let i = 0; i < permissionForm.value.list.length; i++) {
    let { index, title, url } = permissionForm.value.list[i];
    if (index === editvalue.value.index) {
      if (!editvalue.value.title) return message.error("权限名不能为空");
      if (!editvalue.value.url) return message.error("权限值不能为空");
      list.push({ index, title: editvalue.value.title, url: editvalue.value.url });
    } else {
      list.push({ index, title, url });
    }
  }
  permissionForm.value.list = list;
  editing.value[item.index] = false;
}
const cancelsave = (item) => {
  editing.value = {};
}
const remove = (item) => {
  let list = [];
  for (let i = 0; i < permissionForm.value.list.length; i++) {
    let { index, title, url } = permissionForm.value.list[i];
    if (index === item.index) continue;
    list.push({ index: i, title, url });
  }
  permissionForm.value.list = list;
}
const moveUp = ({ index }) => {
  let permission_item1 = toRaw(permissionForm.value.list)[index];
  let permission_item2 = toRaw(permissionForm.value.list)[index - 1];
  permissionForm.value.list[index - 1] = permission_item1;
  permissionForm.value.list[index] = permission_item2;
}
const moveDown = ({ index }) => {
  let permission_item1 = toRaw(permissionForm.value.list)[index];
  let permission_item2 = toRaw(permissionForm.value.list)[index + 1];
  permissionForm.value.list[index + 1] = permission_item1;
  permissionForm.value.list[index] = permission_item2;
}
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

:deep(.ant-table-title) {
  padding: 0;
}

:deep(.ant-switch) {
  background-color: #ff4d4f;
}

:deep(.ant-switch-checked) {
  background-color: #52c41a;
}

p {
  margin: 0;
  padding: 0;
}
</style>
