<template>
  <div class="header">
    <div class="search">
      <span style="width:80px;">查询搜索：</span>
      <a-input-search v-model:value="value" placeholder="品牌名称查询" enter-button="搜索" @search="onSearch" />
    </div>
    <div class="op">
      <a-button type="primary">
        <template #icon>
          <PlusCircleOutlined />
        </template>新增商品品牌
      </a-button>
    </div>
  </div>
  <a-table :columns="columns" :data-source="data" size="small">
    <template #bodyCell="{ column }">
      <template v-if="column.dataIndex === 'operation'">
        <a-space>
          <a href="javascript:;">编辑</a>
          <a-popconfirm v-if="data.length" title="确定要删除?" @confirm="onDelete(record.key)">
            <a href="javascript:;" class="text-error">删除</a>
          </a-popconfirm>
        </a-space>
      </template>
    </template>
  </a-table>
</template>
<script>
import { DownOutlined, SearchOutlined, PlusCircleOutlined } from '@ant-design/icons-vue';
import { defineComponent } from 'vue';
const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    width: 80,
    align: 'center'
  },
  {
    title: '品牌名称',
    dataIndex: 'name',
    width: 150,
    align: 'center'
  },
  {
    title: '创建时间',
    dataIndex: 'createtime',
    width: 150,
    align: 'center'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    width: 180,
    fixed: 'right'
  },
];
const data = [];
for (let i = 0; i < 5; i++) {
  data.push({
    index: i + 1,
    parent: '',
    name: '衣服' + i,
    createtime: ''
  });
}
export default defineComponent({
  name: 'ProductBasicBrands',
  components: {
    DownOutlined,
    SearchOutlined,
    PlusCircleOutlined
  },
  setup() {
    return {
      selectedKeys1: ['2'],
      selectedKeys2: ['1'],
      collapsed: false,
      openKeys: ['sub1'],
      data,
      columns
    };
  }
});
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}
:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

.header {
  .search {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }

  .op {
    display: flex;
    justify-content: flex-end;
  }
}
p {
  margin: 0;
  padding: 0;
}
</style>
