<template>
  <a-row>
    <a-col :span="20">
      <a-input-group compact>
        <a-select v-model:value="queryForm.alias" :options="salias" show-search :filter-option="$filters.filterOption"
          placeholder="选择类型" style="width: 50%">
          <template #option="{ value, label }">
            <a-card size="small" :bordered="false" style="width:100%;">
              <template #title>
                {{ label }}
              </template>
              <a-table bordered size="small" :columns="acolumns" :data-source="[getalias(value)]" :pagination="false">
                <template #bodyCell="{ record, column, }">
                  <template v-if="column.dataIndex === 'Format'">
                    <div v-html="record.Format.replace(/\n/g, '<br />')"></div>
                  </template>
                  <template v-if="column.dataIndex === 'Comment'">
                    <div v-html="record.Comment.replace(/\n/g, '<br />')"></div>
                  </template>
                </template>
              </a-table>
            </a-card>
          </template>
        </a-select>
        <a-input v-model:value="queryForm.text" placeholder="输入查询内容" style="width: 50%" @keyup.enter="search" />
      </a-input-group>
    </a-col>
    <a-col :span="4">
      <a-space>
        <a-button type="primary" danger @click="search">查询</a-button>
      </a-space>
    </a-col>
  </a-row>
  <a-table bordered size="small" :columns="columns" :data-source="data" row-key="_id" :scroll="{ x: width, y: height }"
    :loading="dataLoading"
    :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: false, showTotal: (total) => `共${total}条`, }"
    @change="(pagination) => { page = pagination.current; }">
    <template #title>
      <span v-if="data.length > 0">
        L1: {{ count }} results found. Currently displaying results {{ (page - 1) * limit + 1 }} - {{ page * limit }}.
        Filtered
        by Family ID ({{ fcount }} families).
      </span>
    </template>
    <template #bodyCell="{ record, column, index }">
      <template v-if="column.dataIndex === 'index'">
        {{ index + 1 + (page - 1) * limit }}
      </template>
      <template v-if="column.dataIndex === 'inventionTitle'">
        <span title="Flip-folding lift desk" v-html="record.inventionTitle"></span>
      </template>
      <template v-if="column.dataIndex === 'cpcInventiveFlattened'">
        <p class="in1line" :title="record.cpcInventiveFlattened">
          {{ record.cpcInventiveFlattened }}
        </p>
      </template>
      <template v-if="column.dataIndex === 'cpcAdditionalFlattened'">
        <p class="in1line" :title="record.cpcAdditionalFlattened">
          {{ record.cpcAdditionalFlattened }}
        </p>
      </template>
      <template v-if="column.dataIndex === 'inventorsShort'">
        <p class="in1line" :title="record.inventorsShort">
          {{ record.inventorsShort }}
        </p>
      </template>
      <template v-if="column.dataIndex === 'assigneeName'">
        <p class="in1line" :title="record.assigneeName">
          {{ record.assigneeName }}
        </p>
      </template>
      <template v-if="column.dataIndex === 'primaryExaminer'">
        <p class="in1line" :title="record.primaryExaminer">
          {{ record.primaryExaminer }}
        </p>
      </template>
      <template v-if="column.dataIndex === 'assistantExaminer'">
        <p class="in1line" :title="record.assistantExaminer">
          {{ record.assistantExaminer }}
        </p>
      </template>
      <template v-if="column.dataIndex === 'uspcFullClassificationFlattened'">
        <p class="in1line" :title="record.uspcFullClassificationFlattened">
          {{ record.uspcFullClassificationFlattened }}
        </p>
      </template>
      <template v-if="column.dataIndex === 'operation'">
        <a-space>
          <a-button type="link" color="primary" @click="view(record)">
            查看
          </a-button>
        </a-space>
      </template>
    </template>
  </a-table>
</template>
<script setup>
import { LoadingOutlined, DownOutlined, LinkOutlined, CopyOutlined, UploadOutlined, SearchOutlined, PlusOutlined, PlusCircleOutlined, InfoCircleFilled } from '@ant-design/icons-vue';
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from "vue";
import _ from "underscore";
import { message } from "ant-design-vue";
const columns = [
  { title: 'Result #', dataIndex: 'index', width: 100, },
  { title: 'Document ID', dataIndex: 'documentId', width: 150, },
  { title: 'Date Published', dataIndex: 'datePublished', width: 120, },
  { title: 'Family ID', dataIndex: 'familyIdentifierCur', width: 150 },
  { title: 'Pages', dataIndex: 'pageCountDisplay', width: 100, },
  { title: 'Title', dataIndex: 'inventionTitle', width: 200, },
  { title: 'CPCI', dataIndex: 'cpcInventiveFlattened', width: 150 },
  { title: 'CPCA', dataIndex: 'cpcAdditionalFlattened', width: 150 },
  { title: 'Inventor', dataIndex: 'inventorsShort', width: 150 },
  { title: 'Assignee', dataIndex: 'assigneeName', width: 150 },
  { title: 'Application Number', dataIndex: 'applicationNumber', width: 150 },
  { title: 'Filing Date', dataIndex: 'applicationFilingDate', width: 120 },
  { title: 'Primary Examiner', dataIndex: 'primaryExaminer', width: 150 },
  { title: 'Assistant Examiner', dataIndex: 'assistantExaminer', width: 150 },
  { title: 'OR', dataIndex: 'mainClassificationCode', width: 120 },
  { title: 'XREF', dataIndex: 'uspcFullClassificationFlattened', width: 150 },
  { title: 'Applicant Name', dataIndex: 'applicantName', width: 150 },
  { title: 'Relevancy', dataIndex: 'score', width: 150 },
  { title: 'Operation', dataIndex: 'operation', width: 150, fixed: 'right' },
];
const acolumns = [
  { title: 'Format', dataIndex: 'Format', width: 150 },
  { title: 'Comment', dataIndex: 'Comment', width: 200 },
  { title: 'USPAT', dataIndex: 'USPAT', width: 100 },
  { title: 'PGPUB', dataIndex: 'PGPUB', width: 100 },
  { title: 'USOCR', dataIndex: 'USOCR', width: 100 },
]
const { proxy } = getCurrentInstance();

const queryForm = ref({ alias: null, text: '' });
const height = ref(600);
const page = ref(1);
const limit = ref(500);
const length = ref(0);
const count = ref(0);
const fcount = ref(0);
const counts_id = ref(0);
const dataLoading = ref(false);
const data = ref([]);
const alias = ref([]);

const salias = computed(() => {
  return _.map(
    alias.value,
    // _.filter(alias.value, n => n.ALIAS === 'AD'),
    n => {
      return { value: n.ALIAS, label: `[${n.ALIAS}] ${n.DescriptiveName}` };
    }
  );
});
const width = computed(() => proxy.$utils.twidth(columns));

watch(() => page.value, () => search());

onActivated(() => {
  init();
})

const init = () => {
  dataLoading.value = true;
  proxy.$api.doAPI("/uspto/init").then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      alias.value = res.data.list;
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
}
const search = () => {
  dataLoading.value = true;
  proxy.$api.doAPI("/uspto/search", { page: page.value, limit: limit.value, ...queryForm.value }).then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      data.value = _.map(res.data.list, n => {
        return {
          ...n,
          datePublished: proxy.$utils.dateFormat(new Date(n.datePublished)),
          applicationFilingDate: n.applicationFilingDate?.[0] ? proxy.$utils.dateFormat(new Date(n.applicationFilingDate[0])) : '',
          assigneeName: n.assigneeName?.join(','),
          assistantExaminer: n.assistantExaminer?.join(','),
        };
      });
      length.value = res.data.length;
      count.value = res.data.count;
      fcount.value = res.data.fcount;
      counts_id.value = res.data.counts_id;
      if (length.value > 0 && page.value > length.value) page.value = 1;
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
}
const highlight = (item) => {
  dataLoading.value = true;
  proxy.$api.doAPI("/uspto/highlight", { guid: item.guid, type: item.type, counts_id: counts_id.value }).then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      // TODO
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
}
const view = (item) => {
  dataLoading.value = true;
  proxy.$api.doAPI("/uspto/convert", { pageCount: item.pageCount, imageLocation: item.imageLocation, imageFileName: item.imageFileName }).then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      proxy.$preview(res.data.images[0], res.data.images);
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
}
const getalias = (value) => {
  return _.findWhere(alias.value, { ALIAS: value });
}
</script>
<style lang="less" scoped>
p {
  margin: 0;
  padding: 0;
}

:deep(span[class^="highlight"]) {
  background-color: rgb(148, 226, 252);
}
</style>
