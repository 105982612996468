<template>
  <a-spin :spinning="ui_loading" style="overflow-x: hidden;">
    <a-row>
      <a-col :span="4">
        <div class="d-flex justify-center">
          <a-menu v-model:selectedKeys="activecards" @click="clickMenu">
            <a-menu-item key="card1">店铺类目</a-menu-item>
            <a-menu-item key="card2">基本信息</a-menu-item>
            <a-menu-item key="card3">分类属性</a-menu-item>
            <a-menu-item key="card4">商品素材</a-menu-item>
            <a-menu-item key="card5">变种信息</a-menu-item>
            <a-menu-item key="card6">交易信息</a-menu-item>
            <a-menu-item key="card7">物流信息</a-menu-item>
          </a-menu>
        </div>
      </a-col>
      <a-col :span="20">
        <div style="background:#f0f2f5;">
          <div id="mainform" class="mainform">
            <!-- 主要内容区 -->
            <a-form ref="listingformRef" name="listingForm" :model="listingForm" autocomplete="off"
              :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" :labelWrap="true">
              <a-card size="small" title="店铺类目" id="card1" class="mb-2 code-box">
                <a-form-item label="店铺名称" name="merchant_id" :rules="[{ required: true, message: '请选择店铺' }]">
                  <a-select v-model:value="listingForm.merchant_id" :options="shops" placeholder="请选择店铺" showSearch
                    style="width:400px;" :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" />
                  <a-checkbox class="ml-2" v-model:checked="listingForm.autopublish"
                    :disabled="(listingForm.state !== 'draft' && listingForm.state !== 'fail') || !listingForm.merchant_id"
                    @change="(e) => toggleautopublish(e.target.checked)">
                    上架后自动发布到站点店铺
                  </a-checkbox>
                  <div v-if="listingForm.autopublish" class="shops" style="display: inline-block;"
                    @click="toggleautopublish(true)">
                    <div class="shop-box">
                      <div class="shop-checked">
                        <span>所选店铺</span>
                        <span class="number ml5">{{ listingForm.regions.length }}</span>
                        <span>个</span>
                      </div>
                      <div class="shop-feat-btn">
                        <UnorderedListOutlined />
                        <UpOutlined v-if="autopublishmodal" />
                        <DownOutlined v-else />
                      </div>
                    </div>
                  </div>
                </a-form-item>
                <a-form-item label="产品类目" name="category_name" :rules="[{ required: true, message: '请选择' }]">
                  <a-input v-model:value="listingForm.category_name" placeholder="请选择" readonly
                    style="width:400px;"></a-input>
                  <a-button
                    :class="{ 'btn-orange ml-2': true, 'disabled': (listingForm.state !== 'draft' && listingForm.state !== 'fail') || !listingForm.merchant_id }"
                    :disabled="(listingForm.state !== 'draft' && listingForm.state !== 'fail') || !listingForm.merchant_id"
                    @click="showCategorys">
                    选择类目
                  </a-button>
                  <template #extra>
                    <div v-if="category_id1" class="d-flex flex-wrap">
                      <span>{{ categoryNAME1 }}</span>
                      <span v-if="categoryNAME2">
                        》 <span :class="{ 'text-orange': categoryID === category_id2 }">
                          {{ categoryNAME2 }}
                        </span>
                      </span>
                      <span v-if="categoryNAME3">
                        》 <span :class="{ 'text-orange': categoryID === category_id3 }">
                          {{ categoryNAME3 }}
                        </span>
                      </span>
                      <span v-if="categoryNAME4">
                        》 <span :class="{ 'text-orange': categoryID === category_id4 }">
                          {{ categoryNAME4 }}
                        </span>
                      </span>
                      <span v-if="categoryNAME5">
                        》 <span :class="{ 'text-orange': categoryID === category_id5 }">
                          {{ categoryNAME5 }}
                        </span>
                      </span>
                      <span v-if="categoryNAME6">
                        》 <span :class="{ 'text-orange': categoryID === category_id6 }">
                          {{ categoryNAME6 }}
                        </span>
                      </span>
                      <span v-if="categoryNAME7">
                        》 <span :class="{ 'text-orange': categoryID === category_id7 }">
                          {{ categoryNAME7 }}
                        </span>
                      </span>
                    </div>
                  </template>
                </a-form-item>
              </a-card>
              <a-card size="small" title="基本信息" id="card2" class="mb-2 code-box">
                <a-form-item label="Parent SKU" name="seller_sku">
                  <a-input v-model:value="listingForm.seller_sku" placeholder="例如：A001" style="width:400px;"
                    :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'"></a-input>
                </a-form-item>
                <a-form-item label="商品标题" name="item_name" :rules="[{ required: true, message: '请输入商品标题' }]">
                  <a-input v-model:value="listingForm.item_name" :maxlength="180" placeholder="请输入商品标题"
                    style="width:100%;" :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'"
                    showCount></a-input>
                </a-form-item>
                <a-form-item label="商品描述" name="product_description" :rules="[{ required: true, message: '请输入商品描述' }]">
                  <a-textarea v-model:value="listingForm.product_description" :maxlength="5000" placeholder="请输入商品描述"
                    :auto-size="{ minRows: 15, maxRows: 15 }" style="width:100%;"
                    :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" showCount />
                </a-form-item>
                <a-form-item label="商品类型" name="sale_type" :rules="[{ required: true, message: '请选择商品类型' }]">
                  <a-radio-group v-model:value="listingForm.sale_type"
                    :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'">
                    <a-radio value="1">单品</a-radio>
                    <a-radio value="2">变种</a-radio>
                  </a-radio-group>
                </a-form-item>
              </a-card>
              <a-card size="small" title="分类属性" id="card3" class="mb-2 code-box">
                <a-row v-if="listingForm.category_id" :gutter="16">
                  <a-col :span="10">
                    <a-form-item label="品牌" name="brand_id" :rules="[{ required: true, message: '请选择品牌' }]"
                      :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }">
                      <a-select v-model:value="listingForm.brand_id" :options="category_brands"
                        :filter-option="$filters.filterOption" showSearch style="width:100%;"
                        :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" />
                    </a-form-item>
                  </a-col>
                  <a-col :span="10" v-for="item in category_attributes" :key="item._id">
                    <a-form-item :label="item.label" :name="item.key" :label-col="{ span: 8 }"
                      :wrapper-col="{ span: 16 }">
                      <!--! 单选 -->
                      <a-select v-if="item.cat === 1 || item.cat === 2" v-model:value="listingForm.attributes[item.key]"
                        :options="item.options" placeholder="请选择" allowClear></a-select>
                      <!--! 多选 -->
                      <a-select v-if="item.cat === 4 || item.cat === 5" mode="multiple" :maxTagCount="2"
                        v-model:value="listingForm.attributes[item.key]" placeholder="请选择">
                        <template #maxTagPlaceholder>
                          +{{ listingForm.attributes[item.key].length - 2 }}
                        </template>
                        <a-select-option v-for="option in item.options" :key="option.value" :value="option.value"
                          :disabled="listingForm.attributes[item.key]?.length >= item.max_value_count && listingForm.attributes[item.key]?.indexOf(option.value) === -1">
                          {{ option.label }}
                        </a-select-option>
                      </a-select>
                      <!--! 输入 -->
                      <template v-if="item.cat === 3">
                        <a-input-group compact v-if="item.options?.length > 0">
                          <a-input v-model:value="listingForm.attributes[item.key].value" :type="item.type"
                            style="width: 60%" />
                          <a-select v-model:value="listingForm.attributes[item.key].unit" :options="item.options"
                            style="width: 40%">
                          </a-select>
                        </a-input-group>
                        <a-input v-else v-model:value="listingForm.attributes[item.key]" :type="item.type" />
                      </template>
                      <template #extra>
                        <template v-if="item.cat === 4 || item.cat === 5">
                          {{ listingForm.attributes[item.key]?.length || 0 }}/{{ item.max_value_count }}
                        </template>
                      </template>
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-empty v-else description="请先选择商品类目，获取属性"></a-empty>
              </a-card>
              <a-card size="small" title="商品素材" id="card4" class="mb-2 code-box">
                <div class="pa-2">
                  <div class="pb-2">
                    <span>
                      <span class="text-error">*</span> <span> 产品图：</span>
                    </span>
                  </div>
                  <p class="text-info">
                    <info-circle-outlined />
                    <span class="ml-1">按住图片拖动，即可调整图片顺序</span>
                  </p>
                  <p class="text-info">
                    <info-circle-outlined />
                    <span
                      class="ml-1">单张不超过2MB；仅支持JPEG、JPG、PNG格式；图片尺寸大于400；最多上传9张图片。建议主图大于800*800，比例为1:1，200KB以下。在线图片链接中不要包含特殊字符（如：“=”），否则会导致刊登失败</span>
                  </p>
                  <!-- 上传图片 -->
                </div>
                <div class="pa-2">
                  <div class="pb-2">
                    产品视频：
                  </div>
                  <p class="text-info">
                    <info-circle-outlined />
                    <span class="ml-1">视频仅支持mp4格式，分辨率不超过1280x1280px；时长：10~60s；视频文件大小：30M以内</span>
                  </p>
                  <!-- 上传视频 -->
                </div>
              </a-card>
              <a-card size="small" title="变种信息" id="card5" class="mb-2 code-box"></a-card>
              <a-card size="small" title="交易信息" id="card6" class="mb-2 code-box">
                <a-form-item label="全球价格" name="standard_price" :rules="[{ required: true, message: '请输入价格' }]">
                  <a-input v-model:value="listingForm.standard_price" type="number" placeholder="请输入价格"
                    addon-before="CNY" style="width:400px;"
                    :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'"></a-input>
                </a-form-item>
                <a-form-item label="库存" name="quantity" :rules="[{ required: true, message: '请输库存' }]">
                  <a-input v-model:value="listingForm.quantity" type="number" placeholder="请输入库存" style="width:400px;"
                    :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'"></a-input>
                </a-form-item>
              </a-card>
              <a-card size="small" title="物流信息" id="card7" class="mb-2 code-box">
                <a-form-item label="重量" name="weight" :rules="[{ required: true, message: '请输入重量' }]">
                  <a-input v-model:value="listingForm.weight" type="number" placeholder="请输入重量" addon-after="KG"
                    style="width:400px;"
                    :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'"></a-input>
                </a-form-item>
                <a-form-item label="包裹尺寸" name="dimension">
                  <div class="d-flex align-center" style="width:600px;">
                    <a-input v-model:value="listingForm.length" type="number" placeholder="长(整数)" addon-after="cm"
                      style="width:30%" :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" />
                    <span style="padding: 0px 7px; vertical-align: sub;">
                      <CloseOutlined />
                    </span>
                    <a-input v-model:value="listingForm.width" type="number" placeholder="宽(整数)" addon-after="cm"
                      style="width:30%" :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" />
                    <span style="padding: 0px 7px; vertical-align: sub;">
                      <CloseOutlined />
                    </span>
                    <a-input v-model:value="listingForm.height" type="number" placeholder="高(整数)" addon-after="cm"
                      style="width:30%" :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" />
                  </div>
                </a-form-item>
                <a-form-item label="产品保存状况" name="condition_type">
                  <a-radio-group v-model:value="listingForm.condition_type"
                    :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'">
                    <a-radio value="new">全新</a-radio>
                    <a-radio value="used">二手</a-radio>
                  </a-radio-group>
                </a-form-item>
                <a-form-item label="是否预购" name="is_preorder">
                  <a-radio-group v-model:value="listingForm.is_preorder"
                    :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'">
                    <a-radio value="yes">是</a-radio>
                    <a-radio value="no">否</a-radio>
                  </a-radio-group>
                </a-form-item>
                <a-form-item label="发货" name="fulfillment_latency" :rules="[{ required: true, message: '请输入发货天数' }]">
                  <a-input v-model:value="listingForm.fulfillment_latency" type="number" placeholder="请输入发货天数"
                    addon-after="天" style="width:200px;"
                    :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'"></a-input>
                </a-form-item>
              </a-card>
            </a-form>
            <!-- 底部操作区 -->
            <div class="footer">
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
  </a-spin>
  <!-- #region 选择SIP店铺 -->
  <a-modal v-model:visible="autopublishmodal" title="选择发布店铺" @ok="confirmautopublish" ok-text="确定" cancel-text="取消"
    width="600px">
    <a-alert type="warning" show-icon class="mb-4">
      <template #message>
        <span class="text-warning">SIP店铺无法手动发布，Shopee会将全球商品自动发布至SIP店铺</span>
      </template>
    </a-alert>
    <a-checkbox-group v-if="shop_regions.length > 0" v-model:value="autopublishregions" :options="shop_regions" />
  </a-modal>
  <!-- #endregion -->
  <!-- #region 产品类目 -->
  <a-modal v-model:visible="ui_visible" width="1200px" @cancel="closeCategory">
    <template #title>
      <div class="d-flex align-center">
        <span class="text-h6 mr-2">选择类目</span>
        <a-input v-model:value="category_searchtext" placeholder="类目搜索：可输入产品类目名称" style="width:80%;"
          @keyup.enter="doSearchCategory">
          <template #addonAfter>
            <SearchOutlined @click="doSearchCategory" />
          </template>
        </a-input>
      </div>
    </template>
    <a-spin :spinning="ui_loading">
      <div v-if="category_showsearchresults">
        <!-- <a-divider>
          <a-space>
            <div>查询到<span> {{ category_searchresults.length }} </span>个产品分类</div>
            <a-button @click="backToSelect">
              返回产品分类选择
            </a-button>
          </a-space>
        </a-divider>
        <a-alert v-if="category_searchresults.length === 0" message="暂无符合条件的分类" description="请尝试其他搜索关键字，或返回产品分类选择"
          type="warning" show-icon />
        <div v-else class="search-results-group">
          <a-list size="small" bordered :data-source="category_searchresults" :pagination="false">
            <template #renderItem="{ item, index }">
              <a-list-item>
                <a href="javascript:;" :class="{ 'search-results-item': true, 'active': item.selected }"
                  @click="selectSearchItem(index)">
                  {{ item.label }}
                </a>
              </a-list-item>
            </template>
          </a-list>
        </div> -->
      </div>
      <div v-else class="tree-group">
        <a-card v-if="category_count >= 1 && category_list1.length > 0" title="一级类目" size="small"
          class="tree-item-card">
          <div class="tree-item">
            <a-menu :selectedKeys="[category_id1]">
              <a-menu-item v-for="item in category_list1" :key="item.category_id" @click="selectCategory1(item)"
                style="border-bottom: 1px solid #ddd;">
                <div v-if="item.has_children" class="d-flex justify-space-between align-center"
                  :title="`${item.category_id}:${item.title}`">
                  <p class="in1line"> {{ item.title }} </p>
                  <RightOutlined />
                </div>
                <div class="d-flex justify-space-between align-center" :title="`${item.category_id}:${item.title}`">
                  <p class="in1line"> {{ item.title }} </p>
                </div>
              </a-menu-item>
            </a-menu>
          </div>
        </a-card>
        <a-card v-if="category_count >= 2 && category_list2.length > 0" title="二级类目" size="small"
          class="tree-item-card">
          <div class="tree-item">
            <a-menu :selectedKeys="[category_id2]">
              <a-menu-item v-for="item in category_list2" :key="item.category_id" @click="selectCategory2(item)"
                style="border-bottom: 1px solid #ddd;">
                <div v-if="item.has_children" class="d-flex justify-space-between align-center"
                  :title="`${item.category_id}:${item.title}`">
                  <p class="in1line"> {{ item.title }} </p>
                  <RightOutlined />
                </div>
                <div class="d-flex justify-space-between align-center" :title="`${item.category_id}:${item.title}`">
                  <p class="in1line"> {{ item.title }} </p>
                </div>
              </a-menu-item>
            </a-menu>
          </div>
        </a-card>
        <a-card v-if="category_count >= 3 && category_list3.length > 0" title="三级类目" size="small"
          class="tree-item-card">
          <div class="tree-item">
            <a-menu :selectedKeys="[category_id3]">
              <a-menu-item v-for="item in category_list3" :key="item.category_id" @click="selectCategory3(item)"
                style="border-bottom: 1px solid #ddd;">
                <div v-if="item.has_children" class="d-flex justify-space-between align-center"
                  :title="`${item.category_id}:${item.title}`">
                  <p class="in1line"> {{ item.title }} </p>
                  <RightOutlined />
                </div>
                <div class="d-flex justify-space-between align-center" :title="`${item.category_id}:${item.title}`">
                  <p class="in1line"> {{ item.title }} </p>
                </div>
              </a-menu-item>
            </a-menu>
          </div>
        </a-card>
        <a-card v-if="category_count >= 4 && category_list4.length > 0" title="四级类目" size="small"
          class="tree-item-card">
          <div class="tree-item">
            <a-menu :selectedKeys="[category_id4]">
              <a-menu-item v-for="item in category_list4" :key="item.category_id" @click="selectCategory4(item)"
                style="border-bottom: 1px solid #ddd;">
                <div v-if="item.has_children" class="d-flex justify-space-between align-center"
                  :title="`${item.category_id}:${item.title}`">
                  <p class="in1line"> {{ item.title }} </p>
                  <RightOutlined />
                </div>
                <div class="d-flex justify-space-between align-center" :title="`${item.category_id}:${item.title}`">
                  <p class="in1line"> {{ item.title }} </p>
                </div>
              </a-menu-item>
            </a-menu>
          </div>
        </a-card>
        <a-card v-if="category_count >= 5 && category_list5.length > 0" title="五级类目" size="small"
          class="tree-item-card">
          <div class="tree-item">
            <a-menu :selectedKeys="[category_id5]">
              <a-menu-item v-for="item in category_list5" :key="item.category_id" @click="selectCategory5(item)"
                style="border-bottom: 1px solid #ddd;">
                <div v-if="item.has_children" class="d-flex justify-space-between align-center"
                  :title="`${item.category_id}:${item.title}`">
                  <p class="in1line"> {{ item.title }} </p>
                  <RightOutlined />
                </div>
                <div class="d-flex justify-space-between align-center" :title="`${item.category_id}:${item.title}`">
                  <p class="in1line"> {{ item.title }} </p>
                </div>
              </a-menu-item>
            </a-menu>
          </div>
        </a-card>
        <a-card v-if="category_count >= 6 && category_list6.length > 0" title="六级类目" size="small"
          class="tree-item-card">
          <div class="tree-item">
            <a-menu :selectedKeys="[category_id6]">
              <a-menu-item v-for="item in category_list6" :key="item.category_id" @click="selectCategory6(item)"
                style="border-bottom: 1px solid #ddd;">
                <div v-if="item.has_children" class="d-flex justify-space-between align-center"
                  :title="`${item.category_id}:${item.title}`">
                  <p class="in1line"> {{ item.title }} </p>
                  <RightOutlined />
                </div>
                <div class="d-flex justify-space-between align-center" :title="`${item.category_id}:${item.title}`">
                  <p class="in1line"> {{ item.title }} </p>
                </div>
              </a-menu-item>
            </a-menu>
          </div>
        </a-card>
        <a-card v-if="category_count >= 7 && category_list7.length > 0" title="七级类目" size="small"
          class="tree-item-card">
          <div class="tree-item">
            <a-menu :selectedKeys="[category_id7]">
              <a-menu-item v-for="item in category_list7" :key="item.category_id" @click="selectCategory7(item)"
                style="border-bottom: 1px solid #ddd;">
                <div v-if="item.has_children" class="d-flex justify-space-between align-center"
                  :title="`${item.category_id}:${item.title}`">
                  <p class="in1line"> {{ item.title }} </p>
                  <RightOutlined />
                </div>
                <div class="d-flex justify-space-between align-center" :title="`${item.category_id}:${item.title}`">
                  <p class="in1line"> {{ item.title }} </p>
                </div>
              </a-menu-item>
            </a-menu>
          </div>
        </a-card>
      </div>
    </a-spin>
    <template #footer>
      <div class="d-flex justify-space-between">
        <div class="d-flex flex-wrap text-left">
          已选择：
          <span v-if="category_searchresultitem" class="text-primary">
            {{ category_searchresultitem.label }}
          </span>
          <template v-else>
            <span :class="{ 'text-orange': categoryID === category_id1 }">
              {{ categoryNAME1 }}
            </span>
            <span v-if="categoryNAME2" :class="{ 'text-orange': categoryID === category_id2 }">
              <span class="text-primary"> 》 </span>{{ categoryNAME2 }}
            </span>
            <span v-if="categoryNAME3" :class="{ 'text-orange': categoryID === category_id3 }">
              <span class="text-primary"> 》 </span>{{ categoryNAME3 }}
            </span>
            <span v-if="categoryNAME4" :class="{ 'text-orange': categoryID === category_id4 }">
              <span class="text-primary"> 》 </span>{{ categoryNAME4 }}
            </span>
            <span v-if="categoryNAME5" :class="{ 'text-orange': categoryID === category_id5 }">
              <span class="text-primary"> 》 </span>{{ categoryNAME5 }}
            </span>
            <span v-if="categoryNAME6" :class="{ 'text-orange': categoryID === category_id6 }">
              <span class="text-primary"> 》 </span>{{ categoryNAME6 }}
            </span>
            <span v-if="categoryNAME7" :class="{ 'text-orange': categoryID === category_id7 }">
              <span class="text-primary"> 》 </span>{{ categoryNAME7 }}
            </span>
          </template>
        </div>
        <a-space>
          <a-button @click="closeCategory">取消</a-button>
          <a-button :class="{ 'btn-warning': categoryConfirmable, 'btn-warning-disabled': !categoryConfirmable }"
            :disabled="!categoryConfirmable" :loading="ui_loading" @click="confirmCategory">确定</a-button>
        </a-space>
      </div>
    </template>
  </a-modal>
  <!-- #endregion -->
</template>
<script setup>
import _ from "underscore";
import { InfoCircleFilled, QuestionCircleOutlined, UpOutlined, DownOutlined, HomeOutlined, DeleteOutlined, EditOutlined, FormOutlined, SaveOutlined, RollbackOutlined, PlusOutlined, MinusOutlined, LeftOutlined, RightOutlined, CopyOutlined, SearchOutlined, EyeOutlined, InfoCircleOutlined, UnorderedListOutlined, CloseOutlined, } from '@ant-design/icons-vue';
import { message, Modal } from "ant-design-vue";
import { ref, shallowRef, reactive, watch, computed, onMounted, onBeforeUnmount, onActivated, onDeactivated, getCurrentInstance, toRaw, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
let timer = 0;
const { proxy } = getCurrentInstance();
//#region 数据
const ui_loading = ref(false);
const ui_visible = ref(false);
const ui_saving = ref(false);
const activecards = ref([]);
const stopscroll = ref(false);
const listingForm = ref({});
const shops = ref([]);
const autopublishregions = ref([]);
const autopublishmodal = ref(false);
//#region 类目菜单选择
const category_searchtext = ref("");
const category_searchresults = ref([]);
const category_showsearchresults = ref(false);
const category_searchresultitem = ref(null);
const category_index = ref(0);
const category_count = ref(0);
const category_id1 = ref(0);
const category_id2 = ref(0);
const category_id3 = ref(0);
const category_id4 = ref(0);
const category_id5 = ref(0);
const category_id6 = ref(0);
const category_id7 = ref(0);
const category_list1 = ref([]);
const category_list2 = ref([]);
const category_list3 = ref([]);
const category_list4 = ref([]);
const category_list5 = ref([]);
const category_list6 = ref([]);
const category_list7 = ref([]);
const category_data = ref(null);
const category_attributes = ref([]);
const category_brands = ref([]);
//#endregion 类目菜单选择
//#endregion 数据
//#region computed
const shop_regions = computed(() => {
  return _.findWhere(shops.value, { value: listingForm.value.merchant_id })?.regions || [];
});
const categoryID = computed(() => {
  if (category_list7.value?.length > 0) return category_id7.value || 0;
  if (category_list6.value?.length > 0) return category_id6.value || 0;
  if (category_list5.value?.length > 0) return category_id5.value || 0;
  if (category_list4.value?.length > 0) return category_id4.value || 0;
  if (category_list3.value?.length > 0) return category_id3.value || 0;
  if (category_list2.value?.length > 0) return category_id2.value || 0;
  if (category_list1.value?.length > 0) return category_id1.value || 0;
  return 0;
});
const categoryNAME1 = computed(() => _.findWhere(category_list1.value || [], { category_id: category_id1.value })?.title || '')
const categoryNAME2 = computed(() => _.findWhere(category_list2.value || [], { category_id: category_id2.value })?.title || '')
const categoryNAME3 = computed(() => _.findWhere(category_list3.value || [], { category_id: category_id3.value })?.title || '')
const categoryNAME4 = computed(() => _.findWhere(category_list4.value || [], { category_id: category_id4.value })?.title || '')
const categoryNAME5 = computed(() => _.findWhere(category_list5.value || [], { category_id: category_id5.value })?.title || '')
const categoryNAME6 = computed(() => _.findWhere(category_list6.value || [], { category_id: category_id6.value })?.title || '')
const categoryNAME7 = computed(() => _.findWhere(category_list7.value || [], { category_id: category_id7.value })?.title || '')
const categoryNAME = computed(() => {
  return categoryNAME7.value || categoryNAME6.value || categoryNAME5.value || categoryNAME4.value || categoryNAME3.value || categoryNAME2.value || categoryNAME1.value || '';
});

const categoryConfirmable = computed(() => {
  if (category_list7.value?.length > 0) return !!category_id7.value || !!category_searchresultitem.value;
  if (category_list6.value?.length > 0) return !!category_id6.value || !!category_searchresultitem.value;
  if (category_list5.value?.length > 0) return !!category_id5.value || !!category_searchresultitem.value;
  if (category_list4.value?.length > 0) return !!category_id4.value || !!category_searchresultitem.value;
  if (category_list3.value?.length > 0) return !!category_id3.value || !!category_searchresultitem.value;
  if (category_list2.value?.length > 0) return !!category_id2.value || !!category_searchresultitem.value;
  if (category_list1.value?.length > 0) return !!category_id1.value || !!category_searchresultitem.value;
  return !!category_searchresultitem.value;
});
//#endregion computed
//#region watch

//#endregion watch
//#region 方法
const update_uistate = (payload = {}) => {
  if (typeof payload.loading === 'boolean') ui_loading.value = payload.loading;
  if (typeof payload.visible === 'boolean') ui_visible.value = payload.visible;
}
const toggleautopublish = (value) => {
  console.log('toggleautopublish', value);
  autopublishregions.value = listingForm.value.regions;
  autopublishmodal.value = value;
}
const confirmautopublish = () => {
  listingForm.value.regions = autopublishregions.value;
  autopublishmodal.value = false;
}
const init = () => {
  initListingForm();
  getData();
}
const initListingForm = (formdata = {}) => {
  listingForm.value = {
    _id: "", merchant_id: undefined, regions: [], state: "draft",
    autopublish: false,
    category_id: "", category_name: "",
    seller_sku: "", item_name: "", product_description: "",
    sale_type: "1", brand_id: 0, attributes: {},
    standard_price: "", quantity: 0,
    weight: "", length: "", width: "", height: "",
    condition_type: "new",
    is_preorder: "no", fulfillment_latency: 0,
    ...formdata,
  };
}
const initListingFormAttributes = (attributes) => {
  let listing_attributes = toRaw(listingForm.value).attributes || {};
  for (let i = 0; i < attributes.length; i++) {
    let { key, cat, options } = attributes[i];
    if (cat === 1 || cat === 2) {//单选
      listing_attributes[key] = listing_attributes[key] || undefined;
    }
    if (cat === 4 || cat === 5) {//多选
      listing_attributes[key] = listing_attributes[key] || [];
    }
    if (cat === 3) {//输入
      if (options?.length > 0) {
        listing_attributes[key] = listing_attributes[key] || { value: '', unit: options[0].value };
      } else {
        listing_attributes[key] = listing_attributes[key] || '';
      }
    }
  }
  listingForm.value.attributes = listing_attributes;
}
const getData = () => {
  update_uistate({ loading: true });
  proxy.$api.doAPI("/listingform/shopee/shops").then((res) => {
    update_uistate({ loading: false });
    if (res.code === 0) {
      shops.value = res.data.shops;
      // TODO
      // if (route.query.id) {
      //   initForm(route.query.id).then(() => {
      //     startAutoSave();
      //   });
      // }
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    update_uistate({ loading: false });
    console.error(err);
  });
}
//#region 类目菜单选择
const set_data = (options) => {
  console.log('set_data', options);
  let { index, count, category_data: category_confirmed, attributes, brands } = options;
  if (typeof index !== 'undefined') category_index.value = index;
  if (typeof count !== 'undefined') category_count.value = count;
  if (typeof category_confirmed !== 'undefined') category_data.value = category_confirmed;
  if (typeof attributes !== 'undefined') {
    category_attributes.value = attributes;
    initListingFormAttributes(attributes);
  }
  if (typeof brands !== 'undefined') category_brands.value = brands;
  let { id1, list1 } = options;
  let { id2, list2 } = options;
  let { id3, list3 } = options;
  let { id4, list4 } = options;
  let { id5, list5 } = options;
  let { id6, list6 } = options;
  let { id7, list7 } = options;
  if (typeof id1 !== 'undefined') category_id1.value = id1;
  if (typeof id2 !== 'undefined') category_id2.value = id2;
  if (typeof id3 !== 'undefined') category_id3.value = id3;
  if (typeof id4 !== 'undefined') category_id4.value = id4;
  if (typeof id5 !== 'undefined') category_id5.value = id5;
  if (typeof id6 !== 'undefined') category_id6.value = id6;
  if (typeof id7 !== 'undefined') category_id7.value = id7;
  if (typeof list1 !== 'undefined') category_list1.value = list1;
  if (typeof list2 !== 'undefined') category_list2.value = list2;
  if (typeof list3 !== 'undefined') category_list3.value = list3;
  if (typeof list4 !== 'undefined') category_list4.value = list4;
  if (typeof list5 !== 'undefined') category_list5.value = list5;
  if (typeof list6 !== 'undefined') category_list6.value = list6;
  if (typeof list7 !== 'undefined') category_list7.value = list7;
}
const confirm_data = () => {
  category_data.value = {
    index: category_index.value,
    count: category_count.value,
    list1: category_list1.value,
    list2: category_list2.value,
    list3: category_list3.value,
    list4: category_list4.value,
    list5: category_list5.value,
    list6: category_list6.value,
    list7: category_list7.value,
    id1: category_id1.value,
    id2: category_id2.value,
    id3: category_id3.value,
    id4: category_id4.value,
    id5: category_id5.value,
    id6: category_id6.value,
    id7: category_id7.value,
  };
  listingForm.value.category_id = categoryID.value;
  listingForm.value.category_name = categoryNAME.value;
}
const clear_data = () => {
  update_uistate({ visible: false });
  setTimeout(() => {
    set_data(
      category_data.value || {
        index: 0, count: 0,
        list1: [], id1: 0,
        list2: [], id2: 0,
        list3: [], id3: 0,
        list4: [], id4: 0,
        list5: [], id5: 0,
        list6: [], id6: 0,
        list7: [], id7: 0,
        category_data: null,
      }
    );
  }, 100);
}
const showCategorys = () => {
  if (!listingForm.value.merchant_id) return message.error("请选择店铺");
  update_uistate({ visible: true });
  if (category_list1.value?.length > 0) return;
  update_uistate({ loading: true });
  setTimeout(() => {
    getCategorys();
  }, 100);
}
const getCategorys = () => {
  let id = '';
  if (category_index.value === 1) id = category_id1.value;
  if (category_index.value === 2) id = category_id2.value;
  if (category_index.value === 3) id = category_id3.value;
  if (category_index.value === 4) id = category_id4.value;
  if (category_index.value === 5) id = category_id5.value;
  if (category_index.value === 6) id = category_id6.value;
  if (category_index.value === 7) id = category_id7.value;
  proxy.$api.doAPI("/listingform/shopee/categorys", { category_id: id }).then((res) => {
    if (res.code === 0) {
      if (category_index.value === 0) category_list1.value = res.data.list, category_count.value = 1;
      if (category_index.value === 1) category_list2.value = res.data.list, category_count.value = 2;
      if (category_index.value === 2) category_list3.value = res.data.list, category_count.value = 3;
      if (category_index.value === 3) category_list4.value = res.data.list, category_count.value = 4;
      if (category_index.value === 4) category_list5.value = res.data.list, category_count.value = 5;
      if (category_index.value === 5) category_list6.value = res.data.list, category_count.value = 6;
      if (category_index.value === 6) category_list7.value = res.data.list, category_count.value = 7;
    } else {
      message.error(res.msg);
    }
    update_uistate({ loading: false });
  }).catch((err) => {
    update_uistate({ loading: false });
    console.error(err);
  });
}
const getSubCategorys = ({ index, id1, id2, id3, id4, id5, id6 }) => {
  proxy.$api.doAPI("/listingform/shopee/subcategorys", { index, id1, id2, id3, id4, id5, id6 }).then((res) => {
    if (res.code === 0) {
      set_data(res.data.result);
    } else {
      message.error(res.msg);
    }
    update_uistate({ loading: false });
  }).catch((err) => {
    update_uistate({ loading: false });
    console.error(err);
  });
}
const getAttributes = () => {
  let data = {
    id1: category_id1.value,
    id2: category_id2.value,
    id3: category_id3.value,
    id4: category_id4.value,
    id5: category_id5.value,
    id6: category_id6.value,
    id7: category_id7.value,
  };
  proxy.$api.doAPI("/listingform/shopee/attributes", data).then((res) => {
    if (res.code === 0) {
      set_data(res.data);
    } else {
      message.error(res.msg);
    }
    update_uistate({ loading: false });
  }).catch((err) => {
    update_uistate({ loading: false });
    console.error(err);
  });
}
const selectCategory1 = (item) => {
  let data = { index: 1, id1: item.category_id, id2: 0, id3: 0, id4: 0, id5: 0, id6: 0, id7: 0 }
  if (item.has_children) selectCategory(data);
  else set_data({ ...data, list2: [], list3: [], list4: [], list5: [], list6: [], list7: [] });
}
const selectCategory2 = (item) => {
  let data = { index: 2, id2: item.category_id, id3: 0, id4: 0, id5: 0, id6: 0, id7: 0 }
  if (item.has_children) selectCategory(data);
  else set_data({ ...data, list3: [], list4: [], list5: [], list6: [], list7: [] });
}
const selectCategory3 = (item) => {
  let data = { index: 3, id3: item.category_id, id4: 0, id5: 0, id6: 0, id7: 0, }
  if (item.has_children) selectCategory(data);
  else set_data({ ...data, list4: [], list5: [], list6: [], list7: [], });
}
const selectCategory4 = (item) => {
  let data = { index: 4, id4: item.category_id, id5: 0, id6: 0, id7: 0 }
  if (item.has_children) selectCategory(data);
  else set_data({ ...data, list5: [], list6: [], list7: [] });
}
const selectCategory5 = (item) => {
  let data = { index: 5, id5: item.category_id, id6: 0, id7: 0 }
  if (item.has_children) selectCategory(data);
  else set_data({ ...data, list6: [], list7: [] });
}
const selectCategory6 = (item) => {
  let data = { index: 6, id6: item.category_id, id7: 0 }
  if (item.has_children) selectCategory(data)
  else set_data({ ...data, list7: [] });
}
const selectCategory7 = (item) => {
  let data = { index: 7, id7: item.category_id, }
  if (item.has_children) selectCategory(data)
  else set_data({ ...data });
}
const selectCategory = (data) => {
  update_uistate({ loading: true });
  setTimeout(() => {
    getSubCategorys(data);
  }, 100);
}
const closeCategory = () => {
  clear_data();
}
const confirmCategory = () => {
  if (category_searchresultitem.value) {
    let { id1, id2, id3, id4, id5, id6, id7 } = category_searchresultitem.value;
    update_uistate({ visible: false, loading: true });
    setTimeout(() => {
      // TODO
      // getNodesAndAttributes([{
      //   region: curr_region.value,
      //   node_id1: id1,
      //   node_id2: id2,
      //   node_id3: id3,
      //   node_id4: id4,
      //   node_id5: id5,
      //   node_id6: id6,
      //   node_id7: id7,
      // }]);
    }, 100);
  } else {
    update_uistate({ visible: false, loading: true });
    nextTick(() => {
      confirm_data();
      getAttributes();
    });
  }
  backToSelect();
}
const selectSearchItem = (index) => {

}
const backToSelect = () => {

}
const doSearchCategory = () => {

}
//#endregion 类目菜单选择
//#endregion 方法
//#region 自动保存
const startAutoSave = () => {
  if (route.query.id && process.env.OS !== 'darwin') {
    stopAutoSave();
    if (listingForm.value.state === 'draft' || listingForm.value.state === 'fail') {
      timer = setTimeout(() => {
        // TODO
        // doSave({ autosave: true }).then(() => {
        //   startAutoSave();
        // });
      }, 5 * 60 * 1000);
    }
  }
}
const stopAutoSave = () => {
  clearTimeout(timer);
}
//#endregion 自动保存
//#region 快捷滚动
const clickMenu = ({ key }) => {
  stopscroll.value = true;
  document.getElementById(key).scrollIntoView();
  setTimeout(() => {
    stopscroll.value = false;
  }, 100);
}
const onScroll = () => {
  if (stopscroll.value) return;
  // 获取所有锚点元素
  const navContents = document.querySelectorAll('.code-box')
  // 所有锚点元素的 offsetTop
  const offsetTopArr = []
  navContents.forEach(item => {
    offsetTopArr.push(item.offsetTop)
  })
  // 获取当前文档流的 scrollTop
  const scrollTop = document.getElementById('mainform').scrollTop
  // 定义当前点亮的导航下标
  let navIndex = 0
  for (let n = 0; n < offsetTopArr.length; n++) {
    // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
    // 那么此时导航索引就应该是 n 了
    if (scrollTop >= offsetTopArr[n]) {
      navIndex = n
    }
  }
  console.log('onScroll', offsetTopArr, scrollTop, navIndex);
  // 把下标赋值给 vue 的 data
  if (navIndex === 0) activecards.value = ['card1'];
  if (navIndex === 1) activecards.value = ['card2'];
  if (navIndex === 2) activecards.value = ['card3'];
  if (navIndex === 3) activecards.value = ['card4'];
  if (navIndex === 4) activecards.value = ['card5'];
  if (navIndex === 5) activecards.value = ['card6'];
  if (navIndex === 6) activecards.value = ['card7'];
}
//#endregion 快捷滚动
//#region 生命周期
onActivated(() => {
  console.log('onActivated');
  activecards.value = ['card1'];
  document.getElementById('mainform').addEventListener('scroll', onScroll);
  init();
});
onDeactivated(() => {
  console.log('onDeactivated');
  stopAutoSave();
  document.getElementById('mainform')?.removeEventListener('scroll', onScroll)
});
onBeforeUnmount(() => {
  console.log('onBeforeUnmount');
  //TODO editorRef.value?.destroy()
});
//#endregion 生命周期
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-form-item-extra) {
  min-height: 0;
}

p {
  margin: 0;
  padding: 0;
}

.mainform {
  overflow: auto;
  height: 100vh;
  padding-bottom: 80px;
}

.footer {
  // box-shadow: 0 0 6px 0 #d9d9d9;
  height: 64px !important;
  line-height: 64px;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  text-align: center;
  z-index: 9;
  background-color: #fff;
  border-radius: 2px;
}

.shops {
  height: 32px;
  max-width: 300px;
  position: relative;
  width: 100%
}

.shop-box {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  align-items: center;
  border: 1px solid #d9d9d9;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding: 0 8px;
  width: 100%
}

.shop-box .shop-checked .number {
  color: #f19240
}

.ml5 {
  margin-left: 5px !important;
}

.shop-box .shop-feat-btn i {
  cursor: pointer;
  margin-left: 6px
}


.el-alert {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
  opacity: 1;
  overflow: hidden;
  padding: 8px 16px;
  position: relative;
  -webkit-transition: opacity .2s;
  transition: opacity .2s;
  width: 100%;
}

.el-alert--warning {
  background-color: #fdf6ec;
  color: #e6a23c;
}

.mb15 {
  margin-bottom: 15px !important;
}

.el-alert--warning.is-light {
  background-color: #fdf6ec;
  color: #e6a23c;
}

.el-alert__content {
  display: table-cell;
  padding: 0 8px;
}

.el-alert__title {
  font-size: 13px;
  line-height: 18px;
}

.el-alert__title.is-bold {
  font-weight: 700;
}

.el-alert .el-alert__description {
  font-size: 12px;
  margin: 5px 0 0;
}

.el-alert--warning.is-light .el-alert__description {
  color: #e6a23c;
}

.text-danger {
  color: #f56c6c !important;
}

.bold {
  font-weight: 700;
}

.el-alert--info {
  background-color: #f4f4f5;
  color: #909399;
}

.mt15 {
  margin-top: 15px !important;
}

.text-center {
  text-align: center;
}

.el-alert.is-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.el-alert.no-data {
  background-color: #f0f0f0;
  color: #999;
  line-height: 80px;
}

.loadtext {
  color: #7a7a7a;
  line-height: 16px;
  margin: 0;
  padding: 8px 0;
}

.pb0 {
  padding-bottom: 0 !important;
}

.text-warning {
  color: #e6a23c !important;
}

:deep(.ant-card-small > .ant-card-body) {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.tree-group {
  display: flex;
  overflow: auto;
  height: 500px;
}

.tree-item-card {
  min-width: 220px;
  width: 23%;
}

.tree-item {
  height: 435px;
  overflow: auto;
}

.code-box:target {
  border: 1px solid #1890ff;
}
</style>