<template>
  <a-spin :spinning="loading" style="overflow-x: hidden;">
    <a-row :gutter="16">
      <a-col :span="24" :xl="12" :lg="12">
        <a-card size="small" :bordered="false">
          <!-- <template #title>
              <span class="text-h8 font-weight-bold"> 基础信息 </span>
            </template> -->
          <a-form :model="aiForm" autocomplete="off" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }"
            :labelWrap="true">
            <a-form-item label="原标题">
              --
            </a-form-item>
            <a-form-item label="产品类目">
              <div v-if="browsetreenames.length > 0" class="d-flex flex-wrap text-left">
                <template v-for="(item, index) in browsetreenames" :key="index">
                  <span :class="{ 'text-orange': index === browsetreenames.length - 1 }">
                    <span v-if="index > 0" class="text-primary"> 》 </span>{{ item }}
                  </span>
                </template>
              </div>
              <span v-else>未选择分类</span>
            </a-form-item>
            <a-form-item label="关键词" :rules="[{ required: true, message: '请选择店铺' }]">
              <a-textarea v-model:value="keywords" placeholder="输入关键词，回车或逗号分隔，最多填写5个关键词"
                :auto-size="{ minRows: 5, maxRows: 5 }" />
            </a-form-item>
            <a-form-item label="生成语言">
              <a-input-group compact>
                <a-select v-model:value="language" :options="languages" style="width: 100px;" />
                <a-button class="btn-orange ml-2" :disabled="maxcount <= usedcount" @click="chat">
                  开始生成
                </a-button>
              </a-input-group>
            </a-form-item>
            <a-form-item :wrapper-col="{ span: 20, offset: 4 }">
              <span class="text-gray">
                今日免费:已用 <span class="text-orange"> {{ usedcount }} </span> 次，剩余
                <span class="text-orange"> {{ maxcount - usedcount }} </span>
                此功能处于测试阶段，期间每个账号每天可免费生成{{ maxcount }}次
              </span>
            </a-form-item>
          </a-form>
        </a-card>
      </a-col>
      <a-col :span="24" :xl="12" :lg="12">
        <a-card size="small">
          <template #title>
            <span class="text-h8 font-weight-bold"> AI生成 </span>
          </template>
          <div class="d-flex flex-column justify-space-between" style="min-height:300px;max-height:400px;">
            <a-list v-if="results.length > 0" :data-source="results" item-layout="horizontal"
              style="height:300px;overflow:auto;">
              <template #renderItem="{ item }">
                <a-list-item>
                  <template #actions>
                    <a href="javascript:;" @click="useResult(item)">应用</a>
                  </template>
                  <a-textarea :value="item" readonly :auto-size="{ minRows: 10, maxRows: 10 }" style="flex:1">
                  </a-textarea>
                </a-list-item>
              </template>
            </a-list>
            <a-empty v-else description="输入所需内容，点击开始生成即可智能生成内容" :image="false" />
            <div class="text-h10 pa-1">
              免责声明：
              <span class="text-gray">
                当前智能生成标题或描述的服务，所有内容都是由人工智能模型生成，生成的内容仅供参考，不代表我方的观点和态度。因使用此服务而产生的任何直接或间接损失，正店通均不承担责任
              </span>
            </div>
          </div>
        </a-card>
      </a-col>
    </a-row>
  </a-spin>
</template>
<script>
import { DownOutlined, CloseCircleFilled, CheckOutlined, CloudServerOutlined, IeOutlined, DesktopOutlined, } from '@ant-design/icons-vue';
import { message, } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, toRaw, onDeactivated, onUnmounted, } from "vue";
import _ from "underscore";
export default defineComponent({
  name: 'AI',
  components: { DownOutlined, CloseCircleFilled, CheckOutlined, CloudServerOutlined, IeOutlined, DesktopOutlined, },
  props: ['ai_field', 'ai_browsetreenames', 'ai_region', 'ai_language'],
  setup(props, ctx) {
    console.log('AI', toRaw(props));
    const { proxy } = getCurrentInstance();
    const state = reactive({
      loading: false,
      field: props.ai_field || 'item_name',
      browsetreenames: props.ai_browsetreenames || [],
      region: props.ai_region || 'US',
      keywords: '',
      language: props.ai_language || 'zh',
      languages: [
        { value: 'zh', label: '中文' },
        { value: 'en', label: '英文' },
      ],
      usedcount: 0,
      maxcount: 0,
      results: [],
    });
    watch(() => props.ai_field, (value) => {
      state.field = value;
    });
    watch(() => props.ai_browsetreenames, (value) => {
      state.browsetreenames = value;
    });
    watch(() => props.ai_region, (value) => {
      state.region = value;
    });
    watch(() => props.ai_language, (value) => {
      state.language = value;
    });
    onMounted(() => {
      console.log('AI-onMounted');
      init();
    });
    onActivated(() => {
      console.log('AI-onActivated');
    });
    onDeactivated(() => {
      console.log('AI-onDeactivated');
    });
    onUnmounted(() => {
      console.log('AI-onUnmounted');
    })
    const init = () => {
      state.loading = true;
      proxy.$api.doAPI("/ai/init").then((res) => {
        state.loading = false;
        if (res.code === 0) {
          state.maxcount = res.data.maxcount;
          state.usedcount = res.data.usedcount;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.loading = false;
        console.error(err);
      });
    }
    const chat = () => {
      let keywords = _.filter(state.keywords.trim().split(/,|\n/g), n => !!n).slice(0, 5);
      console.log(keywords);
      if (keywords.length === 0) return message.error('请填写关键词');
      if (keywords.length > 5) return message.error('关键词不能超过5个');
      let product_type = state.browsetreenames[state.browsetreenames.length - 1];
      if (product_type) product_type = product_type.substring(0, product_type.indexOf('('));
      let chatForm = {
        field: state.field,
        keywords,
        product_type,
        region: state.region,
        language: state.language,
      };
      state.loading = true;
      proxy.$api.doAPI("/ai/chat", chatForm).then((res) => {
        state.loading = false;
        if (res.code === 0) {
          state.results = res.data.results;
          init();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.loading = false;
        console.error(err);
      });
    }
    const useResult = (content) => {
      ctx.emit('content-generated', { content });
    }
    const saveText = () => {

    }
    return {
      ...toRefs(state),
      chat,
      useResult,
      saveText,
    };
  }
});
</script>
<style lang="less" scoped></style>