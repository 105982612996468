<template>
  <a-card size="small">
    <template #title>
      <a-row :gutter="16">
        <a-col :span="4">
          <a-form-item label="站点" style="margin-bottom:unset;">
            <a-select v-model:value="searchForm.region" :options="sellingcenters2" :disabled="loading"
              style="width: 100%;" />
          </a-form-item>
        </a-col>
        <a-col :span="20">
          <a-form-item label="ASIN" style="margin-bottom:unset;">
            <a-input-search v-model:value="searchForm.asin" placeholder="ASIN" enter-button="搜索" @search="getData"
              :loading="loading" :disabled="loading" />
          </a-form-item>
        </a-col>
      </a-row>
    </template>
    <a-table size="small" bordered :columns="columns" :data-source="list" :loading="loading" :pagination="false">
      <template #bodyCell="{ record, column }">
        <template v-if="column.dataIndex === 'image_url'">
          <a-tooltip v-if="record.storage.image_url" color="white" placement="rightBottom">
            <template #title>
              <a-image :src="record.storage.image_url" width="480px" height="480px"
                style="object-fit: contain;height:100%;" :preview="false">
                <template #placeholder>
                  <a-image :src="$filters.image_placeholder" width="480px" height="480px" :preview="false" />
                </template>
              </a-image>
            </template>
            <a href="javascript:;">
              <a-image :src="record.storage.image_url" width="80px" height="80px"
                style="object-fit: contain;height:100%;" :preview="false">
                <template #placeholder>
                  <a-image :src="$filters.image_placeholder" width="80px" height="80px" :preview="false" />
                </template>
              </a-image>
            </a>
          </a-tooltip>
          <a-image v-else :src="$filters.image_placeholder" width="80px" height="80px"
            style="object-fit: contain;height:100%;" :preview="false">
            <template #placeholder>
              <a-image :src="$filters.image_placeholder" width="80px" height="80px" :preview="false" />
            </template>
          </a-image>
        </template>
        <template v-if="column.dataIndex === 'title'">
          {{ record.storage.title }}
        </template>
        <template v-if="column.dataIndex === 'product_type'">
          <p>{{ record.storage.product_type }}</p>
          <p>{{ record.storage.productType }}</p>
        </template>
        <template v-if="column.dataIndex === 'asin'">
          <a-tooltip color="white">
            <template #title>
              <a href="javascript:;" @click="$filters.doCopy(record.asin)">复制</a>
            </template>
            <a :href="record.amazon_product_url" target="_blank">
              <span :class="`fi fi-${record.storage.region.toLowerCase()}`"></span> {{ record.asin }}
            </a>
          </a-tooltip>
        </template>
        <template v-if="column.dataIndex === 'sku'">
          <a-tooltip color="white">
            <template #title>
              <a href="javascript:;" @click="$filters.doCopy(record.sku)">复制</a>
            </template>
            {{ record.sku }}
          </a-tooltip>
        </template>
        <template v-if="column.dataIndex === 'price'">
          <p v-if="record.original_price" class="text-success">
            {{ record.original_price }}(原始价)
          </p>
          <p>{{ record.price }} {{ record.storage.currency }}</p>
          <p v-if="record.price_state === 'processing'" class="text-error">
            {{ record.new_price }} {{ record.storage.currency }}(更新中)
          </p>
          <p v-if="record.genmai_price" class="text-error">
            {{ record.genmai_price }}(最低价)
          </p>
        </template>
        <template v-if="column.dataIndex === 'quantity'">
          <p>{{ record.quantity }}</p>
          <p v-if="record.quantity_state === 'processing'" class="text-error">
            {{ record.new_quantity }}(更新中)
          </p>
        </template>
        <template v-if="column.dataIndex === 'state'">
          <a-space direction="vertical">
            <a-space>
              <a-tag v-if="record.state === 'waiting'" color="warning"> 排队中 </a-tag>
              <a-tag v-if="record.state === 'processing'" color="processing"> 发布中 </a-tag>
              <a-tag v-if="record.state === 'success'" color="success"> 已发布 </a-tag>
              <a-tag v-if="record.state === 'error'" color="error"> 发布失败</a-tag>
              <question-circle-outlined v-if="record.error_list?.length > 0" class="text-error font-weight-bold link"
                @click="showError(record._id)" />
            </a-space>
            <a-tag v-if="record.is_buybox_winnner" color="#87d068">赢得购物车</a-tag>
            <a-tag v-else color="#ff5500">未赢得购物车</a-tag>
            <a-space>
              <a-tag v-if="record.pstate === 'processing'" color="processing"> 更新中 </a-tag>
              <a-tag v-if="record.pstate === 'success'" color="success"> 已更新 </a-tag>
              <a-tag v-if="record.pstate === 'error'" color="error"> 更新失败</a-tag>
            </a-space>
          </a-space>
        </template>
        <template v-if="column.dataIndex === 'pricerule'">
          <a href="javascript:;" @click="showRule(record._id)">
            {{ record.pricerule || '绑定' }}
          </a>
        </template>
        <template v-if="column.dataIndex === 'operation'">
          <a-space>
            <a-popconfirm v-if="record.state === 'waiting'" title="发布跟卖商品?" @confirm="doPublish(record._id)">
              <a href="javascript:;" class="text-error"> 发布 </a>
            </a-popconfirm>
            <a-popconfirm v-if="record.state === 'error'" title="删除跟卖商品?" @confirm="doDelete(record._id)">
              <a href="javascript:;" class="text-error"> 删除 </a>
            </a-popconfirm>
          </a-space>
          <a-space direction="vertical" v-if="record.state === 'success'">
            <a href="javascript:;" class="text-error" @click="pullListing(record._id)">
              拉取商品
            </a>
            <a v-if="record.price_state !== 'processing'" href="javascript:;" class="text-error"
              @click="showEdit(record, 'price')">
              ①更新价格
            </a>
            <a v-if="record.quantity_state !== 'processing'" href="javascript:;" class="text-error"
              @click="showEdit(record, 'quantity')">
              ②更新库存
            </a>
            <a v-if="record.pstate !== 'processing'" href="javascript:;" class="text-error"
              @click="showEdit(record, 'price_quantity')">
              ③同时更新
            </a>
          </a-space>
        </template>
      </template>
    </a-table>
  </a-card>
  <a-modal v-model:visible="editModal" title="更新商品" :maskClosable="false" :closable="!editing">
    <a-form ref="editFormRef" name="editForm" :model="editForm" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }"
      autocomplete="off">
      <a-form-item v-if="editForm._type.indexOf('price') > -1" label="价格" name="price"
        :rules="[{ required: true, message: '请输入价格' }]">
        <a-input-number v-model:value="editForm.price" :addon-after="editForm.currency"
          style="width:100%;"></a-input-number>
      </a-form-item>
      <a-form-item v-if="editForm._type.indexOf('quantity') > -1" label="数量" name="quantity"
        :rules="[{ required: true, message: '请输入数量' }]">
        <a-input-number v-model:value="editForm.quantity" addon-after="库存" style="width:100%;"></a-input-number>
      </a-form-item>
    </a-form>
    <template #footer>
      <div class="d-flex justify-end align-center">
        <a-button @click="confirmEdit" type="danger" :loading="editing">
          更新
        </a-button>
        <a-button @click="() => editModal = false" :disabled="editing">
          取消
        </a-button>
      </div>
    </template>
  </a-modal>
  <a-modal v-model:visible="errorModal" title="异常" :footer="null">
    <a-alert v-if="error_msg" :message="error_msg" type="error" />
    <a-table size="small" bordered :columns="ecolumns" :data-source="error_list" :pagination="false">
      <template #bodyCell="{ record, column }">
        <template v-if="column.dataIndex === 'message'">
          <p>{{ record.message }}</p>
          <p v-if="record.desc" class="text-error">{{ record.desc }}</p>
        </template>
      </template>
    </a-table>
  </a-modal>
</template>

<script setup>
import _ from 'underscore';
import { ref, reactive, computed, getCurrentInstance, onActivated, watch } from 'vue';
import { PlusOutlined, MinusOutlined, QuestionCircleOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';

const columns = ref([
  { title: '图片', dataIndex: 'image_url', width: 80, },
  { title: '标题', dataIndex: 'title', width: 200, },
  { title: '类目', dataIndex: 'product_type', width: 100, },
  { title: 'ASIN', dataIndex: 'asin', width: 100, },
  { title: '店铺', dataIndex: 'shop_name', width: 120, },
  { title: 'SKU', dataIndex: 'sku', width: 120, },
  { title: '价格', dataIndex: 'price', width: 100, },
  { title: '数量', dataIndex: 'quantity', width: 100, },
  { title: '状态', dataIndex: 'state', width: 120, },
  { title: '调价规则', dataIndex: 'pricerule', width: 100, },
  { title: '操作', dataIndex: 'operation', width: 120, },
]);
const ecolumns = ref([
  { title: 'code', dataIndex: 'code', width: 100, },
  { title: 'message', dataIndex: 'message' },
]);
const { proxy } = getCurrentInstance();
const editFormRef = ref(null);

const loading = ref(false);
const sellingcenters = ref([]);
const searchForm = ref({ region: "ALL", asin: "", });
const page = ref(1);
const limit = ref(10);
const length = ref(0);
const count = ref(0);
const list = ref([]);
const editForm = ref({});
const editModal = ref(false);
const editing = ref(false);
const errorModal = ref(false);
const error_msg = ref("");
const error_list = ref([]);

const sellingcenters2 = computed(() => [{ value: "ALL", label: "全部" }, ...sellingcenters.value])

watch(() => page.value, () => {
  getData();
});
watch(() => limit.value, () => {
  if (page.value !== 1) page.value = 1;
  else getData();
});

const init = () => {
  loading.value = true;
  proxy.$api.doAPI2("/dev/amazon/genmai/init").then((res) => {
    loading.value = false;
    if (res.code === 0) {
      sellingcenters.value = res.data.sellingcenters;
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    loading.value = false;
    console.error(err);
  });
}
const getData = () => {
  loading.value = true;
  proxy.$api.doAPI2("/dev/amazon/genmai/tasklist", { page: page.value, limit: limit.value, ...searchForm.value }).then((res) => {
    loading.value = false;
    if (res.code === 0) {
      list.value = res.data.list;
      length.value = res.data.length;
      count.value = res.data.count;
      if (length.value > 0 && page.value > length.value) page.value = 1;
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    loading.value = false;
    console.error(err);
  });
}
const doPublish = (_id) => {
  loading.value = true;
  proxy.$api.doAPI2("/dev/amazon/genmai/publish", { _id }).then((res) => {
    loading.value = false;
    if (res.code === 0) {
      getData();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    loading.value = false;
    console.error(err);
  });
}
const doDelete = (_id) => {
  loading.value = true;
  proxy.$api.doAPI2("/dev/amazon/genmai/removetask", { _id }).then((res) => {
    loading.value = false;
    if (res.code === 0) {
      getData();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    loading.value = false;
    console.error(err);
  });
}
const pullListing = (_id) => {
  loading.value = true;
  proxy.$api.doAPI2("/dev/amazon/genmai/pulllisting", { _id }).then((res) => {
    loading.value = false;
    if (res.code === 0) {
      getData();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    loading.value = false;
    console.error(err);
  });
}
const showError = (_id) => {
  loading.value = true;
  proxy.$api.doAPI2("/dev/amazon/genmai/error", { _id }).then((res) => {
    loading.value = false;
    if (res.code === 0) {
      error_msg.value = res.data.error_msg;
      error_list.value = res.data.error_list;
      errorModal.value = true;
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    loading.value = false;
    console.error(err);
  });
}
const showEdit = (item, key) => {
  let editform = {
    _id: item._id,
    _type: key,
  };
  if (key.indexOf('price') > -1) {
    _.extend(editform, {
      price: item.price,
      currency: item.storage.currency,
    });
  }
  if (key.indexOf('quantity') > -1) {
    _.extend(editform, {
      quantity: item.quantity,
    });
  }
  editForm.value = editform;
  editModal.value = true;
}
const confirmEdit = async () => {
  try {
    await editFormRef.value.validateFields();
    editing.value = true;
    proxy.$api.doAPI2("/dev/amazon/genmai/confirmedit", { dataForm: editForm.value }).then((res) => {
      editing.value = false;
      if (res.code === 0) {
        getData();
        editModal.value = false;
      } else {
        message.error(res.msg);
      }
    }).catch((err) => {
      editing.value = false;
      console.error(err);
    });
  } catch (errorInfo) {
    console.log("Failed:", errorInfo);
  }
}
const showRule = (_id) => {

}
defineExpose({ init, getData });
onActivated(() => {
  init();
  getData();
});
</script>

<style lang="less" scoped>
@import "/node_modules/flag-icons/css/flag-icons.min.css";

p {
  margin: 0;
  padding: 0;
}
</style>