<template>
  <a-spin :spinning="ui_loading" style="overflow-x: hidden;">
    <a-row>
      <a-col :span="4">
        <div class="d-flex justify-center">
          <a-menu v-model:selectedKeys="activecards" @click="clickMenu">
            <a-menu-item key="card1">账号与站点</a-menu-item>
            <a-menu-item key="card2" v-show="listingForm.account_username">站点差异化信息</a-menu-item>
            <a-menu-item key="card3">产品描述</a-menu-item>
            <a-menu-item key="card4">产品信息</a-menu-item>
            <a-menu-item key="card5">产品图片</a-menu-item>
            <a-menu-item key="card6">售价和多属性</a-menu-item>
          </a-menu>
        </div>
      </a-col>
      <a-col :span="20">
        <div style="background:#f0f2f5;">
          <div id="mainform" class="mainform">
            <a-form ref="listingformRef" name="listingForm" :model="listingForm" autocomplete="off">
              <a-card size="small" title="账号与站点" id="card1" class="mb-2 code-box">
                <div class="form-item">
                  <div class="form-label ">
                    <span class="cn">账号</span>
                  </div>
                  <a-form-item name="account_username" style="width:100%;">
                    <div class="d-flex align-center">
                      <div class="el-alert el-alert--info" style="width:600px;">
                        <span :title="listingForm.account_username" class="el-alert__title">
                          {{ listingForm.account_username || '--' }}
                        </span>
                      </div>
                      <a-button v-if="listingForm.state === 'draft'" class="btn-primary ml-4" @click="showShopAccounts">
                        <FormOutlined />重选账号和站点
                      </a-button>
                    </div>
                  </a-form-item>
                </div>
                <div class="form-item">
                  <div class="form-label ">
                    <span class="cn">站点</span>
                  </div>
                  <a-form-item name="region_sites">
                    <div class="el-alert el-alert--info" style="width:600px;">
                      <span v-if="listingForm.state === 'fail'" class="el-alert__title text-danger">
                        {{ fail_region_sites }} <span>( 刊登失败 )</span>
                      </span>
                      <span v-else class="el-alert__title">
                        {{ region_sites || '--' }}
                      </span>
                    </div>
                  </a-form-item>
                </div>
              </a-card>
              <a-card size="small" title="站点差异化信息" id="card2" class="mb-2 code-box">
                <a-tabs v-if="listingForm.regions?.length > 0" v-model:activeKey="curr_region"
                  @change="getNodeProperties">
                  <a-tab-pane v-for="(region, index) in listingForm.regions" :key="region"
                    :tab="listingForm.region_names[index]">
                  </a-tab-pane>
                </a-tabs>
                <template v-if="listingForm.account_username">
                  <div class="form-item">
                    <div class="form-label required">
                      <span class="cn">产品标题</span>
                    </div>
                    <a-form-item name="item_name" style="width:100%;">
                      <a-input-group compact>
                        <a-input v-model:value="listingForm[curr_region].item_name" :maxlength="200"
                          placeholder="最多200字符，建议120字符" style="width:calc(100% - 130px);"
                          :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" showCount></a-input>
                        <a href="javascript:;" style="color:#51a77f;width:120px;line-height:32px;margin-left:8px;"
                          @click="showAIGenerater('item_name')">
                          <svg-icon iconClass="chatgpt" :style="{ 'width': '15px', 'height': '15px' }" />
                          AI推荐商品标题
                        </a>
                      </a-input-group>
                    </a-form-item>
                  </div>
                  <div class="form-item">
                    <div class="form-label required">
                      <span class="cn">Search Terms</span>
                    </div>
                    <a-form-item name="generic_keywords" style="width:100%;">
                      <a-input v-model:value="listingForm[curr_region].generic_keywords" :maxlength="250"
                        placeholder="最多250字符" style="width:calc(100% - 130px);"
                        :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" showCount></a-input>
                    </a-form-item>
                  </div>
                  <div class="form-item">
                    <div class="form-label required">
                      <span class="cn">Bullet Point</span>
                    </div>
                    <a-form-item name="bullet_points" style="width:100%;">
                      <template #extra>
                        <div class="text-gray">
                          每行一条Bullet Point，最多<span class="text-error"> 5 </span>行，每行最多
                          <span class="text-error"> 500 </span>个字符（按回车键换行）
                        </div>
                      </template>
                      <a-input-group compact>
                        <a-textarea v-model:value="listingForm[curr_region].bullet_points"
                          :auto-size="{ minRows: 7, maxRows: 7 }" style="width:calc(100% - 130px);"
                          :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" />
                        <a href="javascript:;" style="color:#51a77f;width:120px;line-height:32px;margin-left:8px;"
                          @click="showAIGenerater('bullet_points')">
                          <svg-icon iconClass="chatgpt" :style="{ 'width': '15px', 'height': '15px' }" />
                          AI推荐五点描述
                        </a>
                      </a-input-group>
                    </a-form-item>
                  </div>
                  <div class="form-item">
                    <div class="form-label required">
                      <span class="cn">产品分类</span>
                    </div>
                    <a-form-item name="browsenodename" style="width:100%;">
                      <div class="d-flex align-center">
                        <a-input v-model:value="listingForm[curr_region].browsenodename" placeholder="请选择" readonly
                          style="width:800px;"></a-input>
                        <a-button class="btn-primary ml-4"
                          :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'"
                          @click="selectBrowseTree">
                          <FormOutlined /> 浏览及搜索Amazon产品分类
                        </a-button>
                      </div>
                      <template #extra>
                        <div v-if="browsetreeID1" class="d-flex flex-wrap">
                          <span>{{ browsetreeNAME1 }}</span>
                          <span v-if="browsetreeNAME2">
                            》 <span :class="{ 'text-orange': browsetreeID === browsetreeID2 }">
                              {{ browsetreeNAME2 }}
                            </span>
                          </span>
                          <span v-if="browsetreeNAME3">
                            》 <span :class="{ 'text-orange': browsetreeID === browsetreeID3 }">
                              {{ browsetreeNAME3 }}
                            </span>
                          </span>
                          <span v-if="browsetreeNAME4">
                            》 <span :class="{ 'text-orange': browsetreeID === browsetreeID4 }">
                              {{ browsetreeNAME4 }}
                            </span>
                          </span>
                          <span v-if="browsetreeNAME5">
                            》 <span :class="{ 'text-orange': browsetreeID === browsetreeID5 }">
                              {{ browsetreeNAME5 }}
                            </span>
                          </span>
                          <span v-if="browsetreeNAME6">
                            》 <span :class="{ 'text-orange': browsetreeID === browsetreeID6 }">
                              {{ browsetreeNAME6 }}
                            </span>
                          </span>
                          <span v-if="browsetreeNAME7">
                            》 <span :class="{ 'text-orange': browsetreeID === browsetreeID7 }">
                              {{ browsetreeNAME7 }}
                            </span>
                          </span>
                        </div>
                      </template>
                    </a-form-item>
                  </div>
                  <div class="form-item">
                    <div class="form-label required">
                      <span class="cn">Product Type</span>
                    </div>
                    <a-form-item name="feed_product_type">
                      <a-select v-model:value="listingForm[curr_region].feed_product_type"
                        :options="browsetreeProductTypes" placeholder="请选择" show-search
                        :filter-option="$filters.filterOption" @change="(v) => changeProductType(v, curr_region)"
                        style="width:400px;"
                        :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" />
                    </a-form-item>
                  </div>
                  <div class="form-item">
                    <div class="form-label required">
                      <span class="cn">分类属性</span>
                    </div>
                    <a-form-item style="width:100%;">
                      <a-card v-if="listingForm[curr_region].feed_product_type">
                        <template #extra>
                          <a-input-search v-model:value="attribute_search_key" placeholder="请输入属性名称" enter-button="属性搜索"
                            allow-clear @search="(v) => doSearchAttribute(v, curr_region)" style="width:400px;"
                            :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" />
                        </template>
                        <div class="d-flex flex-column align-center"
                          v-if="browsetree[curr_region]?.properties?.length > 0">
                          <div v-for="p in browsetree[curr_region].properties" :key="p.field_key">
                            <div v-for="ot in listingForm._properties[curr_region][p.field_key].list"
                              :key="ot.field_key">
                              <div class="form-item">
                                <div class="form-label" style="width:300px;">
                                  <span class="cn " v-if="ot.label_cn">{{ ot.label_cn }}</span>
                                  <span class="en text-gray">{{ ot.label }}({{ p.field_key }})</span>
                                </div>
                                <a-tooltip :mouseEnterDelay="0.5">
                                  <template #title>{{ ot.description }}</template>
                                  <a-form-item :name="ot.field_key">
                                    <template #extra>
                                      {{ ot.field_key }}
                                    </template>
                                    <a-auto-complete v-if="ot?.options?.length > 0" v-model:value="ot.value"
                                      :options="ot.options" :placeholder="ot.example ? `例如：${ot.example}` : ''"
                                      style="width:400px;" @change="(v) => { if (!v) ot.value = null }"
                                      :filter-option="$filters.filterOption"
                                      :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" />
                                    <a-input v-else v-model:value="ot.value"
                                      :placeholder="ot.example ? `例如：${ot.example}` : ''" style="width:400px;"
                                      :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" />
                                  </a-form-item>
                                </a-tooltip>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a-card>
                    </a-form-item>
                  </div>
                </template>
              </a-card>
              <a-card size="small" title="产品描述" id="card3" class="mb-2 code-box">
                <a-tabs v-if="listingForm.regions?.length > 0" v-model:activeKey="curr_region">
                  <a-tab-pane v-for="(region, index) in listingForm.regions" :key="region"
                    :tab="listingForm.region_names[index]">
                  </a-tab-pane>
                </a-tabs>
                <div v-show="product_description_edit_type === 'editor'">
                  <div v-show="listingForm.state === 'draft' || listingForm.state === 'fail'"
                    style="border: 1px solid #ccc;width:calc(100% - 130px);">
                    <Toolbar style="border-bottom: 1px solid #ccc" :editor="editorRef" :defaultConfig="toolbarConfig"
                      mode="default" />
                    <Editor style="height: 300px; overflow-y: hidden;" v-model="product_description"
                      :defaultConfig="editorConfig" mode="default" @onCreated="(editor) => handleCreated(editor)" />
                  </div>
                  <div v-show="listingForm.state !== 'draft' && listingForm.state !== 'fail'"
                    style="height:300px;overflow:auto;border: 1px solid #ccc;" v-html="product_description">
                  </div>
                </div>
                <div v-show="product_description_edit_type === 'textarea'">
                  <a-textarea v-model:value="product_description2" :auto-size="{ minRows: 15, maxRows: 15 }"
                    style="width:calc(100% - 130px);"
                    :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" />
                </div>
                <a href="javascript:;" class="underline text-h10" v-if="product_description_edit_type === 'editor'"
                  @click="() => {
                    product_description_edit_type = 'textarea';
                    product_description2 = product_description;
                  }">
                  使用纯文本编辑器
                </a>
                <a href="javascript:;" class="underline text-h10" v-if="product_description_edit_type === 'textarea'"
                  @click="() => {
                    product_description_edit_type = 'editor';
                    product_description = product_description2;
                  }">
                  使用富文本编辑器
                </a>
              </a-card>
              <a-card size="small" title="产品信息" id="card4" class="mb-2 code-box">
                <a-row>
                  <a-col :span="12">
                    <div class="form-item">
                      <div class="form-label required">
                        <span class="cn">主SKU编号</span>
                      </div>
                      <a-form-item name="item_sku" :rules="[{ required: true, message: '请输入主SKU编号' }]"
                        style="width:100%;">
                        <a-input v-model:value="listingForm.item_sku" :maxlength="40" style="width:100%;"
                          :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" showCount></a-input>
                      </a-form-item>
                    </div>
                  </a-col>
                  <a-col :span="12">
                    <div class="form-item">
                      <div class="form-label required">
                        <span class="cn">品牌</span>
                      </div>
                      <a-form-item name="brand_name" :rules="[{ required: true, message: '请输入品牌' }]">
                        <div class="d-flex align-center">
                          <a-auto-complete v-model:value="listingForm.brand_name" :options="brands" placeholder="请输入品牌"
                            style="width:400px;" :filter-option="$filters.filterOption"
                            :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" />
                          <a-button v-if="listingForm.state === 'draft' || listingForm.state === 'fail'"
                            @click="() => { listingForm.brand_name = 'Generic' }">
                            无品牌
                          </a-button>
                        </div>
                      </a-form-item>
                    </div>
                  </a-col>
                  <a-col :span="12">
                    <div class="form-item">
                      <div class="form-label required">
                        <span class="cn">制造商</span>
                      </div>
                      <a-form-item name="manufacturer" :rules="[{ required: true, message: '请输入制造商' }]">
                        <a-auto-complete v-model:value="listingForm.manufacturer" :options="manufacturers"
                          placeholder="请输入制造商" style="width:400px;" :filter-option="$filters.filterOption"
                          :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" />
                      </a-form-item>
                    </div>
                  </a-col>
                  <a-col :span="12">
                    <div class="form-item">
                      <div class="form-label">
                        <span class="cn">制造商零件编号</span>
                      </div>
                      <a-form-item name="part_number">
                        <div class="d-flex align-center">
                          <a-input v-model:value="listingForm.part_number" placeholder="最多40个字符" :maxlength="40"
                            style="width:400px;"
                            :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" showCount />
                          <a-tooltip title="品牌-主SKU编号来生成"
                            v-if="listingForm.state === 'draft' || listingForm.state === 'fail'">
                            <a-button
                              @click="() => { listingForm.part_number = (listingForm.brand_name || '') + '-' + (listingForm.item_sku || '') }">
                              一键生成
                            </a-button>
                          </a-tooltip>
                        </div>
                      </a-form-item>
                    </div>
                  </a-col>
                  <a-col :span="12">
                    <div class="form-item">
                      <div class="form-label required">
                        <span class="cn">物品状况</span>
                      </div>
                      <a-form-item name="condition_type" :rules="[{ required: true, message: '请选择物品状况' }]"
                        style="width:100%;">
                        <a-select v-model:value="listingForm.condition_type"
                          :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'">
                          <a-select-option v-for="item in condition_types" :key="item" :value="item">
                            {{ item }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </div>
                  </a-col>
                  <a-col :span="24">
                    <div class="form-item">
                      <div class="form-label">
                        <span class="cn">物品状况描述</span>
                      </div>
                      <a-form-item name="condition_note" style="width:100%;">
                        <a-textarea v-model:value="listingForm.condition_note" :auto-size="{ minRows: 4, maxRows: 4 }"
                          :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'"
                          style="width:calc(100% - 130px);" />
                      </a-form-item>
                    </div>
                  </a-col>
                  <a-col :span="24">
                    <div class="form-item">
                      <div class="form-label">
                        <span class="cn">Platinum Keywords</span>
                      </div>
                      <a-form-item name="platinum_keywords" style="width:100%;">
                        <a-row :gutter="16" style="width:calc(100% - 130px);">
                          <a-col :span="12">
                            <a-form-item>
                              <a-input v-model:value="listingForm.platinum_keywords1" placeholder="最多100字符"
                                style="width:100%;" :maxlength="100"
                                :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" showCount />
                            </a-form-item>
                          </a-col>
                          <a-col :span="12">
                            <a-form-item>
                              <a-input v-model:value="listingForm.platinum_keywords2" placeholder="最多100字符"
                                style="width:100%;" :maxlength="100"
                                :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" showCount />
                            </a-form-item>
                          </a-col>
                          <a-col :span="12">
                            <a-form-item>
                              <a-input v-model:value="listingForm.platinum_keywords4" placeholder="最多100字符"
                                style="width:100%;" :maxlength="100"
                                :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" showCount />
                            </a-form-item>
                          </a-col>
                          <a-col :span="12">
                            <a-form-item>
                              <a-input v-model:value="listingForm.platinum_keywords4" placeholder="最多100字符"
                                style="width:100%;" :maxlength="100"
                                :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" showCount />
                            </a-form-item>
                          </a-col>
                          <a-col :span="12">
                            <a-form-item>
                              <a-input v-model:value="listingForm.platinum_keywords5" placeholder="最多100字符"
                                style="width:100%;" :maxlength="100"
                                :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" showCount />
                            </a-form-item>
                          </a-col>
                        </a-row>
                      </a-form-item>
                    </div>
                  </a-col>
                </a-row>
              </a-card>
              <a-card size="small" title="产品图片" id="card5" class="mb-2 code-box">
                <template #extra>
                  <div style="background-color:#f4f4f5;color:#909399;padding:8px 16px;">
                    <InfoCircleFilled style="font-size:16px;width:16px;" />
                    图片最多支持<span class="text-error">9</span>张，图片拖放可改变排序
                  </div>
                </template>
                <div class="el-alert el-alert--warning is-light mb15">
                  <div class="el-alert__content">
                    <span class="el-alert__title is-bold">图像必须符合以下要求：</span>
                    <div class="el-alert__description">
                      <ul style="list-style: disc; line-height: 200%;margin:0;">
                        <li>
                          主图的背景<span class="text-danger bold">必须是纯白色</span>，
                          只能是照片，不能包含实际不在订单内的配件和道具；主图不能带LOGO和水印（产品本身的LOGO是允许的）主图中的产品最好是占据图片大约85%左右的空间；
                        </li>
                        <li>图片像素建议大于1000*1000像素，可有'zoom function'图片放大功能，图片不能小于500*500像素；</li>
                        <li>推荐上传JPEG格式 , 也可上传TIFF, GIF 格式的图片；</li>
                        <li>图片中如有模特，模特不能是坐姿，最好站立，只能用真人模特，不能使用模型模特，不能包含裸体信息；</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <a-row v-if="listingForm.state === 'draft' || listingForm.state === 'fail'">
                  <a-col :span="12" class="d-flex align-center">
                    <p class="text-h8 font-weight-bold">添加图片：</p>
                    <ImagePicker0 :direction="'horizontal'" :max_count="MAX_IMAGE_COUNT" :images="listingForm.images"
                      @local-upload-success="(data) => onLocalUploadSuccess({ type: 'listingform', data })"
                      @network-input-success="(data) => onNetworkInputSuccess({ type: 'listingform', data })"
                      @space-pick-success="(data) => onSpacePickSuccess({ type: 'listingform', data })" />
                  </a-col>
                  <a-col :span="12">

                  </a-col>
                </a-row>
                <draggable v-if="listingForm.images?.length > 0" v-model="listingForm.images" item-key="index"
                  class="d-flex flex-wrap mt-2" style="min-height:175px;"
                  :move="() => (listingForm.state === 'draft' || listingForm.state === 'fail')">
                  <template #item="{ index, element: image }">
                    <div style="width:145px;border:1px solid #e8e8e8;" class="mr-1">
                      <div class="image-header" style="border-bottom:1px solid #e8e8e8;">
                        <img :src="image" style="object-fit: contain;" />
                      </div>
                      <div v-if="listingForm.state === 'draft' || listingForm.state === 'fail'"
                        style="height:30px;padding:5px;" class="d-flex justify-space-between align-center">
                        <a-dropdown>
                          <template #overlay>
                            <ImagePicker1 :max_count="1" :images="[]"
                              @local-upload-success="(data) => onLocalUploadSuccess({ type: 'listingform2', index, data })"
                              @network-input-success="(data) => onNetworkInputSuccess({ type: 'listingform2', index, data })"
                              @space-pick-success="(data) => onSpacePickSuccess({ type: 'listingform2', index, data })" />
                          </template>
                          <a-tooltip>
                            <template #title>更换图片</template>
                            <svg-icon iconClass="huantu"
                              :style="{ 'height': '20px', 'width': '20px', cursor: 'pointer' }" />
                          </a-tooltip>
                        </a-dropdown>
                        <!-- <a-divider /> -->
                        <a-tooltip>
                          <template #title>预览图片</template>
                          <EyeOutlined @click="$preview(image, listingForm.images)" style="font-size:20px;"
                            class="text-info" />
                        </a-tooltip>
                        <a-tooltip>
                          <template #title>删除图片</template>
                          <DeleteOutlined @click="removeImage({ type: 'listingform', index })"
                            style="font-size:20px;color:#ff4d4f;" />
                        </a-tooltip>
                      </div>
                    </div>
                  </template>
                </draggable>
                <div v-else class="el-alert text-center no-data mt15 el-alert--info is-center is-light">
                  <div class="el-alert__content"><span class="el-alert__title">暂无图片，请上传产品图片</span>
                  </div>
                </div>
              </a-card>
              <a-card size="small" title="售价和多属性" id="card6" class="mb-2 code-box">
                <div class="form-item">
                  <div class="form-label ">
                    <span class="cn">售卖形式</span>
                  </div>
                  <a-form-item name="sale_type">
                    <a-radio-group v-model:value="listingForm.sale_type" name="sale_type"
                      :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'">
                      <a-radio value="1">单品</a-radio>
                      <a-radio value="2">变种</a-radio>
                    </a-radio-group>
                    <p class="loadtext pb0 text-warning">
                      {{
                        listingForm.external_product_id_type === 'GCID' ?
                          '注意：如果您申请过亚马逊品牌备案，并且针对您刊登的分类也申请过GTIN豁免（UPC豁免)，在GCID选项下填写对应的MPN(制造商零件编号）即可，建议不超过40个字符，且每个子SKU的MPN编号不同。'
                          :
                          '注意：如果您申请过亚马逊品牌备案，并且针对您刊登的分类也申请过GTIN豁免（UPC豁免)，则无需填写UPC。产品ID类型选择GTIN后，保持产品ID为空即可。'
                      }}
                    </p>
                  </a-form-item>
                </div>
                <a-row>
                  <a-col :span="12">
                    <div class="form-item">
                      <div class="form-label ">
                        <span class="cn">税务编码</span>
                      </div>
                      <a-form-item name="product_tax_code">
                        <a-input v-model:value="listingForm.product_tax_code" style="width:400px;"
                          :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" />
                      </a-form-item>
                    </div>
                  </a-col>
                  <a-col :span="12">
                    <div class="form-item">
                      <div class="form-label ">
                        <span class="cn">处理天数</span>
                      </div>
                      <a-form-item name="fulfillment_latency">
                        <a-input v-model:value="listingForm.fulfillment_latency" type="number" suffix="天"
                          placeholder="请输入处理天数,不填写系统默认为2天" style="width:400px;"
                          :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" />
                      </a-form-item>
                    </div>
                  </a-col>
                  <template v-if="listingForm.sale_type === '1'">
                    <a-col :span="24">
                      <div class="form-item">
                        <div
                          :class="{ 'form-label': true, 'required': listingForm.external_product_id_type !== 'GTIN' }">
                          <span class="cn">产品ID</span>
                        </div>
                        <a-form-item name="external_product_id">
                          <div class="d-flex align-center">
                            <a-select v-model:value="listingForm.external_product_id_type" style="width:80px;"
                              :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'">
                              <a-select-option v-for="item in external_product_id_types" :key="item" :value="item">
                                {{ item }}
                              </a-select-option>
                            </a-select>
                            <a-input v-model:value="listingForm.external_product_id" placeholder="最多16个字符"
                              :maxlength="16" style="width:400px;"
                              :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" showCount />
                            <a-button class="btn-primary ml-2" @click="getExternalProductID">
                              自动获取
                            </a-button>
                          </div>
                        </a-form-item>
                      </div>
                    </a-col>
                    <a-col :span="12">
                      <div class="form-item">
                        <div class="form-label required">
                          <span class="cn">价格</span>
                        </div>
                        <a-form-item name="standard_price"
                          :rules="[{ required: true, message: '价格不能为空或0' }, { validator: validatePrice, trigger: 'change' }]">
                          <div class="d-flex align-center" style="width:100%">
                            <a-input v-model:value="listingForm.standard_price" type="number" disabled
                              style="width:400px;" />
                            <a-button @click="editListingPrice"
                              :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'">
                              <template #icon>
                                <FormOutlined />
                              </template>
                            </a-button>
                          </div>
                        </a-form-item>
                      </div>
                    </a-col>
                    <a-col :span="12">
                      <div class="form-item">
                        <div class="form-label required">
                          <span class="cn">数量</span>
                        </div>
                        <a-form-item name="quantity"
                          :rules="[{ required: true, message: '数量不能为空' }, { validator: validateQuantity, trigger: 'change' }]">
                          <a-input v-model:value="listingForm.quantity" type="number" style="width:400px;"
                            :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" />
                        </a-form-item>
                      </div>
                    </a-col>
                  </template>
                  <template v-if="listingForm.sale_type === '2'">
                    <a-col :span="12">
                      <div class="form-item">
                        <div class="form-label required">
                          <span class="cn">商品属性</span>
                        </div>
                        <a-form-item name="variation_theme" :rules="[{ required: true, message: `请选择商品属性` }]">
                          <a-select v-model:value="listingForm.variation_theme" :options="variation_themes"
                            placeholder="请选择" show-search :filter-option="$filters.filterOption" style="width:400px;"
                            @change="changeVariationTheme"
                            :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'">
                          </a-select>
                        </a-form-item>
                      </div>
                    </a-col>
                    <a-col :span="24">
                      <div class="d-flex justify-space-between">
                        <a-button danger @click="removeVariationItems"
                          :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'">
                          <DeleteOutlined />批量删除多属性
                        </a-button>
                        <a-space>
                          <a-button class="btn-success" @click="addVariationItem"
                            :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'">
                            <PlusOutlined />添加一行多属性
                          </a-button>
                          <a-button class="btn-primary" @click="editVariationTheme"
                            :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'">
                            <FormOutlined />批量修改多属性
                          </a-button>
                        </a-space>
                      </div>
                      <template v-if="variation_list.length > 0">
                        <a-table :columns="variation_headers" :data-source="variation_list" bordered :pagination="false"
                          row-key="_id"
                          :row-selection="{ selectedRowKeys: variation_ids, onChange: (keys) => variation_ids = keys }"
                          size="small">
                          <template #headerCell="{ column }">
                            <template v-if="column.dataIndex === 'color_name'">
                              <span class="header required">color_name</span>
                            </template>
                            <template v-if="column.dataIndex === 'size_name'">
                              <span class="header required">size_name</span>
                            </template>
                            <template v-if="column.dataIndex === 'material_type'">
                              <span class="header required">material</span>
                            </template>
                            <template v-if="column.dataIndex === 'item_sku'">
                              <div class="d-flex flex-column justify-center align-center">
                                <span class="header required">SKU编号</span>
                                <a v-if="listingForm.state === 'draft' || listingForm.state === 'fail'"
                                  href="javascript:;" class="text-primary font-weight-bold text-h10 underline"
                                  @click="oneClickGenerate('item_sku')">一键生成</a>
                              </div>
                            </template>
                            <template v-if="column.dataIndex === 'external_product_id'">
                              <div class="d-flex justify-center align-center">
                                <a-select v-model:value="variation_list[0].external_product_id_type"
                                  @change="changeVariationListProductIDType"
                                  :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'">
                                  <a-select-option v-for="item in external_product_id_types" :key="item" :value="item">
                                    {{ item }}
                                  </a-select-option>
                                </a-select>
                                <div v-if="listingForm.state === 'draft' || listingForm.state === 'fail'"
                                  class="d-flex flex-column ml-2">
                                  <a v-if="variation_list[0].external_product_id_type === 'GCID'" href="javascript:;"
                                    class="text-primary font-weight-bold text-h10 underline"
                                    @click="oneClickGenerate('external_product_id')">一键生成</a>
                                  <a href="javascript:;" class="text-primary font-weight-bold text-h10 underline"
                                    @click="getExternalProductID">批量修改</a>
                                </div>
                              </div>
                            </template>
                            <template v-if="column.dataIndex === 'quantity'">
                              <div class="d-flex flex-column justify-center align-center">
                                <span class="header required">数量</span>
                                <a v-if="listingForm.state === 'draft' || listingForm.state === 'fail'"
                                  href="javascript:;" class="text-primary font-weight-bold text-h10 underline"
                                  @click="editVariationList('quantity')">批量修改</a>
                              </div>
                            </template>
                            <template v-if="column.dataIndex === 'standard_price'">
                              <div class="d-flex flex-column justify-center align-center">
                                <span class="header required">价格</span>
                                <a-space v-if="listingForm.state === 'draft' || listingForm.state === 'fail'">
                                  <a href="javascript:;" class="text-primary font-weight-bold text-h10 underline"
                                    @click="editVariationList('standard_price')">批量修改</a>
                                  <a-tooltip :overlayStyle="{ 'width': '750px', 'max-width': '750px' }">
                                    <template #title>
                                      <p>将第一个站点的金额，换算成其他站点对应币种的金额</p>
                                      <p>汇率设置请至--系统管理>汇率管理去设置，如果没有设置有效汇率，点击汇率换算，将不会执行换算，价格栏为空值</p>
                                    </template>
                                    <a href="javascript:;"
                                      class="text-primary font-weight-bold text-h10 underline">汇率换算</a>
                                  </a-tooltip>
                                </a-space>
                              </div>
                            </template>
                            <template v-if="column.dataIndex === 'item_name'">
                              <div class="d-flex flex-column justify-center align-center">
                                <span class="header">
                                  标题
                                  <a-tooltip color="#fff" :overlayStyle="{ 'width': '400px', 'max-width': '400px' }">
                                    <template #title>
                                      <div class="text-black pa-1">
                                        <div class="text-h6">多属性标题</div>
                                        标题建议修改成200字符以内，如仍要上传，商品能上传成功但后台数据可能禁止显示
                                      </div>
                                    </template>
                                    <QuestionCircleOutlined class="text-primary" />
                                  </a-tooltip>
                                </span>
                                <a v-if="listingForm.state === 'draft' || listingForm.state === 'fail'"
                                  href="javascript:;" class="text-primary font-weight-bold text-h10 underline"
                                  @click="oneClickGenerate('item_name')">一键生成</a>
                              </div>
                            </template>
                          </template>
                          <template #bodyCell="{ record, index, column }">
                            <template v-if="column.dataIndex === 'index'">
                              <div class="d-flex flex-column align-center">
                                <a-button v-if="listingForm.state === 'draft' || listingForm.state === 'fail'"
                                  style="width:20px;height:20px;padding:0;" :disabled="index === 0"
                                  @click="moveUp({ index })">
                                  <template #icon>
                                    <UpOutlined />
                                  </template>
                                </a-button>
                                {{ index + 1 }}
                                <a-button v-if="listingForm.state === 'draft' || listingForm.state === 'fail'"
                                  style="width:20px;height:20px;padding:0;"
                                  :disabled="index === variation_list.length - 1" @click="moveDown({ index })">
                                  <template #icon>
                                    <DownOutlined />
                                  </template>
                                </a-button>
                              </div>
                            </template>
                            <template v-if="column.dataIndex === 'color_name'">
                              <a-input v-model:value="record.color_name" style="width:100%"
                                @blur="(e) => blurVariationItemAttribute(e, record.color_name)"
                                :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" />
                            </template>
                            <template v-if="column.dataIndex === 'size_name'">
                              <a-input v-model:value="record.size_name" style="width:100%"
                                @blur="(e) => blurVariationItemAttribute(e, record.size_name)"
                                :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" />
                            </template>
                            <template v-if="column.dataIndex === 'material_type'">
                              <a-input v-model:value="record.material_type" style="width:100%"
                                @blur="(e) => blurVariationItemAttribute(e, record.material_type)"
                                :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" />
                            </template>
                            <template v-if="column.dataIndex === 'item_sku'">
                              <a-input v-model:value="record.item_sku" :maxlength="40" style="width:100%"
                                :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" showCount />
                            </template>
                            <template v-if="column.dataIndex === 'external_product_id'">
                              <a-input v-model:value="record.external_product_id" :maxlength="40" style="width:100%"
                                :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" showCount />
                            </template>
                            <template v-if="column.dataIndex === 'quantity'">
                              <a-input v-model:value="record.quantity" type="number" style="width:100%"
                                :disabled="listingForm.state !== 'draft' && listingForm.state !== 'fail'" />
                            </template>
                            <template v-if="column.dataIndex === 'standard_price'">
                              <div class="d-flex align-center" style="width:100%">
                                <a-input v-model:value="record.standard_price" type="number" disabled />
                                <a-button v-if="listingForm.state === 'draft' || listingForm.state === 'fail'"
                                  @click="editVariationItem(index, record, 'standard_price')">
                                  <template #icon>
                                    <FormOutlined />
                                  </template>
                                </a-button>
                              </div>
                            </template>
                            <template v-if="column.dataIndex === 'item_name'">
                              <div class="d-flex align-center" style="width:100%">
                                <a-input v-model:value="record.item_name" disabled style="width:100%" />
                                <a-button v-if="listingForm.state === 'draft' || listingForm.state === 'fail'"
                                  @click="editVariationItem(index, record, 'item_name')">
                                  <template #icon>
                                    <FormOutlined />
                                  </template>
                                </a-button>
                              </div>
                            </template>
                            <template v-if="column.dataIndex === 'operation'">
                              <a-space v-if="listingForm.state === 'draft' || listingForm.state === 'fail'"
                                direction="vertical">
                                <a href="javascript:;" class="mb-2" @click="useAllVariationItem(index)">
                                  <CopyOutlined /> 应用到全部
                                </a>
                                <a href="javascript:;" class="text-error" @click="removeVariationItem(index)">
                                  <DeleteOutlined /> 删除
                                </a>
                              </a-space>
                            </template>
                          </template>
                        </a-table>
                        <a-table :columns="variation_headers2" :data-source="variation_list" bordered
                          :pagination="false" size="small">
                          <template #bodyCell="{ record, index, column }">
                            <template v-if="column.dataIndex === 'index'">
                              {{ index + 1 }}
                            </template>
                            <template v-if="column.dataIndex === 'attribute'">
                              <a-space direction="vertical">
                                <div v-if="variation_color">
                                  color_name:<span class="text-primary">{{ record.color_name }}</span>
                                </div>
                                <div v-if="variation_size">
                                  size_name:<span class="text-primary">{{ record.size_name }}</span>
                                </div>
                                <div v-if="variation_material">
                                  material:<span class="text-primary">{{ record.material_type }}</span>
                                </div>
                                <template v-if="listingForm.state === 'draft' || listingForm.state === 'fail'">
                                  <template v-if="is_variation">
                                    <a-dropdown>
                                      <template #overlay>
                                        <a-menu @click="(e) => useAllImages(index, e.key)">
                                          <a-menu-item v-if="variation_color" key="color"> 相同的color_name </a-menu-item>
                                          <a-menu-item v-if="variation_size" key="size"> 相同的size_name </a-menu-item>
                                          <a-menu-item v-if="variation_material" key="material"> 相同的material
                                          </a-menu-item>
                                          <a-menu-item key="all"> 全部多属性 </a-menu-item>
                                        </a-menu>
                                      </template>
                                      <a href="javascript:;">
                                        <CopyOutlined /> 图片应用到
                                        <DownOutlined />
                                      </a>
                                    </a-dropdown>
                                    <a-dropdown>
                                      <template #overlay>
                                        <a-menu @click="(e) => useAllMainImages(index, e.key)">
                                          <a-menu-item v-if="variation_color" key="color"> 相同的color_name </a-menu-item>
                                          <a-menu-item v-if="variation_size" key="size"> 相同的size_name </a-menu-item>
                                          <a-menu-item v-if="variation_material" key="material"> 相同的material
                                          </a-menu-item>
                                          <a-menu-item key="all"> 全部多属性 </a-menu-item>
                                        </a-menu>
                                      </template>
                                      <a href="javascript:;">
                                        <CopyOutlined /> 主图应用到
                                        <DownOutlined />
                                      </a>
                                    </a-dropdown>
                                    <a-dropdown>
                                      <template #overlay>
                                        <a-menu @click="(e) => useAllOtherImages(index, e.key)">
                                          <a-menu-item v-if="variation_color" key="color"> 相同的color_name </a-menu-item>
                                          <a-menu-item v-if="variation_size" key="size"> 相同的size_name </a-menu-item>
                                          <a-menu-item v-if="variation_material" key="material"> 相同的material
                                          </a-menu-item>
                                          <a-menu-item key="all"> 全部多属性 </a-menu-item>
                                        </a-menu>
                                      </template>
                                      <a href="javascript:;">
                                        <CopyOutlined /> 附图应用到
                                        <DownOutlined />
                                      </a>
                                    </a-dropdown>
                                  </template>
                                  <template v-else>
                                    <a href="javascript:;" class="mt-2" @click="useAllImages(index)">
                                      <CopyOutlined /> 应用到全部图片
                                    </a>
                                    <a href="javascript:;" class="mt-2" @click="useAllMainImages(index)">
                                      <CopyOutlined /> 应用到全部主图
                                    </a>
                                    <a href="javascript:;" class="mt-2 mb-2" @click="useAllOtherImages(index)">
                                      <CopyOutlined /> 应用到全部附图
                                    </a>
                                  </template>
                                </template>
                              </a-space>
                            </template>
                            <template v-if="column.dataIndex === 'main_image'">
                              <a-dropdown v-if="listingForm.state === 'draft' || listingForm.state === 'fail'">
                                <template #overlay>
                                  <ImagePicker2 :max_count="1" :images="[]"
                                    :pre_menus="[{ label: '引用橱窗图', value: 'choose-from-images' }]"
                                    @choose-from-images-clicked="() => chooseFromImages({ type: 'main_image', index })"
                                    @local-upload-success="(data) => record.main_image = data.url"
                                    @network-input-success="(data) => record.main_image = data.images[0]"
                                    @space-pick-success="(data) => record.main_image = data.images[0]" />
                                </template>
                                <a-button>
                                  更改图片
                                  <DownOutlined />
                                </a-button>
                              </a-dropdown>
                              <div style="width:105px;height:125px;border:1px solid #e8e8e8;">
                                <div v-if="record.main_image" class="image-header"
                                  style="border-bottom:1px solid #e8e8e8;">
                                  <img :src="record.main_image" style="object-fit: contain;" />
                                </div>
                                <div v-else class="image-header" style="border-bottom:1px solid #e8e8e8;">
                                  <img :src="$filters.image_placeholder" style="object-fit: contain;" />
                                </div>
                                <div
                                  v-if="record.main_image && (listingForm.state === 'draft' || listingForm.state === 'fail')"
                                  style="width:105px;height:20px;" class="d-flex justify-space-between align-center">
                                  <a-tooltip>
                                    <template #title>预览图片</template>
                                    <EyeOutlined
                                      @click="$preview(record.main_image, [record.main_image || '', record.swatch_image || '', ...record.other_images])"
                                      style="font-size:20px;" class="text-info" />
                                  </a-tooltip>
                                  <a-tooltip>
                                    <template #title>删除图片</template>
                                    <DeleteOutlined @click="() => record.main_image = ''"
                                      style="font-size:20px;color:#ff4d4f;" />
                                  </a-tooltip>
                                </div>
                              </div>
                            </template>
                            <template v-if="column.dataIndex === 'swatch_image'">
                              <a-dropdown v-if="listingForm.state === 'draft' || listingForm.state === 'fail'">
                                <template #overlay>
                                  <ImagePicker3 :max_count="1" :images="[]"
                                    :pre_menus="[{ label: '引用橱窗图', value: 'choose-from-images' }]"
                                    @choose-from-images-clicked="() => chooseFromImages({ type: 'swatch_image', index })"
                                    @local-upload-success="(data) => record.swatch_image = data.url"
                                    @network-input-success="(data) => record.swatch_image = data.images[0]"
                                    @space-pick-success="(data) => record.swatch_image = data.images[0]" />
                                </template>
                                <a-button>
                                  更改图片
                                  <DownOutlined />
                                </a-button>
                              </a-dropdown>
                              <div style="width:105px;height:125px;border:1px solid #e8e8e8;">
                                <div v-if="record.swatch_image" class="image-header"
                                  style="border-bottom:1px solid #e8e8e8;">
                                  <img :src="record.swatch_image" style="object-fit: contain;" />
                                </div>
                                <div v-else class="image-header" style="border-bottom:1px solid #e8e8e8;">
                                  <img :src="$filters.image_placeholder" style="object-fit: contain;" />
                                </div>
                                <div
                                  v-if="record.swatch_image && (listingForm.state === 'draft' || listingForm.state === 'fail')"
                                  style="width:105px;height:20px;" class="d-flex justify-space-between align-center">
                                  <a-tooltip>
                                    <template #title>预览图片</template>
                                    <EyeOutlined
                                      @click="$preview(record.swatch_image, [record.main_image || '', record.swatch_image || '', ...record.other_images])"
                                      style="font-size:20px;" class="text-info" />
                                  </a-tooltip>
                                  <a-tooltip>
                                    <template #title>删除图片</template>
                                    <DeleteOutlined @click="() => record.swatch_image = ''"
                                      style="font-size:20px;color:#ff4d4f;" />
                                  </a-tooltip>
                                </div>
                              </div>
                            </template>
                            <template v-if="column.dataIndex === 'other_images'">
                              <div class="d-flex align-center">
                                <a-dropdown v-if="listingForm.state === 'draft' || listingForm.state === 'fail'"
                                  :disabled="record.other_images.length >= MAX_IMAGE_COUNT - 1">
                                  <template #overlay>
                                    <ImagePicker4 :max_count="MAX_IMAGE_COUNT - 1" :images="record.other_images"
                                      :pre_menus="[{ label: '引用橱窗图', value: 'choose-from-images' }]"
                                      @choose-from-images-clicked="() => chooseFromImages({ type: 'other_images', count: MAX_IMAGE_COUNT - 1 - record.other_images.length, index })"
                                      @local-upload-success="(data) => onLocalUploadSuccess({ type: 'other_images', index, data })"
                                      @network-input-success="(data) => onNetworkInputSuccess({ type: 'other_images', index, data })"
                                      @space-pick-success="(data) => onSpacePickSuccess({ type: 'other_images', index, data })" />
                                  </template>
                                  <a-button>
                                    添加图片
                                    <DownOutlined />
                                  </a-button>
                                </a-dropdown>
                                <span>【{{ record.other_images.length }} / {{ MAX_IMAGE_COUNT - 1 }}】</span>
                              </div>
                              <draggable v-model="record.other_images" item-key="index" class="d-flex flex-wrap"
                                style="min-height:125px;"
                                :move="() => (listingForm.state === 'draft' || listingForm.state === 'fail')">
                                <template #item="{ index: iindex, element: image }">
                                  <div style="width:105px;border:1px solid #e8e8e8;" class="mr-1">
                                    <div class="image-header" style="border-bottom:1px solid #e8e8e8;">
                                      <img :src="image" style="object-fit: contain;" />
                                    </div>
                                    <div v-if="listingForm.state === 'draft' || listingForm.state === 'fail'"
                                      style="height:20px;padding:5px;"
                                      class="d-flex justify-space-between align-center">
                                      <a-dropdown>
                                        <template #overlay>
                                          <ImagePicker5 :max_count="1" :images="[]"
                                            :pre_menus="[{ label: '引用橱窗图', value: 'choose-from-images' }]"
                                            @choose-from-images-clicked="() => chooseFromImages({ type: 'other_images2', index, index2: iindex, count: 1 })"
                                            @local-upload-success="(data) => onLocalUploadSuccess({ type: 'other_images', index, index2: iindex, data })"
                                            @network-input-success="(data) => onNetworkInputSuccess({ type: 'other_images', index, index2: iindex, data })"
                                            @space-pick-success="(data) => onSpacePickSuccess({ type: 'other_images', index, index2: iindex, data })" />
                                        </template>
                                        <a-tooltip>
                                          <template #title>更换图片</template>
                                          <svg-icon iconClass="huantu"
                                            :style="{ 'height': '20px', 'width': '20px', cursor: 'pointer' }" />
                                        </a-tooltip>
                                      </a-dropdown>
                                      <!-- <a-divider /> -->
                                      <a-tooltip>
                                        <template #title>预览图片</template>
                                        <EyeOutlined
                                          @click="$preview(image, [record.main_image || '', record.swatch_image || '', ...record.other_images])"
                                          style="font-size:20px;" class="text-info" />
                                      </a-tooltip>
                                      <a-tooltip>
                                        <template #title>删除图片</template>
                                        <DeleteOutlined
                                          @click="removeImage({ type: 'other_images', index, index2: iindex })"
                                          style="font-size:20px;color:#ff4d4f;" />
                                      </a-tooltip>
                                    </div>
                                  </div>
                                </template>
                              </draggable>
                            </template>
                            <template v-if="column.dataIndex === 'operation'">

                            </template>
                          </template>
                        </a-table>
                      </template>
                      <div v-else class="el-alert text-center no-data el-alert--info is-center mt15">
                        <div class="el-alert__content">
                          <span class="el-alert__title">
                            暂无多属性SKU，您可以
                            <a v-if="listingForm.state === 'draft' || listingForm.state === 'fail'" href="javascript:;"
                              @click="editVariationTheme">
                              <span>点击此处</span>
                            </a>
                            创建或编辑多属性
                          </span>
                        </div>
                      </div>
                    </a-col>
                  </template>
                </a-row>
              </a-card>
            </a-form>
            <div class="footer">
              <template v-if="listingForm.state === 'draft' || listingForm.state === 'fail'">
                <a-button class="btn-primary mr-2" size="large" @click="doCheck" :loading="ui_saving">
                  检测侵权词
                </a-button>
                <!-- <a-button class="btn-warning mr-2" size="large" @click="doTranslate" disabled :loading="ui_saving">
            一键翻译(标题、纯文本描述等)
          </a-button> -->
                <a-button class="btn-primary mr-2" size="large" @click="() => doSave({ publish: false })"
                  :loading="ui_saving">
                  保存
                </a-button>
                <a-button class="btn-primary mr-2" size="large" @click="doSend" :loading="ui_saving">
                  保存并刊登
                </a-button>
              </template>
              <a-button size="large" @click="doClose">关闭</a-button>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
  </a-spin>
  <!-- #region 修改账号和站点 -->
  <a-modal v-model:visible="shopaccountModal" title="修改账号和站点" @ok="confirmShopAccount" ok-text="确定" cancel-text="取消"
    width="600px">
    <a-alert type="warning" show-icon class="mb-4">
      <template #message>
        <span class="text-warning">修改账号和站点可能会导致已编辑的内容(差异化信息)清空，请谨慎修改</span>
      </template>
    </a-alert>
    <a-form ref="shopaccountformRef" name="shopaccountForm" :model="shopaccountForm" :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }" autocomplete="off">
      <a-form-item label="选择账号" name="account_username" :rules="[{ required: true, message: '请选择账号' }]">
        <a-select v-model:value="shopaccountForm.account_username" :options="shopaccounts" placeholder="请选择" show-search
          :filter-option="$filters.filterOption" />
      </a-form-item>
      <a-form-item label="选择站点" name="regions"
        :rules="[{ required: true, message: '请选勾选至少一个站点', validator: validateShopRegion }]">
        <a-checkbox-group v-if="shop_regions.length > 0" v-model:value="shopaccountForm.regions"
          :options="shop_regions" />
        <div v-else style="background-color:#f4f4f5;color:#909399;padding:8px 16px;">
          <InfoCircleFilled style="font-size:16px;width:16px;" /> 请先选择账号
        </div>
      </a-form-item>
    </a-form>
  </a-modal>
  <!-- #endregion -->
  <!-- #region 变体多属性 -->
  <a-modal v-model:visible="variationThemeModal" title="批量编辑多属性" @ok="confirmVariationTheme" ok-text="确认添加"
    cancel-text="关闭" width="750px">
    <a-row :gutter="4">
      <a-col :span="8" v-if="variation_color">
        <div class="variation_panel">
          <div class="d-flex align-center variation_header">
            color_name
          </div>
          <div style="height:246px;">
            <a-input-search v-model:value="variation_input_color" placeholder="请输入属性项名称" @search="addVariationColor">
              <template #enterButton>
                <a-button style="background:#f5f7fa;">添加</a-button>
              </template>
            </a-input-search>
            <div class="d-flex flex-column">
              <a-space v-for="(color, index) in variationThemeForm.colors" :key="index" class="pt-2 pl-2 pr-2">
                <a-input v-model:value="color.value"></a-input>
                <DeleteOutlined @click="removeVariationColor(index)" />
              </a-space>
            </div>
          </div>
        </div>
      </a-col>
      <a-col :span="8" v-if="variation_size">
        <div class="variation_panel">
          <div class="d-flex align-center variation_header">
            size_name
          </div>
          <div style="height:246px;">
            <a-input-search v-model:value="variation_input_size" placeholder="请输入属性项名称" @search="addVariationSize">
              <template #enterButton>
                <a-button style="background:#f5f7fa;">添加</a-button>
              </template>
            </a-input-search>
            <div class="d-flex flex-column">
              <a-space v-for="(size, index) in variationThemeForm.sizes" :key="index" class="pt-2 pl-2 pr-2">
                <a-input v-model:value="size.value"></a-input>
                <DeleteOutlined @click="removeVariationSize(index)" />
              </a-space>
            </div>
          </div>
        </div>
      </a-col>
      <a-col :span="8" v-if="variation_material">
        <div class="variation_panel">
          <div class="d-flex align-center variation_header">
            material
          </div>
          <div style="height:246px;">
            <a-input-search v-model:value="variation_input_material" placeholder="请输入属性项名称"
              @search="addVariationMaterial">
              <template #enterButton>
                <a-button style="background:#f5f7fa;">添加</a-button>
              </template>
            </a-input-search>
            <div class="d-flex flex-column">
              <a-space v-for="(material, index) in variationThemeForm.materials" :key="index" class="pt-2 pl-2 pr-2">
                <a-input v-model:value="material.value"></a-input>
                <DeleteOutlined @click="removeVariationMaterial(index)" />
              </a-space>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
  </a-modal>
  <!-- #endregion -->
  <!-- #region 变体单行多属性 -->
  <a-modal v-model:visible="variationItemModal" title="添加单行多属性" @cancel="() => variationItemModal = false"
    width="600px">
    <a-form ref="variationItemFormRef" name="variationItemForm" :model="variationItemForm" :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }" autocomplete="off">
      <a-form-item label="SKU编号" name="item_sku" :rules="[{ required: true, message: 'SKU编号不能为空' }]">
        <a-input v-model:value="variationItemForm.item_sku" />
      </a-form-item>
      <a-form-item v-if="variation_color" label="color_name" name="color_name"
        :rules="[{ required: true, message: '属性项名称不能为空！' }]">
        <a-input v-model:value="variationItemForm.color_name" />
      </a-form-item>
      <a-form-item v-if="variation_size" label="size_name" name="size_name"
        :rules="[{ required: true, message: '属性项名称不能为空！' }]">
        <a-input v-model:value="variationItemForm.size_name" />
      </a-form-item>
      <a-form-item v-if="variation_material" label="material" name="material_type"
        :rules="[{ required: true, message: '属性项名称不能为空！' }]">
        <a-input v-model:value="variationItemForm.material_type" />
      </a-form-item>
    </a-form>
    <template #footer>
      <div class="d-flex justify-end align-center">
        <a-space>
          <a-button @click="() => variationItemModal = false">关闭</a-button>
          <a-button class="btn-primary" @click="confirmVariationItem">确定</a-button>
        </a-space>
      </div>
    </template>
  </a-modal>
  <!-- #endregion -->
  <!-- #region 变体批量修改数量 -->
  <a-modal v-model:visible="variationListQuantityModal" title="批量修改数量"
    @cancel="() => variationListQuantityModal = false" width="400px">
    <div class="d-flex justify-center align-center">
      <a-form-item label="数量" name="variationListQuantity">
        <a-input v-model:value="variationListQuantity" type="number" />
      </a-form-item>
    </div>
    <template #footer>
      <div class="d-flex justify-end align-center">
        <a-space>
          <a-button @click="() => variationListQuantityModal = false">关闭</a-button>
          <a-button class="btn-primary" @click="confirmVariationListQuantity">确定</a-button>
        </a-space>
      </div>
    </template>
  </a-modal>
  <!-- #endregion -->
  <!-- #region 变体批量修改价格 -->
  <a-modal v-model:visible="variationListPriceModal" title="批量修改多属性价格" @cancel="() => variationListPriceModal = false"
    width="400px">
    <a-form :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }" autocomplete="off">
      <a-form-item v-for="(region, index) in listingForm.regions" :key="index" :label="listingForm.region_names[index]">
        <a-input v-model:value="variationListPrice[region].standard_price" type="number" />
      </a-form-item>
    </a-form>
    <template #footer>
      <div class="d-flex justify-end align-center">
        <a-space>
          <a-button @click="() => variationListPriceModal = false">关闭</a-button>
          <a-button class="btn-primary" @click="confirmVariationListPrice">确定</a-button>
        </a-space>
      </div>
    </template>
  </a-modal>
  <!-- #endregion -->
  <!-- #region 变体修改站点价格 -->
  <a-modal v-model:visible="variationItemPriceModal" title="修改站点价格" @cancel="() => variationItemPriceModal = false"
    width="400px">
    <a-form :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }" autocomplete="off">
      <a-form-item v-for="(region, index) in listingForm.regions" :key="index" :label="listingForm.region_names[index]">
        <a-input v-model:value="variationItemPrice[region].standard_price" type="number" />
      </a-form-item>
    </a-form>
    <template #footer>
      <div class="d-flex justify-space-between align-center">
        <a-space>
          <a-button>
            汇率换算
          </a-button>
          <a-tooltip color="#fff" :overlayStyle="{ 'width': '750px', 'max-width': '750px' }">
            <template #title>
              <div class="text-black pa-1">
                <div class="text-h6">汇率换算</div>
                <p>将第一个站点的金额，换算成其他站点对应币种的金额</p>
                <p>汇率设置请至--系统管理>汇率管理去设置，如果没有设置有效汇率，点击汇率换算，将不会执行换算，价格栏为空值</p>
              </div>
            </template>
            <QuestionCircleOutlined class="text-primary" />
          </a-tooltip>
        </a-space>
        <a-space>
          <a-button @click="() => variationItemPriceModal = false">取消</a-button>
          <a-button class="btn-primary" @click="confirmVariationItemPrice">确定</a-button>
        </a-space>
      </div>
    </template>
  </a-modal>
  <!-- #endregion -->
  <!-- #region 单品修改站点价格 -->
  <a-modal v-model:visible="listingPriceModal" title="修改站点价格" @cancel="() => listingPriceModal = false" width="400px">
    <a-form :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }" autocomplete="off">
      <a-form-item v-for="(region, index) in listingForm.regions" :key="index" :label="listingForm.region_names[index]">
        <a-input v-model:value="listingPrice[region].standard_price" type="number" />
      </a-form-item>
    </a-form>
    <template #footer>
      <div class="d-flex justify-space-between align-center">
        <a-space>
          <a-button>
            汇率换算
          </a-button>
          <a-tooltip placement="bottom" color="#fff" :overlayStyle="{ 'width': '750px', 'max-width': '750px' }">
            <template #title>
              <div class="text-black pa-1">
                <div class="text-h6">汇率换算</div>
                <p>将第一个站点的金额，换算成其他站点对应币种的金额</p>
                <p>汇率设置请至--系统管理>汇率管理去设置，如果没有设置有效汇率，点击汇率换算，将不会执行换算，价格栏为空值</p>
              </div>
            </template>
            <QuestionCircleOutlined class="text-primary" />
          </a-tooltip>
        </a-space>
        <a-space>
          <a-button @click="() => listingPriceModal = false">取消</a-button>
          <a-button class="btn-primary" @click="confirmListingPrice">确定</a-button>
        </a-space>
      </div>
    </template>
  </a-modal>
  <!-- #endregion -->
  <!-- #region 变体修改标题 -->
  <a-modal v-model:visible="variationItemNameModal" title="修改标题" @cancel="() => variationItemNameModal = false"
    width="750px">
    <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }" autocomplete="off">
      <a-form-item v-for="(region, index) in listingForm.regions" :key="index" :label="listingForm.region_names[index]">
        <a-input v-model:value="variationItemName[region].item_name" :maxlength="200" showCount />
      </a-form-item>
    </a-form>
    <template #footer>
      <div class="d-flex justify-end align-center">
        <a-space>
          <a-button @click="() => variationItemNameModal = false">取消</a-button>
          <a-button class="btn-primary" @click="confirmVariationItemName">确定</a-button>
        </a-space>
      </div>
    </template>
  </a-modal>
  <!-- #endregion -->
  <!-- #region 变体引用橱窗图 -->
  <a-modal v-model:visible="chooseImagesModal" title="引用橱窗图" width="750px" @cancel="() => chooseImagesModal = false">
    <a-checkbox-group v-if="chooseImagesType === 'other_images'" v-model:value="chooseImagesList">
      <a-row :gutter="8">
        <a-col :span="4" v-for="(image, index) in listingForm.images" :key="index">
          <div class="d-flex flex-column align-center mb-2"
            style="border:1px solid #ddd;box-shadow:0 2px 12px 0 rgba(0,0,0,.1);">
            <a-image width="105px" height="105px" :src="image" style="object-fit: contain;height:100%;">
              <template #placeholder>
                <a-image :src="$filters.image_placeholder" width="105px" height="105px" :preview="false" />
              </template>
            </a-image>
            <a-checkbox class="mt-2 mb-2" :value="image">选择</a-checkbox>
          </div>
        </a-col>
      </a-row>
    </a-checkbox-group>
    <a-row :gutter="8" v-else>
      <a-col :span="4" v-for="(image, index) in listingForm.images" :key="index">
        <div class="d-flex flex-column align-center mb-2"
          style="border:1px solid #ddd;box-shadow:0 2px 12px 0 rgba(0,0,0,.1);">
          <a-image width="105px" height="105px" :src="image" style="object-fit: contain;height:100%;">
            <template #placeholder>
              <a-image :src="$filters.image_placeholder" width="105px" height="105px" :preview="false" />
            </template>
          </a-image>
          <a-button class="btn-primary mt-2 mb-2" @click="confirmChooseImage(image)">选择</a-button>
        </div>
      </a-col>
    </a-row>
    <template #footer>
      <div
        :class="{ 'd-flex': true, 'justify-space-between': chooseImagesType === 'other_images', 'justify-end': chooseImagesType !== 'other_images', 'align-center': true }">
        <div v-if="chooseImagesType === 'other_images'">
          已选中 <span class="text-primary"> {{ chooseImagesList.length }} </span>
          张图片， 最多 <span class="text-error"> {{ chooseImagesMaxCount }} </span> 张
        </div>
        <a-space>
          <a-button @click="() => chooseImagesModal = false">关闭</a-button>
          <a-button v-if="chooseImagesType === 'other_images'" type="primary" @click="confirmChooseImages">确定</a-button>
        </a-space>
      </div>
    </template>
  </a-modal>
  <!-- #endregion -->
  <!-- #region 侵权词检测结果 -->
  <a-modal v-model:visible="tortwordsResultModal" :title="`检测到侵权词(共${tortwordsResult.length}条)`" width="750px"
    @cancel="() => tortwordsResultModal = false">
    <a-table :columns="tortwordsHeader" :data-source="tortwordsResult" :pagination="false" :scroll="{ y: 600 }"
      size="small">
      <template #bodyCell="{ record, index, column }">
        <template v-if="column.dataIndex === 'index'">
          {{ index + 1 }}
        </template>
        <template v-if="column.dataIndex === 'message'">
          <div class="text-danger" v-html="record.message"></div>
        </template>
      </template>
    </a-table>
    <template #footer>
      <div class="d-flex justify-end">
        <a-button @click="() => tortwordsResultModal = false">关闭</a-button>
      </div>
    </template>
  </a-modal>
  <!-- #endregion -->
  <!-- #region 产品类目 -->
  <a-modal v-model:visible="ui_visible" width="1200px" @cancel="closeBrowseTree">
    <template #title>
      <a-space>
        <span class="text-h6">选择产品分类</span>
        <a-input v-model:value="browsetree_searchtext" addon-before="查找产品分类" placeholder="请输入内容" style="width:600px;"
          @keyup.enter="doSearchBrowseTree">
          <template #addonAfter>
            <SearchOutlined @click="doSearchBrowseTree" />
          </template>
        </a-input>
      </a-space>
    </template>
    <a-spin :spinning="ui_loading">
      <div v-if="browsetree_showsearchresults">
        <a-divider>
          <a-space>
            <div>查询到<span> {{ browsetree_searchresults.length }} </span>个产品分类</div>
            <a-button @click="backToSelect">
              返回产品分类选择
            </a-button>
          </a-space>
        </a-divider>
        <a-alert v-if="browsetree_searchresults.length === 0" message="暂无符合条件的分类" description="请尝试其他搜索关键字，或返回产品分类选择"
          type="warning" show-icon />
        <div v-else class="search-results-group">
          <a-list size="small" bordered :data-source="browsetree_searchresults" :pagination="false">
            <template #renderItem="{ item, index }">
              <a-list-item>
                <a href="javascript:;" :class="{ 'search-results-item': true, 'active': item.selected }"
                  @click="selectSearchItem(index)">
                  {{ item.label }}
                </a>
              </a-list-item>
            </template>
          </a-list>
        </div>
      </div>
      <div v-else class="tree-group">
        <a-card v-if="browsetreeCount >= 1 && browsetreeList1.length > 0" title="一级类目" size="small"
          class="tree-item-card">
          <div class="tree-item">
            <a-menu :selectedKeys="[browsetreeID1]">
              <a-menu-item v-for="item in browsetreeList1" :key="item._id" @click="selectTree1(item)"
                style="border-bottom: 1px solid #ddd;">
                <div v-if="item.hasChildren" class="d-flex justify-space-between align-center"
                  :title="`${item.browseNodeId}:${item.title}`">
                  <p class="in1line"> {{ item.title }} </p>
                  <RightOutlined />
                </div>
                <a-tooltip v-else :mouseEnterDelay="0.5">
                  <template #title>该分类产品类型为：{{ item.productTypeDefinitions }}</template>
                  <div class="d-flex justify-space-between align-center" :title="`${item.browseNodeId}:${item.title}`">
                    <p class="in1line"> {{ item.title }} </p>
                  </div>
                </a-tooltip>
              </a-menu-item>
            </a-menu>
          </div>
        </a-card>
        <a-card v-if="browsetreeCount >= 2 && browsetreeList2.length > 0" title="二级类目" size="small"
          class="tree-item-card">
          <div class="tree-item">
            <a-menu :selectedKeys="[browsetreeID2]">
              <a-menu-item v-for="item in browsetreeList2" :key="item._id" @click="selectTree2(item)"
                style="border-bottom: 1px solid #ddd;">
                <div v-if="item.hasChildren" class="d-flex justify-space-between align-center"
                  :title="`${item.browseNodeId}:${item.title}`">
                  <p class="in1line"> {{ item.title }} </p>
                  <RightOutlined />
                </div>
                <a-tooltip v-else :mouseEnterDelay="0.5">
                  <template #title>该分类产品类型为：{{ item.productTypeDefinitions }}</template>
                  <div class="d-flex justify-space-between align-center" :title="`${item.browseNodeId}:${item.title}`">
                    <p class="in1line"> {{ item.title }} </p>
                  </div>
                </a-tooltip>
              </a-menu-item>
            </a-menu>
          </div>
        </a-card>
        <a-card v-if="browsetreeCount >= 3 && browsetreeList3.length > 0" title="三级类目" size="small"
          class="tree-item-card">
          <div class="tree-item">
            <a-menu :selectedKeys="[browsetreeID3]">
              <a-menu-item v-for="item in browsetreeList3" :key="item._id" @click="selectTree3(item)"
                style="border-bottom: 1px solid #ddd;">
                <div v-if="item.hasChildren" class="d-flex justify-space-between align-center"
                  :title="`${item.browseNodeId}:${item.title}`">
                  <p class="in1line"> {{ item.title }} </p>
                  <RightOutlined />
                </div>
                <a-tooltip v-else :mouseEnterDelay="0.5">
                  <template #title>该分类产品类型为：{{ item.productTypeDefinitions }}</template>
                  <div class="d-flex justify-space-between align-center" :title="`${item.browseNodeId}:${item.title}`">
                    <p class="in1line"> {{ item.title }} </p>
                  </div>
                </a-tooltip>
              </a-menu-item>
            </a-menu>
          </div>
        </a-card>
        <a-card v-if="browsetreeCount >= 4 && browsetreeList4.length > 0" title="四级类目" size="small"
          class="tree-item-card">
          <div class="tree-item">
            <a-menu :selectedKeys="[browsetreeID4]">
              <a-menu-item v-for="item in browsetreeList4" :key="item._id" @click="selectTree4(item)"
                style="border-bottom: 1px solid #ddd;">
                <div v-if="item.hasChildren" class="d-flex justify-space-between align-center"
                  :title="`${item.browseNodeId}:${item.title}`">
                  <p class="in1line"> {{ item.title }} </p>
                  <RightOutlined />
                </div>
                <a-tooltip v-else :mouseEnterDelay="0.5">
                  <template #title>该分类产品类型为：{{ item.productTypeDefinitions }}</template>
                  <div class="d-flex justify-space-between align-center" :title="`${item.browseNodeId}:${item.title}`">
                    <p class="in1line"> {{ item.title }} </p>
                  </div>
                </a-tooltip>
              </a-menu-item>
            </a-menu>
          </div>
        </a-card>
        <a-card v-if="browsetreeCount >= 5 && browsetreeList5.length > 0" title="五级类目" size="small"
          class="tree-item-card">
          <div class="tree-item">
            <a-menu :selectedKeys="[browsetreeID5]">
              <a-menu-item v-for="item in browsetreeList5" :key="item._id" @click="selectTree5(item)"
                style="border-bottom: 1px solid #ddd;">
                <div v-if="item.hasChildren" class="d-flex justify-space-between align-center"
                  :title="`${item.browseNodeId}:${item.title}`">
                  <p class="in1line"> {{ item.title }} </p>
                  <RightOutlined />
                </div>
                <a-tooltip v-else :mouseEnterDelay="0.5">
                  <template #title>该分类产品类型为：{{ item.productTypeDefinitions }}</template>
                  <div class="d-flex justify-space-between align-center" :title="`${item.browseNodeId}:${item.title}`">
                    <p class="in1line"> {{ item.title }} </p>
                  </div>
                </a-tooltip>
              </a-menu-item>
            </a-menu>
          </div>
        </a-card>
        <a-card v-if="browsetreeCount >= 6 && browsetreeList6.length > 0" title="六级类目" size="small"
          class="tree-item-card">
          <div class="tree-item">
            <a-menu :selectedKeys="[browsetreeID6]">
              <a-menu-item v-for="item in browsetreeList6" :key="item._id" @click="selectTree6(item)"
                style="border-bottom: 1px solid #ddd;">
                <div v-if="item.hasChildren" class="d-flex justify-space-between align-center"
                  :title="`${item.browseNodeId}:${item.title}`">
                  <p class="in1line"> {{ item.title }} </p>
                  <RightOutlined />
                </div>
                <a-tooltip v-else :mouseEnterDelay="0.5">
                  <template #title>该分类产品类型为：{{ item.productTypeDefinitions }}</template>
                  <div class="d-flex justify-space-between align-center" :title="`${item.browseNodeId}:${item.title}`">
                    <p class="in1line"> {{ item.title }} </p>
                  </div>
                </a-tooltip>
              </a-menu-item>
            </a-menu>
          </div>
        </a-card>
        <a-card v-if="browsetreeCount >= 7 && browsetreeList7.length > 0" title="七级类目" size="small"
          class="tree-item-card">
          <div class="tree-item">
            <a-menu :selectedKeys="[browsetreeID7]">
              <a-menu-item v-for="item in browsetreeList7" :key="item._id" @click="selectTree7(item)"
                style="border-bottom: 1px solid #ddd;">
                <div v-if="item.hasChildren" class="d-flex justify-space-between align-center"
                  :title="`${item.browseNodeId}:${item.title}`">
                  <p class="in1line"> {{ item.title }} </p>
                  <RightOutlined />
                </div>
                <a-tooltip v-else :mouseEnterDelay="0.5">
                  <template #title>该分类产品类型为：{{ item.productTypeDefinitions }}</template>
                  <div class="d-flex justify-space-between align-center" :title="`${item.browseNodeId}:${item.title}`">
                    <p class="in1line"> {{ item.title }} </p>
                  </div>
                </a-tooltip>
              </a-menu-item>
            </a-menu>
          </div>
        </a-card>
      </div>
    </a-spin>
    <template #footer>
      <div class="d-flex justify-space-between">
        <div class="d-flex flex-wrap text-left">
          已选择：
          <span v-if="browsetree_searchresultitem" class="text-primary">
            {{ browsetree_searchresultitem.label }}
          </span>
          <template v-else>
            <span :class="{ 'text-orange': browsetreeID === browsetreeID1 }">
              {{ browsetreeNAME1 }}
            </span>
            <span v-if="browsetreeNAME2" :class="{ 'text-orange': browsetreeID === browsetreeID2 }">
              <span class="text-primary"> 》 </span>{{ browsetreeNAME2 }}
            </span>
            <span v-if="browsetreeNAME3" :class="{ 'text-orange': browsetreeID === browsetreeID3 }">
              <span class="text-primary"> 》 </span>{{ browsetreeNAME3 }}
            </span>
            <span v-if="browsetreeNAME4" :class="{ 'text-orange': browsetreeID === browsetreeID4 }">
              <span class="text-primary"> 》 </span>{{ browsetreeNAME4 }}
            </span>
            <span v-if="browsetreeNAME5" :class="{ 'text-orange': browsetreeID === browsetreeID5 }">
              <span class="text-primary"> 》 </span>{{ browsetreeNAME5 }}
            </span>
            <span v-if="browsetreeNAME6" :class="{ 'text-orange': browsetreeID === browsetreeID6 }">
              <span class="text-primary"> 》 </span>{{ browsetreeNAME6 }}
            </span>
            <span v-if="browsetreeNAME7" :class="{ 'text-orange': browsetreeID === browsetreeID7 }">
              <span class="text-primary"> 》 </span>{{ browsetreeNAME7 }}
            </span>
          </template>
        </div>
        <a-space>
          <a-button @click="closeBrowseTree">取消</a-button>
          <a-button :class="{ 'btn-warning': browsetreeConfirmable, 'btn-warning-disabled': !browsetreeConfirmable }"
            :disabled="!browsetreeConfirmable" :loading="ui_loading" @click="confirmBrowseTree">确定</a-button>
        </a-space>
      </div>
    </template>
  </a-modal>
  <!-- #endregion -->
  <!-- #region external_product_id -->
  <a-modal v-model:visible="externalProductModal" :title="`获取${externalProductForm.type}`" width="600px"
    @cancel="() => externalProductModal = false" :maskClosable="false" :closable="!externalProductLoading">
    <a-spin :spinning="externalProductLoading">
      <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }" autocomplete="off">
        <a-form-item label="获取类型">
          <a-select v-model:value="externalProductForm.type" @change="changeExternalProductType" show-search>
            <a-select-option v-for="item in externalProductTypes" :key="item" :value="item">
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="获取方式">
          <a-radio-group v-model:value="externalProductForm.method" name="method">
            <a-radio value="auto">自动获取</a-radio>
            <a-radio value="manual">手动填写</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item v-if="externalProductForm.method === 'auto'" label="选择号码池">
          <template #extra>
            <div>剩余可用数量<span class="text-danger"> {{ externalProductForm.unused_count }} </span>个</div>
          </template>
          <div class="d-flex">
            <a-select v-model:value="externalProductForm.groupid" @change="changeExternalProductGroup" placeholder="请选择"
              show-search>
              <a-select-option v-for="item in externalProductGroups" :key="item._id" :value="item._id">
                {{ item.name }}
              </a-select-option>
            </a-select>
            <a-button class="ml-2" @click="gotoUPC">管理号码池</a-button>
          </div>
        </a-form-item>
        <template v-if="externalProductForm.method === 'manual'">
          <a-form-item label="输入号码">
            <a-input v-if="externalProductForm.count === 1" v-model:value="externalProductForm.codes"
              placeholder="请输入" />
            <a-textarea v-if="externalProductForm.count > 1" v-model:value="externalProductForm.codes"
              placeholder="多个号码用回车换行" :auto-size="{ minRows: 3, maxRows: 3 }" />
          </a-form-item>
        </template>
      </a-form>
    </a-spin>
    <template #footer>
      <div class="d-flex justify-space-between">
        <div>
          需要<span class="text-warning"> {{ externalProductForm.count }} </span>个{{ externalProductForm.type }}号码
        </div>
        <a-space>
          <a-button @click="() => externalProductModal = false">取消</a-button>
          <a-button class="btn-warning" @click="confirmExternalProduct">确定</a-button>
        </a-space>
      </div>
    </template>
  </a-modal>
  <!-- #endregion -->
  <!-- #region AI -->
  <a-modal v-model:visible="ai_generator" title="AI智能生成" width="960px" :footer="false" :maskClosable="false">
    <AIGenerator v-if="ai_generator" :ai_field="ai_field" :ai_browsetreenames="ai_browsetreenames"
      :ai_region="ai_region" @content-generated="(data) => useAIContent(data)" />
  </a-modal>
  <!-- #endregion AI -->
</template>
<script setup>
import _ from "underscore";
import { InfoCircleFilled, QuestionCircleOutlined, UpOutlined, DownOutlined, DeleteOutlined, FormOutlined, PlusOutlined, RightOutlined, CopyOutlined, SearchOutlined, EyeOutlined, } from '@ant-design/icons-vue';
import { message, Modal } from "ant-design-vue";
import { defineComponent, ref, shallowRef, reactive, toRefs, watch, computed, onMounted, onBeforeUnmount, onActivated, onDeactivated, getCurrentInstance, toRaw, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import '@wangeditor/editor/dist/css/style.css'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
const MAX_IMAGE_COUNT = 9;
import ImagePicker0 from '@/components/ImagePicker.vue';
import ImagePicker1 from '@/components/ImagePicker.vue';
import ImagePicker2 from '@/components/ImagePicker.vue';
import ImagePicker3 from '@/components/ImagePicker.vue';
import ImagePicker4 from '@/components/ImagePicker.vue';
import ImagePicker5 from '@/components/ImagePicker.vue';
import AIGenerator from '@/views/dev/ai/generator.vue';
import draggable from 'vuedraggable';
let timer = 0;
const { proxy } = getCurrentInstance();
const route = useRoute();
const router = useRouter();
const shopaccountformRef = ref();
const listingformRef = ref();
const variationItemFormRef = ref();
const editorRef = shallowRef();
const toolbarConfig = { toolbarKeys: ["bold", "italic", "bulletedList", "numberedList", "undo", "redo"] };
const editorConfig = {};
//#region 数据
const ui_loading = ref(false);
const ui_visible = ref(false);
const ui_saving = ref(false);
const activecards = ref([]);
const stopscroll = ref(false);
const shopaccounts = ref([]);
const brands = ref([]);
const manufacturers = ref([]);
const shopaccountModal = ref(false);
const shopaccountForm = ref(false);
const listingForm = ref({});
const attribute_search_key = ref({});
const curr_region = ref("");
const product_description = ref("");
const product_description2 = ref("");
const product_description_edit_type = ref("eidtor");
const condition_types = ['New'];
const external_product_id_types = ["UPC", "EAN", "ISBN", "GTIN", "GCID", "PZN"];
const externalProductForm = ref({});
const externalProductGroups = ref([]);
const externalProductModal = ref(false);
const externalProductLoading = ref(false);
const externalProductTypes = ["UPC", "EAN", "ISBN", "GTIN"];
//#region 类目菜单选择
const browsetree_searchtext = ref("");
const browsetree_searchresults = ref([]);
const browsetree_showsearchresults = ref(false);
const browsetree_searchresultitem = ref(null);
const browsetree = ref({
  US: { index: 0, count: 0, id1: '', id2: '', id3: '', id4: '', id5: '', id6: '', id7: [], list1: [], list2: [], list3: [], list4: [], list5: [], list6: [], list7: [], confirm_data: null, product_types: [], attributes: [], properties: [], },
  CA: { index: 0, count: 0, id1: '', id2: '', id3: '', id4: '', id5: '', id6: '', id7: [], list1: [], list2: [], list3: [], list4: [], list5: [], list6: [], list7: [], confirm_data: null, product_types: [], attributes: [], properties: [], },
  MX: { index: 0, count: 0, id1: '', id2: '', id3: '', id4: '', id5: '', id6: '', id7: [], list1: [], list2: [], list3: [], list4: [], list5: [], list6: [], list7: [], confirm_data: null, product_types: [], attributes: [], properties: [], },
  BR: { index: 0, count: 0, id1: '', id2: '', id3: '', id4: '', id5: '', id6: '', id7: [], list1: [], list2: [], list3: [], list4: [], list5: [], list6: [], list7: [], confirm_data: null, product_types: [], attributes: [], properties: [], },
  ES: { index: 0, count: 0, id1: '', id2: '', id3: '', id4: '', id5: '', id6: '', id7: [], list1: [], list2: [], list3: [], list4: [], list5: [], list6: [], list7: [], confirm_data: null, product_types: [], attributes: [], properties: [], },
  UK: { index: 0, count: 0, id1: '', id2: '', id3: '', id4: '', id5: '', id6: '', id7: [], list1: [], list2: [], list3: [], list4: [], list5: [], list6: [], list7: [], confirm_data: null, product_types: [], attributes: [], properties: [], },
  FR: { index: 0, count: 0, id1: '', id2: '', id3: '', id4: '', id5: '', id6: '', id7: [], list1: [], list2: [], list3: [], list4: [], list5: [], list6: [], list7: [], confirm_data: null, product_types: [], attributes: [], properties: [], },
  BE: { index: 0, count: 0, id1: '', id2: '', id3: '', id4: '', id5: '', id6: '', id7: [], list1: [], list2: [], list3: [], list4: [], list5: [], list6: [], list7: [], confirm_data: null, product_types: [], attributes: [], properties: [], },
  NL: { index: 0, count: 0, id1: '', id2: '', id3: '', id4: '', id5: '', id6: '', id7: [], list1: [], list2: [], list3: [], list4: [], list5: [], list6: [], list7: [], confirm_data: null, product_types: [], attributes: [], properties: [], },
  DE: { index: 0, count: 0, id1: '', id2: '', id3: '', id4: '', id5: '', id6: '', id7: [], list1: [], list2: [], list3: [], list4: [], list5: [], list6: [], list7: [], confirm_data: null, product_types: [], attributes: [], properties: [], },
  IT: { index: 0, count: 0, id1: '', id2: '', id3: '', id4: '', id5: '', id6: '', id7: [], list1: [], list2: [], list3: [], list4: [], list5: [], list6: [], list7: [], confirm_data: null, product_types: [], attributes: [], properties: [], },
  SE: { index: 0, count: 0, id1: '', id2: '', id3: '', id4: '', id5: '', id6: '', id7: [], list1: [], list2: [], list3: [], list4: [], list5: [], list6: [], list7: [], confirm_data: null, product_types: [], attributes: [], properties: [], },
  PL: { index: 0, count: 0, id1: '', id2: '', id3: '', id4: '', id5: '', id6: '', id7: [], list1: [], list2: [], list3: [], list4: [], list5: [], list6: [], list7: [], confirm_data: null, product_types: [], attributes: [], properties: [], },
  AU: { index: 0, count: 0, id1: '', id2: '', id3: '', id4: '', id5: '', id6: '', id7: [], list1: [], list2: [], list3: [], list4: [], list5: [], list6: [], list7: [], confirm_data: null, product_types: [], attributes: [], properties: [], },
  JP: { index: 0, count: 0, id1: '', id2: '', id3: '', id4: '', id5: '', id6: '', id7: [], list1: [], list2: [], list3: [], list4: [], list5: [], list6: [], list7: [], confirm_data: null, product_types: [], attributes: [], properties: [], },
});
//#endregion 类目菜单选择
const variation_themes = [
  { label: "Color", value: "color_name" },
  { label: "Size", value: "size_name" },
  { label: "Material", value: "material_type" },
  { label: "Color-Size", value: "color_name,size_name" },
  { label: "Color-Material", value: "color_name,material_type" },
  { label: "Size-Material", value: "size_name,material_type" },
  { label: "Color-Size-Material", value: "color_name,size_name,material_type" },
];
const variation_color = ref(false);
const variation_size = ref(false);
const variation_material = ref(false);
const variation_colors = ref([]);
const variation_sizes = ref([]);
const variation_materials = ref([]);
const variation_input_color = ref("");
const variation_input_size = ref("");
const variation_input_material = ref("");
const variation_ids = ref([]);
const variation_list = ref([]);
const variation_headers2 = [
  { title: '序号', dataIndex: 'index', width: 50, },
  { title: '多属性', dataIndex: 'attribute', width: 150, },
  { title: '主图', dataIndex: 'main_image', width: 120, },
  { title: '样品图', dataIndex: 'swatch_image', width: 120, },
  { title: '附图(最多8张，可左右拖动排序)', dataIndex: 'other_images', },
  { title: '操作', dataIndex: 'operation', width: 100, fixed: "right", },
];
const variationThemeModal = ref(false);
const variationThemeForm = ref({});
const variationItemModal = ref(false);
const variationItemForm = ref({});
const chooseImagesType = ref(null);
const chooseImagesModal = ref(false);
const chooseImagesList = ref([]);
const chooseImagesMaxCount = ref(0);
const chooseImagesIndex = ref(-1);
const chooseImagesIndex2 = ref(-1);
const variationListQuantity = ref("");
const variationListQuantityModal = ref(false);
const variationListPrice = ref({});
const variationListPriceModal = ref(false);
const variationItemIndex = ref(-1);
const variationItemPrice = ref({});
const variationItemPriceModal = ref(false);
const listingPrice = ref({});
const listingPriceModal = ref(false);
const variationItemName = ref({});
const variationItemNameModal = ref(false);
const tortwordsResultModal = ref(false);
const tortwordsResult = ref([]);
const tortwordsHeader = [
  { title: '序号', dataIndex: 'index', width: 50, },
  { title: '站点', dataIndex: 'region', width: 150, },
  { title: '细节', dataIndex: 'message', width: 300, },
];
const ai_browsetreenames = ref([]);
const ai_generator = ref(false);
const ai_field = ref('');
//#endregion 数据
//#region computed
const shop_regions = computed(() => {
  return _.findWhere(shopaccounts.value, { value: shopaccountForm.value.account_username })?.regions || [];
});
const region_sites = computed(() => {
  let shopaccount = _.findWhere(shopaccounts.value, { value: listingForm.value.account_username });
  if (shopaccount) {
    let select_regions = _.filter(shopaccount.regions, n => listingForm.value.regions?.indexOf(n.value) > -1);
    return _.pluck(select_regions, "label").join(',');
  }
  return "";
});
const fail_region_sites = computed(() => {
  let { regions, region_names, region_state } = listingForm.value;
  let sites = [];
  for (let i = 0; i < regions.length; i++) {
    let region = regions[i];
    if (region_state?.[region] === 'fail') {
      sites.push(region_names[i]);
    }
  }
  return sites.join(',');
});
const variation_headers = computed(() => {
  let headers = [{ title: "序号", dataIndex: "index", width: 50 }];
  if (variation_color.value) headers.push({ title: "color_name", dataIndex: "color_name", width: 120 });
  if (variation_size.value) headers.push({ title: "size_name", dataIndex: "size_name", width: 120 });
  if (variation_material.value) headers.push({ title: "material_type", dataIndex: "material_type", width: 120 });
  headers.push({ title: "SKU编号", dataIndex: "item_sku", width: 200 });
  headers.push({ title: "", dataIndex: "external_product_id", width: 180 });
  headers.push({ title: "数量", dataIndex: "quantity", width: 120 });
  headers.push({ title: "价格", dataIndex: "standard_price", width: 180 });
  headers.push({ title: "标题", dataIndex: "item_name", width: 400 });
  headers.push({ title: "操作", dataIndex: "operation", width: 150 });
  return headers;
});
const browsetreeCount = computed(() => browsetree.value[curr_region.value]?.count);
const browsetreeID1 = computed(() => browsetree.value[curr_region.value]?.id1);
const browsetreeID2 = computed(() => browsetree.value[curr_region.value]?.id2);
const browsetreeID3 = computed(() => browsetree.value[curr_region.value]?.id3);
const browsetreeID4 = computed(() => browsetree.value[curr_region.value]?.id4);
const browsetreeID5 = computed(() => browsetree.value[curr_region.value]?.id5);
const browsetreeID6 = computed(() => browsetree.value[curr_region.value]?.id6);
const browsetreeID7 = computed(() => browsetree.value[curr_region.value]?.id7);
const browsetreeList1 = computed(() => browsetree.value[curr_region.value]?.list1 || []);
const browsetreeList2 = computed(() => browsetree.value[curr_region.value]?.list2 || []);
const browsetreeList3 = computed(() => browsetree.value[curr_region.value]?.list3 || []);
const browsetreeList4 = computed(() => browsetree.value[curr_region.value]?.list4 || []);
const browsetreeList5 = computed(() => browsetree.value[curr_region.value]?.list5 || []);
const browsetreeList6 = computed(() => browsetree.value[curr_region.value]?.list6 || []);
const browsetreeList7 = computed(() => browsetree.value[curr_region.value]?.list7 || []);
const browsetreeID = computed(() => {
  if (browsetree.value[curr_region.value]?.list7?.length > 0) return browsetree.value[curr_region.value]?.id7 || '';
  if (browsetree.value[curr_region.value]?.list6?.length > 0) return browsetree.value[curr_region.value]?.id6 || '';
  if (browsetree.value[curr_region.value]?.list5?.length > 0) return browsetree.value[curr_region.value]?.id5 || '';
  if (browsetree.value[curr_region.value]?.list4?.length > 0) return browsetree.value[curr_region.value]?.id4 || '';
  if (browsetree.value[curr_region.value]?.list3?.length > 0) return browsetree.value[curr_region.value]?.id3 || '';
  if (browsetree.value[curr_region.value]?.list2?.length > 0) return browsetree.value[curr_region.value]?.id2 || '';
  if (browsetree.value[curr_region.value]?.list1?.length > 0) return browsetree.value[curr_region.value]?.id1 || '';
  return '';
});
const browsetreeNAME1 = computed(() => _.findWhere(browsetree.value[curr_region.value]?.list1 || [], { _id: browsetree.value[curr_region.value]?.id1 })?.title || '')
const browsetreeNAME2 = computed(() => _.findWhere(browsetree.value[curr_region.value]?.list2 || [], { _id: browsetree.value[curr_region.value]?.id2 })?.title || '')
const browsetreeNAME3 = computed(() => _.findWhere(browsetree.value[curr_region.value]?.list3 || [], { _id: browsetree.value[curr_region.value]?.id3 })?.title || '')
const browsetreeNAME4 = computed(() => _.findWhere(browsetree.value[curr_region.value]?.list4 || [], { _id: browsetree.value[curr_region.value]?.id4 })?.title || '')
const browsetreeNAME5 = computed(() => _.findWhere(browsetree.value[curr_region.value]?.list5 || [], { _id: browsetree.value[curr_region.value]?.id5 })?.title || '')
const browsetreeNAME6 = computed(() => _.findWhere(browsetree.value[curr_region.value]?.list6 || [], { _id: browsetree.value[curr_region.value]?.id6 })?.title || '')
const browsetreeNAME7 = computed(() => _.findWhere(browsetree.value[curr_region.value]?.list7 || [], { _id: browsetree.value[curr_region.value]?.id7 })?.title || '')
const browsetreeConfirmable = computed(() => {
  if (browsetree.value[curr_region.value]?.list7?.length > 0) return !!browsetree.value[curr_region.value]?.id7 || !!browsetree_searchresultitem.value;
  if (browsetree.value[curr_region.value]?.list6?.length > 0) return !!browsetree.value[curr_region.value]?.id6 || !!browsetree_searchresultitem.value;
  if (browsetree.value[curr_region.value]?.list5?.length > 0) return !!browsetree.value[curr_region.value]?.id5 || !!browsetree_searchresultitem.value;
  if (browsetree.value[curr_region.value]?.list4?.length > 0) return !!browsetree.value[curr_region.value]?.id4 || !!browsetree_searchresultitem.value;
  if (browsetree.value[curr_region.value]?.list3?.length > 0) return !!browsetree.value[curr_region.value]?.id3 || !!browsetree_searchresultitem.value;
  if (browsetree.value[curr_region.value]?.list2?.length > 0) return !!browsetree.value[curr_region.value]?.id2 || !!browsetree_searchresultitem.value;
  if (browsetree.value[curr_region.value]?.list1?.length > 0) return !!browsetree.value[curr_region.value]?.id1 || !!browsetree_searchresultitem.value;
  return !!browsetree_searchresultitem.value;
})
const browsetreeProductTypes = computed(() => browsetree.value[curr_region.value]?.product_types || []);
const is_variation = computed(() => {
  return _.filter([variation_color.value, variation_size.value, variation_material.value], n => !!n).length > 1
});
//#endregion computed
//#region watch
watch(() => curr_region.value, (region) => {
  if (product_description_edit_type.value === 'editor') {
    product_description.value = listingForm.value[region]?.product_description || '';
  }
  if (product_description_edit_type.value === 'textarea') {
    product_description2.value = listingForm.value[region]?.product_description || '';
  }
});
watch(() => product_description.value, (product_description) => {
  if (listingForm.value[curr_region.value]) {
    listingForm.value[curr_region.value].product_description = product_description;
  }
});
watch(() => product_description2.value, (product_description) => {
  if (listingForm.value[curr_region.value]) {
    listingForm.value[curr_region.value].product_description = product_description;
  }
});
watch(() => browsetree.value.US, () => { listingForm.value.US.browsenodeid = browsetree_id('US'); listingForm.value.US.browsenodename = browsetree_name('US'); }, { deep: true });
watch(() => browsetree.value.CA, () => { listingForm.value.CA.browsenodeid = browsetree_id('CA'); listingForm.value.CA.browsenodename = browsetree_name('CA'); }, { deep: true });
watch(() => browsetree.value.MX, () => { listingForm.value.MX.browsenodeid = browsetree_id('MX'); listingForm.value.MX.browsenodename = browsetree_name('MX'); }, { deep: true });
watch(() => browsetree.value.BR, () => { listingForm.value.BR.browsenodeid = browsetree_id('BR'); listingForm.value.BR.browsenodename = browsetree_name('BR'); }, { deep: true });
watch(() => browsetree.value.ES, () => { listingForm.value.ES.browsenodeid = browsetree_id('ES'); listingForm.value.ES.browsenodename = browsetree_name('ES'); }, { deep: true });
watch(() => browsetree.value.UK, () => { listingForm.value.UK.browsenodeid = browsetree_id('UK'); listingForm.value.UK.browsenodename = browsetree_name('UK'); }, { deep: true });
watch(() => browsetree.value.FR, () => { listingForm.value.FR.browsenodeid = browsetree_id('FR'); listingForm.value.FR.browsenodename = browsetree_name('FR'); }, { deep: true });
watch(() => browsetree.value.BE, () => { listingForm.value.BE.browsenodeid = browsetree_id('BE'); listingForm.value.BE.browsenodename = browsetree_name('BE'); }, { deep: true });
watch(() => browsetree.value.NL, () => { listingForm.value.NL.browsenodeid = browsetree_id('NL'); listingForm.value.NL.browsenodename = browsetree_name('NL'); }, { deep: true });
watch(() => browsetree.value.DE, () => { listingForm.value.DE.browsenodeid = browsetree_id('DE'); listingForm.value.DE.browsenodename = browsetree_name('DE'); }, { deep: true });
watch(() => browsetree.value.IT, () => { listingForm.value.IT.browsenodeid = browsetree_id('IT'); listingForm.value.IT.browsenodename = browsetree_name('IT'); }, { deep: true });
watch(() => browsetree.value.SE, () => { listingForm.value.SE.browsenodeid = browsetree_id('SE'); listingForm.value.SE.browsenodename = browsetree_name('SE'); }, { deep: true });
watch(() => browsetree.value.PL, () => { listingForm.value.PL.browsenodeid = browsetree_id('PL'); listingForm.value.PL.browsenodename = browsetree_name('PL'); }, { deep: true });
watch(() => browsetree.value.AU, () => { listingForm.value.AU.browsenodeid = browsetree_id('AU'); listingForm.value.AU.browsenodename = browsetree_name('AU'); }, { deep: true });
watch(() => browsetree.value.JP, () => { listingForm.value.JP.browsenodeid = browsetree_id('JP'); listingForm.value.JP.browsenodename = browsetree_name('JP'); }, { deep: true });

watch(() => browsetree.value.US.id1, (node_id) => listingForm.value.US.node_id1 = node_id);
watch(() => browsetree.value.CA.id1, (node_id) => listingForm.value.CA.node_id1 = node_id);
watch(() => browsetree.value.MX.id1, (node_id) => listingForm.value.MX.node_id1 = node_id);
watch(() => browsetree.value.BR.id1, (node_id) => listingForm.value.BR.node_id1 = node_id);
watch(() => browsetree.value.ES.id1, (node_id) => listingForm.value.ES.node_id1 = node_id);
watch(() => browsetree.value.UK.id1, (node_id) => listingForm.value.UK.node_id1 = node_id);
watch(() => browsetree.value.FR.id1, (node_id) => listingForm.value.FR.node_id1 = node_id);
watch(() => browsetree.value.BE.id1, (node_id) => listingForm.value.BE.node_id1 = node_id);
watch(() => browsetree.value.NL.id1, (node_id) => listingForm.value.NL.node_id1 = node_id);
watch(() => browsetree.value.DE.id1, (node_id) => listingForm.value.DE.node_id1 = node_id);
watch(() => browsetree.value.IT.id1, (node_id) => listingForm.value.IT.node_id1 = node_id);
watch(() => browsetree.value.SE.id1, (node_id) => listingForm.value.SE.node_id1 = node_id);
watch(() => browsetree.value.PL.id1, (node_id) => listingForm.value.PL.node_id1 = node_id);
watch(() => browsetree.value.AU.id1, (node_id) => listingForm.value.AU.node_id1 = node_id);
watch(() => browsetree.value.JP.id1, (node_id) => listingForm.value.JP.node_id1 = node_id);

watch(() => browsetree.value.US.id2, (node_id) => listingForm.value.US.node_id2 = node_id);
watch(() => browsetree.value.CA.id2, (node_id) => listingForm.value.CA.node_id2 = node_id);
watch(() => browsetree.value.MX.id2, (node_id) => listingForm.value.MX.node_id2 = node_id);
watch(() => browsetree.value.BR.id2, (node_id) => listingForm.value.BR.node_id2 = node_id);
watch(() => browsetree.value.ES.id2, (node_id) => listingForm.value.ES.node_id2 = node_id);
watch(() => browsetree.value.UK.id2, (node_id) => listingForm.value.UK.node_id2 = node_id);
watch(() => browsetree.value.FR.id2, (node_id) => listingForm.value.FR.node_id2 = node_id);
watch(() => browsetree.value.BE.id2, (node_id) => listingForm.value.BE.node_id2 = node_id);
watch(() => browsetree.value.NL.id2, (node_id) => listingForm.value.NL.node_id2 = node_id);
watch(() => browsetree.value.DE.id2, (node_id) => listingForm.value.DE.node_id2 = node_id);
watch(() => browsetree.value.IT.id2, (node_id) => listingForm.value.IT.node_id2 = node_id);
watch(() => browsetree.value.SE.id2, (node_id) => listingForm.value.SE.node_id2 = node_id);
watch(() => browsetree.value.PL.id2, (node_id) => listingForm.value.PL.node_id2 = node_id);
watch(() => browsetree.value.AU.id2, (node_id) => listingForm.value.AU.node_id2 = node_id);
watch(() => browsetree.value.JP.id2, (node_id) => listingForm.value.JP.node_id2 = node_id);

watch(() => browsetree.value.US.id3, (node_id) => listingForm.value.US.node_id3 = node_id);
watch(() => browsetree.value.CA.id3, (node_id) => listingForm.value.CA.node_id3 = node_id);
watch(() => browsetree.value.MX.id3, (node_id) => listingForm.value.MX.node_id3 = node_id);
watch(() => browsetree.value.BR.id3, (node_id) => listingForm.value.BR.node_id3 = node_id);
watch(() => browsetree.value.ES.id3, (node_id) => listingForm.value.ES.node_id3 = node_id);
watch(() => browsetree.value.UK.id3, (node_id) => listingForm.value.UK.node_id3 = node_id);
watch(() => browsetree.value.FR.id3, (node_id) => listingForm.value.FR.node_id3 = node_id);
watch(() => browsetree.value.BE.id3, (node_id) => listingForm.value.BE.node_id3 = node_id);
watch(() => browsetree.value.NL.id3, (node_id) => listingForm.value.NL.node_id3 = node_id);
watch(() => browsetree.value.DE.id3, (node_id) => listingForm.value.DE.node_id3 = node_id);
watch(() => browsetree.value.IT.id3, (node_id) => listingForm.value.IT.node_id3 = node_id);
watch(() => browsetree.value.SE.id3, (node_id) => listingForm.value.SE.node_id3 = node_id);
watch(() => browsetree.value.PL.id3, (node_id) => listingForm.value.PL.node_id3 = node_id);
watch(() => browsetree.value.AU.id3, (node_id) => listingForm.value.AU.node_id3 = node_id);
watch(() => browsetree.value.JP.id3, (node_id) => listingForm.value.JP.node_id3 = node_id);

watch(() => browsetree.value.US.id4, (node_id) => listingForm.value.US.node_id4 = node_id);
watch(() => browsetree.value.CA.id4, (node_id) => listingForm.value.CA.node_id4 = node_id);
watch(() => browsetree.value.MX.id4, (node_id) => listingForm.value.MX.node_id4 = node_id);
watch(() => browsetree.value.BR.id4, (node_id) => listingForm.value.BR.node_id4 = node_id);
watch(() => browsetree.value.ES.id4, (node_id) => listingForm.value.ES.node_id4 = node_id);
watch(() => browsetree.value.UK.id4, (node_id) => listingForm.value.UK.node_id4 = node_id);
watch(() => browsetree.value.FR.id4, (node_id) => listingForm.value.FR.node_id4 = node_id);
watch(() => browsetree.value.BE.id4, (node_id) => listingForm.value.BE.node_id4 = node_id);
watch(() => browsetree.value.NL.id4, (node_id) => listingForm.value.NL.node_id4 = node_id);
watch(() => browsetree.value.DE.id4, (node_id) => listingForm.value.DE.node_id4 = node_id);
watch(() => browsetree.value.IT.id4, (node_id) => listingForm.value.IT.node_id4 = node_id);
watch(() => browsetree.value.SE.id4, (node_id) => listingForm.value.SE.node_id4 = node_id);
watch(() => browsetree.value.PL.id4, (node_id) => listingForm.value.PL.node_id4 = node_id);
watch(() => browsetree.value.AU.id4, (node_id) => listingForm.value.AU.node_id4 = node_id);
watch(() => browsetree.value.JP.id4, (node_id) => listingForm.value.JP.node_id4 = node_id);

watch(() => browsetree.value.US.id5, (node_id) => listingForm.value.US.node_id5 = node_id);
watch(() => browsetree.value.CA.id5, (node_id) => listingForm.value.CA.node_id5 = node_id);
watch(() => browsetree.value.MX.id5, (node_id) => listingForm.value.MX.node_id5 = node_id);
watch(() => browsetree.value.BR.id5, (node_id) => listingForm.value.BR.node_id5 = node_id);
watch(() => browsetree.value.ES.id5, (node_id) => listingForm.value.ES.node_id5 = node_id);
watch(() => browsetree.value.UK.id5, (node_id) => listingForm.value.UK.node_id5 = node_id);
watch(() => browsetree.value.FR.id5, (node_id) => listingForm.value.FR.node_id5 = node_id);
watch(() => browsetree.value.BE.id5, (node_id) => listingForm.value.BE.node_id5 = node_id);
watch(() => browsetree.value.NL.id5, (node_id) => listingForm.value.NL.node_id5 = node_id);
watch(() => browsetree.value.DE.id5, (node_id) => listingForm.value.DE.node_id5 = node_id);
watch(() => browsetree.value.IT.id5, (node_id) => listingForm.value.IT.node_id5 = node_id);
watch(() => browsetree.value.SE.id5, (node_id) => listingForm.value.SE.node_id5 = node_id);
watch(() => browsetree.value.PL.id5, (node_id) => listingForm.value.PL.node_id5 = node_id);
watch(() => browsetree.value.AU.id5, (node_id) => listingForm.value.AU.node_id5 = node_id);
watch(() => browsetree.value.JP.id5, (node_id) => listingForm.value.JP.node_id5 = node_id);

watch(() => browsetree.value.US.id6, (node_id) => listingForm.value.US.node_id6 = node_id);
watch(() => browsetree.value.CA.id6, (node_id) => listingForm.value.CA.node_id6 = node_id);
watch(() => browsetree.value.MX.id6, (node_id) => listingForm.value.MX.node_id6 = node_id);
watch(() => browsetree.value.BR.id6, (node_id) => listingForm.value.BR.node_id6 = node_id);
watch(() => browsetree.value.ES.id6, (node_id) => listingForm.value.ES.node_id6 = node_id);
watch(() => browsetree.value.UK.id6, (node_id) => listingForm.value.UK.node_id6 = node_id);
watch(() => browsetree.value.FR.id6, (node_id) => listingForm.value.FR.node_id6 = node_id);
watch(() => browsetree.value.BE.id6, (node_id) => listingForm.value.BE.node_id6 = node_id);
watch(() => browsetree.value.NL.id6, (node_id) => listingForm.value.NL.node_id6 = node_id);
watch(() => browsetree.value.DE.id6, (node_id) => listingForm.value.DE.node_id6 = node_id);
watch(() => browsetree.value.IT.id6, (node_id) => listingForm.value.IT.node_id6 = node_id);
watch(() => browsetree.value.SE.id6, (node_id) => listingForm.value.SE.node_id6 = node_id);
watch(() => browsetree.value.PL.id6, (node_id) => listingForm.value.PL.node_id6 = node_id);
watch(() => browsetree.value.AU.id6, (node_id) => listingForm.value.AU.node_id6 = node_id);
watch(() => browsetree.value.JP.id6, (node_id) => listingForm.value.JP.node_id6 = node_id);

watch(() => browsetree.value.US.id7, (node_id) => listingForm.value.US.node_id7 = node_id);
watch(() => browsetree.value.CA.id7, (node_id) => listingForm.value.CA.node_id7 = node_id);
watch(() => browsetree.value.MX.id7, (node_id) => listingForm.value.MX.node_id7 = node_id);
watch(() => browsetree.value.BR.id7, (node_id) => listingForm.value.BR.node_id7 = node_id);
watch(() => browsetree.value.ES.id7, (node_id) => listingForm.value.ES.node_id7 = node_id);
watch(() => browsetree.value.UK.id7, (node_id) => listingForm.value.UK.node_id7 = node_id);
watch(() => browsetree.value.FR.id7, (node_id) => listingForm.value.FR.node_id7 = node_id);
watch(() => browsetree.value.BE.id7, (node_id) => listingForm.value.BE.node_id7 = node_id);
watch(() => browsetree.value.NL.id7, (node_id) => listingForm.value.NL.node_id7 = node_id);
watch(() => browsetree.value.DE.id7, (node_id) => listingForm.value.DE.node_id7 = node_id);
watch(() => browsetree.value.IT.id7, (node_id) => listingForm.value.IT.node_id7 = node_id);
watch(() => browsetree.value.SE.id7, (node_id) => listingForm.value.SE.node_id7 = node_id);
watch(() => browsetree.value.PL.id7, (node_id) => listingForm.value.PL.node_id7 = node_id);
watch(() => browsetree.value.AU.id7, (node_id) => listingForm.value.AU.node_id7 = node_id);
watch(() => browsetree.value.JP.id7, (node_id) => listingForm.value.JP.node_id7 = node_id);

watch(() => browsetree.value.US.product_types, (product_types) => { if (!listingForm.value.US.feed_product_type && product_types.length > 0) changeProductType("home", "US") });
watch(() => browsetree.value.CA.product_types, (product_types) => { if (!listingForm.value.CA.feed_product_type && product_types.length > 0) changeProductType("home", "CA") });
watch(() => browsetree.value.MX.product_types, (product_types) => { if (!listingForm.value.MX.feed_product_type && product_types.length > 0) changeProductType("home", "MX") });
watch(() => browsetree.value.BR.product_types, (product_types) => { if (!listingForm.value.BR.feed_product_type && product_types.length > 0) changeProductType("home", "BR") });
watch(() => browsetree.value.ES.product_types, (product_types) => { if (!listingForm.value.ES.feed_product_type && product_types.length > 0) changeProductType("home", "ES") });
watch(() => browsetree.value.UK.product_types, (product_types) => { if (!listingForm.value.UK.feed_product_type && product_types.length > 0) changeProductType("home", "UK") });
watch(() => browsetree.value.FR.product_types, (product_types) => { if (!listingForm.value.FR.feed_product_type && product_types.length > 0) changeProductType("home", "FR") });
watch(() => browsetree.value.BE.product_types, (product_types) => { if (!listingForm.value.BE.feed_product_type && product_types.length > 0) changeProductType("home", "BE") });
watch(() => browsetree.value.NL.product_types, (product_types) => { if (!listingForm.value.NL.feed_product_type && product_types.length > 0) changeProductType("home", "NL") });
watch(() => browsetree.value.DE.product_types, (product_types) => { if (!listingForm.value.DE.feed_product_type && product_types.length > 0) changeProductType("home", "DE") });
watch(() => browsetree.value.IT.product_types, (product_types) => { if (!listingForm.value.IT.feed_product_type && product_types.length > 0) changeProductType("home", "IT") });
watch(() => browsetree.value.SE.product_types, (product_types) => { if (!listingForm.value.SE.feed_product_type && product_types.length > 0) changeProductType("home", "SE") });
watch(() => browsetree.value.PL.product_types, (product_types) => { if (!listingForm.value.PL.feed_product_type && product_types.length > 0) changeProductType("home", "PL") });
watch(() => browsetree.value.AU.product_types, (product_types) => { if (!listingForm.value.AU.feed_product_type && product_types.length > 0) changeProductType("home", "AU") });
watch(() => browsetree.value.JP.product_types, (product_types) => { if (!listingForm.value.JP.feed_product_type && product_types.length > 0) changeProductType("home", "JP") });
watch(() => route.query.id, (id1, id2) => {
  console.log([id1, id2]);
  if (!id1 && id2) init();
});
//#endregion watch
//#region 方法
const update_uistate = (payload = {}) => {
  if (typeof payload.visible === 'boolean') ui_visible.value = payload.visible;
  if (typeof payload.loading === 'boolean') ui_loading.value = payload.loading;
}
const init = () => {
  shopaccountForm.value = initShopAccountForm();
  initListingForm();
  getData();
}
const initShopAccountForm = (formdata = {}) => {
  return {
    account_username: null, regions: [], region_names: [],
    ...formdata,
  };
}
const initRegionForm = (formdata = {}) => {
  return {
    item_name: '', generic_keywords: '', bullet_points: '',
    node_id1: '', node_id2: '', node_id3: '', node_id4: '', node_id5: '', node_id6: '', node_id7: '',
    browsenodeid: '', browsenodename: '', feed_product_type: null, product_description: '', standard_price: '',
    ...formdata,
  };
}
const initListingForm = (formdata = {}) => {
  listingForm.value = {
    _id: "", account_username: null, regions: [], region_names: [], state: "draft",
    US: initRegionForm(),//美国
    CA: initRegionForm(),//加拿大
    MX: initRegionForm(),//墨西哥
    BR: initRegionForm(),//巴西
    ES: initRegionForm(),//西班牙
    UK: initRegionForm(),//英国
    FR: initRegionForm(),//法国
    BE: initRegionForm(),//比利时
    NL: initRegionForm(),//荷兰
    DE: initRegionForm(),//德国
    IT: initRegionForm(),//意大利
    SE: initRegionForm(),//瑞典
    PL: initRegionForm(),//波兰
    AU: initRegionForm(),//澳大利亚
    JP: initRegionForm(),//日本
    item_sku: '', brand_name: '', manufacturer: '', part_number: '', condition_type: 'New', condition_note: '',
    platinum_keywords1: '', platinum_keywords2: '', platinum_keywords3: '', platinum_keywords4: '', platinum_keywords5: '',
    images: [], sale_type: "2", product_tax_code: '', fulfillment_latency: '',
    external_product_id_type: 'UPC', external_product_id: '', standard_price: '', quantity: '', variation_theme: null,
    _properties: {},
    ...formdata,
  };
}
const getData = () => {
  update_uistate({ loading: true });
  proxy.$api.doAPI("/listingform/amazon/shopaccounts").then((res) => {
    update_uistate({ loading: false });
    if (res.code === 0) {
      shopaccounts.value = res.data.shopaccounts;
      brands.value = res.data.brands;
      manufacturers.value = res.data.manufacturers;
      if (route.query.id) {
        initForm(route.query.id).then(() => {
          startAutoSave();
        });
      }
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    update_uistate({ loading: false });
    console.error(err);
  });
};
const initForm = (_id) => {
  return new Promise(resolve => {
    update_uistate({ loading: true });
    proxy.$api.doAPI("/listingform/amazon/init", { _id }).then(async (res) => {
      update_uistate({ loading: false });
      if (res.code === 0) {
        if (res.data?.listingForm) {
          listingForm.value = res.data.listingForm;
          variation_list.value = _.map(res.data.variation_list, n => {
            return {
              ...n,
              other_images: n.other_images || [],
            };
          });
          initTheme(res.data.listingForm, res.data.variation_list);
          let { regions } = res.data.listingForm;
          curr_region.value = regions[0];
          initData(regions);
        } else if (!listingForm.value._id) {
          listingForm.value._id = _id;
        }
        if (!listingForm.value.account_username) shopaccountModal.value = true;
      } else {
        message.error(res.msg);
      }
      resolve();
    }).catch((err) => {
      update_uistate({ loading: false });
      console.error(err);
      resolve();
    });
  })
}
const initTheme = (listingForm, variation_list) => {
  if (listingForm.sale_type === '2') {
    let themes = listingForm.variation_theme.split(',');
    if (themes.indexOf('color_name') > -1) {
      variation_color.value = true;
      variation_colors.value = _.pluck(variation_list, "color_name");
    } else {
      variation_color.value = false;
      variation_colors.value = [];
    }
    if (themes.indexOf('size_name') > -1) {
      variation_size.value = true;
      variation_sizes.value = _.pluck(variation_list, "size_name");
    } else {
      variation_size.value = false;
      variation_sizes.value = [];
    }
    if (themes.indexOf('material_type') > -1) {
      variation_material.value = true;
      variation_materials.value = _.pluck(variation_list, "material_type");
    } else {
      variation_material.value = false;
      variation_materials.value = [];
    }
  }
}
const initData = (regions) => {
  let list = [];
  for (let i = 0; i < regions.length; i++) {
    let region = regions[i];
    clear_data(region);
    let { node_id1, node_id2, node_id3, node_id4, node_id5, node_id6, node_id7, browsenodeid, feed_product_type } = listingForm.value[region];
    list.push({
      region, node_id1, node_id2, node_id3, node_id4, node_id5, node_id6, node_id7, browsenodeid, feed_product_type,
    });
  }
  getNodesAndAttributes(list);
}
const showShopAccounts = () => {
  shopaccountForm.value = initShopAccountForm(_.pick(listingForm.value, ["account_username", "regions"]));
  shopaccountModal.value = true;
}
const confirmShopAccount = async () => {
  try {
    await shopaccountformRef.value.validateFields();
    listingForm.value.account_username = shopaccountForm.value.account_username;
    let regions = _.pluck(_.findWhere(shopaccounts.value, { value: shopaccountForm.value.account_username }).regions, "value");
    let region_names = _.pluck(_.findWhere(shopaccounts.value, { value: shopaccountForm.value.account_username }).regions, "label");
    listingForm.value.region_names = _.map(shopaccountForm.value.regions, region => {
      let region_index = regions.indexOf(region);
      return region_names[region_index];
    });
    for (let i = 0; i < shopaccountForm.value.regions.length; i++) {
      let region = shopaccountForm.value.regions[i];
      listingForm.value[region] = initRegionForm(listingForm.value[region] || listingForm.value[listingForm.value.regions[0]] || {});
      clear_data(region);
    }
    listingForm.value.regions = shopaccountForm.value.regions;
    curr_region.value = listingForm.value.regions[0];
    shopaccountModal.value = false;
  } catch (errorInfo) {
    console.log("Failed:", errorInfo);
  }
}
const validateShopRegion = async (_rule, value) => {
  if (!value || (_.isArray(value) && value.length > 0)) {
    return Promise.resolve();
  } else {
    return Promise.reject('请选勾选至少一个站点');
  }
}
const handleCreated = (editor) => {
  editorRef.value = editor;
}
const onLocalUploadSuccess = ({ type, data, index, index2 }) => {
  console.log('onLocalUploadSuccess', type, data, index, index2);
  if (type === 'listingform' || type === 'listingform2') {
    let images = toRaw(listingForm.value.images);
    if (type === 'listingform') images.push(data.url);
    if (type === 'listingform2') images[index] = data.url;
    listingForm.value.images = _.uniq(images);
  }
  if (type === 'other_images') {
    let other_images = toRaw(variation_list.value[index].other_images);
    if (index2 >= 0) other_images[index2] = data.url;
    else other_images.push(data.url);
    variation_list.value[index].other_images = _.uniq(other_images);
  }
}
const onNetworkInputSuccess = ({ type, data, index, index2 }) => {
  console.log('onNetworkInputSuccess', type, data, index, index2);
  if (type === 'listingform' || type === 'listingform2') {
    let images = toRaw(listingForm.value.images);
    if (type === 'listingform') images.push(...data.images);
    if (type === 'listingform2') images[index] = data.images[0];
    listingForm.value.images = _.uniq(images);
  }
  if (type === 'other_images') {
    let other_images = toRaw(variation_list.value[index].other_images);
    if (index2 >= 0) other_images[index2] = data.images[0];
    else other_images.push(...data.images);
    variation_list.value[index].other_images = _.uniq(other_images);
  }
}
const onSpacePickSuccess = ({ type, data, index, index2 }) => {
  console.log('onSpacePickSuccess', type, data, index, index2);
  if (type === 'listingform' || type === 'listingform2') {
    let images = toRaw(listingForm.value.images);
    if (type === 'listingform') images.push(...data.images);
    if (type === 'listingform2') images[index] = data.images[0];
    listingForm.value.images = _.uniq(images);
  }
  if (type === 'other_images') {
    let other_images = toRaw(variation_list.value[index].other_images);
    if (index2 >= 0) other_images[index2] = data.images[0];
    else other_images.push(...data.images);
    variation_list.value[index].other_images = _.uniq(other_images);
  }
}
const removeImage = ({ type, index, index2 }) => {
  if (type === 'listingform') listingForm.value.images.splice(index, 1);
  if (type === 'other_images') variation_list.value[index].other_images.splice(index2, 1);
}
const getExternalProductID = () => {
  let externalProductForm = {}, externalProductGroups = [];
  if (listingForm.value.sale_type === '1') {
    _.extend(externalProductForm, {
      type: listingForm.value.external_product_id_type,
      method: "auto",
      groupid: null,
      unused_count: 0,
      codes: "",
      count: 1,
    });
  } else if (listingForm.value.sale_type === '2') {
    _.extend(externalProductForm, {
      type: variation_list.value[0].external_product_id_type,
      method: "auto",
      groupid: null,
      unused_count: 0,
      codes: "",
      count: variation_list.value.length,
    });
  }
  externalProductForm.value = externalProductForm;
  externalProductGroups.value = externalProductGroups;
  externalProductModal.value = true;
  getProductGroups();
}
const getProductGroups = () => {
  externalProductLoading.value = true;
  proxy.$api.doAPI("/listingform/amazon/productgroups", { type: externalProductForm.value.type }).then((res) => {
    externalProductLoading.value = false;
    if (res.code === 0) {
      // externalProductTypes.value = res.data.types;
      externalProductGroups.value = res.data.list;
      externalProductForm.value.groupid = null;
      externalProductForm.value.unused_count = 0;
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    externalProductLoading.value = false;
    console.error(err);
  });
}
const confirmExternalProduct = () => {
  if (externalProductForm.value.method === 'auto') {//自动获取
    if (externalProductForm.value.unused_count === 0) return message.error("请选择剩余可用数量大于0的号码池");
    externalProductLoading.value = true;
    proxy.$api.doAPI("/listingidentifier/getcodes", { groupid: externalProductForm.value.groupid, count: externalProductForm.value.count }).then((res) => {
      externalProductLoading.value = false;
      if (res.code === 0) {
        fillExternalProductID(res.data.codes);
        externalProductModal.value = false;
      } else {
        message.error(res.msg);
      }
    }).catch((err) => {
      externalProductLoading.value = false;
      console.error(err);
    });
  } else if (externalProductForm.value.method === 'manual') {//手动输入
    if (!externalProductForm.value.codes || !externalProductForm.value.codes.trim()) return message.error("请输入号码");
    let codes = _.filter(_.uniq(externalProductForm.value.codes.trim().split('\n')), n => !!n);
    fillExternalProductID(codes);
    externalProductModal.value = false;
  } else return;
}
const fillExternalProductID = (codes) => {
  if (listingForm.value.sale_type === '1') {
    listingForm.value.external_product_id = codes[0];
  } else if (listingForm.value.sale_type === '2') {
    variation_list.value = _.map(toRaw(variation_list.value), (n, i) => {
      if (codes[i]) n.external_product_id = codes[i];
      return n;
    });
  }
}
const changeExternalProductType = () => {
  getProductGroups();
}
const changeExternalProductGroup = (groupid) => {
  let group = _.findWhere(externalProductGroups.value, { _id: groupid });
  externalProductForm.value.unused_count = group.unused_count;
}
const gotoUPC = () => {
  proxy.$utils.downloadBrowser({ href: "/shops/published/upc" });
}
const editListingPrice = () => {
  listingPrice.value = _.reduce(listingForm.value.regions, (a, b) => _.extend(a, { [b]: { standard_price: listingForm.value[b].standard_price || "" } }), {});
  listingPriceModal.value = true;
}
const confirmListingPrice = () => {
  for (let i = 0; i < listingForm.value.regions.length; i++) {
    let region = listingForm.value.regions[i];
    if (!listingPrice.value[region].standard_price) return message.error(`站点：${listingForm.value.region_names[i]}的价格不能为空！`);
  }
  for (let i = 0; i < listingForm.value.regions.length; i++) {
    let region = listingForm.value.regions[i];
    listingForm.value[region].standard_price = listingPrice.value[region].standard_price;
  }
  listingForm.value.standard_price = listingForm.value[listingForm.value.regions[0]].standard_price;
  listingPriceModal.value = false;
}
const moveLeft = ({ type, index, index2 }) => {
  if (type === 'listingform') {
    let image1 = toRaw(listingForm.value.images)[index];
    let image2 = toRaw(listingForm.value.images)[index - 1]
    listingForm.value.images[index - 1] = image1;
    listingForm.value.images[index] = image2;
  } else if (type === 'other_images') {
    let image1 = variation_list.value[index].other_images[index2];
    let image2 = variation_list.value[index].other_images[index2 - 1];
    variation_list.value[index].other_images[index2 - 1] = image1;
    variation_list.value[index].other_images[index2] = image2;
  }
}
const moveRight = ({ type, index, index2 }) => {
  if (type === 'listingform') {
    let image1 = toRaw(listingForm.value.images)[index];
    let image2 = toRaw(listingForm.value.images)[index + 1]
    listingForm.value.images[index + 1] = image1;
    listingForm.value.images[index] = image2;
  } else if (type === 'other_images') {
    let image1 = variation_list.value[index].other_images[index2];
    let image2 = variation_list.value[index].other_images[index2 + 1];
    variation_list.value[index].other_images[index2 + 1] = image1;
    variation_list.value[index].other_images[index2] = image2;
  }
}
const moveUp = ({ index }) => {
  let variation_item1 = toRaw(variation_list.value)[index];
  let variation_item2 = toRaw(variation_list.value)[index - 1];
  variation_list.value[index - 1] = variation_item1;
  variation_list.value[index] = variation_item2;
}
const moveDown = ({ index }) => {
  let variation_item1 = toRaw(variation_list.value)[index];
  let variation_item2 = toRaw(variation_list.value)[index + 1];
  variation_list.value[index + 1] = variation_item1;
  variation_list.value[index] = variation_item2;
}
//变更商品属性
const changeVariationTheme = (theme) => {
  let themes = theme.split(',');
  if (themes.indexOf('color_name') > -1) {
    variation_color.value = true;
    variation_colors.value = toRaw(variation_colors.value);
  } else {
    variation_color.value = false;
    variation_colors.value = [];
  }
  if (themes.indexOf('size_name') > -1) {
    variation_size.value = true;
    variation_sizes.value = toRaw(variation_sizes.value);
  } else {
    variation_size.value = false;
    variation_sizes.value = [];
  }
  if (themes.indexOf('material_type') > -1) {
    variation_material.value = true;
    variation_materials.value = toRaw(variation_materials.value);
  } else {
    variation_material.value = false;
    variation_materials.value = [];
  }
  createVariationList(false);
}
//批量删除多属性
const removeVariationItems = () => {
  if (variation_ids.value.length === 0) return message.error("请先勾选您要删除的多属性");
  variation_list.value = _.filter(toRaw(variation_list.value), n => variation_ids.value.indexOf(n._id) === -1);
  variation_colors.value = _.filter(_.uniq(_.pluck(variation_list.value, "color_name")), n => !!n);
  variation_sizes.value = _.filter(_.uniq(_.pluck(variation_list.value, "size_name")), n => !!n);
  variation_materials.value = _.filter(_.uniq(_.pluck(variation_list.value, "material_type")), n => !!n);
  variation_ids.value = [];
}
//添加一行多属性
const addVariationItem = () => {
  if (!listingForm.value.variation_theme) return message.error("暂无商品属性，请先选择商品属性！");
  if (!listingForm.value.account_username) return message.error("暂无站点，请先选择站点！");
  variationItemForm.value = {
    item_sku: listingForm.value.item_sku,
    color_name: "",
    size_name: "",
    material_type: "",
  };
  variationItemModal.value = true;
}
const confirmVariationItem = async () => {
  try {
    await variationItemFormRef.value.validateFields();
    let variation_item = initVariationItem();
    _.extend(variation_item, _.pick(variationItemForm.value, ["color_name", "size_name", "material_type"]))
    let { color_name, size_name, material_type } = variation_item;
    let { item_sku } = variationItemForm.value;
    let values = [];
    if (color_name) values.push(color_name);
    if (size_name) values.push(size_name);
    if (material_type) values.push(material_type);
    _.extend(variation_item, { item_sku: `${item_sku}-${values.join('-')}` });
    variation_list.value.push(variation_item);
    variationItemModal.value = false;
  } catch (errorInfo) {
    console.log("Failed:", errorInfo);
  }
}
//#region 批量编辑多属性
const editVariationTheme = () => {
  if (!listingForm.value.variation_theme) return message.error("暂无商品属性，请先选择商品属性！");
  if (!listingForm.value.account_username) return message.error("暂无站点，请先选择站点！");
  let variation_list = toRaw(variation_list.value);
  let colors = _.filter(_.uniq(_.pluck(variation_list, "color_name")));
  if (colors.length === 0) colors = toRaw(variation_colors.value);
  let sizes = _.filter(_.uniq(_.pluck(variation_list, "size_name")));
  if (sizes.length === 0) sizes = toRaw(variation_sizes.value);
  let materials = _.filter(_.uniq(_.pluck(variation_list, "material_type")));
  if (materials.length === 0) materials = toRaw(variation_materials.value);
  variationThemeForm.value = {
    colors: _.map(colors, n => ({ key: n, value: n })),
    sizes: _.map(sizes, n => ({ key: n, value: n })),
    materials: _.map(materials, n => ({ key: n, value: n })),
  };
  variationThemeModal.value = true;
}
const addVariationColor = () => {
  if (!variation_input_color.value) return message.error("请输入属性项名称");
  if (variationThemeForm.value.colors.findIndex(n => n.value.toLowerCase() === variation_input_color.value.toLowerCase()) >= 0) {
    return message.error("名称不能重复，请输入其他名称");
  }
  variationThemeForm.value.colors.push({ key: variation_input_color.value, value: variation_input_color.value });
  variation_input_color.value = "";
}
const removeVariationColor = (index) => {
  variationThemeForm.value.colors.splice(index, 1);
}
const addVariationSize = () => {
  if (!variation_input_size.value) return message.error("请输入属性项名称");
  if (variationThemeForm.value.sizes.findIndex(n => n.value.toLowerCase() === variation_input_size.value.toLowerCase()) >= 0) {
    return message.error("名称不能重复，请输入其他名称");
  }
  variationThemeForm.value.sizes.push({ key: variation_input_size.value, value: variation_input_size.value });
  variation_input_size.value = "";
}
const removeVariationSize = (index) => {
  variationThemeForm.value.sizes.splice(index, 1);
}
const addVariationMaterial = () => {
  if (!variation_input_material.value) return message.error("请输入属性项名称");
  if (variationThemeForm.value.materials.findIndex(n => n.value.toLowerCase() === variation_input_material.value.toLowerCase()) >= 0) {
    return message.error("名称不能重复，请输入其他名称");
  }
  variationThemeForm.value.materials.push({ key: variation_input_material.value, value: variation_input_material.value });
  variation_input_material.value = "";
}
const removeVariationMaterial = (index) => {
  variationThemeForm.value.materials.splice(index, 1);
}
const confirmVariationTheme = () => {
  if (variation_color.value) {
    if (variationThemeForm.value.colors.length === 0) return message.error(`请为color_name添加至少一项属性项`);
    variation_colors.value = _.pluck(variationThemeForm.value.colors, "value");
  }
  if (variation_size.value) {
    if (variationThemeForm.value.sizes.length === 0) return message.error(`请为size_name添加至少一项属性项`);
    variation_sizes.value = _.pluck(variationThemeForm.value.sizes, "value");
  }
  if (variation_material.value) {
    if (variationThemeForm.value.materials.length === 0) return message.error(`请为material添加至少一项属性项`);
    variation_materials.value = _.pluck(variationThemeForm.value.materials, "value");
  }
  createVariationList(true);
  variationThemeModal.value = false;
}
//#endregion 批量编辑多属性
const combineVariation = () => {
  let variations = [];
  if (variation_color.value) variations.push({ name: "color_name", values: variation_colors.value });
  if (variation_size.value) variations.push({ name: "size_name", values: variation_sizes.value });
  if (variation_material.value) variations.push({ name: "material_type", values: variation_materials.value });
  let list = {};
  for (let i = 0; i < variations.length; i++) {
    let { name, values } = variations[i];
    if (!list[name]) list[name] = [];
    for (let j = 0; j < values.length; j++) {
      list[name].push({ [name]: values[j] });
    }
  }
  list = _.values(list);
  let list2 = proxy.$utils.choose(_.reduce(list, (a, b) => a.concat(b), []), list.length);
  list2 = _.map(list2, n => _.extend({}, ...n));
  list2 = _.filter(list2, n => _.keys(n).length === list.length);
  return list2;
}
const initVariationItem = (item_data = {}) => {
  let item = {
    _id: proxy.$utils.uuid(), color_name: "", size_name: "", material_type: "", item_sku: "", external_product_id_type: "UPC", external_product_id: "", quantity: "", standard_price: "", item_name: "", main_image: "", swatch_image: "", other_images: [],
  };
  for (let i = 0; i < listingForm.value.regions.length; i++) {
    let region = listingForm.value.regions[i];
    _.extend(item, { [region]: { item_name: "", standard_price: "" } });
  }
  _.extend(item, item_data);
  item.other_images = item.other_images || [];
  item.item_name = item[listingForm.value.regions[0]]?.item_name || "";
  item.standard_price = item[listingForm.value.regions[0]]?.standard_price || "";
  return item;
}
const createVariationList = (init) => {
  let has_color = variation_color.value;
  let has_size = variation_size.value;
  let has_material = variation_material.value;
  let colors = toRaw(variation_colors.value);
  let sizes = toRaw(variation_sizes.value);
  let materials = toRaw(variation_materials.value);
  let list = combineVariation();
  let variation_list = toRaw(variation_list.value);
  if (init) variation_list = [];
  let item_sku = listingForm.value.item_sku || "";
  let item_data = {};
  if (variation_list[0]?.external_product_id_type) item_data.external_product_id_type = variation_list[0].external_product_id_type;
  for (let i = 0; i < list.length; i++) {
    for (let j = 0; j < colors.length; j++) {
      if (!_.findWhere(variation_list, list[i])) {
        let vname = "color_name";
        let value = colors[j];
        let vitem = _.find(variation_list, n => !_.has(n, vname));
        if (!vitem) vitem = _.find(variation_list, n => n[vname] === value);
        if (vitem) {
          vitem[vname] = value;
        } else {
          let item = _.extend({}, initVariationItem(item_data), list[i]);
          variation_list.push(item);
        }
      }
    }
    for (let j = 0; j < sizes.length; j++) {
      if (!_.findWhere(variation_list, list[i])) {
        let vname = "size_name";
        let value = sizes[j];
        let vitem = _.find(variation_list, n => (!has_color || n.color_name === list[i].color_name) && !_.has(n, vname));
        if (!vitem) vitem = _.find(variation_list, n => (!has_color || n.color_name === list[i].color_name) && n[vname] === value);
        if (vitem) {
          vitem[vname] = value;
        } else {
          let item = _.extend({}, initVariationItem(item_data), list[i]);
          variation_list.push(item);
        }
      }
    }
    for (let j = 0; j < materials.length; j++) {
      if (!_.findWhere(variation_list, list[i])) {
        let vname = "meterial_type";
        let value = materials[j];
        let vitem = _.find(variation_list, n => (!has_color || n.color_name === list[i].color_name) && (!has_size || n.size_name === list[i].size_name) && !_.has(n, vname));
        if (!vitem) vitem = _.find(variation_list, n => (!has_color || n.color_name === list[i].color_name) && (!has_size || n.size_name === list[i].size_name) && n[vname] === value);
        if (vitem) {
          vitem[vname] = value;
        } else {
          let item = _.extend({}, initVariationItem(item_data), list[i]);
          variation_list.push(item);
        }
      }
    }
  }
  variation_list = _.map(variation_list, n => {
    let values = [];
    if (n.color_name) values.push(n.color_name);
    if (n.size_name) values.push(n.size_name);
    if (n.material_type) values.push(n.material_type);
    n.item_sku = `${item_sku ? (item_sku + '-') : ''}${values.join('-')}`
    return n;
  });
  variation_list.value = variation_list;
  variation_colors.value = _.filter(_.uniq(_.pluck(variation_list.value, "color_name")), n => !!n);
  variation_sizes.value = _.filter(_.uniq(_.pluck(variation_list.value, "size_name")), n => !!n);
  variation_materials.value = _.filter(_.uniq(_.pluck(variation_list.value, "material_type")), n => !!n);
  variation_ids.value = [];
}
//应用到全部 数量 价格
const useAllVariationItem = (index) => {
  let variation_list = toRaw(variation_list.value);
  let variation_item = variation_list[index];
  let { quantity, standard_price } = variation_item;
  variation_list = variation_list.map((n, idx) => {
    if (idx !== index) {
      for (let i = 0; i < listingForm.value.regions.length; i++) {
        let region = listingForm.value.regions[i];
        n[region].standard_price = variation_item[region].standard_price;
      };
      _.extend(n, { quantity, standard_price });
    }
    return n;
  });
  variation_list.value = variation_list;
}
//删除变种
const removeVariationItem = (index) => {
  variation_list.value.splice(index, 1);
  variation_colors.value = _.filter(_.uniq(_.pluck(variation_list.value, "color_name")), n => !!n);
  variation_sizes.value = _.filter(_.uniq(_.pluck(variation_list.value, "size_name")), n => !!n);
  variation_materials.value = _.filter(_.uniq(_.pluck(variation_list.value, "material_type")), n => !!n);
  variation_ids.value = [];
}
const blurVariationItemAttribute = (e, v) => {
  if (!v.trim()) {
    message.error(`属性名称不能为空`);
    e.target.focus();
  }
}
//批量修改 UPC EAN 等 类型
const changeVariationListProductIDType = (type) => {
  variation_list.value.map(n => n.external_product_id_type = type);
  listingForm.value.external_product_id_type = type;
}
//一键生成
const oneClickGenerate = (key) => {
  if (key === "item_sku") {
    let item_sku = listingForm.value.item_sku || "";
    variation_list.value = _.map(toRaw(variation_list.value), n => {
      let values = [];
      if (n.color_name) values.push(n.color_name);
      if (n.size_name) values.push(n.size_name);
      if (n.material_type) values.push(n.material_type);
      n.item_sku = `${item_sku ? (item_sku + '-') : ''}${values.join('-')}`;
      return n;
    });
  } else if (key === "item_name") {
    let item_name = listingForm.value[listingForm.value.regions?.[0]]?.item_name || "";
    variation_list.value = _.map(toRaw(variation_list.value), n => {
      let values = [];
      if (n.color_name) values.push(n.color_name);
      if (n.size_name) values.push(n.size_name);
      if (n.material_type) values.push(n.material_type);
      let item_name_suffix = values.join('-');
      for (let i = 0; i < listingForm.value.regions.length; i++) {
        let region = listingForm.value.regions[i];
        let item_name = listingForm.value[region].item_name;
        n[region].item_name = `${item_name}-${item_name_suffix}`;
      };
      n.item_name = `${item_name}-${item_name_suffix}`;
      return n;
    });
  } else if (key === 'external_product_id') {
    variation_list.value = _.map(toRaw(variation_list.value), n => {
      n.external_product_id = n.item_sku;
      return n;
    });
  }
}
//批量编辑
const editVariationList = (key) => {
  if (key === 'quantity') {
    variationListQuantity.value = "";
    variationListQuantityModal.value = true;
  } else if (key === 'standard_price') {
    variationListPrice.value = _.reduce(listingForm.value.regions, (a, b) => _.extend(a, { [b]: { standard_price: "" } }), {});
    variationListPriceModal.value = true;
  }
}
const confirmVariationListQuantity = () => {
  if (!variationListQuantity.value) return message.error("请输入修改数量");
  if (!proxy.$utils.isDecimal(variationListQuantity.value) || proxy.$utils.d(variationListQuantity.value).lt(0)) return message.error("请输入正确数量");
  variation_list.value = _.map(toRaw(variation_list.value), n => {
    n.quantity = variationListQuantity.value;
    return n;
  });
  variationListQuantityModal.value = false;
}
const confirmVariationListPrice = () => {
  for (let i = 0; i < listingForm.value.regions.length; i++) {
    let region = listingForm.value.regions[i];
    if (!variationListPrice.value[region].standard_price) return message.error(`站点：${listingForm.value.region_names[i]}的价格不能为空！`);
    if (!proxy.$utils.isDecimal(variationListPrice.value[region].standard_price) || proxy.$utils.d(variationListPrice.value[region].standard_price).lte(0)) return message.error(`站点：${listingForm.value.region_names[i]}的价格无效！`);
  }
  variation_list.value = _.map(toRaw(variation_list.value), n => {
    for (let i = 0; i < listingForm.value.regions.length; i++) {
      let region = listingForm.value.regions[i];
      if (!n[region]) n[region] = {};
      n[region].standard_price = variationListPrice.value[region].standard_price;
    };
    n.standard_price = n[listingForm.value.regions[0]].standard_price;
    return n;
  });
  variationListPriceModal.value = false;
}
const editVariationItem = (index, item, key) => {
  variationItemIndex.value = index;
  if (key === 'standard_price') {
    variationItemPrice.value = _.reduce(listingForm.value.regions, (a, b) => _.extend(a, { [b]: { standard_price: item[b]?.standard_price || "" } }), {});
    variationItemPriceModal.value = true;
  } else if (key === 'item_name') {
    variationItemName.value = _.reduce(listingForm.value.regions, (a, b) => _.extend(a, { [b]: { item_name: item[b]?.item_name || "" } }), {});
    variationItemNameModal.value = true;
  }
}
const confirmVariationItemPrice = () => {
  for (let i = 0; i < listingForm.value.regions.length; i++) {
    let region = listingForm.value.regions[i];
    if (!variationItemPrice.value[region].standard_price) return message.error(`站点：${listingForm.value.region_names[i]}的价格不能为空！`);
  }
  variation_list.value = _.map(toRaw(variation_list.value), (n, index) => {
    if (index === variationItemIndex.value) {
      for (let i = 0; i < listingForm.value.regions.length; i++) {
        let region = listingForm.value.regions[i];
        if (!n[region]) n[region] = {};
        n[region].standard_price = variationItemPrice.value[region].standard_price;
      };
      n.standard_price = n[listingForm.value.regions[0]].standard_price;
    }
    return n;
  });
  variationItemPriceModal.value = false;
}
const confirmVariationItemName = () => {
  for (let i = 0; i < listingForm.value.regions.length; i++) {
    let region = listingForm.value.regions[i];
    if (!variationItemName.value[region].item_name) return message.error(`站点：${listingForm.value.region_names[i]}的标题不能为空！`);
  }
  variation_list.value = _.map(toRaw(variation_list.value), (n, index) => {
    if (index === variationItemIndex.value) {
      for (let i = 0; i < listingForm.value.regions.length; i++) {
        let region = listingForm.value.regions[i];
        if (!n[region]) n[region] = {};
        n[region].item_name = variationItemName.value[region].item_name;
      };
      n.item_name = n[listingForm.value.regions[0]].item_name;
    }
    return n;
  });
  variationItemNameModal.value = false;
}
const useAllImages = (index, key) => {
  let variation_list = toRaw(variation_list.value);
  let { main_image, swatch_image, other_images } = variation_list[index];
  variation_list = variation_list.map((n, i) => {
    if (i !== index) {
      if (key === 'color') {
        if (n.color_name === variation_list[index].color_name) {
          _.extend(n, { main_image, swatch_image, other_images: [...other_images] });
        }
      } else if (key === 'size') {
        if (n.size_name === variation_list[index].size_name) {
          _.extend(n, { main_image, swatch_image, other_images: [...other_images] });
        }
      } else if (key === 'material') {
        if (n.material_type === variation_list[index].material_type) {
          _.extend(n, { main_image, swatch_image, other_images: [...other_images] });
        }
      } else {
        _.extend(n, { main_image, swatch_image, other_images: [...other_images] });
      }
    }
    return n;
  });
  variation_list.value = variation_list;
}
const useAllMainImages = (index, key) => {
  let variation_list = toRaw(variation_list.value);
  let { main_image } = variation_list[index];
  variation_list = variation_list.map((n, i) => {
    if (i !== index) {
      if (key === 'color') {
        if (n.color_name === variation_list[index].color_name) {
          n.main_image = main_image;
        }
      } else if (key === 'size') {
        if (n.size_name === variation_list[index].size_name) {
          n.main_image = main_image;
        }
      } else if (key === 'material') {
        if (n.material_type === variation_list[index].material_type) {
          n.main_image = main_image;
        }
      } else {
        n.main_image = main_image;
      }
    }
    return n;
  });
  variation_list.value = variation_list;
}
const useAllOtherImages = (index, key) => {
  let variation_list = toRaw(variation_list.value);
  let { other_images } = variation_list[index];
  variation_list = variation_list.map((n, i) => {
    if (i !== index) {
      if (key === 'color') {
        if (n.color_name === variation_list[index].color_name) {
          n.other_images = [...other_images];
        }
      } else if (key === 'size') {
        if (n.size_name === variation_list[index].size_name) {
          n.other_images = [...other_images];
        }
      } else if (key === 'material') {
        if (n.material_type === variation_list[index].material_type) {
          n.other_images = [...other_images];
        }
      } else {
        n.other_images = [...other_images];
      }
    }
    return n;
  });
  variation_list.value = variation_list;
}
const chooseFromImages = ({ type, count, index, index2 }) => {
  if (listingForm.value.images.length === 0) return message.error("暂无橱窗图");
  chooseImagesType.value = type;
  chooseImagesList.value = [];
  chooseImagesMaxCount.value = count;
  chooseImagesIndex.value = index;
  chooseImagesIndex2.value = index2;
  chooseImagesModal.value = true;
}
const confirmChooseImages = () => {
  if (chooseImagesList.value.length === 0) return message.error("请勾选橱窗图");
  if (chooseImagesList.value.length > chooseImagesMaxCount.value) return message.error(`本次上传最多${chooseImagesMaxCount.value}张图片，您勾选了${chooseImagesList.value.length}张`);
  variation_list.value[chooseImagesIndex.value].other_images.push(...chooseImagesList.value);
  chooseImagesModal.value = false;
}
const confirmChooseImage = (image) => {
  if (chooseImagesType.value === 'other_images2') {
    variation_list.value[chooseImagesIndex.value].other_images[chooseImagesIndex2.value] = image;
  } else {
    variation_list.value[chooseImagesIndex.value][chooseImagesType.value] = image;
  }
  chooseImagesModal.value = false;
}
const validatePrice = async (_rule, value) => {
  if (!value) {
    return Promise.resolve();
  } else if (!proxy.$utils.isDecimal(value) || proxy.$utils.d(value).lte(0)) {
    return Promise.reject("价格无效");
  } else {
    return Promise.resolve();
  }
};
const validateQuantity = async (_rule, value) => {
  if (!value) {
    return Promise.resolve();
  } else if (!proxy.$utils.isDecimal(value) || proxy.$utils.d(value).lte(0)) {
    return Promise.reject("数量无效");
  } else {
    return Promise.resolve();
  }
};
const doCheck = () => {
  ui_saving.value = true;
  let check_data = {};
  for (let i = 0; i < listingForm.value.regions.length; i++) {
    let region = listingForm.value.regions[i];
    check_data[region] = {
      item_name: listingForm.value[region].item_name,
      generic_keywords: listingForm.value[region].generic_keywords,
      bullet_points: listingForm.value[region].bullet_points,
      product_description: listingForm.value[region].product_description,
      brand_name: listingForm.value.brand_name,
      condition_note: listingForm.value.condition_note,
      platinum_keywords: _.filter([
        listingForm.value.platinum_keywords1 || "",
        listingForm.value.platinum_keywords2 || "",
        listingForm.value.platinum_keywords3 || "",
        listingForm.value.platinum_keywords4 || "",
        listingForm.value.platinum_keywords5 || "",
      ], n => !!n),
      item_names: [listingForm.value[region].item_name, ..._.filter(_.uniq(_.map(variation_list.value, n => n[region].item_name)), n => !!n)],
    };
  }
  proxy.$api.doAPI("/tortword/checkreq", { check_data }).then((res) => {
    if (res.code === 0) {
      doCheckResult(res.data._id);
    } else {
      message.error(res.msg);
      ui_saving.value = false;
    }
  }).catch((err) => {
    ui_saving.value = false;
    console.error(err);
  });
}
const doCheckResult = (check_id) => {
  proxy.$api.doAPI("/tortword/checkres", { check_id }).then((res) => {
    if (res.code === 0) {
      if (res.data.state === 'finished') {
        if (res.data.result.length === 0) {
          message.success("没有发现所包含的侵权词");
        } else {
          tortwordsResult.value = res.data.result;
          tortwordsResultModal.value = true;
        }
        ui_saving.value = false;
      } else if (res.data.state === 'error') {
        message.error(res.data.error_message);
        ui_saving.value = false;
      } else {
        setTimeout(() => {
          doCheckResult(check_id);
        }, 1500);
      }
    } else {
      message.error(res.msg);
      ui_saving.value = false;
    }
  }).catch((err) => {
    ui_saving.value = false;
    console.error(err);
  });
}
const doTranslate = () => {

}
const checkListingForm = (autosave = false) => {
  let { account_username, regions, region_names, item_sku, brand_name, manufacturer, sale_type, external_product_id_type, external_product_id, quantity, variation_theme } = listingForm.value;
  if (!account_username || regions.length === 0) return "暂无站点，请先选择站点！";
  for (let i = 0; i < regions.length; i++) {
    let region = regions[i];
    let region_name = region_names[i];
    let { item_name, generic_keywords, bullet_points, browsenodeid, feed_product_type, product_description, standard_price } = listingForm.value[region];
    if (!item_name) return `${region_name}站点:产品标题 数据不完整不允许刊登`;
    // if (proxy.$utils.u8length(item_name).length > 200) return `${region_name}站点:产品标题 长度不能超过200字节`;
    if (!autosave) {
      if (!generic_keywords) return `${region_name}站点:Search Terms 数据不完整不允许刊登`;
      // if (proxy.$utils.u8length(generic_keywords).length > 255) return `${region_name}站点:Search Terms 长度不能超过255字节`;
      if (!bullet_points || !bullet_points.trim()) return `${region_name}站点:Bullet Point 数据不完整不允许刊登`;
      if (bullet_points.split('\n').length > 5) return `${region_name}站点:Bullet Point 不能超过5条`;
      if (_.filter(bullet_points.split('\n'), n => n.length > 500).length > 0) return `${region_name}站点：Bullet Point每行长度不能超过500字符`;
      if (!browsenodeid) return `${region_name}站点:产品分类 数据不完整不允许刊登`;
      if (!feed_product_type) return `${region_name}站点:Product Type 数据不完整不允许刊登`;
      if (!product_description) return `${region_name}站点:产品描述 数据不完整不允许刊登`;
      if (sale_type === '1') {
        if (!standard_price) return `${region_name}站点:价格不能为空`;
        if (!proxy.$utils.isDecimal(standard_price) || proxy.$utils.d(standard_price).lte(0)) return `${region_name}站点:价格无效`;
      } else if (sale_type === '2') {
        if (variation_list.value.length === 0) return `${region_name}站点:多属性商品不能为空`;
        for (let j = 0; j < variation_list.value.length; j++) {
          if (!variation_list.value[j][region]) {
            variation_list.value[j][region] = {};
            // return `${region_name}站点：变体产品标题和价格不能为空`;
          }
          let { item_name, standard_price } = variation_list.value[j][region];
          if (!item_name) {
            if (variation_list.value[j].item_name) {
              variation_list.value[j][region].item_name = variation_list.value[j].item_name;
            } else {
              return `${region_name}站点：变体产品标题长度不能为空`;
            }
          }
          if (!standard_price) {
            if (variation_list.value[j].standard_price) {
              variation_list.value[j][region].standard_price = variation_list.value[j].standard_price;
            } else {
              return `${region_name}站点：变体产品价格不能为空`;
            }
          }
        }
      }
    }
  }
  if (!item_sku) return `主sku不能为空`;
  if (!autosave) {
    if (!brand_name) return `品牌不能为空`;
    if (!manufacturer) return `制造商不能为空`;
    if (sale_type === '1') {
      if (external_product_id_type !== 'GTIN' && !external_product_id) return `单品产品ID不能为空`;
      if (external_product_id_type === 'UPC' && external_product_id?.length !== 12) return "请填写正确的UPC码";
      if (external_product_id_type === 'EAN' && external_product_id?.length !== 13) return "请填写正确的EAN码";
      if (!quantity) return `数量不能为空`;
      if (!proxy.$utils.isDecimal(quantity) || proxy.$utils.d(quantity).lt(0)) return `数量无效`;
    } else if (sale_type === '2') {
      if (!variation_theme) return `商品属性不能为空`;
      for (let i = 0; i < variation_list.value.length; i++) {
        let { external_product_id_type, external_product_id } = variation_list.value[i];
        if (external_product_id_type === 'UPC' && external_product_id?.length !== 12) return "请填写正确的UPC码";
        if (external_product_id_type === 'EAN' && external_product_id?.length !== 13) return "请填写正确的EAN码";
      }
    }
  }
}
const doSave = ({ publish = false, autosave = false }) => {
  if (!autosave) ui_saving.value = true;
  return new Promise(resolve => {
    setTimeout(async () => {
      try {
        await listingformRef.value.validateFields();
        let result = checkListingForm(autosave);
        if (typeof result === "string") {
          if (!autosave) ui_saving.value = false;
          message.error(result);
          return resolve({ success: false });
        }
        proxy.$api.doAPI("/listingform/amazon/save", { listingForm: listingForm.value, variation_list: variation_list.value, autosave }).then((res) => {
          if (!autosave) ui_saving.value = false;
          if (res.code === 0) {
            if (!autosave) {
              Modal.success({
                title: "提示",
                content: "刊登信息保存成功",
                centered: true,
                closable: false,
                maskClosable: false,
                keyboard: false,
                onOk: () => {
                  resolve({ success: true, _id: res.data._id });
                  if (!publish) {
                    if (route.query.id) {
                      getData();
                    } else {
                      router.replace({ path: route.path, query: { id: res.data._id } });
                    }
                  }
                }
              });
            } else {
              resolve({ success: true, _id: res.data._id });
              if (!publish) {
                if (route.query.id) {
                  getData();
                } else {
                  router.replace({ path: route.path, query: { id: res.data._id } });
                }
              }
            }
          } else {
            if (!autosave) message.error(res.msg);
            resolve({ success: false });
          }
        }).catch((err) => {
          if (!autosave) ui_saving.value = false;
          console.error(err);
          resolve({ success: false });
        });
      } catch (errorInfo) {
        if (!autosave) ui_saving.value = false;
        console.log("Failed:", errorInfo);
        // message.error(_.reduce(errorInfo.errorFields, (a, b) => a.concat(b.errors), []).join('；'));
        let result = checkListingForm(autosave);
        if (!autosave && typeof result === "string") message.error(result);
        resolve({ success: false });
      }
    }, 100);
  })
}
const doSend = () => {
  doSave({ publish: true }).then(({ success, _id }) => {
    if (success) {
      ui_saving.value = true;
      proxy.$api.doAPI("/listingform/amazon/publish", { _id }).then((res) => {
        ui_saving.value = false;
        if (res.code === 0) {
          if (route.query.id) getData();
          else router.replace({ path: route.path, query: { id: res.data._id } });
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        ui_saving.value = false;
        console.error(err);
      });
    }
  });
}
const doClose = () => {
  window.close();
}
const doSearchAttribute = (field_key, region) => {
  if (field_key) {
    getProperties({ field_key, region });
  }
}
//#region 类目菜单选择
const set_data = (payload, region) => {
  let browsetree = toRaw(browsetree.value[region]);
  let properties_keys = _.pluck(browsetree.properties, "field_key");
  browsetree.value[region] = { ...browsetree, ...payload, properties: [..._.filter(payload.properties || [], n => properties_keys.indexOf(n.field_key) === -1), ...browsetree.properties] };
  if (payload.properties?.length > 0) {
    let { _properties } = toRaw(listingForm.value);
    console.log('set_data', _properties);
    _properties = _properties || {};
    if (!_properties[region]) _properties[region] = {};
    for (let i = 0; i < payload.properties.length; i++) {
      let { field_key, properties } = payload.properties[i];
      if (!_properties[region][field_key]) _properties[region][field_key] = { field_key, list: [] };
      if (properties.language_tag) _properties[region][field_key].language_tag = properties.language_tag;
      if (properties.marketplace_id) _properties[region][field_key].marketplace_id = properties.marketplace_id;
      if (_properties[region][field_key].list.length === 0) {
        _properties[region][field_key].list = properties.list;
      } else {
        for (let j = 0; j < properties.list.length; j++) {
          let { field_key: key } = properties.list[j];
          let index = _.findIndex(_properties[region][field_key].list, { field_key: key });
          console.log('index-', index);
          if (index === -1) {
            _properties[region][field_key].list.push({ ...properties.list[j] });
          } else {
            _.extend(_properties[region][field_key].list[index], _.omit(properties.list[j], 'value'));
          }
        }
      }
      for (let j = _properties[region][field_key].list.length - 1; j >= 0; j--) {
        let { field_key: key } = _properties[region][field_key].list[j];
        let index = _.findIndex(properties.list, { field_key: key });
        if (index === -1) {
          _properties[region][field_key].list.splice(j, 1);
        }
      }
    }
    console.log('set_data', _properties);
    listingForm.value._properties = _properties;
  }
}
const confirm_data = (region) => {
  browsetree.value[region].confirm_data = {
    index: browsetree.value[region].index,
    count: browsetree.value[region].count,
    list1: browsetree.value[region].list1,
    list2: browsetree.value[region].list2,
    list3: browsetree.value[region].list3,
    list4: browsetree.value[region].list4,
    list5: browsetree.value[region].list5,
    list6: browsetree.value[region].list6,
    list7: browsetree.value[region].list7,
    id1: browsetree.value[region].id1,
    id2: browsetree.value[region].id2,
    id3: browsetree.value[region].id3,
    id4: browsetree.value[region].id4,
    id5: browsetree.value[region].id5,
    id6: browsetree.value[region].id6,
    id7: browsetree.value[region].id7,
  };
}
const getProductTypes = (region) => {
  update_uistate({ loading: true });
  proxy.$api.doAPI("/browsetree/product_types", { node_id: browsetree.value[region].id1 }).then((res) => {
    if (res.code === 0) {
      set_data({ product_types: res.data.product_types }, region);
    } else {
      message.error(res.msg);
      update_uistate({ loading: false });
    }
  }).catch((err) => {
    update_uistate({ loading: false });
    console.error(err);
  });
}
const changeProductType = (product_type, region) => {
  listingForm.value[region].feed_product_type = product_type;
  // getAttributes({ product_type, region });
}
const clear_data = (region) => {
  update_uistate({ visible: false });
  setTimeout(() => {
    set_data(
      browsetree.value[region].confirm_data ?
        toRaw(browsetree.value[region].confirm_data) :
        {
          index: 0, count: 0,
          list1: [], id1: '',
          list2: [], id2: '',
          list3: [], id3: '',
          list4: [], id4: '',
          list5: [], id5: '',
          list6: [], id6: '',
          list7: [], id7: '',
          confirm_data: null, product_types: [],
        },
      region
    );
  }, 100);
}
const selectBrowseTree = () => {
  update_uistate({ visible: true });
  if (browsetree.value[curr_region.value]?.list1?.length > 0) return;
  update_uistate({ loading: true });
  setTimeout(() => {
    getNodes();
  }, 100);
}
const getNodes = () => {
  let region = curr_region.value;
  let id = '';
  if (browsetree.value[region].index === 1) id = browsetree.value[region].id1;
  if (browsetree.value[region].index === 2) id = browsetree.value[region].id2;
  if (browsetree.value[region].index === 3) id = browsetree.value[region].id3;
  if (browsetree.value[region].index === 4) id = browsetree.value[region].id4;
  if (browsetree.value[region].index === 5) id = browsetree.value[region].id5;
  if (browsetree.value[region].index === 6) id = browsetree.value[region].id6;
  if (browsetree.value[region].index === 7) id = browsetree.value[region].id7;
  proxy.$api.doAPI("/browsetree/nodes", { region, node_id: id }).then((res) => {
    if (res.code === 0) {
      let payload = {};
      if (browsetree.value[region].index === 0) _.extend(payload, { list1: res.data.list, count: 1 });
      if (browsetree.value[region].index === 1) _.extend(payload, { list2: res.data.list, count: 2 });
      if (browsetree.value[region].index === 2) _.extend(payload, { list3: res.data.list, count: 3 });
      if (browsetree.value[region].index === 3) _.extend(payload, { list4: res.data.list, count: 4 });
      if (browsetree.value[region].index === 4) _.extend(payload, { list5: res.data.list, count: 5 });
      if (browsetree.value[region].index === 5) _.extend(payload, { list6: res.data.list, count: 6 });
      if (browsetree.value[region].index === 6) _.extend(payload, { list7: res.data.list, count: 7 });
      set_data(payload, region);
    } else {
      message.error(res.msg);
    }
    update_uistate({ loading: false });
  }).catch((err) => {
    update_uistate({ loading: false });
    console.error(err);
  });
}
const getRegionNodes = ({ index, id1, id2, id3, id4, id5, id6 }) => {
  let region = curr_region.value;
  proxy.$api.doAPI("/browsetree/region_nodes", { region, index, id1, id2, id3, id4, id5, id6 }).then((res) => {
    if (res.code === 0) {
      set_data(res.data.result, curr_region.value);
    } else {
      message.error(res.msg);
    }
    update_uistate({ loading: false });
  }).catch((err) => {
    update_uistate({ loading: false });
    console.error(err);
  });
}
const getAttributes = ({ product_type, region }) => {
  update_uistate({ loading: true });
  return proxy.$api.doAPI("/browsetree/region_attributes", { region, node_id: browsetree.value[region].id1, browsenodeid: listingForm.value[region].browsenodeid, product_type }).then((res) => {
    if (res.code === 0) {
      set_data({ attributes: res.data.attributes }, region);
    } else {
      message.error(res.msg);
    }
    update_uistate({ loading: false });
  }).catch((err) => {
    update_uistate({ loading: false });
    console.error(err);
  });
}
const getProperties = ({ field_key, region }) => {
  update_uistate({ loading: true });
  return proxy.$api.doAPI("/browsetree/region_properties", { account_username: listingForm.value.account_username, region, browsenodeid: listingForm.value[region].browsenodeid, field_key }).then((res) => {
    if (res.code === 0) {
      set_data({ properties: res.data.properties }, region);
    } else {
      message.error(res.msg);
    }
    update_uistate({ loading: false });
  }).catch((err) => {
    update_uistate({ loading: false });
    console.error(err);
  });
}
const getNodeProperties = () => {
  console.log('getNodeProperties');
  update_uistate({ loading: true });
  return proxy.$api.doAPI("/browsetree/nodes_properties", { listingform_id: route.query.id, account_username: listingForm.value.account_username, region: curr_region.value, browsenodeid: listingForm.value[curr_region.value].browsenodeid }).then((res) => {
    if (res.code === 0) {
      set_data({ properties: res.data.properties }, curr_region.value);
    } else {
      message.error(res.msg);
    }
    update_uistate({ loading: false });
  }).catch((err) => {
    update_uistate({ loading: false });
    console.error(err);
  });
}
const getNodesAndAttributes = (regions) => {
  update_uistate({ loading: true });
  proxy.$api.doAPI("/browsetree/nodes_attributes", { regions }).then((res) => {
    if (res.code === 0) {
      for (let i = 0; i < res.data.result.length; i++) {
        let { region, index, count, list1, id1, list2, id2, list3, id3, list4, id4, list5, id5, list6, id6, list7, id7 } = res.data.result[i];
        let payload = { index, count, list1, attributes: res.data.attributes, product_types: res.data.product_types };
        if (index >= 1) _.extend(payload, { list2, id1 });
        if (index >= 2) _.extend(payload, { list3, id2 });
        if (index >= 3) _.extend(payload, { list4, id3 });
        if (index >= 4) _.extend(payload, { list5, id4 });
        if (index >= 5) _.extend(payload, { list6, id5 });
        if (index >= 6) _.extend(payload, { list7, id6 });
        if (index >= 7) _.extend(payload, { id7 });
        set_data(payload, region);
        confirm_data(region);
      }
      nextTick(() => {
        getNodeProperties();
      });
    } else {
      message.error(res.msg);
      update_uistate({ loading: false });
    }
  }).catch((err) => {
    update_uistate({ loading: false });
    console.error(err);
  });
}
const browsetree_id = (region) => {
  if (browsetree.value[region]?.list7?.length > 0) return browsetree.value[region].id7;
  if (browsetree.value[region]?.list6?.length > 0) return browsetree.value[region].id6;
  if (browsetree.value[region]?.list5?.length > 0) return browsetree.value[region].id5;
  if (browsetree.value[region]?.list4?.length > 0) return browsetree.value[region].id4;
  if (browsetree.value[region]?.list3?.length > 0) return browsetree.value[region].id3;
  if (browsetree.value[region]?.list2?.length > 0) return browsetree.value[region].id2;
  if (browsetree.value[region]?.list1?.length > 0) return browsetree.value[region].id1;
  return '';
};
const browsetree_name = (region) => {
  if (browsetree.value[region]?.list7?.length > 0) return _.findWhere(browsetree.value[region].list7, { _id: browsetree.value[region].id7 })?.title || '';
  if (browsetree.value[region]?.list6?.length > 0) return _.findWhere(browsetree.value[region].list6, { _id: browsetree.value[region].id6 })?.title || '';
  if (browsetree.value[region]?.list5?.length > 0) return _.findWhere(browsetree.value[region].list5, { _id: browsetree.value[region].id5 })?.title || '';
  if (browsetree.value[region]?.list4?.length > 0) return _.findWhere(browsetree.value[region].list4, { _id: browsetree.value[region].id4 })?.title || '';
  if (browsetree.value[region]?.list3?.length > 0) return _.findWhere(browsetree.value[region].list3, { _id: browsetree.value[region].id3 })?.title || '';
  if (browsetree.value[region]?.list2?.length > 0) return _.findWhere(browsetree.value[region].list2, { _id: browsetree.value[region].id2 })?.title || '';
  if (browsetree.value[region]?.list1?.length > 0) return _.findWhere(browsetree.value[region].list1, { _id: browsetree.value[region].id1 })?.title || '';
  return '';
}
const selectTree1 = (item) => {
  let data = { index: 1, id1: item._id, id2: '', id3: '', id4: '', id5: '', id6: '', id7: '' }
  if (item.hasChildren) selectTree(data);
  else set_data({ ...data, list2: [], list3: [], list4: [], list5: [], list6: [], list7: [] }, curr_region.value);
}
const selectTree2 = (item) => {
  let data = { index: 2, id2: item._id, id3: '', id4: '', id5: '', id6: '', id7: '' }
  if (item.hasChildren) selectTree(data);
  else set_data({ ...data, list3: [], list4: [], list5: [], list6: [], list7: [] }, curr_region.value);
}
const selectTree3 = (item) => {
  let data = { index: 3, id3: item._id, id4: '', id5: '', id6: '', id7: '' }
  if (item.hasChildren) selectTree(data);
  else set_data({ ...data, list4: [], list5: [], list6: [], list7: [] }, curr_region.value);
}
const selectTree4 = (item) => {
  let data = { index: 4, id4: item._id, id5: '', id6: '', id7: '' }
  if (item.hasChildren) selectTree(data);
  else set_data({ ...data, list5: [], list6: [], list7: [] }, curr_region.value);
}
const selectTree5 = (item) => {
  let data = { index: 5, id5: item._id, id6: '', id7: '' }
  if (item.hasChildren) selectTree(data);
  else set_data({ ...data, list6: [], list7: [] }, curr_region.value);
}
const selectTree6 = (item) => {
  let data = { index: 6, id6: item._id, id7: '' }
  if (item.hasChildren) selectTree(data)
  else set_data({ ...data, list7: [] }, curr_region.value);
}
const selectTree7 = (item) => {
  let data = { index: 7, id7: item._id, }
  if (item.hasChildren) selectTree(data)
  else set_data({ ...data }, curr_region.value);
}
const selectTree = (data) => {
  update_uistate({ loading: true });
  setTimeout(() => {
    getRegionNodes(data);
  }, 100);
}
const closeBrowseTree = () => {
  clear_data(curr_region.value);
}
const confirmBrowseTree = () => {
  if (browsetree_searchresultitem.value) {
    let { id1, id2, id3, id4, id5, id6, id7 } = browsetree_searchresultitem.value;
    update_uistate({ visible: false, loading: true });
    setTimeout(() => {
      getNodesAndAttributes([{
        region: curr_region.value,
        node_id1: id1,
        node_id2: id2,
        node_id3: id3,
        node_id4: id4,
        node_id5: id5,
        node_id6: id6,
        node_id7: id7,
      }]);
    }, 100);
  } else {
    update_uistate({ visible: false, loading: true });
    nextTick(() => {
      confirm_data(curr_region.value);
      getProductTypes(curr_region.value);
      getNodeProperties();
    });
  }
  backToSelect();
}
const selectSearchItem = (index) => {
  console.log('selectSearchItem', index);
  let searchresults = toRaw(browsetree_searchresults.value);
  browsetree_searchresultitem.value = searchresults[index];
  browsetree_searchresults.value = _.map(searchresults, (n, i) => {
    return {
      ...n,
      selected: i === index,
    }
  });
}
const backToSelect = () => {
  browsetree_showsearchresults.value = false;
  browsetree_searchresults.value = [];
  browsetree_searchresultitem.value = null;
  browsetree_searchtext.value = "";
}
const doSearchBrowseTree = () => {
  update_uistate({ loading: true });
  setTimeout(() => {
    proxy.$api.doAPI("/browsetree/search", { country_code: curr_region.value, searchtext: browsetree_searchtext.value }).then((res) => {
      update_uistate({ loading: false });
      if (res.code === 0) {
        browsetree_searchresults.value = res.data.list;
        browsetree_showsearchresults.value = true;
      } else {
        message.error(res.msg);
      }
    }).catch((err) => {
      update_uistate({ loading: false });
      console.error(err);
    });
  }, 100);
}
//#endregion 类目菜单选择
//#region AI
const showAIGenerater = (field) => {
  console.log('showAIGenerater');
  if (listingForm.value.state !== 'draft' && listingForm.value.state !== 'fail') return;
  ai_field.value = field;
  ai_browsetreenames.value = _.filter([
    browsetreeNAME1.value,
    browsetreeNAME2.value,
    browsetreeNAME3.value,
    browsetreeNAME4.value,
    browsetreeNAME5.value,
    browsetreeNAME6.value,
    browsetreeNAME7.value,
  ], n => !!n);
  ai_region.value = curr_region.value;
  ai_generator.value = true;
}
const useAIContent = (data) => {
  console.log('useAIContent', data);
  if (data) {
    if (ai_field.value === 'item_name') listingForm.value[curr_region.value].item_name = data.content;
    if (ai_field.value === 'bullet_points') listingForm.value[curr_region.value].bullet_points = data.content;
    ai_generator.value = false;
  }
}
//#endregion AI
//#endregion 方法
//#region 自动保存
const startAutoSave = () => {
  if (route.query.id && process.env.OS !== 'darwin') {
    stopAutoSave();
    if (listingForm.value.state === 'draft' || listingForm.value.state === 'fail') {
      timer = setTimeout(() => {
        doSave({ autosave: true }).then(() => {
          startAutoSave();
        });
      }, 5 * 60 * 1000);
    }
  }
}
const stopAutoSave = () => {
  clearTimeout(timer);
}
//#endregion 自动保存
//#region 快捷滚动
const clickMenu = ({ key }) => {
  stopscroll.value = true;
  document.getElementById(key).scrollIntoView();
  setTimeout(() => {
    stopscroll.value = false;
  }, 100);
}
const onScroll = () => {
  if (stopscroll.value) return;
  // 获取所有锚点元素
  const navContents = document.querySelectorAll('.code-box')
  // 所有锚点元素的 offsetTop
  const offsetTopArr = []
  navContents.forEach(item => {
    offsetTopArr.push(item.offsetTop)
  })
  // 获取当前文档流的 scrollTop
  const scrollTop = document.getElementById('mainform').scrollTop
  // 定义当前点亮的导航下标
  let navIndex = 0
  for (let n = 0; n < offsetTopArr.length; n++) {
    // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
    // 那么此时导航索引就应该是 n 了
    if (scrollTop >= offsetTopArr[n]) {
      navIndex = n
    }
  }
  console.log('onScroll', offsetTopArr, scrollTop, navIndex);
  // 把下标赋值给 vue 的 data
  if (navIndex === 0) activecards.value = ['card1'];
  if (navIndex === 1) activecards.value = ['card2'];
  if (navIndex === 2) activecards.value = ['card3'];
  if (navIndex === 3) activecards.value = ['card4'];
  if (navIndex === 4) activecards.value = ['card5'];
  if (navIndex === 5) activecards.value = ['card6'];
}
//#endregion 快捷滚动
//#region 生命周期
onActivated(() => {
  console.log('onActivated');
  activecards.value = ['card1'];
  document.getElementById('mainform').addEventListener('scroll', onScroll);
  init();
});
onDeactivated(() => {
  console.log('onDeactivated');
  stopAutoSave();
  document.getElementById('mainform')?.removeEventListener('scroll', onScroll)
});
onBeforeUnmount(() => {
  console.log('onBeforeUnmount');
  editorRef.value?.destroy()
});
//#endregion 生命周期
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-form-item-extra) {
  min-height: 0;
}

p {
  margin: 0;
  padding: 0;
}

.form-item {
  display: flex;
}

.form-label {
  min-width: 150px;
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 3px;
  padding-right: 10px;
  text-align: right;
}

.form-label>span:first-child::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin-left: 2px;
}

.form-label>.en {
  word-break: break-word;
}

.required>span:first-child::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.header.required::before {
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.mainform {
  overflow: auto;
  height: 100vh;
  padding-bottom: 80px;
}

.footer {
  // box-shadow: 0 0 6px 0 #d9d9d9;
  height: 64px !important;
  line-height: 64px;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  text-align: center;
  z-index: 9;
  background-color: #fff;
  border-radius: 2px;
}

.el-alert {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
  opacity: 1;
  overflow: hidden;
  padding: 8px 16px;
  position: relative;
  -webkit-transition: opacity .2s;
  transition: opacity .2s;
  width: 100%;
}

.el-alert--warning {
  background-color: #fdf6ec;
  color: #e6a23c;
}

.mb15 {
  margin-bottom: 15px !important;
}

.el-alert--warning.is-light {
  background-color: #fdf6ec;
  color: #e6a23c;
}

.el-alert__content {
  display: table-cell;
  padding: 0 8px;
}

.el-alert__title {
  font-size: 13px;
  line-height: 18px;
}

.el-alert__title.is-bold {
  font-weight: 700;
}

.el-alert .el-alert__description {
  font-size: 12px;
  margin: 5px 0 0;
}

.el-alert--warning.is-light .el-alert__description {
  color: #e6a23c;
}

.text-danger {
  color: #f56c6c !important;
}

.bold {
  font-weight: 700;
}

.el-alert--info {
  background-color: #f4f4f5;
  color: #909399;
}

.mt15 {
  margin-top: 15px !important;
}

.text-center {
  text-align: center;
}

.el-alert.is-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.el-alert.no-data {
  background-color: #f0f0f0;
  color: #999;
  line-height: 80px;
}

.loadtext {
  color: #7a7a7a;
  line-height: 16px;
  margin: 0;
  padding: 8px 0;
}

.pb0 {
  padding-bottom: 0 !important;
}

.text-warning {
  color: #e6a23c !important;
}

.variation_panel {
  width: 100%;
  border: 1px solid #ebeef5;
}

.variation_header {
  background: #f5f7fa;
  border-bottom: 1px solid #ebeef5;
  color: #000;
  height: 40px;
  padding-left: 15px;
}

:deep(.ant-card-small > .ant-card-body) {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.tree-group {
  display: flex;
  overflow: auto;
  height: 500px;
}

.tree-item-card {
  min-width: 220px;
  width: 23%;
}

.tree-item {
  height: 435px;
  overflow: auto;
}

.search-results-group {
  display: flex;
  overflow: auto;
  height: 500px;
}

.search-results-item {
  text-decoration: none;
}

.search-results-item:hover {
  text-decoration: underline;
}

.search-results-item.active {
  background-color: #7ca4d2;
  color: #fff;
}

.code-box:target {
  border: 1px solid #1890ff;
}
</style>