const state = {
  visible: false,
  port: '',
  printer: '',
  printers: [],
};
const mutations = {
  set_visible(state, visible) {
    state.visible = visible || false;
  },
  set_port(state, port) {
    state.port = port || "";
  },
  set_printer(state, printer) {
    state.printer = printer || "";
  },
  set_printers(state, printers) {
    state.printers = printers || [];
  },
};
const actions = {
  setVisible({ commit }, payload) {
    commit('set_visible', payload);
  },
  setPort({ commit }, payload) {
    commit('set_port', payload);
  },
  setPrinter({ commit }, payload) {
    commit('set_printer', payload);
  },
  setPrinters({ commit }, payload) {
    commit('set_printers', payload);
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
