<template>
  <div class="header">
    <div class="d-flex align-center mb-2">
      <span style="width:95px;">商品关键词：</span>
      <a-input-search v-model:value="queryForm.searchtext" placeholder="请输入sku、标题、采集链接、产品标签等" enter-button="搜索"
        @search="getData" />
    </div>
    <div class="d-flex align-center mb-2">
      <a-row>
        <a-col><span style="width:75px;">来源平台：</span></a-col>
        <a-col>
          <div>
            <a-tag class="click" :color="queryForm.platform === 'ALL' ? 'processing' : ''" @click="setPlatform('ALL')">
              全部
            </a-tag>
            <a-tag class="click" v-for="item in platforms" :key="item.value"
              :color="queryForm.platform === item.value ? 'processing' : ''" @click="setPlatform(item.value)">
              {{ item.text }}
            </a-tag>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
  <div class="list">
    <div class="d-flex justify-space-between mb-2">
      <a-space>
        <a-button type="primary" @click="getData" :loading="dataLoading">刷新</a-button>
        <a-button type="primary" @click="bulkPublish">批量认领上架</a-button>
        <a-button type="primary" @click="bulkDownloadImages">批量下载图片</a-button>
      </a-space>
      <a-space>
        <a-button type="danger" @click="bulkDisableListings">批量作废</a-button>
      </a-space>
    </div>
    <a-tabs v-model:activeKey="tab" @change="getData">
      <a-tab-pane key="1" tab="已完成"></a-tab-pane>
      <a-tab-pane key="2" tab="已作废"></a-tab-pane>
    </a-tabs>
    <a-table bordered :columns="columns" :data-source="data" :scroll="{ x: width }" :loading="dataLoading"
      :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
      @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" row-key="_id"
      :row-selection="{ selectedRowKeys, onChange: (keys) => { selectedRowKeys = keys; } }" size="small">
      <template #bodyCell="{ record, column }">
        <div v-if="column.dataIndex === 'main_image'" style="width:60px;">
          <a-tooltip v-if="record.images[0]" placement="rightBottom" color="#fff"
            :overlayStyle="{ width: '500px', height: '500px' }">
            <template #title>
              <img :src="record.images[0]" style="width:500px;height:500px;object-fit: contain;" />
            </template>
            <div class="image-header" style="border:1px solid #ddd;">
              <img :src="record.images[0]" style="object-fit:contain;padding:2px;" />
            </div>
          </a-tooltip>
          <div v-else class="image-header">
            <img :src="$filters.image_placeholder" style="object-fit: contain;" />
          </div>
        </div>
        <template v-if="column.dataIndex === 'item_name'">
          <p>
            <span>
              <a href="javascript:;" class="text-teal font-weight-bold" style="text-decoration: none;"
                @click="edit(record._id)">
                {{ record.item_sku }}
              </a>
              <CopyOutlined title="复制SKU" class="ml-1" @click="$filters.doCopy(record.item_sku)" />
            </span>
            <a :class="`ml-1 label label-${record.source}`" target="_blank" title="查看在线商品" :href="record.url">
              {{ record.source }}
              <LinkOutlined />
            </a>
          </p>
          <a-tooltip>
            <template #title>
              {{ record.item_name }}
            </template>
            <p class="in3line">
              {{ record.item_name }}
            </p>
          </a-tooltip>
        </template>
        <template v-if="column.dataIndex === 'product_details'">
          <a-tooltip>
            <template #title>
              <p v-for="item in record.product_details" :key="item.label">
                <span>{{ item.label }}: </span>
                <span class="text-info">{{ item.value }}</span>
              </p>
            </template>
            <p class="in3line">
              {{ record._product_details }}
            </p>
          </a-tooltip>
        </template>
        <template v-if="column.dataIndex === 'standard_price'">
          <p>{{ record.standard_price }}</p>
          <p>--</p>
          <p>--</p>
        </template>
        <template v-if="column.dataIndex === 'create_time'">
          <p>{{ record.create_by }}</p>
          <p>{{ record.create_time }}</p>
        </template>
        <template v-if="column.dataIndex === 'operation'">
          <div class="d-flex flex-column align-center">
            <a href="javascript:;" class="underline" @click="publish([record._id])"> 认领上架 </a>
            <a href="javascript:;" class="underline" @click="edit(record._id)"> 编辑 </a>
            <a-dropdown>
              <a class="ant-dropdown-link underline" @click.prevent>
                更多
                <DownOutlined />
              </a>
              <template #overlay>
                <a-menu @click="({ key }) => handleMenuClick(key, record._id)">
                  <a-menu-item key="downloadImages">
                    <a href="javascript:;" class="underline text-info">下载图片</a>
                  </a-menu-item>
                  <a-menu-item key="disable">
                    <a href="javascript:;" class="underline text-error">作废</a>
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </div>
        </template>
      </template>
    </a-table>
  </div>
  <a-modal v-model:visible="downloadModal" title="图片下载" :maskClosable="false" @cancel="() => downloadModal = false"
    :footer="null">
    <a-card style="width:100%">
      <template #title>
        <span :class="{ 'text-success': downloadState === 'success', 'text-error': downloadState === 'fail' }">
          {{ downloadState === 'process' ? '图片正在打包中，请稍后...' : '' }}
          {{ downloadState === 'success' ? '图片下载成功！' : '' }}
          {{ downloadState === 'fail' ? '图片下载失败！' : '' }}
        </span>
      </template>
      <div class="d-flex justify-center align-center">
        <div v-if="downloadState === 'process'">
          <div class="loader-bar"></div>
          <div class="mt-2 text-center">下载中请勿关闭提示窗口</div>
        </div>
        <div v-if="downloadState === 'success'">
          <a-button @click="() => downloadModal = false">关闭</a-button>
        </div>
        <div v-if="downloadState === 'fail'">图片下载失败，请稍后重试</div>
      </div>
    </a-card>
  </a-modal>
  <a-modal v-model:visible="publishModal" :title="`认领到刊登草稿箱(${publishForm.list.length}个产品)`" centered
    :maskClosable="!publishing" :closable="!publishing" @cancel="() => publishModal = false" width="800px">
    <a-alert message="目前支持刊登的站点：美国、加拿大、墨西哥、巴西、西班牙、英国、法国、比利时、荷兰、德国、意大利、瑞典、波兰、澳大利亚、日本；其他站点陆续增加中" type="info" show-icon
      class="mb-2" />
    <a-form ref="publishFormRef" name="publishForm" :model="publishForm" :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }" autocomplete="off">
      <a-form-item label="选择账号" name="account_username" :rules="[{ required: true, message: '请选择账号' }]">
        <a-select v-model:value="publishForm.account_username" :options="shopaccounts" placeholder="请选择" show-search
          :filter-option="$filters.filterOption" />
      </a-form-item>
      <a-form-item label="选择站点" name="regions"
        :rules="[{ required: true, message: '请选勾选至少一个站点', validator: validateShopRegion }]">
        <a-checkbox-group v-if="shop_regions.length > 0" v-model:value="publishForm.regions" :options="shop_regions" />
        <div v-else style="background-color:#f4f4f5;color:#909399;padding:8px 16px;">
          <InfoCircleFilled style="font-size:16px;width:16px;" /> 请先选择账号
        </div>
      </a-form-item>
    </a-form>
    <a-table bordered :columns="pcolumns" :data-source="publishForm.list" :scroll="{ x: pwidth }" :pagination="null"
      size="small">
      <template #bodyCell="{ record, column }">
        <div v-if="column.dataIndex === 'main_image'" class="text-center" style="width:60px;">
          <div class="image-header" style="border:1px solid #ddd;">
            <img v-if="record.cover" :src="record.cover" style="object-fit: contain;padding:2px;" />
            <img v-else :src="$filters.image_placeholder" style="object-fit: contain;" />
          </div>
        </div>
        <div v-if="column.dataIndex === 'info'" class="d-flex flex-column" style="font-size: 12px;">
          <div>来源：<a :class="`ml-1 label label-${record.source}`" target="_blank" title="查看在线商品" :href="record.url">
              {{ record.source }}
              <LinkOutlined />
            </a>
          </div>
          <div>SKU：<span class="text-teal font-weight-bold" style="text-decoration: none;">
              {{ record.item_sku }}
            </span>
          </div>
          <div>标题：{{ record.item_name }}</div>
        </div>
        <div v-if="column.dataIndex === 'variation'">
          <table>
            <thead>
              <tr>
                <td width="50%">源</td>
                <td width="50%">目标</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in record.variations" :key="item.s">
                <td>{{ item.s }}</td>
                <td>
                  <a-select v-model:value="item.t" placeholder="请选择" show-search style="width:100%;">
                    <a-select-option value="Color">Color</a-select-option>
                    <a-select-option value="Size">Size</a-select-option>
                    <a-select-option value="Material">Material</a-select-option>
                  </a-select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </a-table>
    <template #footer>
      <div class="d-flex justify-end align-center">
        <a-space>
          <a-button @click="() => publishModal = false" :disabled="publishing">取消</a-button>
          <a-button class="btn-info" @click="confirmPublish" :loading="publishing">确定提交</a-button>
        </a-space>
      </div>
    </template>
  </a-modal>
</template>
<script>
import { LoadingOutlined, DownOutlined, LinkOutlined, CopyOutlined, UploadOutlined, SearchOutlined, PlusOutlined, PlusCircleOutlined, InfoCircleFilled } from '@ant-design/icons-vue';
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from "vue";
import _ from "underscore";
import { message, Modal } from "ant-design-vue";
const columns = [
  { title: '缩略图', dataIndex: 'main_image', width: 100, },
  { title: 'SKU编号 / 来源 / 标题', dataIndex: 'item_name', width: 600, },
  // { title: '描述', dataIndex: 'product_details', width: 200, },
  { title: '属性', dataIndex: 'variation', width: 80, },
  { title: '成本/重量(g)/体积', dataIndex: 'standard_price', width: 150, },
  { title: '创建人/时间', dataIndex: 'create_time', width: 150, },
  { title: '操作', dataIndex: 'operation', width: 150, align: 'center', fixed: 'right' },
];
const pcolumns = [
  { title: '缩略图', dataIndex: 'main_image', width: 80, },
  { title: '源数据信息', dataIndex: 'info', width: 400, },
  { title: '变种属性映射', dataIndex: 'variation', width: 200, },
]
export default defineComponent({
  name: "ShopsPublishProduct",
  components: { LoadingOutlined, DownOutlined, LinkOutlined, CopyOutlined, UploadOutlined, SearchOutlined, PlusOutlined, PlusCircleOutlined, InfoCircleFilled },
  setup() {
    const { proxy } = getCurrentInstance();
    const publishFormRef = ref();
    const env_DEV = ref(process.env.DEV);
    const state = reactive({
      tab: "1",
      queryForm: {
        searchtext: '',
        platform: 'ALL'
      },
      platforms: [
        { text: '亚马逊', value: 'amz' },
        { text: '1688', value: '1688' },
      ],
      page: 1,
      limit: 10,
      length: 0,
      count: 0,
      dataLoading: false,
      data: [],
      selectedRowKeys: [],
      publishModal: false,
      publishForm: { account_username: undefined, regions: [], list: [] },
      shopaccounts: [],
      publishing: false,
      downloadModal: false,
      downloadState: "",
    });
    const width = computed(() => proxy.$utils.twidth(columns));
    const pwidth = computed(() => proxy.$utils.twidth(pcolumns));
    const shop_regions = computed(() => {
      return _.findWhere(state.shopaccounts, { value: state.publishForm.account_username })?.regions || [];
    });
    watch(() => state.page, () => getData());
    watch(() => state.limit, () => {
      if (state.page !== 1) state.page = 1;
      else getData();
    });
    onActivated(() => {
      getData();
    })
    const setPlatform = (platform) => {
      state.queryForm.platform = platform;
      getData();
    }
    const validateShopRegion = async (_rule, value) => {
      if (!value || (_.isArray(value) && value.length > 0)) {
        return Promise.resolve();
      } else {
        return Promise.reject('请选勾选至少一个站点');
      }
    }
    const getData = () => {
      state.dataLoading = true;
      proxy.$api.doAPI("/listingcollect/list", { page: state.page, limit: state.limit, tab: state.tab, ...state.queryForm, }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.data = _.map(res.data.list, n => {
            return {
              ...n,
              _product_details: _.reduce(n.product_details || [], (a, b) => a + ` ${b.label}:${b.value}`, ''),
              create_time: proxy.$utils.dateFormat(n.create_time, 'YYYY-MM-DD HH:mm:ss'),
            }
          });
          state.length = res.data.length;
          state.count = res.data.count;
          if (state.length > 0 && state.page > state.length) state.page = 1;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    }
    const publish = (ids) => {
      state.dataLoading = true;
      proxy.$api.doAPI("/listingcollect/publishform", { ids }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.publishForm = res.data.form;
          state.shopaccounts = res.data.shopaccounts;
          state.publishModal = true;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    }
    const edit = (id) => {
      proxy.$utils.downloadBrowser({ href: `/shops/published/gatherform?id=${id}` });
    }
    const confirmPublish = () => {
      state.publishing = true;
      let regions = _.pluck(_.findWhere(state.shopaccounts, { value: state.publishForm.account_username }).regions, "value");
      let region_names = _.pluck(_.findWhere(state.shopaccounts, { value: state.publishForm.account_username }).regions, "label");
      let publishForm = {
        ...state.publishForm,
        region_names: _.map(state.publishForm.regions, region => {
          let region_index = regions.indexOf(region);
          return region_names[region_index];
        })
      }
      proxy.$api.doAPI("/listingcollect/confirmpublish", { publishForm }).then((res) => {
        state.publishing = false;
        if (res.code === 0) {
          state.publishModal = false;
          message.success("产品认领成功");
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.publishing = false;
        console.error(err);
      });
    }
    const downloadImages = (ids) => {
      state.downloadModal = true;
      state.downloadState = "process";
      proxy.$api.doAPI("/listingcollect/downloadimages", { ids }).then((res) => {
        if (res.code === 0) {
          proxy.$utils.downloadImages(res.data.list).then(() => {
            state.downloadState = "success";
          });
        } else {
          message.error(res.msg);
          state.downloadState = "fail";
        }
      }).catch((err) => {
        console.error(err);
        state.downloadState = "fail";
      });
    }
    const disableListings = (ids) => {
      state.dataLoading = true;
      proxy.$api.doAPI("/listingcollect/disablelistings", { ids }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          getData();
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    }
    const bulkPublish = () => {
      if (state.selectedRowKeys.length === 0) return message.error("请先勾选您要认领上架的产品");
      publish(state.selectedRowKeys);
    }
    const bulkDownloadImages = () => {
      if (state.selectedRowKeys.length === 0) return message.error("请先勾选您要下载的产品");
      downloadImages(state.selectedRowKeys);
    }
    const bulkDisableListings = () => {
      if (state.selectedRowKeys.length === 0) return message.error("请先勾选您要作废的产品");
      disableListings(state.selectedRowKeys);
    }
    const handleMenuClick = (key, id) => {
      console.log('handleMenuClick', key, id);
      switch (key) {
        case "downloadImages": //下载图片
          downloadImages([id]);
          break;
        case "disable": //作废
          Modal.confirm({
            title: '提示',
            content: `确认要作废该产品吗？`,
            onOk() {
              disableListings([id])
            },
            onCancel() {
            },
          })
          break;
      }
    }
    const getplatformname = (platform) => {
      return _.findWhere(state.supportsites, { id: platform })?.title;
    }
    return {
      ...toRefs(state),
      env_DEV, publishFormRef,
      columns, pcolumns,
      width, pwidth,
      shop_regions,
      setPlatform, validateShopRegion,
      getData, publish, edit, confirmPublish, downloadImages, disableListings,
      bulkPublish, bulkDownloadImages, bulkDisableListings,
      handleMenuClick,
      getplatformname,
    }
  }
});
</script>
<style lang="less" scoped>
p {
  margin: 0;
  padding: 0;
}

.loader-bar {
  width: 300px;
  height: 20px;
  margin: 0 auto;
  background: linear-gradient(-45deg, #4183d7 25%, #52b3d9 0, #52b3d9 50%, #4183d7 0, #4183d7 75%, #52b3d9 0, #52b3d9);
  background-size: 20px 20px;
  box-shadow: inset 0 10px 0 hsla(0, 0%, 100%, .2), 0 0 0 5px rgba(0, 0, 0, .2);
  border-radius: 15px;
  animation: moveBar 1.5s linear infinite;
}

@keyframes moveBar {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 20px 20px;
  }
}
</style>
