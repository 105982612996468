<template>
  <a-card size="small">
    <template #title>
      <a-row :gutter="16">
        <a-col :span="4">
          <a-form-item label="站点" style="margin-bottom:unset;">
            <a-select v-model:value="searchForm.region" :options="sellingcenters2" :disabled="loading"
              style="width: 100%;" />
          </a-form-item>
        </a-col>
        <a-col :span="20">
          <a-form-item label="ASIN" style="margin-bottom:unset;">
            <a-input-search v-model:value="searchForm.asin" placeholder="ASIN" enter-button="搜索" @search="getData"
              :loading="loading" :disabled="loading" />
          </a-form-item>
        </a-col>
      </a-row>
    </template>
    <a-table size="small" bordered :columns="columns" :data-source="list" :loading="loading" :pagination="false">
      <template #bodyCell="{ record, column }">
        <template v-if="column.dataIndex === 'image_url'">
          <a-tooltip v-if="record.image_url" color="white" placement="rightBottom">
            <template #title>
              <a-image :src="record.image_url" width="480px" height="480px" style="object-fit: contain;height:100%;"
                :preview="false">
                <template #placeholder>
                  <a-image :src="$filters.image_placeholder" width="480px" height="480px" :preview="false" />
                </template>
              </a-image>
            </template>
            <a href="javascript:;">
              <a-image :src="record.image_url" width="80px" height="80px" style="object-fit: contain;height:100%;"
                :preview="false">
                <template #placeholder>
                  <a-image :src="$filters.image_placeholder" width="80px" height="80px" :preview="false" />
                </template>
              </a-image>
            </a>
          </a-tooltip>
          <a-image v-else :src="$filters.image_placeholder" width="80px" height="80px"
            style="object-fit: contain;height:100%;" :preview="false">
            <template #placeholder>
              <a-image :src="$filters.image_placeholder" width="80px" height="80px" :preview="false" />
            </template>
          </a-image>
        </template>
        <template v-if="column.dataIndex === 'product_type'">
          <p>{{ record.product_type }}</p>
          <p>{{ record.productType }}</p>
        </template>
        <template v-if="column.dataIndex === 'asin'">
          <a-tooltip color="white">
            <template #title>
              <a href="javascript:;" @click="$filters.doCopy(record.asin)">复制</a>
            </template>
            <a :href="record.amazon_product_url" target="_blank">
              <span :class="`fi fi-${record.region.toLowerCase()}`"></span> {{ record.asin }}
            </a>
          </a-tooltip>
        </template>
        <template v-if="column.dataIndex === 'price'">
          <span>{{ record.price }} {{ record.currency }}</span>
        </template>
        <template v-if="column.dataIndex === 'tasks'">
          <a-row v-if="record.tasks.length > 0" :gutter="16">
            <template v-for="(item, index) in record.tasks" :key="index">
              <a-col :span="8"> {{ item.shop_name }} </a-col>
              <a-col :span="6"> {{ item.price }}({{ record.currency }}) </a-col>
              <a-col :span="4"> {{ item.quantity }} </a-col>
              <a-col :span="6">
                <a-tag v-if="item.state === 'waiting'" color="warning"> 排队中 </a-tag>
                <a-tag v-if="item.state === 'processing'" color="processing"> 发布中 </a-tag>
                <a-tag v-if="item.state === 'success'" color="success"> 已发布 </a-tag>
                <a-tag v-if="item.state === 'error'" color="error"> 发布失败 </a-tag>
                <question-circle-outlined v-if="item.error_list?.length > 0" class="text-error font-weight-bold link"
                  @click="showError(item._id)" />
              </a-col>
            </template>
          </a-row>
          <a-tag v-else color="error">未发布</a-tag>
        </template>
        <template v-if="column.dataIndex === 'operation'">
          <a-space>
            <a href="javascript:;" class="text-error" @click="showPublish(record._id)"> 发布 </a>
            <a-popconfirm title="从跟卖库中删除?" @confirm="remove(record._id)">
              <a href="javascript:;" class="text-error"> 删除 </a>
            </a-popconfirm>
          </a-space>
        </template>
      </template>
    </a-table>
  </a-card>
  <a-modal v-model:visible="publishModal" title="发布商品" :maskClosable="false" :closable="!publishing">
    <a-form ref="publishFormRef" name="publishForm" :model="publishForm" :label-col="{ span: 6 }"
      :wrapper-col="{ span: 14 }" autocomplete="off">
      <a-form-item label="店铺" name="shopids" :rules="[{ required: true, message: '请选择店铺' }]">
        <div class="d-flex flex-column">
          <a-checkbox v-model:checked="publishForm.checkAll" :disabled="publishing" @change="onCheckAllChange">
            全选
          </a-checkbox>
          <a-checkbox-group v-model:value="publishForm.shopids" :options="shops" :disabled="publishing"
            @change="onCheckChange" />
        </div>
      </a-form-item>
      <a-form-item label="价格" name="price" :rules="[{ required: true, message: '请输入价格' }]">
        <a-input-number v-model:value="publishForm.price" :addon-after="publishForm.currency"
          style="width:100%;"></a-input-number>
      </a-form-item>
      <a-form-item label="数量" name="quantity" :rules="[{ required: true, message: '请输入数量' }]">
        <a-input-number v-model:value="publishForm.quantity" addon-after="库存" style="width:100%;"></a-input-number>
      </a-form-item>
      <!-- <a-form-item label="SKU" name="sku">
        <a-input v-model:value="publishForm.sku" placeholder="留空则系统自动生成" style="width:100%;"></a-input>
      </a-form-item> -->
    </a-form>
    <template #footer>
      <div class="d-flex justify-end align-center">
        <a-checkbox v-model:checked="publishForm.immediately_publish" :disabled="publishing">
          <span style="color:#3f56a1;font-weight:700;">添加任务成功后立即发布</span>
        </a-checkbox>
        <a-button @click="confirmPublish" :type="publishForm.immediately_publish ? 'danger' : 'primary'"
          :loading="publishing">
          添加任务
        </a-button>
        <a-button @click="() => publishModal = false" :disabled="publishing">
          取消
        </a-button>
      </div>
    </template>
  </a-modal>
  <a-modal v-model:visible="errorModal" title="异常" :footer="null">
    <a-alert v-if="error_msg" :message="error_msg" type="error" />
    <a-table size="small" bordered :columns="ecolumns" :data-source="error_list" :pagination="false">
      <template #bodyCell="{ record, column }">
        <template v-if="column.dataIndex === 'message'">
          <p>{{ record.message }}</p>
          <p v-if="record.desc" class="text-error">{{ record.desc }}</p>
        </template>
      </template>
    </a-table>
  </a-modal>
</template>

<script setup>
import _ from 'underscore';
import { ref, reactive, computed, getCurrentInstance, onActivated, watch } from 'vue';
import { PlusOutlined, MinusOutlined, QuestionCircleOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';

const columns = ref([
  { title: '图片', dataIndex: 'image_url', width: 80, },
  { title: '标题', dataIndex: 'title', width: 200, },
  { title: '类目', dataIndex: 'product_type', width: 100, },
  { title: 'ASIN', dataIndex: 'asin', width: 100, },
  { title: '价格', dataIndex: 'price', width: 100, },
  { title: '状态', dataIndex: 'tasks', width: 240, },
  { title: '操作', dataIndex: 'operation', width: 100, },
]);
const ecolumns = ref([
  { title: 'code', dataIndex: 'code', width: 100, },
  { title: 'message', dataIndex: 'message' },
]);
const { proxy } = getCurrentInstance();
const publishFormRef = ref(null);

const loading = ref(false);
const sellingcenters = ref([]);
const searchForm = ref({ region: "ALL", asin: "", });
const page = ref(1);
const limit = ref(10);
const length = ref(0);
const count = ref(0);
const list = ref([]);
const shops = ref([]);
const publishForm = ref({});
const publishModal = ref(false);
const publishing = ref(false);
const errorModal = ref(false);
const error_msg = ref("");
const error_list = ref([]);

const sellingcenters2 = computed(() => [{ value: "ALL", label: "全部" }, ...sellingcenters.value])

watch(() => page.value, () => {
  getData();
});
watch(() => limit.value, () => {
  if (page.value !== 1) page.value = 1;
  else getData();
});
const init = () => {
  loading.value = true;
  proxy.$api.doAPI2("/dev/amazon/genmai/init").then((res) => {
    loading.value = false;
    if (res.code === 0) {
      sellingcenters.value = res.data.sellingcenters;
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    loading.value = false;
    console.error(err);
  });
}
const getData = () => {
  loading.value = true;
  proxy.$api.doAPI2("/dev/amazon/genmai/list", { page: page.value, limit: limit.value, ...searchForm.value }).then((res) => {
    loading.value = false;
    if (res.code === 0) {
      list.value = res.data.list;
      length.value = res.data.length;
      count.value = res.data.count;
      if (length.value > 0 && page.value > length.value) page.value = 1;
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    loading.value = false;
    console.error(err);
  });
}
const remove = (_id) => {
  loading.value = true;
  proxy.$api.doAPI2("/dev/amazon/genmai/remove", { _id }).then((res) => {
    loading.value = false;
    if (res.code === 0) {
      getData();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    loading.value = false;
    console.error(err);
  });
}
const showPublish = (_id) => {
  loading.value = true;
  proxy.$api.doAPI2("/dev/amazon/genmai/prepublish", { _id }).then((res) => {
    loading.value = false;
    if (res.code === 0) {
      shops.value = res.data.shops;
      publishForm.value = res.data.publishForm;
      publishModal.value = true;
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    loading.value = false;
    console.error(err);
  });
}
const confirmPublish = async () => {
  try {
    await publishFormRef.value.validateFields();
    publishing.value = true;
    proxy.$api.doAPI2("/dev/amazon/genmai/confirmpublish", { dataForm: publishForm.value }).then((res) => {
      publishing.value = false;
      if (res.code === 0) {
        getData();
        publishModal.value = false;
      } else {
        message.error(res.msg);
      }
    }).catch((err) => {
      publishing.value = false;
      console.error(err);
    });
  } catch (errorInfo) {
    console.log("Failed:", errorInfo);
  }
}
const showError = (_id) => {
  loading.value = true;
  proxy.$api.doAPI2("/dev/amazon/genmai/error", { _id }).then((res) => {
    loading.value = false;
    if (res.code === 0) {
      error_msg.value = res.data.error_msg;
      error_list.value = res.data.error_list;
      errorModal.value = true;
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    loading.value = false;
    console.error(err);
  });
}
const onCheckAllChange = () => {
  if (publishForm.value.checkAll) {
    publishForm.value.shopids = _.pluck(shops.value, "value");
  } else {
    publishForm.value.shopids = [];
  }
}
const onCheckChange = () => {
  publishForm.value.checkAll = publishForm.value.shopids.length === _.pluck(shops.value, "value").length;
}
defineExpose({ init, getData });
onActivated(() => {
  init();
  getData();
})
</script>

<style lang="less" scoped>
@import "/node_modules/flag-icons/css/flag-icons.min.css";

p {
  margin: 0;
  padding: 0;
}
</style>