<template>
  <div :style="{ 'display': 'inline', ...styleValue }" :title="displayTitle">
    <svg :class="svgClass" :style="styleValue" aria-hidden="true">
      <use :xlink:href="iconName" />
    </svg>
  </div>
</template>
<script>
export default {
  name: 'SvgIcon',
  props: {
    iconClass: {
      type: String,
      required: true
    },
    className: {
      type: String,
      default: ''
    },
    style: {
      type: Object,
      default: () => {
        return {};
      },
    },
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    iconName() {
      return `#icon-${this.iconClass}`
    },
    svgClass() {
      if (this.className) {
        return 'svg-icon ' + this.className
      } else {
        return 'svg-icon'
      }
    },
    styleValue() {
      // console.log('styleValue', this.style);
      return this.style;
    },
    displayTitle() {
      return this.title;
    },
  }
}
</script>
<style scoped>
.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>