<template>
  <a-tabs v-model:activeKey="activeTab" type="card" @change="changeTab">
    <a-tab-pane key="1" tab="MSKU">
      <div class="header">
        <a-space>
          <a-select style="width: 180px;" v-model:value="shopid" placeholder="请选择店铺" mode="tags"
            :options="shops"></a-select>
          <a-range-picker v-model:value="daterange" value-format="YYYY-MM-DD" />
          <a-input v-model:value="sku" placeholder="msku/asin/sku查询"></a-input>
          <a-button type="primary" @click="getData">查询</a-button>
        </a-space>
      </div>
      <div class="content">
        <a-table :columns="columns" bordered :data-source="data" :loading="dataLoading" :scroll="{ x: width, y: 600 }"
          size="small">
          <template #title>
            <div class="theader">
              <span class="title">MSKU</span>
            </div>
          </template>
          <template #bodyCell="{ record, column, index }">
            <template v-if="column.dataIndex === 'index'">
              <p>{{ index + 1 }}</p>
            </template>
            <div v-if="column.dataIndex === 'cover'" class="text-center" style="width:80px;">
              <a-tooltip v-if="record.product?.cover || ''" placement="rightBottom" color="#fff"
                :overlayStyle="{ width: '500px', height: '500px' }">
                <template #title>
                  <img :src="record.product?.cover || ''" style="width:500px;height:500px;object-fit: contain;" />
                </template>
                <div class="image-header">
                  <img :src="record.product?.cover || ''" style="object-fit: contain;" />
                </div>
              </a-tooltip>
              <div v-else class="image-header">
                <img :src="$filters.image_placeholder" style="object-fit: contain;" />
              </div>
            </div>
            <template v-if="column.dataIndex === 'shop'">
              {{ getShop(record.shopid) }}
            </template>
            <template v-if="column.dataIndex === 'product'">
              <div class="d-flex flex-column">
                <a-tooltip color="white" placement="topLeft">
                  <template #title>
                    <a href="javascript:;" @click="$filters.doCopy(record.product?.sku)">复制</a>
                  </template>
                  <a href="javascript:;">{{ record.product?.sku }}</a>
                </a-tooltip>
                <a-tooltip placement="topLeft">
                  <template #title>{{ record.product?.name_cn }} </template>
                  <p class="in1line">{{ record.product?.name_cn }}</p>
                </a-tooltip>
              </div>
            </template>
          </template>
        </a-table>
      </div>
    </a-tab-pane>
    <a-tab-pane key="2" tab="ASIN">
      <div class="header">
        <a-space>
          <a-range-picker v-model:value="daterange" value-format="YYYY-MM-DD" />
          <a-button type="primary" @click="getData">查询</a-button>
        </a-space>
      </div>
      <div class="content">
        <a-table :columns="columns1" bordered :data-source="data" :loading="dataLoading" :scroll="{ x: width1, y: 600 }"
          size="small">
          <template #title>
            <div class="theader">
              <span class="title">ASIN</span>
            </div>
          </template>
          <template #bodyCell="{ record, column, index }">
            <template v-if="column.dataIndex === 'index'">
              <p>{{ index + 1 }}</p>
            </template>
            <div v-if="column.dataIndex === 'cover'" class="text-center" style="width:80px;">
              <a-tooltip v-if="record.product?.cover || ''" placement="rightBottom" color="#fff"
                :overlayStyle="{ width: '500px', height: '500px' }">
                <template #title>
                  <img :src="record.product?.cover || ''" style="width:500px;height:500px;object-fit: contain;" />
                </template>
                <div class="image-header">
                  <img :src="record.product?.cover || ''" style="object-fit: contain;" />
                </div>
              </a-tooltip>
              <div v-else class="image-header">
                <img :src="$filters.image_placeholder" style="object-fit: contain;" />
              </div>
            </div>
            <template v-if="column.dataIndex === 'shop'">
              {{ getShop(record.shopid) }}
            </template>
            <template v-if="column.dataIndex === 'product'">
              <div class="d-flex flex-column">
                <a-tooltip color="white" placement="topLeft">
                  <template #title>
                    <a href="javascript:;" @click="$filters.doCopy(record.product?.sku)">复制</a>
                  </template>
                  <a href="javascript:;">{{ record.product?.sku }}</a>
                </a-tooltip>
                <a-tooltip placement="topLeft">
                  <template #title>{{ record.product?.name_cn }} </template>
                  <p class="in1line">{{ record.product?.name_cn }}</p>
                </a-tooltip>
              </div>
            </template>
          </template>
        </a-table>
      </div>
    </a-tab-pane>
    <a-tab-pane key="3" tab="店铺">
      <div class="header">
        <a-space>
          <a-range-picker v-model:value="daterange" value-format="YYYY-MM-DD" />
          <a-button type="primary" @click="recedisreport">查询</a-button>
        </a-space>
      </div>
      <div class="content">
        <a-table :columns="columns2" :data-source="data1" bordered :scroll="{ x: width2 }" :loading="dataLoading"
          size="small">
          <template #title>
            <div class="theader">
              <span class="title">店铺</span>
            </div>
          </template>
          <template #bodyCell="{ column, record, index, }">
            <template v-if="column.dataIndex === 'index'">
              <p>{{ index + 1 }}</p>
            </template>
            <template v-if="column.dataIndex === 'shop'">
              {{ getShop(record.shopid) }}
            </template>
            <template v-if="column.dataIndex === 'order_count'">
              {{ record.order_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'product_count'">
              {{ record.product_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'platform_sku_count'">
              {{ record.platform_sku_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'order_amount'">
              {{ record.order_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'other_amount'">
              {{ record.other_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'shouru_sum'">
              {{ record.shouru_sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'product_cost'">
              {{ record.product_cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'freight'">
              {{ record.freight.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'platform_fee'">
              {{ record.platform_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'fba_fee'">
              {{ record.fba_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'other_fee'">
              {{ record.other_fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'zhichu_sum'">
              {{ record.zhichu_sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'refund_amount'">
              {{ record.refund_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
            <template v-if="column.dataIndex === 'maoli'">
              {{ record.maoli.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
            </template>
          </template>
        </a-table>
      </div>
    </a-tab-pane>
  </a-tabs>
</template>
<script>
import { DownOutlined } from '@ant-design/icons-vue';
import { message, Modal } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from 'vue';
import _ from "underscore";
const columns = [
  { title: '序号', dataIndex: 'index', width: 70, align: 'center' },
  { title: '图片', dataIndex: 'cover', width: 100, align: 'center' },
  { title: 'MSKU', dataIndex: 'seller_sku', key: 'seller_sku', width: 150, align: 'center' },
  { title: 'ASIN', dataIndex: 'asin', key: 'asin', width: 150, align: 'center' },
  { title: '店铺', dataIndex: 'shop', key: 'shop', width: 200, align: 'center' },
  { title: 'SKU/品名', dataIndex: 'product', key: 'product', width: 150, },
  { title: '销量', dataIndex: 'product_count', key: 'product_count', width: 80, align: 'center' },
  {
    title: '收入(元)',
    children: [
      { title: '销售额', dataIndex: 'order_amount', key: 'order_amount', width: 80, align: 'center' },
      { title: '其他收入', dataIndex: 'other_amount', key: 'other_amount', width: 80, align: 'center' },
      { title: '小计', dataIndex: 'shouru_sum', key: 'shouru_sum', width: 80, align: 'center' }
    ]
  },
  {
    title: '支出(元)',
    children: [
      { title: '商品成本', dataIndex: 'product_cost', key: 'product_cost', width: 80, align: 'center' },
      // { title: '运费', dataIndex: 'freight', key: 'freight', width: 100, align: 'center' },
      { title: '平台费', dataIndex: 'platform_fee', key: 'platform_fee', width: 80, align: 'center' },
      { title: '其他费用', dataIndex: 'other_fee', key: 'other_fee', width: 80, align: 'center' },
      { title: '小计', dataIndex: 'zhichu_sum', key: 'zhichu_sum', width: 80, align: 'center' }
    ]
  },
  { title: '退款金额(元)', dataIndex: 'refund_amount', width: 80, align: 'center' },
  { title: '退款率%', dataIndex: 'refund_rate', width: 80, align: 'center' },
  { title: '毛利(元)', dataIndex: 'maoli', width: 80, align: 'center' },
  { title: '毛利率%', dataIndex: 'maoli_rate', width: 80, align: 'center' },

];
const columns1 = [
  { title: '序号', dataIndex: 'index', width: 70, align: 'center' },
  { title: '图片', dataIndex: 'cover', width: 100, align: 'center' },
  { title: 'ASIN', dataIndex: 'asin', key: 'asin', width: 150, align: 'center' },
  // { title: '父ASIN', dataIndex: 'asin', key: 'asin', width: 150, align: 'center' },
  { title: '店铺', dataIndex: 'shop', key: 'shop', width: 200, align: 'center' },
  { title: '品名/SKU', dataIndex: 'product', key: 'product', width: 150, },
  { title: '销量', dataIndex: 'product_count', key: 'product_count', width: 100, align: 'center' },
  {
    title: '收入(元)',
    children: [
      { title: '销售额', dataIndex: 'order_amount', key: 'order_amount', width: 80, align: 'center' },
      { title: '其他收入', dataIndex: 'other_amount', key: 'other_amount', width: 80, align: 'center' },
      { title: '小计', dataIndex: 'shouru_sum', key: 'shouru_sum', width: 80, align: 'center' }
    ]
  },
  {
    title: '支出(元)',
    children: [
      { title: '商品成本', dataIndex: 'product_cost', key: 'product_cost', width: 80, align: 'center' },
      // { title: '运费', dataIndex: 'freight', key: 'freight', width: 100, align: 'center' },
      { title: '平台费', dataIndex: 'platform_fee', key: 'platform_fee', width: 80, align: 'center' },
      { title: '其他费用', dataIndex: 'other_fee', key: 'other_fee', width: 80, align: 'center' },
      { title: '小计', dataIndex: 'zhichu_sum', key: 'zhichu_sum', width: 80, align: 'center' }
    ]
  },
  { title: '退款金额(元)', dataIndex: 'refund_amount', width: 80, align: 'center' },
  { title: '退款率%', dataIndex: 'refund_rate', width: 80, align: 'center' },
  { title: '毛利(元)', dataIndex: 'maoli', width: 80, align: 'center' },
  { title: '毛利率%', dataIndex: 'maoli_rate', width: 80, align: 'center' },
];
const columns2 = [
  { title: '序号', dataIndex: 'index', width: 70, align: 'center' },
  { title: '店铺', dataIndex: 'shop', key: 'shop', width: 200, align: 'center' },
  { title: '订单量', dataIndex: 'order_count', key: 'order_count', width: 80, align: 'center' },
  { title: '商品量', dataIndex: 'product_count', key: 'product_count', width: 80, align: 'center' },
  {
    title: '收入(元)',
    children: [
      { title: '销售额', dataIndex: 'order_amount', key: 'order_amount', width: 100, align: 'center' },
      { title: '其他收入', dataIndex: 'other_amount', key: 'other_amount', width: 80, align: 'center' },
      { title: '小计', dataIndex: 'shouru_sum', key: 'shouru_sum', width: 100, align: 'center' }
    ]
  },
  {
    title: '支出(元)',
    children: [
      { title: '商品成本', dataIndex: 'product_cost', key: 'product_cost', width: 100, align: 'center' },
      { title: '运费', dataIndex: 'freight', key: 'freight', width: 100, align: 'center' },
      { title: '平台费', dataIndex: 'platform_fee', key: 'platform_fee', width: 100, align: 'center' },
      { title: '其他费用', dataIndex: 'other_fee', key: 'other_fee', width: 120, align: 'center' },
      { title: '小计', dataIndex: 'zhichu_sum', key: 'zhichu_sum', width: 100, align: 'center' }
    ]
  },
  { title: '退款金额(元)', dataIndex: 'refund_amount', width: 100, align: 'center' },
  { title: '退款率%', dataIndex: 'refund_rate', width: 100, align: 'center' },
  { title: '毛利(元)', dataIndex: 'maoli', width: 100, align: 'center' },
  { title: '毛利率%', dataIndex: 'maoli_rate', width: 100, align: 'center' },
];
export default defineComponent({
  name: 'FinanceProfit',
  components: { DownOutlined },
  setup() {
    const { proxy } = getCurrentInstance();
    const state = reactive({
      activeTab: "1",
      data: [],
      data1: [],
      daterange: [],
      dataLoading: false,
      shops: [],
      shopid: undefined,
      sku: "",
    });
    const width = computed(() => proxy.$utils.twidth(columns));
    const width1 = computed(() => proxy.$utils.twidth(columns1));
    const width2 = computed(() => proxy.$utils.twidth(columns2));
    onMounted(() => {
      changeTab();
    });
    const changeTab = () => {
      let beforeDay = proxy.$utils.moment().subtract(0, "days").format('YYYY-MM-DD');
      let before7Day = proxy.$utils.moment().subtract(6, "days").format('YYYY-MM-DD');
      state.daterange = [];
      state.daterange.push(before7Day);
      state.daterange.push(beforeDay);
      if (state.activeTab === '3') {
        recedisreport();
      } else {
        getData();
      }
    };
    const getData = () => {
      state.dataLoading = true;
      proxy.$api.doAPI("/finance/financereport/caiwureport", { activeTab: state.activeTab, shopid: state.shopid, sku: state.sku, daterange: state.daterange }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.data = res.data.list;
          state.shops = res.data.shops;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    };
    const recedisreport = () => {
      state.dataLoading = true;
      proxy.$api.doAPI("/finance/salesreport/recedisreport", { activeTab: "2", daterange: state.daterange }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.data1 = res.data.list;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    };
    const getShop = (value) => {
      return _.findWhere(state.shops, { value })?.label;
    };
    return {
      ...toRefs(state),
      columns,
      columns1,
      columns2,
      width,
      width1,
      width2,
      changeTab,
      getShop,
      getData,
      recedisreport,
    }
  }
});
</script>
<style lang="less" scoped>
:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

.header {
  margin-bottom: 10px;
}

.theader {
  display: flex;
  justify-content: space-between;

  .title {
    font-weight: 600;
  }
}

a {
  text-decoration: underline;
}

p {
  margin: 0;
  padding: 0;
}
</style>