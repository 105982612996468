<template>
  <div class="d-flex justify-space-between align-center mb-2">
    <a-button type="primary" @click="getData" :loading="dataLoading">刷新数据</a-button>
    <a-button type="primary" @click="addRole" :loading="loading">
      <template #icon>
        <PlusCircleOutlined />
      </template>新增角色
    </a-button>
  </div>
  <a-table :columns="columns" :data-source="data" :loading="dataLoading" size="small">
    <template #bodyCell="{ record, column, index }">
      <template v-if="column.dataIndex === 'index'">
        {{ index + 1 }}
      </template>
      <template v-if="column.dataIndex === 'permissions'">
        <div class="d-flex flex-column">
          <span v-for="p in record.permissions" :key="p._id">
            {{ p.title }}（{{ p.list.map(n => n.title).join('，') }}）
          </span>
        </div>
      </template>
      <template v-if="column.dataIndex === 'operation'">
        <a-space>
          <a href="javascript:;" @click="editRole(record)">编辑</a>
        </a-space>
      </template>
    </template>
  </a-table>
  <a-modal v-model:visible="roleModal" :title="`${roleForm._id !== '' ? '编辑' : '新增'}角色`" @ok="saveRole" ok-text="确认"
    cancel-text="取消" :confirm-loading="saving" :cancel-button-props="{ disabled: saving }" :maskClosable="false"
    :closable="!saving" width="600px">
    <a-form ref="roleRef" name="roleForm" :model="roleForm" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }"
      autocomplete="off">
      <a-form-item label="角色名" name="name" :rules="[{ required: true, message: '请输入角色名称' }]">
        <a-input v-model:value="roleForm.name" placeholder="角色名称" :disabled="saving" />
      </a-form-item>
      <a-form-item label="权限" name="permissions" :rules="[{ required: true, message: '请选择权限' }]">
        <a-checkbox-group v-model:value="roleForm.permissions" style="width:100%;max-height:60vh;overflow-y:scroll;">
          <a-card :title="item.label" v-for="item in permissions" :key="item.label"
            :headStyle="{ lineHeight: 0.5, minHeight: 0, paddingLeft: '12px', paddingRight: '12px' }"
            :bodyStyle="{ padding: '12px' }">
            <template #title>
              <span>
                {{ item.label }}
                ({{ selectedCount(item.options) }}/{{ item.options.length }})
              </span>
            </template>
            <template #extra>
              <div>
                <a href="javascript:;" @click.stop="selectAll(item.options)" class="ml-1">全选</a>
                <a href="javascript:;" @click.stop="clearAll(item.options)" class="ml-1">全不选</a>
              </div>
            </template>
            <a-checkbox v-for="option in item.options" :key="option.value" :value="option.value">
              {{ option.label }}
            </a-checkbox>
          </a-card>
        </a-checkbox-group>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script setup>
import { DownOutlined, SearchOutlined, PlusCircleOutlined } from '@ant-design/icons-vue';
import { message } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from "vue";
import _ from "underscore";
const columns = [
  { title: '序号', dataIndex: 'index', width: 80, },
  { title: '角色名', dataIndex: 'name', width: 120, },
  { title: '开通权限', dataIndex: 'permissions', },
  { title: '创建时间', dataIndex: 'create_time', width: 120, },
  { title: '操作', dataIndex: 'operation', width: 120, fixed: 'right' },
];
const { proxy } = getCurrentInstance();
const roleRef = ref();
const data = ref([]);
const dataLoading = ref(false);
const saving = ref(false);
const roleForm = ref({});
const roleModal = ref(false);
const loading = ref(false);
const permissions = ref([]);

onActivated(() => {
  getData();
});
const getData = () => {
  dataLoading.value = true;
  proxy.$api.doAPI("/account/rolelist").then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      data.value = _.map(res.data.list, n => {
        return {
          ...n,
          create_time: proxy.$utils.dateFormat(n.create_time, 'YYYY-MM-DD HH:mm:ss'),
        };
      });
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
};
const addRole = () => {
  getRole();
}
const editRole = (item) => {
  getRole(item._id);
}
const getRole = (_id = "") => {
  loading.value = true;
  proxy.$api.doAPI("/account/getrole", { _id }).then((res) => {
    loading.value = false;
    if (res.code === 0) {
      roleForm.value = res.data.roleForm;
      permissions.value = res.data.permissions;
      roleModal.value = true;
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    loading.value = false;
    console.error(err);
  });
}
const saveRole = async () => {
  try {
    await roleRef.value.validateFields();
    saving.value = true;
    proxy.$api.doAPI("/account/saverole", { dataForm: roleForm.value, }).then((res) => {
      saving.value = false;
      if (res.code === 0) {
        message.success("保存成功");
        roleModal.value = false;
        getData();
      } else {
        message.error(res.msg);
      }
    }).catch((err) => {
      saving.value = false;
      console.error(err);
    });
  } catch (errorInfo) {
    console.log("Failed:", errorInfo);
  }
}
const selectAll = (options) => {
  let values = _.pluck(options, "value");
  console.log('selectAll', values);
  roleForm.value.permissions = _.uniq([
    ...roleForm.value.permissions,
    ...values,
  ]);
}
const clearAll = (options) => {
  let values = _.pluck(options, "value");
  console.log('clearAll', values);
  roleForm.value.permissions = _.filter(roleForm.value.permissions, n => values.indexOf(n) === -1);
}
const selectedCount = (options) => {
  let values = _.pluck(options, "value");
  return _.filter(values, n => roleForm.value.permissions.indexOf(n) > -1).length;
}
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

:deep(.ant-checkbox-wrapper + .ant-checkbox-wrapper) {
  margin-left: 0 !important;
}

:deep(.ant-collapse-content > .ant-collapse-content-box) {
  padding: 8px !important;
}

p {
  margin: 0;
  padding: 0;
}
</style>
