<template>
  <template v-if="convertType">
    <a-page-header style="border: 1px solid rgb(235, 237, 240)" :title="title" @back="changeConvertType(null)">
    </a-page-header>
    <a-row>
      <a-col :xxl="6" :xl="6" :lg="6" :md="24" :sm="24" :xs="24">
        <a-button @click="() => number--">
          <template #icon>
            <MinusOutlined />
          </template>
        </a-button>
        <a-input-number v-model:value="number" style="width:25%;max-width:100px;" />
        <a-button @click="() => number++">
          <template #icon>
            <PlusOutlined />
          </template>
        </a-button>
        <a-select v-model:value="from" style="width:25%;max-width:100px;">
          <a-select-option v-for="(item, index) in units[convertType]" :key="index" :value="item.unit">
            {{ item.unit }}
          </a-select-option>
        </a-select>
        <span> => </span>
        <a-select v-model:value="to" style="width:25%;max-width:100px;">
          <a-select-option value="ALL">全部</a-select-option>
          <a-select-option v-for="(item, index) in units[convertType]" :key="index" :value="item.unit">
            {{ item.unit }}
          </a-select-option>
        </a-select>
      </a-col>
    </a-row>
    <a-row :gutter="[16, 16]">
      <a-col v-for="(item, index) in list" :key="index" :xxl="12" :xl="12" :lg="12" :md="12" :sm="24" :xs="24">
        <div class="d-flex justify-space-between">
          <span>{{ item[0] }}</span>
          <span>{{ item[1] }}</span>
        </div>
      </a-col>
    </a-row>
  </template>
  <a-row v-else :gutter="[16, 16]">
    <a-col v-for="func in funcs" :key="func.type" :xxl="3" :xl="4" :lg="6" :md="8" :sm="8" :xs="24">
      <a-card hoverable size="small" @click="changeConvertType(func.type)">
        <div class="d-flex align-center text-h6" style="gap:10%;">
          <a-image :width="60" :src="require('./logo.png')" :preview="false" />
          {{ func.title }}
        </div>
      </a-card>
    </a-col>
  </a-row>
</template>

<script setup>
import _ from 'underscore';
import { ref, reactive, computed, getCurrentInstance } from 'vue';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons-vue';
const { proxy } = getCurrentInstance();
const convertType = ref(null);
const funcs = reactive([
  { type: "length", title: "长度换算" },
  { type: "volume", title: "体积换算" },
  { type: "quality", title: "质量换算" },
  { type: "exchange_rate", title: "汇率换算" },
  { type: "area", title: "面积换算" },
]);
const units = reactive({
  length: [//中间单位 米
    { unit: "米(m)", rate: 1 },
    { unit: "千米(km)", rate: 1000 },
    { unit: "分米(dm)", rate: proxy.$utils.d("0.1") },
    { unit: "厘米(cm)", rate: proxy.$utils.d("0.01") },
    { unit: "毫米(mm)", rate: proxy.$utils.d("0.001") },
    { unit: "英寸(in)", rate: proxy.$utils.d("0.0254") },
    { unit: "英尺(ft)", rate: proxy.$utils.d("0.3048") },
    { unit: "码(yd)", rate: proxy.$utils.d("0.9144") },
    { unit: "英里(mi)", rate: proxy.$utils.d("1609.344") },
    { unit: "海里(mi)", rate: 1854 },
    { unit: "里", rate: 500 },
    { unit: "丈", rate: proxy.$utils.d(10).dividedBy(3) },
    { unit: "尺", rate: proxy.$utils.d(1).dividedBy(3) },
    { unit: "寸", rate: proxy.$utils.d(1).dividedBy(30) },
  ],
  volume: [//中间单位 立方米
    { unit: "立方米(m³)", rate: 1 },
    { unit: "立方千米(km³)", rate: 1000000000 },
    { unit: "立方分米(dm³)", rate: proxy.$utils.d("0.001") },
    { unit: "立方厘米(cm³)", rate: proxy.$utils.d("0.000001") },
    { unit: "升(L)", rate: proxy.$utils.d("0.001") },
    { unit: "毫升(ml)", rate: proxy.$utils.d("0.000001") },
    { unit: "立方英寸(in³)", rate: proxy.$utils.d("0.0254").pow(3) },
    { unit: "立方英尺(ft³)", rate: proxy.$utils.d("0.3048").pow(3) },
    { unit: "立方码(yd³)", rate: proxy.$utils.d("0.9144").pow(3) },
    { unit: "英制液体盎司(fl-oz)", rate: proxy.$utils.d("28.413").times("0.000001") },//28.413毫升
    { unit: "英制加仑(gal)", rate: proxy.$utils.d("4.54609").times("0.001") },//4.54609升
    { unit: "美制液体盎司(fl-oz)", rate: proxy.$utils.d("29.5735").times("0.000001") },//29.5735毫升
    { unit: "美制加仑(gal)", rate: proxy.$utils.d("3.78541").times("0.001") },//3.78541升
  ],
  quality: [//质量
    { unit: "千克(kg)", rate: 1 },
    { unit: "克(g)", rate: proxy.$utils.d("0.001") },
    { unit: "毫克(g)", rate: proxy.$utils.d("0.001").times("0.001") },//0.001克
    { unit: "吨(t)", rate: 1000 },
    { unit: "克拉(ct)", rate: proxy.$utils.d("0.2").times("0.001") },//0.2克
    { unit: "盎司(oz)", rate: proxy.$utils.d("28.3945").times("0.001") },//28.3945克
    { unit: "磅(lb)", rate: proxy.$utils.d("453.592").times("0.001") },//453.592克
    { unit: "担(dan)", rate: 50 },//50千克
    { unit: "斤(jin)", rate: proxy.$utils.d("500").times("0.001") },//500克
    { unit: "两(liang)", rate: proxy.$utils.d("50").times("0.001") },//50克
    { unit: "钱(qian)", rate: proxy.$utils.d("5").times("0.001") },//5克
  ],
  exchange_rate: [],
  area: [//中间单位 平方米
    { unit: "平方米(m²)", rate: 1 },
    { unit: "平方千米(km²)", rate: 1000000 },
    { unit: "平方分米(dm²)", rate: proxy.$utils.d("0.01") },
    { unit: "平方厘米(cm²)", rate: proxy.$utils.d("0.0001") },
    { unit: "公顷(ha)", rate: 10000 },
    { unit: "毫升(ml)", rate: proxy.$utils.d("0.000001") },
    { unit: "平方英寸(in²)", rate: proxy.$utils.d("0.0254").pow(2) },
    { unit: "平方英尺(ft²)", rate: proxy.$utils.d("0.3048").pow(2) },
    { unit: "平方码(yd²)", rate: proxy.$utils.d("0.9144").pow(2) },
    { unit: "平方英里(yd²)", rate: proxy.$utils.d("1609.344").pow(2) },
    { unit: "英亩(ac)", rate: proxy.$utils.d("0.9144").pow(2).times(4840) },//4840平方码
    { unit: "顷", rate: proxy.$utils.d(2).dividedBy(3).times(100000) },//100000平方米的 三分之二
    { unit: "亩", rate: proxy.$utils.d(2).dividedBy(3).times(1000) },//1000平方米的 三分之二
  ],
});
const number = ref(1);
const from = ref(null);
const to = ref('ALL');

const title = computed(() => {
  return _.findWhere(funcs, { type: convertType.value })?.title || '';
});
const list = computed(() => {
  let list = [];
  let unit_list = units[convertType.value];
  if (unit_list) {
    let base = _.findWhere(unit_list, { unit: from.value });
    if (base) {
      for (let i = 0; i < unit_list.length; i++) {
        let { unit, rate } = unit_list[i];
        if (to.value === 'ALL' || to.value === unit) {
          let value = proxy.$utils.d(number.value).times(base.rate).dividedBy(rate).toString();
          list.push([value, unit]);
        }
      }
    }
  }
  return list;
});
const changeConvertType = (type) => {
  convertType.value = type;
  if (!type) {
    number.value = 1;
    from.value = null;
    to.value = 'ALL';
  } else {
    let unit_list = units[convertType.value];
    from.value = unit_list[0].unit;
  }
};
</script>

<style></style>