<template>
  <div class="d-flex align-center mb-2">
    <span style="width:80px;">查询搜索：</span>
    <a-input-search v-model:value="searchText" placeholder="输入ID/账号/手机号/姓名查询" enter-button="搜索" allow-clear
      @search="getData" />
  </div>
  <div class="d-flex justify-space-between align-center mb-2">
    <a-button type="primary" @click="getData">刷新数据</a-button>
    <a-button type="primary" @click="addTrainee">
      <template #icon>
        <PlusCircleOutlined />
      </template>新增账号
    </a-button>
  </div>
  <a-table :columns="columns" :data-source="data" :scroll="{ x: width }" :loading="dataLoading"
    :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
    @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" size="small">
    <template #bodyCell="{ record, column }">
      <template v-if="column.dataIndex === 'initpwd'">
        {{ (record.initpwd && record.initpwd !== null) ? record.initpwd : '已修改' }}
      </template>
      <template v-if="column.dataIndex === 'superstaff'">
        {{ getsuperstaffname(record) }}
      </template>
      <template v-if="column.dataIndex === 'state'">
        <span v-if="record.enabled" class="text-success">启用</span>
        <span v-else class="text-error">禁用</span>
      </template>
      <template v-if="column.dataIndex === 'operation'">
        <a-space>
          <a href="javascript:;" @click="editTrainee(record)">编辑</a>
          <a href="javascript:;" @click="showPermission(record)" class="text-error">权限</a>
          <!-- <a-popconfirm title="确定要删除?" @confirm="onDelete(record.key)">
            <a href="javascript:;" class="text-error">删除</a>
          </a-popconfirm> -->
        </a-space>
      </template>
    </template>
  </a-table>
  <a-modal v-model:visible="traineeModal" :title="`${traineeForm._id !== '' ? '编辑' : '新增'}学员账号`" @ok="saveTrainee"
    @cancel="cancelSave" ok-text="确认" cancel-text="取消" :confirm-loading="saving"
    :cancel-button-props="{ disabled: saving }" :maskClosable="false" :closable="!saving">
    <a-form ref="formRef" name="traineeForm" :model="traineeForm" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }"
      autocomplete="off">
      <a-form-item v-if="traineeForm._id !== ''" label="ID">
        <a-input v-model:value="traineeForm.number" disabled>
        </a-input>
      </a-form-item>
      <a-form-item label="账号" name="username" :rules="traineeForm._id === '' ? username_rule : []">
        <a-input v-model:value="traineeForm.username" placeholder="请输入账号" :disabled="traineeForm._id !== '' || saving">
        </a-input>
      </a-form-item>
      <a-form-item label="密码" name="password">
        <a-input v-model:value="traineeForm.password" :placeholder="traineeForm._id === '' ? '请输入密码' : '密码留空则不修改'"
          :disabled="saving">
        </a-input>
      </a-form-item>
      <a-form-item label="姓名" name="name">
        <a-input v-model:value="traineeForm.name" placeholder="请输入姓名" :disabled="saving">
        </a-input>
      </a-form-item>
      <a-form-item label="手机号" name="mobile">
        <a-input-group compact>
          <a-select v-model:value="traineeForm.mobile_area_code" :disabled="saving" style="width: 30%">
            <a-select-option value="+86">+86</a-select-option>
          </a-select>
          <a-input v-model:value="traineeForm.mobile" placeholder="手机号码" type="number" :maxlength="11"
            :disabled="saving" style="width: 70%">
          </a-input>
        </a-input-group>
      </a-form-item>
      <a-form-item label="所属员工" name="superstaff" :rules="[{ required: true, message: '所属员工不能为空' }]">
        <a-select v-model:value="traineeForm.superstaff" :options="superstaffs" :disabled="saving" show-search
          :filter-option="$filters.filterOption">
        </a-select>
      </a-form-item>
      <a-form-item label="状态" name="enabled">
        <a-checkbox v-model:checked="traineeForm.enabled">
          <span v-if="traineeForm.enabled" class="text-success">启用</span>
          <span v-else class="text-error">禁用</span>
        </a-checkbox>
      </a-form-item>
    </a-form>
  </a-modal>
  <a-modal v-model:visible="powerModal" title="设置权限" @ok="savePower" ok-text="确认" cancel-text="取消"
    :confirm-loading="powerSaving" :cancel-button-props="{ disabled: powerSaving }" :maskClosable="false"
    :closable="!powerSaving">
    <a-form ref="powerRef" name="powerForm" :model="powerForm" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }"
      autocomplete="off">
      <a-form-item label="权限" name="permissions" :rules="[{ required: true, message: '请选择权限' }]">
        <a-checkbox-group v-model:value="powerForm.permissions" style="width:100%;max-height:60vh;overflow-y:scroll;">
          <a-collapse v-model:activeKey="power_groups">
            <a-collapse-panel v-for="item in permissions" :key="item.label">
              <template #header>
                <div class="d-flex justify-space-between" style="width:100%">
                  <span>
                    {{ item.label }}
                    ({{ selectedCount(item.options) }}/{{ item.options.length }})
                  </span>
                  <div>
                    <a href="javascript:;" @click.stop="selectAll(item.options)" class="ml-1">全选</a>
                    <a href="javascript:;" @click.stop="clearAll(item.options)" class="ml-1">全不选</a>
                  </div>
                </div>
              </template>
              <a-checkbox v-for="option in item.options" :key="option.value" :value="option.value">
                {{ option.label }}
              </a-checkbox>
            </a-collapse-panel>
          </a-collapse>
        </a-checkbox-group>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { DownOutlined, SearchOutlined, PlusCircleOutlined } from '@ant-design/icons-vue';
import { message } from "ant-design-vue";
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  watch,
  computed,
  onMounted,
  onActivated,
  getCurrentInstance,
} from "vue";
import { useStore } from "vuex";
import _ from "underscore";
const columns = [
  { title: '学员ID', dataIndex: 'number', width: 120, },
  { title: '账号', dataIndex: 'username', width: 120, },
  { title: '默认密码', dataIndex: 'initpwd', width: 120, },
  { title: '手机号', dataIndex: 'mobile', width: 150, },
  { title: '姓名', dataIndex: 'name', width: 120, },
  { title: '所属员工', dataIndex: 'superstaff', width: 150, },
  { title: '状态', dataIndex: 'state', width: 100, },
  { title: '创建时间', dataIndex: 'create_time', width: 120, },
  { title: '操作', dataIndex: 'operation', width: 150, fixed: 'right' },
];
export default defineComponent({
  name: 'AccountSettingTrainee',
  components: {
    DownOutlined,
    SearchOutlined,
    PlusCircleOutlined
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const formRef = ref();
    const powerRef = ref();
    const store = useStore();
    const state = reactive({
      searchText: "",
      page: 1,
      limit: 10,
      data: [],
      length: 0,
      count: 0,
      dataLoading: false,
      superstaffs: [],
      saving: false,
      traineeModal: false,
      traineeForm: {},
      permissions: [],
      power_groups: [],
      powerForm: {},
      powerModal: false,
      powerSaving: false,
    });
    const width = computed(() => proxy.$utils.twidth(columns));
    const level = computed(() => store.state.user.level);
    watch(() => state.page, () => getData());
    watch(() => state.limit, () => {
      if (state.page !== 1) state.page = 1;
      else getData();
    });
    onActivated(() => {
      getData();
    });
    const getData = () => {
      state.dataLoading = true;
      proxy.$api.doAPI("/account/traineelist", { page: state.page, limit: state.limit, searchText: state.searchText, }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.data = _.map(res.data.list, n => {
            return {
              ...n,
              create_time: proxy.$utils.dateFormat(n.create_time, 'YYYY-MM-DD HH:mm:ss'),
            };
          });
          state.length = res.data.length;
          state.count = res.data.count;
          if (state.length > 0 && state.page > state.length) state.page = 1;
          state.superstaffs = _.map(res.data.superstaffs, n => ({ ...n, label: n.name, value: n._id }));
          state.permissions = res.data.permissions;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    };
    const addTrainee = () => {
      state.traineeForm = {
        _id: "",
        username: "",
        password: "",
        name: "",
        mobile_area_code: "+86",
        mobile: "",
        superstaff: "",
        enabled: true,
      };
      state.traineeModal = true;
    }
    const editTrainee = (item) => {
      state.traineeForm = {
        _id: item._id,
        number: item.number,
        username: item.username,
        password: "",
        name: item.name,
        mobile_area_code: item.mobile_area_code,
        mobile: item.mobile,
        superstaff: item.superstaff,
        enabled: item.enabled,
      };
      state.traineeModal = true;
    }
    const saveTrainee = async () => {
      try {
        await formRef.value.validateFields();
        state.saving = true;
        proxy.$api.doAPI("/account/savetrainee", { dataForm: state.traineeForm, }).then((res) => {
          state.saving = false;
          if (res.code === 0) {
            message.success("保存成功");
            state.traineeModal = false;
            getData();
          } else {
            message.error(res.msg);
          }
        }).catch((err) => {
          state.saving = false;
          console.error(err);
        });
      } catch (errorInfo) {
        console.log("Failed:", errorInfo);
      }
    }
    const cancelSave = () => {
      console.log('cancelSave');
      formRef.value.clearValidate();
    }
    const showPermission = (item) => {
      state.powerForm = {
        _id: item._id,
        permissions: item.permissions || [],
      };
      state.powerModal = true;
    }
    const savePower = async () => {
      try {
        await powerRef.value.validateFields();
        state.powerSaving = true;
        proxy.$api.doAPI("/account/savepower", { dataForm: state.powerForm, }).then((res) => {
          state.powerSaving = false;
          if (res.code === 0) {
            message.success("保存成功");
            state.powerModal = false;
            getData();
          } else {
            message.error(res.msg);
          }
        }).catch((err) => {
          state.powerSaving = false;
          console.error(err);
        });
      } catch (errorInfo) {
        console.log("Failed:", errorInfo);
      }
    }
    const selectAll = (options) => {
      let values = _.pluck(options, "value");
      console.log('selectAll', values);
      state.powerForm.permissions = _.uniq([
        ...state.powerForm.permissions,
        ...values,
      ]);
    }
    const clearAll = (options) => {
      let values = _.pluck(options, "value");
      console.log('clearAll', values);
      state.powerForm.permissions = _.filter(state.powerForm.permissions, n => values.indexOf(n) === -1);
    }
    const selectedCount = (options) => {
      let values = _.pluck(options, "value");
      return _.filter(values, n => state.powerForm.permissions.indexOf(n) > -1).length;
    }
    const getsuperstaffname = (item) => {
      return _.findWhere(state.superstaffs, { value: item.superstaff })?.label;
    }
    return {
      ...toRefs(state),
      formRef,
      powerRef,
      columns,
      width,
      level,
      getData,
      addTrainee,
      editTrainee,
      saveTrainee,
      cancelSave,
      showPermission,
      savePower,
      selectAll,
      clearAll,
      selectedCount,
      getsuperstaffname,
      username_rule: proxy.$constant.username_rule,
      password_rule: proxy.$constant.password_rule,
      mobile_rule: proxy.$constant.mobile_rule,
    };
  }
});
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

:deep(.ant-checkbox-wrapper + .ant-checkbox-wrapper) {
  margin-left: 0 !important;
}

:deep(.ant-collapse-content > .ant-collapse-content-box) {
  padding: 8px !important;
}

p {
  margin: 0;
  padding: 0;
}
</style>
