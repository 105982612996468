import { createStore } from "vuex";
import imagepicker from './modules/imagepicker';
import menu from './modules/menu';
import uploadtask from './modules/uploadtask';
import user from './modules/user';
import zhengbo from './modules/zhengbo';

export default createStore({
  modules: {
    imagepicker,
    menu,
    uploadtask,
    user,
    zhengbo,
  },
});