<template>
  <div class="d-flex justify-space-between align-center mb-2">
    <a-button type="primary" @click="getData">Refresh List</a-button>
    <a-button type="primary" @click="constructFeed">
      <template #icon>
        <PlusCircleOutlined />
      </template>Construct a feed
    </a-button>
  </div>
  <a-table :columns="columns" :data-source="data" :scroll="{ x: width }" :loading="dataLoading"
    :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
    @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" size="small">
    <template #bodyCell="{ record, column }">
      <template v-if="column.dataIndex === 'feed_documentid'">
        <a-tooltip>
          <template #title> {{ record.feed_documentid }} </template>
          <p class="in1line">{{ record.feed_documentid }}</p>
        </a-tooltip>
      </template>
      <template v-if="column.dataIndex === 'upload_url'">
        <a-tooltip>
          <template #title> {{ record.upload_url }} </template>
          <p class="in1line">{{ record.upload_url }}</p>
        </a-tooltip>
      </template>
      <template v-if="column.dataIndex === 'type'">
        <a-tooltip>
          <template #title> {{ record.type }} </template>
          <p class="in1line">{{ record.type }}</p>
        </a-tooltip>
      </template>
      <template v-if="column.dataIndex === 'data'">
        <div class="d-flex align-center">
          <p class="in1line">{{ record.xml || record.json }}</p>
          <eye-outlined class="text-h6 text-info" @click="showData(record)" />
        </div>
      </template>
      <template v-if="column.dataIndex === 'result_documentid'">
        <a-tooltip>
          <template #title> {{ record.result_documentid }} </template>
          <p class="in1line">{{ record.result_documentid }}</p>
        </a-tooltip>
      </template>
      <template v-if="column.dataIndex === 'download_url'">
        <a-tooltip>
          <template #title> {{ record.download_url }} </template>
          <p class="in1line">{{ record.download_url }}</p>
        </a-tooltip>
      </template>
      <template v-if="column.dataIndex === 'operation'">
        <div class="d-flex flex-column">
          <a href="javascript:;" @click="copyFeed(record._id)">
            Copy
          </a>
          <a v-if="record.step === 'createFeedDocument'" href="javascript:;" @click="createFeedDocument(record._id)">
            1. Create feed document
          </a>
          <a v-if="record.step === 'uploadFeedData'" href="javascript:;" @click="uploadFeedData(record._id)">
            2. Upload feed data
          </a>
          <a v-if="record.step === 'createFeed'" href="javascript:;" @click="createFeed(record._id)">
            3. Create feed
          </a>
          <a v-if="record.step === 'getFeed'" href="javascript:;" @click="getFeed(record._id)">
            4. Get feed
          </a>
          <a v-if="record.status === 'DONE' && record.step !== 'SUCCESS'" href="javascript:;"
            @click="getFeedDocument(record._id)">
            5. Get feed document
          </a>
          <a v-if="record.step === 'downloadFeedReport'" href="javascript:;" @click="downloadFeedReport(record._id)">
            6. Download feed report
          </a>
        </div>
      </template>
    </template>
  </a-table>
  <a-modal v-model:visible="feedModal" title="Construct feed" @ok="submitFeed" @cancel="cancelSubmitFeed"
    ok-text="Submit" cancel-text="Cancel" :confirm-loading="feedSubmiting"
    :cancel-button-props="{ disabled: feedSubmiting }" :maskClosable="false" :closable="!feedSubmiting" width="750px">
    <a-form ref="feedFormRef" name="feedForm" :model="feedForm" :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }"
      autocomplete="off">
      <!-- <a-form-item label="FeedType" name="type" :rules="[{ required: true, message: 'FeedType can\'t be empty' }]">
        <a-input v-model:value="feedForm.type" :disabled="feedSubmiting">
        </a-input>
      </a-form-item> -->
      <a-form-item label="AmazonOrderId" name="orderid"
        :rules="[{ required: true, message: 'AmazonOrderId can\'t be empty' }]">
        <a-input v-model:value="feedForm.orderid" :disabled="feedSubmiting" />
      </a-form-item>
      <a-form-item label="FulfillmentDate" name="fulfillment_date"
        :rules="[{ required: true, message: 'FulfillmentDate can\'t be empty' }]">
        <a-input v-model:value="feedForm.fulfillment_date" :disabled="feedSubmiting" />
      </a-form-item>
      <a-form-item label="CarrierCode" name="carrier_code"
        :rules="[{ required: true, message: 'CarrierCode can\'t be empty' }]">
        <a-input v-model:value="feedForm.carrier_code" :disabled="feedSubmiting" />
      </a-form-item>
      <a-form-item label="CarrierName" name="carrier_name">
        <a-input v-model:value="feedForm.carrier_name" :disabled="feedSubmiting" />
      </a-form-item>
      <a-form-item label="ShippingMethod" name="shipping_method">
        <a-input v-model:value="feedForm.shipping_method" :disabled="feedSubmiting" />
      </a-form-item>
      <a-form-item label="ShipperTrackingNumber" name="logistics_tracking_number"
        :rules="[{ required: true, message: 'ShipperTrackingNumber can\'t be empty' }]">
        <a-input v-model:value="feedForm.logistics_tracking_number" :disabled="feedSubmiting" />
      </a-form-item>
    </a-form>
  </a-modal>
  <a-drawer title="完整数据" placement="right" v-model:visible="dataDrawer" width="50%">
    <XmlViewer v-if="xml" :xml="xml" />
    <json-viewer v-if="json" :value="json" :expand-depth="10" copyable expanded></json-viewer>
  </a-drawer>
</template>
<script setup>
import { DownOutlined, SearchOutlined, PlusCircleOutlined, EyeOutlined } from '@ant-design/icons-vue';
import { message, Modal } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from "vue";
import _ from "underscore";
import XmlViewer from 'vue3-xml-viewer'
import JsonViewer from 'vue-json-viewer'
const columns = [
  { title: 'Title', dataIndex: 'title', width: 120, },
  { title: 'Type', dataIndex: 'type', width: 120, },
  { title: 'Data', dataIndex: 'data', width: 120, },
  { title: 'FeedDocumentId', dataIndex: 'feed_documentid', width: 120, },
  { title: 'UploadUrl', dataIndex: 'upload_url', width: 120, },
  { title: 'FeedId', dataIndex: 'feedid', width: 120, },
  { title: 'ResultDocumentId', dataIndex: 'result_documentid', width: 150, },
  { title: 'Step', dataIndex: 'step', width: 120, },
  { title: 'Status', dataIndex: 'status', width: 120, },
  { title: 'DownloadUrl', dataIndex: 'download_url', width: 120, },
  { title: 'CreateTime', dataIndex: 'create_time', width: 120, },
  { title: 'Operation', dataIndex: 'operation', width: 150, fixed: 'right' },
];
const { proxy } = getCurrentInstance();
const feedFormRef = ref();
const page = ref(1);
const limit = ref(10);
const data = ref([]);
const length = ref(0);
const count = ref(0);
const dataLoading = ref(false);
const feedForm = ref({});
const feedModal = ref(false);
const feedSubmiting = ref(false);
const xml = ref("");
const json = ref(null);
const dataDrawer = ref(false);

const width = computed(() => proxy.$utils.twidth(columns));
watch(() => page.value, () => {
  getData();
});
watch(() => limit.value, () => {
  if (page.value !== 1) page.value = 1;
  else getData();
});
onActivated(() => {
  getData();
});
const getData = () => {
  dataLoading.value = true;
  proxy.$api.doAPI2("/dev/amazon/feed/list", { page: page.value, limit: limit.value }).then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      data.value = _.map(res.data.list, n => {
        return {
          ...n,
          create_time: proxy.$utils.dateFormat(n.create_time, 'YYYY-MM-DD HH:mm:ss'),
        };
      });
      length.value = res.data.length;
      count.value = res.data.count;
      if (length.value > 0 && page.value > length.value) page.value = 1;
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
};
const constructFeed = () => {
  feedForm.value = {
    orderid: '',
    fulfillment_date: '',
    carrier_code: '',
    carrier_name: '',
    shipping_method: '',
    logistics_tracking_number: '',
  };
  feedModal.value = true;
}
const submitFeed = async () => {
  try {
    await feedFormRef.value.validateFields();
    feedSubmiting.value = true;
    proxy.$api.doAPI2("/dev/amazon/feed/submit", { feedForm: feedForm.value }).then((res) => {
      feedSubmiting.value = false;
      if (res.code === 0) {
        feedModal.value = false;
        getData();
      } else {
        message.error(res.msg);
      }
    }).catch((err) => {
      feedSubmiting.value = false;
      console.error(err);
    });
  } catch (errorInfo) {
    console.log("Failed:", errorInfo);
  }
}
const cancelSubmitFeed = () => {
  feedFormRef.value.clearValidate();
  feedSubmiting.value = false;
}
const copyFeed = (_id) => {
  dataLoading.value = true;
  proxy.$api.doAPI2("/dev/amazon/feed/copy", { _id }).then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      getData();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
}
const createFeedDocument = (_id) => {
  dataLoading.value = true;
  proxy.$api.doAPI2("/dev/amazon/feed/create_document", { _id }).then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      getData();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
}
const uploadFeedData = (_id) => {
  dataLoading.value = true;
  proxy.$api.doAPI2("/dev/amazon/feed/upload", { _id }).then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      getData();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
}
const createFeed = (_id) => {
  dataLoading.value = true;
  proxy.$api.doAPI2("/dev/amazon/feed/create", { _id }).then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      getData();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
}
const getFeed = (_id) => {
  dataLoading.value = true;
  proxy.$api.doAPI2("/dev/amazon/feed/get", { _id }).then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      getData();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
}
const getFeedDocument = (_id) => {
  dataLoading.value = true;
  proxy.$api.doAPI2("/dev/amazon/feed/get_document", { _id }).then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      getData();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
}
const downloadFeedReport = (_id) => {
  dataLoading.value = true;
  proxy.$api.doAPI2("/dev/amazon/feed/download", { _id }).then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      getData();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
}
const showData = (item) => {
  xml.value = item.xml;
  json.value = item.json;
  dataDrawer.value = true;
}
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

p {
  margin: 0;
  padding: 0;
}
</style>
