<template>
  <a-spin :spinning="dataLoading">
    <div class="page">
      <a-row>
        <a-col :span="12" :offset="6">
          <div class="group_1 flex-col">
            <div class="section_1 flex-row">
              <img class="image_1" referrerpolicy="no-referrer" :src="image" />
            </div>
          </div>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12" :offset="6">
          <div class="group_3 flex-col">
            <div class="flex-row justify-between">
              <a-tabs v-model:activeKey="activeKey" @change="changeData">
                <a-tab-pane v-for="item in tabs" :key="item" :tab="item">
                </a-tab-pane>
              </a-tabs>
            </div>
          </div>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12" :offset="6">
          <div class="group_4">
            <a-table :columns="columns" bordered :data-source="list" :loading="dataLoading" :pagination="false">
              <template #bodyCell="{ record, column }">
                <template v-if="column.dataIndex === 'url'">
                  <img style="width: 80px;height: 80px;" :src="record.picUrl" />
                </template>
                <template v-if="column.dataIndex === 'name'">
                  <div class="text">
                    <span class="text_1">{{ record.title }}</span>
                  </div>
                </template>
                <template v-if="column.dataIndex === 'price'">
                  <p class="text_2">￥{{ record.reservePrice }}</p>
                  <p class="text_3">{{ record.provcity }}</p>
                </template>
                <template v-if="column.dataIndex === 'sales_volume'">
                  <span class="text_4">{{ record.volume }}</span>
                </template>
                <template v-if="column.dataIndex === 'action'">
                  <a :href="record.url" target="_Blank">详情</a>
                </template>
              </template>
            </a-table>
          </div>
        </a-col>
      </a-row>
    </div>
  </a-spin>
</template>
<script>
import { message } from "ant-design-vue";
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  watch,
  computed,
  onMounted,
  onActivated,
  getCurrentInstance,
  nextTick,
} from "vue";
import _ from "underscore";
import { useRoute } from "vue-router";
const columns = [
  { title: "缩略图", dataIndex: "url", width: 80, align: "center", },
  { title: "标题", dataIndex: "name", width: 500, align: "center", },
  { title: "价格", dataIndex: "price", width: 80, align: "center", },
  { title: "30日销售量", dataIndex: "sales_volume", width: 100, align: "center", },
  { title: "操作", dataIndex: "action", width: 80, align: "center", },
];
export default defineComponent({
  name: "TbList",
  components: {},
  setup() {
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const state = reactive({
      activeKey: "",
      dataLoading: false,
      image: "",
      tabs: [],
      list: [],
      tabList: [],
      base64: "" ,
    });
    onActivated(() => {
      getData();
    });
    const getData = async () => {
      state.dataLoading = true;
      state.image = route.query.url;
      let base64 = await getBase64(state.image);
      proxy.$api.doAPI("/alicloud/search_pic", { fileUrl: state.image, pageNo: 20, base64File: base64 }).then((res) => {
        if (res.code === 0) {
          state.dataLoading = false;
          let { success, message: msg, commodityList } = res.data.list;
          if (success === true) {
            commodityList = _.map(commodityList, n => {
              return {
                ..._.pick(n.result, "levelOneCategoryName", "volume", "title", "provcity", "categoryName", "reservePrice", "url", "picUrl"),
              };
            });
            let groupByValues = _.groupBy(commodityList, 'levelOneCategoryName');
            for (const key in groupByValues) {
              let list = groupByValues[key];
              state.tabs.push(key);
              state.tabList.push({ tab: key, list });
            }
            state.activeKey = state.tabs[0];
            state.list = _.findWhere(state.tabList, { tab: state.tabs[0] })?.list || [];
          } else {
            state.dataLoading = false;
            message.error(msg);
          }
        } else {
          state.dataLoading = false;
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    };
    const getBase64 = async (imgUrl) => {
      message.loading("", 0);
      return new Promise((resolve, reject) => {
        let image = new Image();
        image.setAttribute('crossOrigin', 'anonymous');
        image.src = imgUrl + '?tamp=' + (new Date()).valueOf();
        image.onload = () => {
          let canvas = document.createElement("canvas");
          let width = image.width > 500 ? 500 : image.width;
          let height = image.height > 500 ? 500 : image.height;
          canvas.width = width;
          canvas.height = height;
          let context = canvas.getContext('2d');
          context.drawImage(image, 0, 0, width, height);
          let dataURL = canvas.toDataURL("image/png");
          resolve(dataURL.split(",")[1]);
          message.destroy();
        }
      })
    };
    const changeData = () => {
      state.list = _.findWhere(state.tabList, { tab: state.activeKey })?.list || [];
      console.log(state.list);
    };
    return {
      ...toRefs(state),
      columns,
      changeData,
    };
  },
});
</script>
<style lang="less" scoped>
.page {
  background-color: rgba(244, 246, 250, 1);
  overflow-y: auto;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.justify-between {
  display: flex;
  justify-content: space-between;
}

.group_1 {
  background-color: rgba(255, 255, 255, 1);
  margin: 20px 0 0 0;
}

.section_1 {
  background-color: rgba(246, 246, 246, 1);
  border-radius: 8px;
  width: 187px;
  height: 187px;
  border: 1px solid rgba(80, 160, 252, 1);
  margin: 10px 0 10px 20px;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.image_1 {
  width: 173px;
  height: 173px;
  margin: 7px 0 0 7px;
}


.group_3 {
  background-color: rgba(255, 255, 255, 1);
  margin: 20px 0 0 0;
  overflow-y: auto;
}

.group_4 {
  margin: -16px 0 0 0;
}

.text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.text_1 {
  color: rgba(33, 33, 33, 1);
  font-size: 14px;
  font-weight: 600;
}

.text_2 {
  overflow-wrap: break-word;
  color: rgba(235, 147, 73, 1);
  font-size: 20px;
  font-weight: 600;
  white-space: nowrap;
}

.text_3 {
  color: rgba(33, 33, 33, 1);
  font-size: 14px;
  white-space: nowrap;
}

.text_4 {
  display: inline-block;
  color: rgba(33, 33, 33, 1);
  font-size: 18px;
  font-weight: 600;
}

p {
  margin: 0;
  padding: 0;
}
</style>
