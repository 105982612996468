<template>
  <a-card size="small">
    <template #title>
      <a-space>
        <a-button type="primary" @click="getData">刷新</a-button>
        <a-button type="primary" class="btn-success" @click="add">新增</a-button>
        <a-button type="primary" class="btn-error">删除</a-button>
      </a-space>
    </template>
    <a-table size="small" bordered :columns="columns" :data-source="list" :loading="loading" :pagination="false">
      <template #bodyCell="{ record, column }">
        <template v-if="column.dataIndex === 'desc'">
          <p>
            调价范围：
            <span class="text-error"> {{ record.min_price }} </span> ~
            <span class="text-success"> {{ record.max_price }} </span>
          </p>
          <p>每次降价：<span class="text-error">{{ record.dec_price }}</span></p>
          <p>每次加价：<span class="text-success">{{ record.inc_price }}</span></p>
        </template>
        <template v-if="column.dataIndex === 'operation'">
          <a-space>
            <a href="javascript:;" class="text-info" @click="edit(record)"> 编辑 </a>
            <a-popconfirm title="删除规则?" @confirm="remove(record._id)">
              <a href="javascript:;" class="text-error"> 删除 </a>
            </a-popconfirm>
          </a-space>
        </template>
      </template>
    </a-table>
  </a-card>
  <a-modal v-model:visible="ruleModal" title="调价规则" :maskClosable="false" :closable="!saving">
    <a-form ref="ruleFormRef" name="ruleForm" :model="ruleForm" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }"
      autocomplete="off">
      <a-form-item label="名称" name="name" :rules="[{ required: true, message: '请输入规则名称' }]">
        <a-input v-model:value="ruleForm.name" style="width:100%;"></a-input>
      </a-form-item>
      <a-form-item label="最低价" name="min_price" :rules="[{ required: true, message: '请输入最低价' }]">
        <a-input-number v-model:value="ruleForm.min_price" style="width:100%;"></a-input-number>
      </a-form-item>
      <a-form-item label="最高价" name="max_price" :rules="[{ required: true, message: '请输入最高价' }]">
        <a-input-number v-model:value="ruleForm.max_price" style="width:100%;"></a-input-number>
      </a-form-item>
      <a-form-item label="降价幅度" name="dec_price" :rules="[{ required: true, message: '降价幅度' }]"
        extra="如果调价目标是日元，则最小幅度为1，且必须是整数">
        <a-input-number v-model:value="ruleForm.dec_price" style="width:100%;"></a-input-number>
      </a-form-item>
      <a-form-item label="加价幅度" name="inc_price" :rules="[{ required: true, message: '加价幅度' }]"
        extra="如果调价目标是日元，则最小幅度为1，且必须是整数">
        <a-input-number v-model:value="ruleForm.inc_price" style="width:100%;"></a-input-number>
      </a-form-item>
    </a-form>
    <template #footer>
      <div class="d-flex justify-end align-center">
        <a-button @click="saveRule" type="primary" :loading="saving">
          保存
        </a-button>
        <a-button @click="() => ruleModal = false" :disabled="saving">
          取消
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script setup>
import _ from 'underscore';
import { ref, reactive, computed, getCurrentInstance, onActivated, watch } from 'vue';
import { PlusOutlined, MinusOutlined, QuestionCircleOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';

const columns = ref([
  // { title: 'ID', dataIndex: 'id', width: 80, },
  { title: '名称', dataIndex: 'name', width: 120, },
  { title: '描述', dataIndex: 'desc', width: 240, },
  { title: '操作', dataIndex: 'operation', width: 100, },
]);
const { proxy } = getCurrentInstance();
const ruleFormRef = ref(null);

const loading = ref(false);
const page = ref(1);
const limit = ref(10);
const length = ref(0);
const count = ref(0);
const list = ref([]);
const ruleForm = ref({});
const ruleModal = ref(false);
const saving = ref(false);

watch(() => page.value, () => {
  getData();
});
watch(() => limit.value, () => {
  if (page.value !== 1) page.value = 1;
  else getData();
});
const getData = () => {
  loading.value = true;
  proxy.$api.doAPI2("/dev/amazon/genmai/pricerules", { page: page.value, limit: limit.value }).then((res) => {
    loading.value = false;
    if (res.code === 0) {
      list.value = res.data.list;
      length.value = res.data.length;
      count.value = res.data.count;
      if (length.value > 0 && page.value > length.value) page.value = 1;
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    loading.value = false;
    console.error(err);
  });
}
const add = () => {
  ruleForm.value = {
    _id: "",
    name: "",
    max_price: "",
    min_price: "",
    inc_price: "0.1",
    dec_price: "0.1",
  };
  ruleModal.value = true;
}
const edit = (item) => {
  ruleForm.value = {
    _id: item._id,
    name: item.name,
    max_price: item.max_price,
    min_price: item.min_price,
    inc_price: item.inc_price,
    dec_price: item.dec_price,
  };
  ruleModal.value = true;
}
const remove = (_id) => {
  loading.value = true;
  proxy.$api.doAPI2("/dev/amazon/genmai/removepricerule", { _id }).then((res) => {
    loading.value = false;
    if (res.code === 0) {
      getData();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    loading.value = false;
    console.error(err);
  });
}
const saveRule = async () => {
  try {
    await ruleFormRef.value.validateFields();
    saving.value = true;
    proxy.$api.doAPI2("/dev/amazon/genmai/savepricerule", { dataForm: ruleForm.value }).then((res) => {
      saving.value = false;
      if (res.code === 0) {
        getData();
        ruleModal.value = false;
      } else {
        message.error(res.msg);
      }
    }).catch((err) => {
      saving.value = false;
      console.error(err);
    });
  } catch (errorInfo) {
    console.log("Failed:", errorInfo);
  }
}
defineExpose({ getData });
onActivated(() => {
  getData();
})
</script>

<style lang="less" scoped>
@import "/node_modules/flag-icons/css/flag-icons.min.css";

p {
  margin: 0;
  padding: 0;
}
</style>