<template>
  <component :is="layout"></component>
</template>

<script setup>
import { ref, watch } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const layout = ref("empty-layout");

watch(() => route.meta?.layout, (newVal) => {
  layout.value = newVal || "empty-layout";
}, { immediate: true });
</script>
<style lang="less">
@success: #52c41a;
@info: #1890ff;
@warning: #f19240;
@warning-disabled: #f3d19e;
@error: #f5222d;
@gray: #818181;
@purple: #9c27b0;
@orange: #FF6A00;
@white: #fff;
@black: #000;
@cyan: #2db7f5;
@primary: #7ca4d2;
@teal: #2298a2;
@textposition: left, right, center;
@spacing: 5px;

.colors() {
  success: @success;
  info: @info;
  warning: @warning;
  warning-disabled: @warning-disabled;
  error: @error;
  gray: @gray;
  purple: @purple;
  orange: @orange;
  white: @white;
  black: @black;
  cyan: @cyan;
  primary: @primary;
  teal: @teal;
}

.textsizes() {
  h1: 2.5rem;
  h2: 2.25rem;
  h3: 2rem;
  h4: 1.75rem;
  h5: 1.5rem;
  h6: 1.25rem;
  h7: 1rem;
  h8: 0.95rem;
  h9: 0.9rem;
  h10: 0.85rem;
}

each(range(10), {
  .ma-@{value} {
    margin: (@value * @spacing) !important;
  }

  .mt-@{value} {
    margin-top: (@value * @spacing) !important;
  }

  .mr-@{value} {
    margin-right: (@value * @spacing) !important;
  }

  .mb-@{value} {
    margin-bottom: (@value * @spacing) !important;
  }

  .ml-@{value} {
    margin-left: (@value * @spacing) !important;
  }

  .pa-@{value} {
    padding: (@value * @spacing) !important;
  }

  .pt-@{value} {
    padding-top: (@value * @spacing) !important;
  }

  .pr-@{value} {
    padding-right: (@value * @spacing) !important;
  }

  .pb-@{value} {
    padding-bottom: (@value * @spacing) !important;
  }

  .pl-@{value} {
    padding-left: (@value * @spacing) !important;
  }

  .w-@{value} {
    width: (@value * @spacing) !important;
  }

  .h-@{value} {
    height: (@value * @spacing) !important;
  }
});

each(.colors(), .(@v, @k, @i) {
    .text-@{k} {
      color: @v !important;
    }

    .btn-@{k} {
      color: #fff !important;
      border-color: @v !important;
      background-color: @v !important;
    }

    .tag-@{k} {
      color: #fff !important;
      border-color: @v !important;
      background-color: @v !important;
    }

    .bg-@{k} {
      background-color: @v !important;
    }
  });

each(@textposition, {
  .text-@{value} {
    text-align: @value !important;
  }
});

each(.textsizes(), .(@v, @k, @i) {
    .text-@{k} {
      font-size: @v !important;
    }
  });

.in1line {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.in2line {
  display: -webkit-box !important;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.in3line {
  display: -webkit-box !important;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.in4line {
  display: -webkit-box !important;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  line-clamp: 4;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

.in5line {
  display: -webkit-box !important;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  line-clamp: 5;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}

#nprogress .bar {
  background: red !important; //自定义颜色
}

.d-flex {
  display: flex !important;
}

.flex {
  flex: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-column {
  flex-direction: column !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-start {
  justify-content: flex-start !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-space-between {
  justify-content: space-between !important;
}

.align-center {
  align-items: center !important;
}

.align-start {
  align-items: flex-start !important;
}

.align-end {
  align-items: flex-end !important;
}

.font-weight-bold {
  font-weight: bold !important;
}

.click {
  cursor: pointer !important;
}

.underline {
  text-decoration: underline !important;
}

.link {
  cursor: pointer !important;
  text-decoration: underline !important;
}

.disabled {
  cursor: not-allowed !important;
  opacity: 0.6;
}

.w_100 {
  width: 100%;
}

.h_100 {
  height: 100%;
}

.label {
  display: inline-block;
  font-size: 12px;
  padding: 2px 5px;
  border-radius: 3px;
  line-height: 14px
}

.label-ebay,
.label.label-ebay {
  background-color: #0563d4;
  color: #fff;
  text-decoration: none
}

.text-ebay {
  color: #0563d4 !important
}

.label-amazon,
.label.label-amazon {
  background-color: #f6b81d;
  color: #030303;
  text-decoration: none
}

.text-amazon {
  color: #f6b81d !important
}

.label-aliexpress,
.label.label-aliexpress {
  background-color: #f36d6f;
  color: #fff;
  text-decoration: none
}

.label-Coupang,
.label.label-Coupang {
  background-color: #e6a23c;
  color: #fff;
  text-decoration: none
}

.label-walmart,
.label.label-walmart {
  background-color: #0071dc;
  color: #fff;
  text-decoration: none
}

.text-aliexpress {
  color: #c21f22 !important
}

.label-wish,
.label.label-wish {
  background-color: #75b9e6;
  color: #fff;
  text-decoration: none
}

.text-wish {
  color: #75b9e6 !important
}

.label-1688,
.label.label-1688 {
  background-color: #ff6000;
  color: #fff;
  text-decoration: none
}

.text-1688 {
  color: #ff6000 !important
}

.label-banama,
.label.label-banama {
  background-color: #ff6000;
  color: #fff;
  text-decoration: none
}

.text-banama {
  color: #ff6000 !important
}

.label-taobao,
.label.label-taobao {
  background-color: #f22d00;
  color: #fff;
  text-decoration: none
}

.text-taobao {
  color: #f22d00 !important
}

.label-tmall,
.label.label-tmall {
  background-color: #ff0036;
  color: #fff;
  text-decoration: none
}

.text-tmall {
  color: #ff0036 !important
}

.label-joom,
.label.label-joom {
  background-color: #3d3f56;
  color: #fff;
  text-decoration: none
}

.text-joom {
  color: #3d3f56 !important
}

.label-shopee,
.label.label-shopee {
  background-color: #ff5722;
  color: #fff;
  text-decoration: none
}

.text-shopee {
  color: #ff5722 !important
}

.label-lazada,
.label.label-lazada {
  background-color: #44595b;
  color: #f8862d;
  text-decoration: none
}

.text-lazada {
  color: #44595b !important
}

.label-alibaba,
.label.label-alibaba {
  background-color: #ff6a00;
  color: #fff;
  text-decoration: none
}

.text-alibaba {
  color: #ff6a00 !important
}

.label-allegro,
.label.label-allegro {
  background-color: #ff5a00;
  color: #fff;
  text-decoration: none
}

.label-K100B2B,
.label.label-K100B2B {
  background-color: #ff0036;
  color: #fff;
  text-decoration: none
}

.label-banggood,
.label.label-banggood {
  background-color: #ffa200;
  color: #fff;
  text-decoration: none
}

.label-ozon,
.label.label-ozon {
  background-color: #0071dc;
  color: #fff;
  text-decoration: none
}

.label-temu,
.label.label-temu {
  background-color: #ff6a00;
  color: #fff;
  text-decoration: none
}

.label-tiktok,
.label.label-tiktok {
  background-color: #000;
  color: #fff;
  text-decoration: none
}

.label-other,
.label.label-other {
  background-color: #eee;
  color: #666;
  text-decoration: none
}

.label-supplier,
.label.label-supplier {
  background-color: #fff;
  color: #ff6000;
  border: 1px solid #ff6000;
  text-decoration: none
}

.label-supplier a {
  color: #ff6000;
  text-decoration: none
}

.label-supplier a img {
  width: 16px;
  height: 16px
}

.label-supplier a:hover {
  text-decoration: underline
}

.label-bao66,
.label.label-bao66 {
  background-color: #4184bd;
  color: #f18b26;
  text-decoration: none
}

.text-bao66 {
  color: #4184bd !important
}

.label-k3,
.label.label-k3 {
  background-color: #951c52;
  color: #fdfc00;
  text-decoration: none
}

.text-k3 {
  color: #951c52 !important
}

.label-sooxie,
.label.label-sooxie {
  background-color: #e30a43;
  color: #fff;
  text-decoration: none
}

.text-sooxie {
  color: #e30a43 !important
}

.label-xingfujie,
.label.label-xingfujie {
  background-color: #069;
  color: #fff;
  text-decoration: none
}

.text-xingfujie {
  color: #069 !important
}

.label-vvic,
.label.label-vvic {
  background-color: #f96388;
  color: #fff;
  text-decoration: none
}

.text-vvic {
  color: #f96388 !important
}

.label-17zwd,
.label.label-17zwd {
  background-color: #222;
  color: #e23b3d;
  text-decoration: none
}

.text-17zwd {
  color: #e23b3d !important
}

.label-kaola,
.label.label-kaola {
  background-color: #ff2337;
  color: #fff;
  text-decoration: none
}

.text-kaola {
  color: #ff2337 !important
}

.label-lightinthebox,
.label.label-lightinthebox {
  background-color: #cb2027;
  color: #fff;
  text-decoration: none
}

.text-lightinthebox {
  color: #cb2027 !important
}

.label-jd,
.label.label-jd {
  background-color: #f10215;
  color: #fff;
  text-decoration: none
}

.text-jd {
  color: #f10215 !important
}

.label-dhgate,
.label.label-dhgate {
  background-color: #fdd000;
  color: #231815;
  text-decoration: none
}

.text-dhgate {
  color: #231815 !important
}

.label-ezbuy,
.label.label-ezbuy {
  background-color: #e62222;
  color: #fff;
  text-decoration: none
}

.text-ezbuy {
  color: #e62222 !important
}

.label-yangkeduo,
.label.label-yangkeduo {
  background-color: #e02e24;
  color: #fff;
  text-decoration: none
}

.text-yangkeduo {
  color: #e02e24 !important
}

.label-hottrendtee,
.label.label-hottrendtee {
  background-color: #eee;
  color: #000;
  text-decoration: none
}

.text-hottrendtee {
  color: #000 !important
}

.label-shopline,
.label.label-shopline {
  background-color: #00142d;
  color: #fff;
  text-decoration: none
}

.text-shopline {
  color: #00142d !important
}

.label-mercadolibre,
.label.label-mercadolibre {
  background-color: #ffe600;
  color: #2d3277;
  text-decoration: none
}

.text-mercadolibre {
  color: #ffe600 !important
}

.label-darty,
.label.label-darty {
  background-color: #eb1c24;
  color: #fff;
  text-decoration: none
}

.text-darty {
  color: #eb1c24 !important
}

.label-tophatter,
.label.label-tophatter {
  background-color: #46249d;
  color: #fff;
  text-decoration: none
}

.text-tophatter {
  color: #46249d !important
}

.label-success {
  background-color: #67c23a
}

.label-success,
.label-warning {
  color: #fff !important;
  text-decoration: none
}

.label-warning {
  background-color: #e6a23c
}

.label-danger {
  background-color: #f56c6c
}

.label-danger,
.label-reverse {
  color: #fff !important;
  text-decoration: none
}

.label-reverse {
  background-color: #000
}

.image-header {

  position: relative;
  width: 100%;
  height: 0;
  padding-top: 100%;
}

.image-header img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: center;
}

p {
  margin: 0;
  padding: 0;
}
</style>
