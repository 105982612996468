<template>
  <div class="d-flex align-center mb-2">
    <a-row>
      <a-col> <span style="width:80px;">查询搜索：</span> </a-col>
      <a-col>
        <a-input-search v-model:value="queryForm.searchText" placeholder="SKU/名称/订单号查询" enter-button="搜索" allow-clear
          @search="getData" />
      </a-col>
    </a-row>
  </div>
  <div class="d-flex align-center mb-2">
    <a-row>
      <a-col> <span style="width:75px;">状态：</span> </a-col>
      <a-col>
        <a-tag v-for="item in states" :key="item.value"
          :color="queryForm.state === item.value ? (item.color || 'processing') : 'default'" class="click"
          @click="changeState(item.value)"> {{ item.label }} </a-tag>
      </a-col>
    </a-row>
  </div>
  <div class="d-flex align-center mb-2">
    <a-space v-if="queryForm.state === '0'">
      <a-button :class="{ 'btn-info': true, 'disabled': selectedRowKeys.length === 0 }"
        @click="bulk_requestPurchase('online')" :loading="request_purchaseing" :disabled="selectedRowKeys.length === 0">
        批量线上采购
      </a-button>
      <a-button :class="{ 'btn-purple': true, 'disabled': selectedRowKeys.length === 0 }"
        @click="bulk_requestPurchase('offline')" :loading="request_purchaseing"
        :disabled="selectedRowKeys.length === 0">
        批量线下采购
      </a-button>
    </a-space>
  </div>
  <a-table :columns="columns" :data-source="data" :loading="dataLoading" :scroll="{ x: width, y: height }"
    :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
    @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" row-key="_id"
    :row-selection="{ selectedRowKeys, onChange: (keys) => selectedRowKeys = keys }" size="small">
    <template #bodyCell="{ record, column }">
      <template v-if="column.dataIndex === 'cover'">
        <a-image :src="record.product?.cover || $filters.image_placeholder" :width="50" :height="50"
          style="object-fit: contain;height:100%;" :preview="false" />
      </template>
      <template v-if="column.dataIndex === 'product'">
        <a-tooltip color="white">
          <template #title>
            <a href="javascript:;" @click="$filters.doCopy(record.product._sku || record.product.sku)">复制</a>
          </template>
          <a href="javascript:;">{{ record.product._sku || record.product.sku }}</a>
        </a-tooltip>
        <p>{{ record.product.name_cn }}</p>
      </template>
      <template v-if="column.dataIndex === 'default_supplier'">
        {{ record.default_supplier?.name || '-' }}
      </template>
      <template v-if="column.dataIndex === 'source'">
        <p>店铺：{{ record.shop?.name }}</p>
        <p>订单号：{{ record.orderid }}</p>
        <p>时间：{{ record.update_time }}</p>
      </template>
      <template v-if="column.dataIndex === 'operation'">
        <template v-if="queryForm.state === '0'">
          <a-space>
            <a-button type="link" @click="requestPurchase(record, 'online')"
              :loading="request_purchaseing">线上采购</a-button>
            <a-popconfirm title="取消采购计划" @confirm="cancelPurchase(record._id)" placement="left">
              <a-button type="link" danger :loading="request_purchaseing">取消</a-button>
            </a-popconfirm>
          </a-space>
          <a-space>
            <a-button type="link" class="text-purple" @click="requestPurchase(record, 'offline')"
              :loading="request_purchaseing">线下采购</a-button>
            <a-popconfirm title="标记采购计划所需商品已采购？" @confirm="markPurchase(record._id)" placement="left">
              <a-button type="link" danger :loading="request_purchaseing">标记</a-button>
            </a-popconfirm>
          </a-space>
        </template>
        <template v-if="queryForm.state === '1'">
          <a-space v-if="!record.return_reuse">
            <a-popconfirm title="订单退款/退货，已采购商品重新入库？" @confirm="returnPurchase(record._id)" placement="left">
              <a-button type="link" danger :loading="request_purchaseing">退货入库</a-button>
            </a-popconfirm>
          </a-space>
        </template>
        <template v-if="queryForm.state === '2'">
          <a-space>
            <a-popconfirm title="恢复采购计划？" @confirm="recoverPurchase(record._id)" placement="left">
              <a-button type="link" danger :loading="request_purchaseing">恢复</a-button>
            </a-popconfirm>
          </a-space>
        </template>
      </template>
    </template>
  </a-table>
  <a-modal v-model:visible="purchaseModal" title="申请采购" @ok="confirmPurchase" @cancel="() => purchaseModal = false"
    ok-text="确认" cancel-text="取消" :confirm-loading="purchasing" :cancel-button-props="{ disabled: purchasing }"
    :maskClosable="false" :closable="!purchasing" width="1000px" :bodyStyle="{ padding: 0 }">
    <a-table :columns="pcolumns" :data-source="purchase_list" :scroll="{ x: pwidth }" :pagination="false" size="small">
      <template #headerCell="{ column }">
        <template v-if="column.dataIndex === 'sku'">
          <p>sku</p>
          <p>商品中文名</p>
        </template>
        <template v-if="column.dataIndex === 'purchase_platform'">
          <p>采购平台</p>
          <a-select v-model:value="supplier_platform" :options="supplier_platforms" :disabled="purchasing" show-search
            :filter-option="$filters.filterOption" @change="changeSupplierPlatform" />
        </template>
      </template>
      <template #bodyCell="{ record, column }">
        <template v-if="column.dataIndex === 'cover'">
          <a-image :src="record.product?.cover || $filters.image_placeholder" :width="50" :height="50"
            style="object-fit: contain;height:100%;" :preview="false" />
        </template>
        <template v-if="column.dataIndex === 'sku'">
          <a-tooltip color="white">
            <template #title>
              <a href="javascript:;" @click="$filters.doCopy(record.product._sku || record.product.sku)">复制</a>
            </template>
            <a href="javascript:;">{{ record.product._sku || record.product.sku }}</a>
          </a-tooltip>
          <p>{{ record.product.name_cn }}</p>
        </template>
        <template v-if="column.dataIndex === 'product_supplier_id'">
          <a-select v-model:value="record.product_supplier_id" :options="record.suppliers" :disabled="purchasing"
            show-search :filter-option="$filters.filterOption" />
        </template>
        <template v-if="column.dataIndex === 'purchase_platform'">
          <a-select v-model:value="record.purchase_platform" :options="supplier_platforms" :disabled="purchasing"
            show-search :filter-option="$filters.filterOption" />
        </template>
        <template v-if="column.dataIndex === 'purchase_quantity'">
          <a-input v-model:value="record.purchase_quantity" type="number" :disabled="purchasing" />
        </template>
        <template v-if="column.dataIndex === 'purchase_price'">
          <a-input v-model:value="record.purchase_price" type="number" :disabled="purchasing" />
        </template>
        <template v-if="column.dataIndex === 'source'">
          <p>{{ record.shop?.name }}</p>
          <p>{{ record.orderid }}</p>
        </template>
        <template v-if="column.dataIndex === 'operation'">
          <a-space>
            <a v-if="purchase_list.length > 1" href="javascript:;" class="text-error"
              @click="removePurchase(record)">删除</a>
          </a-space>
        </template>
      </template>
    </a-table>
  </a-modal>
</template>
<script setup>
import { DownOutlined, SearchOutlined, PlusCircleOutlined } from "@ant-design/icons-vue";
import { Modal, message } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from "vue";
import { useRouter } from 'vue-router';
import _ from "underscore";
const columns = [
  { title: '图片', dataIndex: 'cover', width: 100, },
  { title: '商品信息', dataIndex: 'product', width: 150 },
  { title: '默认供应商', dataIndex: 'default_supplier', width: 150, },
  { title: '需求数量', dataIndex: 'quantity', width: 100, },
  { title: '来源', dataIndex: 'source', width: 200, },
  { title: '添加时间', dataIndex: 'create_time', width: 100, },
  { title: '操作', dataIndex: 'operation', width: 120, align: 'center', fixed: 'right' },
];
const { proxy } = getCurrentInstance();
const router = useRouter();

const height = ref(600);
const queryForm = ref({
  searchText: "",
  state: "0",
});
const states = ref([
  { value: "0", label: "待采购" },
  { value: "1", label: "已采购" },
  { value: "2", label: "已标记" },
  // { value: "3", label: "已取消" },
]);
const dataLoading = ref(false);
const page = ref(1);
const limit = ref(10);
const length = ref(0);
const count = ref(0);
const data = ref([]);
const selectedRowKeys = ref([]);
const request_purchaseing = ref(false);
const purchase_list = ref([]);
const purchase_type = ref("");
const purchaseModal = ref(false);
const purchasing = ref(false);
const supplier_platform = ref("");
const supplier_platforms = ref([]);

const width = computed(() => proxy.$utils.twidth(columns));
const pcolumns = computed(() => [
  { title: '缩略图', dataIndex: 'cover', width: 80, },
  { title: 'sku/商品中文名', dataIndex: 'sku', width: 120, },
  ...(
    purchase_type.value === 'online' ?
      [
        { title: '供应商', dataIndex: 'product_supplier_id', width: 100, },
      ]
      : (
        purchase_type.value === 'offline' ?
          [
            { title: '采购平台', dataIndex: 'purchase_platform', width: 100, },
          ]
          : []
      )
  ),
  { title: '需求', dataIndex: 'quantity', width: 80, },
  { title: '采购', dataIndex: 'purchase_quantity', width: 100, },
  { title: '价格', dataIndex: 'purchase_price', width: 100, },
  { title: '来源', dataIndex: 'source', width: 200, },
  { title: '操作', dataIndex: 'operation', width: 80, },
])
const pwidth = computed(() => proxy.$utils.twidth(pcolumns.value));
onMounted(() => {
});
onActivated(() => {
  getData();
});
watch(() => page.value, () => getData());
watch(() => limit.value, () => {
  if (page.value !== 1) page.value = 1;
  else getData();
});
const changeState = (value) => {
  queryForm.value.state = value;
  getData();
};
const getData = () => {
  selectedRowKeys.value = [];
  dataLoading.value = true;
  proxy.$api.doAPI("/purchaseorder/planlist", { page: page.value, limit: limit.value, ...queryForm.value, }).then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      data.value = _.map(res.data.list, n => {
        return {
          ...n,
          create_time: proxy.$utils.dateFormat(n.create_time, 'YYYY-MM-DD HH:mm:ss'),
          update_time: proxy.$utils.dateFormat(n.update_time, 'YYYY-MM-DD HH:mm:ss'),
        }
      });
      length.value = res.data.length;
      count.value = res.data.count;
      if (length.value > 0 && page.value > length.value) page.value = 1;
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
};
const requestPurchase = (item, type) => {
  _requestPurchase([item._id], type);
}
const bulk_requestPurchase = (type) => {
  if (selectedRowKeys.value.length === 0) return;
  _requestPurchase(selectedRowKeys.value, type);
}
const _requestPurchase = (ids, type) => {
  let list = _.filter(data.value, n => ids.indexOf(n._id) > -1);
  console.log(ids, list);
  if (type === 'online' && _.find(list, n => !n.default_supplier)) return message.error("商品没有关联供应商");
  request_purchaseing.value = true;
  proxy.$api.doAPI("/purchaseorder/prepurchase", { ids, type }).then((res) => {
    request_purchaseing.value = false;
    if (res.code === 0) {
      purchase_list.value = res.data.list;
      supplier_platform.value = res.data.supplier_platform;
      supplier_platforms.value = res.data.supplier_platforms;
      purchase_type.value = type;
      purchaseModal.value = true;
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    request_purchaseing.value = false;
    console.error(err);
  });
}
const changeSupplierPlatform = (v) => {
  console.log('changeSupplierPlatform', v);
  purchase_list.value.map(n => n.purchase_platform = v);
}
const confirmPurchase = () => {
  let list = _.map(purchase_list.value, n => {
    return {
      _id: n._id,
      product_supplier_id: n.product_supplier_id,
      purchase_platform: n.purchase_platform,
      purchase_quantity: n.purchase_quantity,
      purchase_price: n.purchase_price,
    }
  });
  if (purchase_type.value === 'online' && _.find(list, n => !n.product_supplier_id)) return message.error("商品没有关联供应商");
  if (purchase_type.value === 'offline' && _.find(list, n => !n.purchase_platform)) return message.error("商品没有关联采购平台");
  purchasing.value = true;
  proxy.$api.doAPI("/purchaseorder/confirmrequirement", { purchase_list: list, purchase_type: purchase_type.value }).then((res) => {
    purchasing.value = false;
    if (res.code === 0) {
      purchaseModal.value = false;
      getData();
      Modal.confirm({
        title: '提示',
        content: `采购单创建成功，是否查看采购单？`,
        onOk() {
          router.push({ path: `/purchase/manage/order` });
        },
        onCancel() {
        },
      })
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    purchasing.value = false;
    console.error(err);
  });
}
const removePurchase = (item) => {
  purchase_list.value = _.filter(purchase_list.value, n => n._id !== item._id);
  selectedRowKeys.value = _.filter(selectedRowKeys.value, n => n !== item._id);
}
const cancelPurchase = (_id) => {
  request_purchaseing.value = true;
  proxy.$api.doAPI("/purchaseorder/cancelrequirement", { _id }).then((res) => {
    request_purchaseing.value = false;
    if (res.code === 0) {
      getData();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    request_purchaseing.value = false;
    console.error(err);
  });
}
const markPurchase = (_id) => {
  request_purchaseing.value = true;
  proxy.$api.doAPI("/purchaseorder/markrequirement", { _id }).then((res) => {
    request_purchaseing.value = false;
    if (res.code === 0) {
      getData();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    request_purchaseing.value = false;
    console.error(err);
  });
}
const returnPurchase = (_id) => {
  request_purchaseing.value = true;
  proxy.$api.doAPI("/purchaseorder/returnrequirement", { _id }).then((res) => {
    request_purchaseing.value = false;
    if (res.code === 0) {
      getData();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    request_purchaseing.value = false;
    console.error(err);
  });
}
const recoverPurchase = (_id) => {
  request_purchaseing.value = true;
  proxy.$api.doAPI("/purchaseorder/recoverrequirement", { _id }).then((res) => {
    request_purchaseing.value = false;
    if (res.code === 0) {
      getData();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    request_purchaseing.value = false;
    console.error(err);
  });
}
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

p {
  margin: 0;
  padding: 0;
}
</style>