<template>
  <div class="d-flex justify-space-between align-center mb-2">
    <a-button type="primary" @click="getData">Refresh List</a-button>
    <a-button type="primary" @click="constructShipment">
      <template #icon>
        <PlusCircleOutlined />
      </template>Get shipment
    </a-button>
  </div>
  <a-table :columns="columns" :data-source="data" :scroll="{ x: width }" :loading="dataLoading"
    :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
    @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" size="small">
    <template #bodyCell="{ record, column }">
      <template v-if="column.dataIndex === 'type'">
        <a-tooltip>
          <template #title> {{ record.type }} </template>
          {{ gettypename(record) }}
        </a-tooltip>
      </template>
      <template v-if="column.dataIndex === 'marketplaceIds'">
        <a-tooltip>
          <template #title> {{ record.marketplaceIds.join(',') }} </template>
          <p class="in1line">{{ record.marketplaceIds.join(',') }}</p>
        </a-tooltip>
      </template>
      <template v-if="column.dataIndex === 'documentId'">
        <a-tooltip>
          <template #title> {{ record.documentId }} </template>
          <p class="in1line">{{ record.documentId }}</p>
        </a-tooltip>
      </template>
      <template v-if="column.dataIndex === 'download_url'">
        <a-tooltip>
          <template #title> {{ record.download_url }} </template>
          <p class="in1line">{{ record.download_url }}</p>
        </a-tooltip>
      </template>
      <template v-if="column.dataIndex === 'operation'">
        <div class="d-flex flex-column">
          <a v-if="record.step === 'getShipments'" href="javascript:;" @click="getShipments(record._id)">
            Get shipments
          </a>
          <a v-if="record.step === 'createShipment'" href="javascript:;" @click="createShipment(record._id)">
            1. Create shipment
          </a>
          <a v-if="record.step === 'getShipment'" href="javascript:;" @click="getShipment(record._id)">
            2. Get shipment
          </a>
          <a v-if="record.status === 'DONE' && record.step !== 'SUCCESS'" href="javascript:;"
            @click="getDocument(record._id)">
            3. Get document
          </a>
          <a v-if="record.step === 'downloadShipmentDocument'" href="javascript:;"
            @click="downloadDocument(record._id)">
            4. Download document
          </a>
          <a v-if="record.step === 'SUCCESS'" href="javascript:;" @click="showDocument(record._id, 1)">
            Show document
          </a>
          <!-- <a v-if="record.step === 'SUCCESS' && !record.saved" href="javascript:;" @click="showDocument(record._id, 2)">
            Save document
          </a> -->
        </div>
      </template>
    </template>
  </a-table>
  <a-modal v-model:visible="shipmentModal" title="Construct shipment" :maskClosable="false"
    :closable="!shipmentSubmiting" width="750px" @cancel="cancelSubmitShipment">
    <a-form ref="shipmentFormRef" name="shipmentForm" :model="shipmentForm" :label-col="{ span: 8 }"
      :wrapper-col="{ span: 16 }" autocomplete="off">
      <a-form-item label="Shop" name="shopid" :rules="[{ required: true, message: 'Shop can\'t be empty' }]">
        <a-select v-model:value="shipmentForm.shopid" :options="shops" placeholder="shop" :disabled="shipmentSubmiting"
          show-search :filter-option="$filters.filterOption" />
      </a-form-item>
      <a-form-item label="ShipmentId" name="shipmentId"
        :rules="[{ required: true, message: 'ShipmentId can\'t be empty' }]">
        <a-input v-model:value="shipmentForm.shipmentId" placeholder="shipment id"
          :disabled="shipmentSubmiting"></a-input>
      </a-form-item>
    </a-form>
    <template #footer>
      <div class="d-flex justify-space-between">
        <a-button type="danger" :disabled="!shipmentForm.shopid" :loading="checking" @click="checkShipmentAccount">
          Check Shipment Account
        </a-button>
        <a-space>
          <a-button :disabled="shipmentSubmiting" @click="cancelSubmitShipment">Cancel</a-button>
          <a-button type="primary" :loading="shipmentSubmiting" @click="submitShipment">Submit</a-button>
        </a-space>
      </div>
    </template>
  </a-modal>
</template>
<script>
import { DownOutlined, SearchOutlined, PlusCircleOutlined, EyeOutlined } from '@ant-design/icons-vue';
import { message, Modal } from "ant-design-vue";
import {
  defineComponent,
  ref,
  reactive,
  toRefs,
  watch,
  computed,
  onMounted,
  onActivated,
  getCurrentInstance,
} from "vue";
import _ from "underscore";
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
const columns = [
  { title: 'Title', dataIndex: 'title', width: 120, },
  { title: 'Step', dataIndex: 'step', width: 120, },
  { title: 'ShipmentId', dataIndex: 'shipmentId', width: 120, },
  { title: 'ClientReferenceId', dataIndex: 'clientReferenceId', width: 120, },
  { title: 'CreateTime', dataIndex: 'create_time', width: 120, },
  { title: 'Operation', dataIndex: 'operation', width: 150, fixed: 'right' },
];
export default defineComponent({
  name: 'AmazonShippingApi',
  components: { DownOutlined, SearchOutlined, PlusCircleOutlined, EyeOutlined },
  setup() {
    const { proxy } = getCurrentInstance();
    const shipmentFormRef = ref();
    const state = reactive({
      page: 1,
      limit: 10,
      data: [],
      length: 0,
      count: 0,
      dataLoading: false,
      shops: [],
      shipmentForm: {},
      shipmentModal: false,
      shipmentSubmiting: false,
      checking: false,
    });
    const width = computed(() => proxy.$utils.twidth(columns));
    watch(() => state.page, () => {
      getData();
    });
    watch(() => state.limit, () => {
      if (state.page !== 1) state.page = 1;
      else getData();
    });
    onActivated(() => {
      getData();
    });
    const getData = () => {
      state.dataLoading = true;
      proxy.$api.doAPI2("/dev/amazon/shipping/list", { page: state.page, limit: state.limit }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
          state.data = _.map(res.data.list, n => {
            return {
              ...n,
              create_time: proxy.$utils.dateFormat(n.create_time, 'YYYY-MM-DD HH:mm:ss'),
            };
          });
          state.length = res.data.length;
          state.count = res.data.count;
          if (state.length > 0 && state.page > state.length) state.page = 1;
          state.shops = res.data.shops;
        } else {
          message.error(res.msg);
        }
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    };
    const constructShipment = () => {
      state.shipmentForm = {
        shopid: null,
        shipmentId: "",
      };
      state.shipmentModal = true;
    }
    const submitShipment = async () => {
      try {
        await shipmentFormRef.value.validateFields();
        state.dataLoading = true;
        proxy.$api.doAPI2("/dev/amazon/shipment/submit", { shipmentForm: state.shipmentForm }).then((res) => {
          state.dataLoading = false;
          if (res.code === 0) {
            state.shipmentModal = false;
          } else {
            message.error(res.msg);
          }
          getData();
        }).catch((err) => {
          state.dataLoading = false;
          console.error(err);
        });
      } catch (errorInfo) {
        console.log("Failed:", errorInfo);
      }
    }
    const cancelSubmitShipment = () => {
      shipmentFormRef.value.clearValidate();
      state.shipmentModal = false;
    }
    const getShipment = (_id) => {
      state.dataLoading = true;
      proxy.$api.doAPI2("/dev/amazon/shipment/get", { _id }).then((res) => {
        state.dataLoading = false;
        if (res.code === 0) {
        } else {
          message.error(res.msg);
        }
        getData();
      }).catch((err) => {
        state.dataLoading = false;
        console.error(err);
      });
    }
    const checkShipmentAccount = () => {
      state.checking = true;
      proxy.$api.doAPI2("/dev/amazon/shipment/get_account", { shopid: state.shipmentForm.shopid }).then((res) => {
        state.checking = false;
        if (res.code === 0) {
        } else {
          message.error(res.msg);
        }
        getData();
      }).catch((err) => {
        state.checking = false;
        console.error(err);
      });
    }
    return {
      ...toRefs(state),
      locale,
      shipmentFormRef,
      columns,
      width,
      getData,
      constructShipment,
      submitShipment,
      cancelSubmitShipment,
      getShipment,
      checkShipmentAccount,
    };
  }
});
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

p {
  margin: 0;
  padding: 0;
}
</style>
