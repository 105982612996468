<template>
  <div class="header">
    <div class="type">
      <span style="width:75px; display:inline-block; text-align: right;">店铺：</span>
      <a-dropdown>
        <template #overlay>
          <a-menu @click="handleMenuClick">
            <a-menu-item key="1">优贝克</a-menu-item>
            <a-menu-item key="2">南鱼</a-menu-item>
            <a-menu-item key="3">候亚磊</a-menu-item>
          </a-menu>
        </template>
        <a-button>
          全部店铺
          <DownOutlined />
        </a-button>
      </a-dropdown>
    </div>
    <div class="search">
      <span style="width:80px;">查询搜索：</span>
      <a-input-search v-model:value="value" placeholder="商品sku" enter-button="搜索" @search="onSearch" />
    </div>
    <div class="type">
      国家区域：
      <a-radio-group v-model:value="inventorystate">
        <a-radio-button value="all">全部</a-radio-button>
        <a-radio-button value="US">美国</a-radio-button>
        <a-radio-button value="CA">加拿大</a-radio-button>
        <a-radio-button value="UK">英国</a-radio-button>
      </a-radio-group>
    </div>
    <div class="type">
      查询时间：
      <a-tag color="blue" class="querytime-tag">过去1天</a-tag>
      <a-tag color="" class="querytime-tag">过去7天</a-tag>
      <a-tag color="" class="querytime-tag">过去30天</a-tag>
      <a-tag color="" class="querytime-tag">过去90天</a-tag>
    </div>
    <div class="op">
      <a-button type="primary">批量导出客户</a-button>
    </div>
  </div>
  <a-table :columns="columns" bordered :data-source="data" :scroll="{ x: width }" size="small">
    <template #bodyCell="{ column, record }">
      <template v-if="column.dataIndex === 'buyeraccount'">
        <span style="width:120px; display:inline-block;">{{ record.buyeraccount }}</span>
      </template>
    </template>
  </a-table>
</template>
<script>
import { DownOutlined } from '@ant-design/icons-vue';
import { message, Modal } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from 'vue';
import _ from "underscore";
const columns = [
  { title: '买家账号', dataIndex: 'buyeraccount', key: 'buyeraccount', width: 100, align: 'center' },
  { title: '国家区域', dataIndex: 'country', key: 'country', width: 150, align: 'center', },
  { title: '下单金额($)', dataIndex: 'amount', key: 'name', width: 120, align: 'center', },
  { title: '下单数量', dataIndex: 'count', key: 'count', width: 120, align: 'center' },
  { title: '付款金额($)', dataIndex: 'payamount', key: 'payamount', width: 120, align: 'center' },
  { title: '付款单数', dataIndex: 'paycount', key: 'paycount', width: 120, align: 'center' },
  { title: '退款金额($)', dataIndex: 'refundamount', key: 'amount', width: 120, align: 'center' },
  { title: '退款单数', dataIndex: 'refundcount', key: 'refundcount', width: 120, align: 'center' },
  { title: '最后下单时间', dataIndex: 'lastsendtime', key: 'lastsendtime', width: 150, align: 'center' },
  { title: '店铺', dataIndex: 'shopname', key: 'shopname', width: 120, align: 'center' },
];
const data = [];
for (let i = 0; i < 46; i++) {
  data.push({
    buyeraccount: 'vx07mnrq86m7dzx@marketplace.amazon.com',
    country: "美国",
    amount: '18.5',
    count: '1',
    payamount: '18.5',
    paycount: '1',
    refundamount: '0',
    refundcount: '0',
    lastsendtime: '2022-11-26 10:12:34',
    shopname: '杨洪程-美国'
  });
}

export default defineComponent({
  name: 'CustomerserviceCustomers',
  components: {
    DownOutlined
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const state = reactive({
      inventorystate: 'all',
      data,
    });
    const width = computed(() => proxy.$utils.twidth(columns));
    return {
      ...toRefs(state),
      columns,
      width,
    };
  }
});
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

.header {
  margin-bottom: 10px;

  .search {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }

  .type {
    margin: 10px 0;
  }

  .op {
    display: flex;
    justify-content: space-between;
  }
}

p {
  margin: 0;
  padding: 0;
}
</style>

