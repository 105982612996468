<template>
  <div class="static">
    <a-row>
      <a-col :span="5">
        <a-statistic
          title="总授信额度(¥)"
          :precision="2"
          :value="total_grant"
          style="margin-right: 50px"
        />
      </a-col>
      <a-col :span="5">
        <a-statistic title="已申请(¥)" :precision="2" :value="total_applied" />
      </a-col>
      <a-col :span="5">
        <a-statistic title="已放款(¥)" :precision="2" :value="total_loan" />
      </a-col>
      <a-col :span="5">
        <a-statistic title="已还款(¥)" :precision="2" :value="total_repayment" />
      </a-col>
      <a-col :span="4">
        <a-statistic title="已产生利息(¥)" :precision="2" :value="total_interest" />
      </a-col>
    </a-row>
  </div>
  <div class="d-flex justify-space-between align-center mb-2">
    <a-space>
      <a-button type="link" @click="getData">刷新数据</a-button>
    </a-space>
  </div>
  <a-table bordered :columns="columns" :data-source="data" :loading="dataLoading" :scroll="{ x: width, y: 600 }"
    :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
    @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" row-key="_id"
    :row-selection="{ selectedRowKeys, onChange: (keys) => selectedRowKeys = keys }">
    <template #bodyCell="{ record, column }">
      <template v-if="column.dataIndex === 'agreement'">
         <a href="javascript:;">上传借款协议</a>
      </template>
      <template v-if="column.dataIndex === 'state'">
        <a-tag v-if="record.state === 'pending'" class="tag-warning">审批中</a-tag>
        <a-tag v-if="record.state === 'passed'" class="tag-success">审批通过</a-tag>
        <a-tag v-if="record.state === 'rejected'" class="tag-error">审批驳回</a-tag>
        <a-tag v-if="record.state === 'loaning'" class="tag-info">放款中</a-tag>
        <a-tag v-if="record.state === 'finished'" class="tag-success">已放款</a-tag>
      </template>
      <template v-if="column.dataIndex === 'operation'">
        <a-space>
          <a href="javascript:;" class="text-success" @click="applyCredit">申请授信</a>
          <!--申请授信审批通过，授信额度大于0，然后可以申请额度-->
          <!-- <a href="javascript:;" class="text-success" @click="addCredit">申请额度</a> -->
        </a-space>
      </template>
    </template>
  </a-table>
  <a-drawer v-model:visible="creditDrawer" title="申请放款" placement="right" :maskClosable="!saving" :closable="!saving"
    @close="cancelSave" width="50%">
    <a-form ref="creditFormRef" name="creditForm" :model="creditForm" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }"
      autocomplete="off">
      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item label="申请额度" name="apply_quota" :rules="[{ required: true, message: '请输入申请额度' }]">
            <a-input v-model:value="creditForm.apply_quota" type="number" placeholder="申请额度" :disabled="saving">
            </a-input>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="放款日期" name="repayment_date" :rules="[{ required: true, message: '请选择放款日期' }]">
            <a-date-picker v-model:value="creditForm.repayment_date" :locale="locale" :show-now="true" :show-today="true"
              value-format="YYYY-MM-DD" style="width:100%" :disabled="saving" />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <a-alert
      message="温馨提示"
      description="申请放款的总额不能超过系统给店铺的授信额度，如有疑问请联系客服。"
      type="warning"
      show-icon
    />
    <template #extra>
      <a-space>
        <a-button @click="cancelSave">取消</a-button>
        <a-button type="primary" @click="saveCredit">提交</a-button>
      </a-space>
    </template>
  </a-drawer>
</template>
<script>
import dayjs from 'dayjs';
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import { DownOutlined, SearchOutlined, PlusCircleOutlined, PlusOutlined,CheckOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, toRaw, } from "vue";
import _ from "underscore";
const columns = [
  { title: '店铺名称', dataIndex: 'name', width: 150 },
  { title: '近6个月的订单数量', dataIndex: 'order_count-6', width: 200, },
  { title: '近6个月的订单总金额', dataIndex: 'order_total-6', width: 200, },
  { title: '可授信额度(¥)', dataIndex: 'lineofcredit', width: 150, },
  { title: '已授信额度(¥)', dataIndex: 'lineofcredited', width: 150, },
  { title: '借款协议', dataIndex: 'agreement', width: 150, },
  { title: '状态', dataIndex: 'state', width: 100, },
  { title: '操作', dataIndex: 'operation', width: 100, fixed: "right" }
];
export default defineComponent({
  name: "SupplychainFinance",
  components: {
    DownOutlined,
    SearchOutlined,
    PlusCircleOutlined,
    PlusOutlined,
    CheckOutlined
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const creditFormRef = ref();
    const auditFormRef = ref();
    const state = reactive({
      total_grant: 0,
      total_applied: 0,
      total_loan: 0,
      total_interest: 0,
      total_repayment:0,
      dataLoading: false,
      page: 1,
      limit: 20,
      length: 0,
      count: 0,
      data: [],
      creditForm: {},
      creditDrawer: false,
      saving: false,
      auditForm: {},
      auditModal: false,
      auditing: false,
      selectedRowKeys: [],
    });
    const width = computed(() => proxy.$utils.twidth(columns));
    onMounted(() => {
    });
    onActivated(() => {
      getData();
      getStatistics();
    });
    watch(() => state.page, () => {
      getData();
    });
    watch(() => state.limit, () => {
      if (state.page !== 1) state.page = 1;
      else getData();
    });
    const getData = () => {
        state.data = [
           {name:'XXX店铺','order_count-6':'','order_total-6':'',lineofcredit:'',lineofcredited:'',agreement:'',operation:''}
        ];
    };
    const getStatistics = () => {
     
    };
    const initForm = () => {
      return {
        _id: "",
        orgid: null, apply_date: null, apply_quota: '', repayment_type: null, repayment_date: null,
      };
    };
    ///申请放款
    const addCredit = () => {
      state.creditForm = initForm();
      state.creditDrawer = true;
    };
    const cancelSave = () => {
      creditFormRef.value.clearValidate();
      state.creditDrawer = false;
    };
    ///还款申请
    const refund = () =>{
        
    };
    const saveCredit = async () => {
      
    };
    const showAudit = (item) => {
      state.auditForm = {
        _id: item._id, org: getorgname(item.orgid),
        ..._.pick(item, ['number', 'apply_date', 'apply_quota', 'repayment_type', 'repayment_date']),
        grant_state: 'passed', grant_remark: '', grant_quota: '', grant_date: null,
      };
      state.auditModal = true;
    }
    const changeGrantState = (state) => {
      if (state === 'rejected') {
        state.auditForm = {
          ...toRaw(state.auditForm),
          grant_quota: '', grant_date: null,
        };
      }
      auditFormRef.value.clearValidate();
    }
    const submitAudit = async () => {
      
    }
    const cancelAudit = () => {
      auditFormRef.value.clearValidate();
      state.auditModal = false;
    }
    const getorgname = (orgid) => {
      return _.findWhere(state.orgs, { _id: orgid })?.name;
    }
    return {
      ...toRefs(state),
      dayjs,
      locale,
      columns,
      creditFormRef,
      auditFormRef,
      width,
      getData,
      addCredit,
      cancelSave,
      saveCredit,
      showAudit,
      changeGrantState,
      submitAudit,
      cancelAudit,
      getorgname,
    };
  },
});
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

p {
  margin: 0;
  padding: 0;
}

.static {
  padding: 20px;
  background-color: #f2f2f2;
  margin-bottom: 10px;
}
</style>