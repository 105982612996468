<template>
  <div class="d-flex justify-space-between align-center mb-2">
    <a-button type="primary" @click="getData">Refresh List</a-button>
    <a-button type="primary" @click="constructReport">
      <template #icon>
        <PlusCircleOutlined />
      </template>Construct a report
    </a-button>
  </div>
  <a-table :columns="columns" :data-source="data" :scroll="{ x: width }" :loading="dataLoading"
    :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
    @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" size="small">
    <template #bodyCell="{ record, column }">
      <template v-if="column.dataIndex === 'type'">
        <a-tooltip>
          <template #title> {{ record.type }} </template>
          {{ gettypename(record) }}
        </a-tooltip>
      </template>
      <template v-if="column.dataIndex === 'marketplaceIds'">
        <a-tooltip>
          <template #title> {{ record.marketplaceIds.join(',') }} </template>
          <p class="in1line">{{ record.marketplaceIds.join(',') }}</p>
        </a-tooltip>
      </template>
      <template v-if="column.dataIndex === 'documentId'">
        <a-tooltip>
          <template #title> {{ record.documentId }} </template>
          <p class="in1line">{{ record.documentId }}</p>
        </a-tooltip>
      </template>
      <template v-if="column.dataIndex === 'download_url'">
        <a-tooltip>
          <template #title> {{ record.download_url }} </template>
          <p class="in1line">{{ record.download_url }}</p>
        </a-tooltip>
      </template>
      <template v-if="column.dataIndex === 'operation'">
        <div class="d-flex flex-column">
          <a v-if="record.step === 'getReports'" href="javascript:;" @click="getReports(record._id)">
            Get reports
          </a>
          <a v-if="record.step === 'createReport'" href="javascript:;" @click="createReport(record._id)">
            1. Create report
          </a>
          <a v-if="record.step === 'getReport'" href="javascript:;" @click="getReport(record._id)">
            2. Get report
          </a>
          <a v-if="record.status === 'DONE' && record.step !== 'SUCCESS'" href="javascript:;"
            @click="getDocument(record._id)">
            3. Get document
          </a>
          <a v-if="record.step === 'downloadReportDocument'" href="javascript:;" @click="downloadDocument(record._id)">
            4. Download document
          </a>
          <a v-if="record.step === 'SUCCESS'" href="javascript:;" @click="showDocument(record._id, 1)">
            Show document
          </a>
          <!-- <a v-if="record.step === 'SUCCESS' && !record.saved" href="javascript:;" @click="showDocument(record._id, 2)">
            Save document
          </a> -->
        </div>
      </template>
    </template>
  </a-table>
  <a-modal v-model:visible="reportModal" title="Construct report" @ok="submitReport" @cancel="cancelSubmitReport"
    ok-text="Submit" cancel-text="Cancel" :confirm-loading="reportSubmiting"
    :cancel-button-props="{ disabled: reportSubmiting }" :maskClosable="false" :closable="!reportSubmiting"
    width="750px">
    <a-form ref="reportFormRef" name="reportForm" :model="reportForm" :label-col="{ span: 8 }"
      :wrapper-col="{ span: 16 }" autocomplete="off">
      <a-form-item label="Shop" name="shopid" :rules="[{ required: true, message: 'Shop can\'t be empty' }]">
        <a-select v-model:value="reportForm.shopid" :options="shops" placeholder="shop" :disabled="reportSubmiting"
          show-search :filter-option="$filters.filterOption" />
      </a-form-item>
      <a-form-item label="ReportType" name="type" :rules="[{ required: true, message: 'ReportType can\'t be empty' }]">
        <a-select v-model:value="reportForm.type" :options="reportTypes" placeholder="report type"
          :disabled="reportSubmiting" show-search :filter-option="$filters.filterOption" />
      </a-form-item>
      <template
        v-if="reportForm.type === 'GET_XML_ALL_ORDERS_DATA_BY_LAST_UPDATE_GENERAL' || reportForm.type === 'GET_FLAT_FILE_ALL_ORDERS_DATA_BY_LAST_UPDATE_GENERAL'">
        <a-form-item label="DateRange" name="daterange"
          :rules="[{ required: true, message: 'DateRange can\'t be empty' }, { validator: validateDateRange, trigger: 'change' },]">
          <a-range-picker v-model:value="reportForm.daterange" :locale="locale" value-format="YYYY-MM-DD" />
        </a-form-item>
      </template>
      <template v-if="reportForm.type === 'GET_BRAND_ANALYTICS_SEARCH_TERMS_REPORT'">
        <a-form-item label="ReportPeriod" name="reportPeriod"
          :rules="[{ required: true, message: 'ReportPeriod can\'t be empty' }]">
          <a-select v-model:value="reportForm.reportPeriod" :options="reportPeriods" placeholder="report period"
            :disabled="reportSubmiting" show-search :filter-option="$filters.filterOption" />
        </a-form-item>
        <a-form-item label="Date" name="date" :rules="[{ required: true, message: 'Date can\'t be empty' }]">
          <a-date-picker v-if="reportForm.reportPeriod === 'DAY'" v-model:value="reportForm.date" :locale="locale" />
          <a-date-picker v-if="reportForm.reportPeriod === 'WEEK'" v-model:value="reportForm.date" :locale="locale"
            picker="week" />
          <a-date-picker v-if="reportForm.reportPeriod === 'MONTH'" v-model:value="reportForm.date" :locale="locale"
            picker="month" />
          <a-date-picker v-if="reportForm.reportPeriod === 'QUARTER'" v-model:value="reportForm.date" :locale="locale"
            picker="quarter" />
        </a-form-item>
      </template>
    </a-form>
  </a-modal>
  <a-drawer v-model:visible="documentDrawer" title="Report Documents" placement="right" width="50%">
    <a-table :columns="dcolumns" :data-source="documents" :scroll="{ y: 600 }"
      :pagination="{ current: dpage, pageSize: dlimit, total: dcount, showQuickJumper: false, showSizeChanger: false, }"
      @change="(pagination) => { dpage = pagination.current; dlimit = pagination.pageSize; }" size="small">
    </a-table>
  </a-drawer>
  <a-modal v-model:visible="documentModal" title="Report Documents" @ok="saveDocument"
    @cancel="() => { documentModal = false }" ok-text="Save" cancel-text="Cancel" :confirm-loading="documentSaving"
    :cancel-button-props="{ disabled: documentSaving }" :maskClosable="false" :closable="!documentSaving"
    width="1000px">
    <a-table :columns="dcolumns" :data-source="documents" :loading="documentSaving" :scroll="{ y: 600 }"
      :pagination="{ current: dpage, pageSize: dlimit, total: dcount, showQuickJumper: false, showSizeChanger: false, }"
      @change="(pagination) => { dpage = pagination.current; dlimit = pagination.pageSize; }" size="small">
      <template #bodyCell="{ record, column }">
        <template v-if="column.dataIndex === 'status'">
          <span v-if="documentSaveStatus[record._id] === 'pending'" class="text-info">Pending</span>
          <span v-if="documentSaveStatus[record._id] === 'saving'" class="text-warning">Saving</span>
          <span v-if="documentSaveStatus[record._id] === 'saved'" class="text-success">Saved</span>
          <span v-if="documentSaveStatus[record._id] === 'error'" class="text-error">Error</span>
        </template>
      </template>
    </a-table>
  </a-modal>
</template>
<script setup>
import { DownOutlined, SearchOutlined, PlusCircleOutlined, EyeOutlined } from '@ant-design/icons-vue';
import { message, Modal } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, toRaw, } from "vue";
import _ from "underscore";
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import 'dayjs/locale/zh-cn';

dayjs.extend(updateLocale);
dayjs.updateLocale('zh-cn', { weekStart: 0 });
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
const columns = [
  { title: 'Title', dataIndex: 'title', width: 120, },
  { title: 'Type', dataIndex: 'type', width: 120, },
  { title: 'MarketplaceIds', dataIndex: 'marketplaceIds', width: 120, },
  { title: 'ReportId', dataIndex: 'reportId', width: 120, },
  { title: 'DocumentId', dataIndex: 'documentId', width: 120, },
  { title: 'DownloadUrl', dataIndex: 'download_url', width: 120, },
  { title: 'Step', dataIndex: 'step', width: 120, },
  { title: 'Status', dataIndex: 'status', width: 120, },
  { title: 'CreateTime', dataIndex: 'report_create_time', width: 120, },
  { title: 'Operation', dataIndex: 'operation', width: 150, fixed: 'right' },
];
const { proxy } = getCurrentInstance();
const reportFormRef = ref();
const page = ref(1);
const limit = ref(10);
const data = ref([]);
const length = ref(0);
const count = ref(0);
const dataLoading = ref(false);
const shops = ref([]);
const reportTypes = ref([]);
const reportPeriods = ref([]);
const reportForm = ref({});
const reportModal = ref(false);
const reportSubmiting = ref(false);
const reportid = ref(null);
const documents = ref([]);
const documentDrawer = ref(false);
const documentModal = ref(false);
const dcolumns = ref([]);
const documentSaving = ref(false);
const documentSaveStatus = ref({});
const dpage = ref(1);
const dlimit = ref(10);
const dlength = ref(0);
const dcount = ref(0);

const width = computed(() => proxy.$utils.twidth(columns));
watch(() => page.value, () => {
  getData();
});
watch(() => limit.value, () => {
  if (page.value !== 1) page.value = 1;
  else getData();
});
onActivated(() => {
  getData();
});
const getData = () => {
  dataLoading.value = true;
  proxy.$api.doAPI2("/dev/amazon/report/list", { page: page.value, limit: limit.value }).then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      data.value = _.map(res.data.list, n => {
        return {
          ...n,
          create_time: proxy.$utils.dateFormat(n.create_time, 'YYYY-MM-DD HH:mm:ss'),
          report_create_time: n.report_create_time ? proxy.$utils.dateFormat(n.report_create_time, 'YYYY-MM-DD HH:mm:ss') : '',
        };
      });
      length.value = res.data.length;
      count.value = res.data.count;
      if (length.value > 0 && page.value > length.value) page.value = 1;
      shops.value = res.data.shops;
      reportTypes.value = res.data.reportTypes;
      reportPeriods.value = res.data.reportPeriods;
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
};
const constructReport = () => {
  reportForm.value = {
    type: undefined,
    shopid: null,
    daterange: ["", ""],
    date: "",
  };
  reportModal.value = true;
}
const submitReport = async () => {
  try {
    await reportFormRef.value.validateFields();
    dataLoading.value = true;
    proxy.$api.doAPI2("/dev/amazon/report/submit", { reportForm: reportForm.value }).then((res) => {
      dataLoading.value = false;
      if (res.code === 0) {
        reportModal.value = false;
        getData();
      } else {
        message.error(res.msg);
      }
    }).catch((err) => {
      dataLoading.value = false;
      console.error(err);
    });
  } catch (errorInfo) {
    console.log("Failed:", errorInfo);
  }
}
const cancelSubmitReport = () => {
  reportFormRef.value.clearValidate();
  reportModal.value = false;
}
const getReports = (_id) => {
  dataLoading.value = true;
  proxy.$api.doAPI2("/dev/amazon/report/getlist", { _id }).then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      getData();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
}
const createReport = (_id) => {
  dataLoading.value = true;
  proxy.$api.doAPI2("/dev/amazon/report/create", { _id }).then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      getData();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
}
const getReport = (_id) => {
  dataLoading.value = true;
  proxy.$api.doAPI2("/dev/amazon/report/get", { _id }).then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      getData();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
}
const getDocument = (_id) => {
  dataLoading.value = true;
  proxy.$api.doAPI2("/dev/amazon/report/get_document", { _id }).then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      getData();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
}
const downloadDocument = (_id) => {
  dataLoading.value = true;
  proxy.$api.doAPI2("/dev/amazon/report/download", { _id }).then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      getData();
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
}
const showDocument = (_id, type) => {
  reportid.value = _id;
  dataLoading.value = true;
  proxy.$api.doAPI2("/dev/amazon/report/show", { _id, type }).then((res) => {
    dataLoading.value = false;
    if (res.code === 0) {
      let headers = {};
      for (let i = 0; i < res.data.report_json.length; i++) {
        let keys = _.keys(res.data.report_json[i]);
        for (let j = 0; j < keys.length; j++) {
          let key = keys[j];
          if (!headers[key]) headers[key] = [];
          if (res.data.report_json[i][key]) headers[key].push(res.data.report_json[i][key]);
        }
      }
      console.log(headers);
      let _dcolumns = _.filter(_.map(_.keys(headers), n => {
        if (headers[n].length > 0) return { title: n, dataIndex: n, width: 150 };
        return null;
      }), n => !!n);
      if (type === 2) {
        _dcolumns.unshift({ title: "Status", dataIndex: "status", width: 100 });
      }
      dcolumns.value = _dcolumns;
      documents.value = _.map(res.data.report_json, n => {
        return {
          ...n,
          _id: proxy.$utils.uuid(),
        };
      });
      documentSaveStatus.value = {
        ...toRaw(documentSaveStatus.value),
        ..._.reduce(documents.value, (a, b) => _.extend(a, { [b._id]: 'pending' }), {}),
      };
      dcount.value = res.data.report_json.length;
      dlength.value = Math.ceil(res.data.report_json.length / dlimit.value);
      if (type === 1) documentDrawer.value = true;
      if (type === 2) documentModal.value = true;
      dpage.value = 1;
    } else {
      message.error(res.msg);
    }
  }).catch((err) => {
    dataLoading.value = false;
    console.error(err);
  });
}
const saveDocument = async () => {
  let list = proxy.$utils.sublist(documents.value, dlimit.value);
  documentSaving.value = true;
  for (let i = 0; i < list.length; i++) {
    let documents = list[i];
    documentSaveStatus.value = {
      ...toRaw(documentSaveStatus.value),
      ..._.reduce(documents, (a, b) => _.extend(a, { [b._id]: 'saving' }), {}),
    };
    try {
      let res = await proxy.$api.doAPI2("/dev/amazon/report/save", { _id: reportid.value, documents, is_last: i === list.length - 1 });
      if (res.code === 0) {
        documentSaveStatus.value = {
          ...toRaw(documentSaveStatus.value),
          ..._.reduce(documents, (a, b) => _.extend(a, { [b._id]: 'saved' }), {}),
        }
      } else {
        documentSaveStatus.value = {
          ...toRaw(documentSaveStatus.value),
          ..._.reduce(documents, (a, b) => _.extend(a, { [b._id]: 'error' }), {}),
        };
      }
    } catch (e) {
      documentSaveStatus.value = {
        ...toRaw(documentSaveStatus.value),
        ..._.reduce(documents, (a, b) => _.extend(a, { [b._id]: 'error' }), {}),
      };
    }
    dpage.value++;
    await proxy.$utils.sleep(100);
  }
  documentSaving.value = false;
  Modal.success({
    title: "Info", content: "Saved",
    onOk() {
      documentModal.value = false;
      getData();
    },
  });
}
const gettypename = (item) => {
  return _.findWhere(reportTypes.value, { value: item.type })?.label;
}
const validateDateRange = (_rule, value) => {
  if (!value[0] || !value[1]) {
    return Promise.reject("DateRange can't be empty");
  } else {
    return Promise.resolve();
  }
};
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

p {
  margin: 0;
  padding: 0;
}
</style>
