<template>
  <div>
    <a-button type="link" @click="showAssistant">
      <setting-outlined /> 正博物流助手
    </a-button>
    <a-drawer title="正博物流助手设置" width="400px" placement="right" v-model:visible="Visible" @close="onClose">
      <a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" autocomplete="off">
        <a-form-item label="服务端口">
          <a-input-group compact>
            <a-input style="width: 75%" v-model:value="Port" type="number" placeholder="服务端口" />
            <a-button type="primary" style="width: 25%" @click="SetPrintPort"> 设置 </a-button>
          </a-input-group>
        </a-form-item>
        <a-form-item label="默认打印机">
          <a-input-group compact>
            <a-select style="width: 75%" v-model:value="Printer" notFoundContent="无可用打印机">
              <a-select-option v-for="(item, index) in Printers" :key="index" :value="item">
                {{ item }}
              </a-select-option>
            </a-select>
            <a-button type="primary" style="width: 25%" @click="SetDefaultPrinter"> 设置 </a-button>
          </a-input-group>
        </a-form-item>
      </a-form>
      <div>
        <a href="https://static.dfsupplychain.com/public/zhengbo/%E6%AD%A3%E5%8D%9A%E7%89%A9%E6%B5%81%E5%8A%A9%E6%89%8B1.0.zip"
          _target="blank">下载正博物流助手</a>
      </div>
      <div>
        <a href="https://static.dfsupplychain.com/public/zhengbo/%E6%AD%A3%E5%8D%9A%E7%89%A9%E6%B5%81%E5%8A%A9%E6%89%8B%E8%BF%90%E8%A1%8C%E7%8E%AF%E5%A2%83.zip"
          _target="blank">下载运行环境</a>
      </div>
    </a-drawer>
  </div>
</template>

<script setup>
import { SettingOutlined } from "@ant-design/icons-vue";
import { defineComponent, toRefs, reactive, watch, ref, computed } from "vue";
import { getPrinters, getPrintPort, setPrintPort, getDefaultPrinter, setDefaultPrinter } from "../zhengbo";
import { useStore } from "vuex";
import { message } from "ant-design-vue";

const store = useStore();
const Visible = ref(false);
const Port = ref("");
const Printer = ref("");
const Printers = ref([]);

const visible = computed(() => store.state.zhengbo.visible);
const port = computed(() => store.state.zhengbo.port);
const printer = computed(() => store.state.zhengbo.printer);
const printers = computed(() => store.state.zhengbo.printers);

watch(() => visible, (nv, ov) => Visible.value = nv);
watch(() => port, (nv, ov) => Port.value = nv);
watch(() => printer, (nv, ov) => Printer.value = nv);
watch(() => printers, (nv, ov) => Printers.value = nv);
const showAssistant = () => {
  store.dispatch("zhengbo/setVisible", Visible.value = true);
  store.dispatch("zhengbo/setPort", Port.value = getPrintPort());
  store.dispatch("zhengbo/setPrinter", Printer.value = getDefaultPrinter());
  getPrinters().then(printers => {
    if (printers) store.dispatch("zhengbo/setPrinters", Printers.value = printers);
  });
}
const onClose = () => {
  store.dispatch("zhengbo/setVisible", Visible.value = false);
}
const SetPrintPort = () => {
  if (!Port.value) return message.error("请输入服务端口");
  setPrintPort(Port.value);
  store.dispatch("zhengbo/setPort", Port.value);
  message.success("设置成功");
}
const SetDefaultPrinter = () => {
  if (!Printer.value) return message.error("请选择默认打印机");
  setDefaultPrinter(Printer.value);
  store.dispatch("zhengbo/setPrinter", Printer.value);
  message.success("设置成功");
}
</script>