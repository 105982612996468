<template>
  <a-input-search v-model:value="url" placeholder="URL" enter-button="加载网页" @search="loadPage" :loading="loading" />
  <a-row>
    <a-col :span="24">
      <iframe ref="page" @load="onPageLoadFinished" v-if="page_content" :srcdoc="page_content"
        style="width:100%;"></iframe>
      <iframe ref="page" @load="onPageLoadFinished" v-else-if="page_url" :src="page_url" style="width:100%;"></iframe>
    </a-col>
    <!-- <a-col :span="8"></a-col> -->
  </a-row>
</template>

<script setup>
import _ from 'underscore';
import { ref, reactive, computed, getCurrentInstance } from 'vue';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons-vue';
const { proxy } = getCurrentInstance();

const loading = ref(false);
const url = ref('https://www.amazon.com');
const page = ref(null);
const page_url = ref('');
const page_content = ref('');

const loadPage = async () => {
  loading.value = true;
  page_url.value = url.value;
}
const onPageLoadFinished = (e) => {
  console.log('onPageLoadFinished', e);
  loading.value = false;
  page.value.style.height = 'calc(100vh - 50px)';
  // page_content.value = page.value.innerHTML;
}
</script>

<style lang="less" scoped>

</style>