<template>
  <div class="header">
    <div class="search">
      <span style="width:80px;">收款平台：</span>
      <a-radio-group v-model:value="platforms">
        <a-radio-button value="all">全部</a-radio-button>
        <a-radio-button value="1">pingpong收款</a-radio-button>
        <a-radio-button value="2">连连收款</a-radio-button>
        <a-radio-button value="3">艾贝盈收款</a-radio-button>
        <a-radio-button value="4">万里汇收款</a-radio-button>
      </a-radio-group>
    </div>
    <div class="search">
      <span style="width:80px;">选择日期：</span>
      <a-range-picker v-model:value="daterange" value-format="YYYY-MM-DD" />
    </div>
    <div class="d-flex justify-space-between align-center mb-2">
      <a-space>
        <a-button type="primary" @click="getData">同步数据</a-button>
        <a-button type="primary" @click="showUpload">
          <template #icon>
            <UploadOutlined />
          </template>导入明细
        </a-button>
      </a-space>
      <a-button type="default">
        <template #icon>
          <DownloadOutlined />
        </template>导出明细
      </a-button>
    </div>
  </div>
  <a-table :columns="columns" :data-source="data" :scroll="{ x: width }" :loading="dataLoading"
    :pagination="{ current: page, pageSize: limit, total: count, showQuickJumper: true, showSizeChanger: true, showTotal: (total) => `共${total}条`, }"
    @change="(pagination) => { page = pagination.current; limit = pagination.pageSize; }" size="small">
    <template #bodyCell="{ column, record }">
      <template v-if="column.dataIndex === 'platform'">
        <p> pingpong </p>
      </template>
      <template v-if="column.dataIndex === 'counry'">
        <p>{{ record.storeaccount?.x_nation || "-" }}</p>
      </template>
      <template v-if="column.dataIndex === 'amount_account'">
        <p>{{ record.storeaccount?.x_cardNo || "-" }}</p>
      </template>
      <template v-if="column.dataIndex === 'BoundShop'">
        <p>{{ record.store?.x_storeName || "-" }}</p>
      </template>
      <template v-if="column.dataIndex === 'nickname'">
        <p>{{ record.store?.x_storeName || "-" }}</p>
      </template>
      <template v-if="column.dataIndex === 'remitter'">
        <p>{{ "-" }}</p>
      </template>
      <template v-if="column.dataIndex === 'x_amount'">
        <p>{{ record.x_amount }}({{ record.x_currency }})</p>
      </template>
    </template>
  </a-table>
  <a-drawer title="上传交易明细" placement="right" v-model:visible="uploadDrawer" @close="cancelUpload" width="70%"
    :closable="!uploading" :maskClosable="!uploading">
    <template #extra>
      <a-button class="mr-2" @click="cancelUpload" :disabled="uploading">取消</a-button>
      <a-button type="primary" @click="doUpload" :loading="uploading"
        :disabled="adding || uploading || files.length === 0">
        <template #icon>
          <UploadOutlined />
        </template>
        上传
      </a-button>
    </template>
    <div class="d-flex justify-space-between mb-2">
      <a-space>
        <a-button type="primary" @click="getUploadFiles">刷新数据</a-button>
      </a-space>
      <a-space>
        <a-button type="primary" :loading="adding" :disabled="uploading" @click="addFiles">
          <template #icon>
            <PlusOutlined />
          </template>
          点击上传文件
        </a-button>
        <span class="text-error">只能上传 .xls/.xlsx 文件，且不超过5MB</span>
      </a-space>
    </div>
    <input ref="uploadRef" type="file"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      style="display: none" @change="selectFiles" />
    <a-table :columns="ucolumns" :data-source="files" :pagination="false" size="small">
      <template #bodyCell="{ record, column, index }">
        <template v-if="column.dataIndex === 'index'">
          {{ index + 1 }}
        </template>
        <template v-if="column.dataIndex === 'name'">
          {{ record.file.name }}
        </template>
        <template v-if="column.dataIndex === 'type'">
          {{ record.file.type }}
        </template>
        <template v-if="column.dataIndex === 'size'">
          {{ sizeToText(record.file.size) }}
        </template>
        <template v-if="column.dataIndex === 'url'">
          <img v-if="record.url" :src="record.url" width="80" height="80" style="object-fit: contain;" />
          <img v-else :src="$filters.image_placeholder" width="80" height="80" style="object-fit: contain;" />
        </template>
        <template v-if="column.dataIndex === 'status'">
          <span v-if="record.status === 'pending'" class="text-info text-h9">等待上传</span>
          <span v-if="record.status === 'uploading'" class="text-orange text-h9">
            正在上传 {{ record.upload_rate }}
          </span>
          <span v-if="record.status === 'success'" class="text-success text-h9">
            上传成功{{ record.upload_msg }}
          </span>
          <a-tooltip v-if="record.status === 'fail'" placement="topLeft">
            <template #title> {{ record.msg }} </template>
            <span class="text-error text-h9 underline">上传失败{{ record.upload_msg }} </span>
          </a-tooltip>
          <a-tooltip v-if="record.status === 'error'" placement="topLeft">
            <template #title> {{ record.msg }} </template>
            <span class="text-error text-h9 underline">无法上传</span>
          </a-tooltip>
        </template>
        <template v-if="column.dataIndex === 'operation'">
          <a-button v-if="record.status === 'fail'" danger type="link" :disabled="uploading"
            @click="uploadFile(index)">重试</a-button>
          <a-button v-if="record.status === 'error'" danger type="link" :disabled="adding"
            @click="removeFile(index)">移除</a-button>
        </template>
      </template>
    </a-table>
  </a-drawer>
</template>
<script>
import { DownOutlined, DownloadOutlined, UploadOutlined } from '@ant-design/icons-vue';
import { message, Modal } from "ant-design-vue";
import { defineComponent, ref, reactive, toRefs, watch, computed, onMounted, onActivated, getCurrentInstance, } from 'vue';
import _ from "underscore";
const columns = [
  { title: '收款平台', dataIndex: 'platform', width: 150, align: 'center', },
  { title: '到账日期', dataIndex: 'x_transferTime', width: 150, align: 'center', },
  { title: '交易号', dataIndex: 'x_txid', width: 150, align: 'center', },
  { title: '账户昵称', dataIndex: 'nickname', width: 100, align: 'center', },
  { title: '账户所在国家', dataIndex: 'counry', width: 150, align: 'center', },
  { title: '收款账号', dataIndex: 'amount_account', width: 150, align: 'center', },
  { title: '收款金额', dataIndex: 'x_amount', width: 150, align: 'center', },
  { title: '汇款人', dataIndex: 'remitter', width: 150, align: 'center', },
  { title: '店铺名称', dataIndex: 'BoundShop', width: 100, align: 'center', },
  { title: '状态', dataIndex: 'state', width: 100, align: 'center', },
  { title: '操作', dataIndex: 'action', width: 100, align: 'center', },
];
export default defineComponent({
  name: 'OperationPayeeList',
  components: {
    DownOutlined,
    DownloadOutlined,
    UploadOutlined
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const uploadRef = ref();
    const state = reactive({
      platforms: 'all',
      data: [],
      daterange: [],
      page: 1,
      limit: 10,
      length: 0,
      count: 0,
      dataLoading: false,
      uploading: false,
      uploadDrawer: false,
      files: [],
      ucolumns: [],
    });
    const width = computed(() => proxy.$utils.twidth(columns));
    watch(() => state.page, () => getData());
    watch(() => state.limit, () => {
      if (state.page !== 1) state.page = 1;
      else getData();
    });
    onActivated(() => {
      getData();
    });
    const getData = () => {
      state.dataLoading = true;
      proxy.$api.doAPI("/finance/payee/transactiondetail", {
        daterange: state.daterange,
        page: state.page,
        limit: state.limit
      }).then((res) => {
        if (res.code === 0) {
          state.data = res.data.list;
          console.log(state.data);
          state.count = res.data.count;
          state.length = res.data.length;
          state.daterange = [];
          state.dataLoading = false;
          if (state.length > 0 && state.page > state.length) state.page = 1;
        } else {
          message.error(res.msg);
          state.daterange = [];
          state.data = [];
          state.dataLoading = false;
        }
      }).catch((err) => {
        console.error(err);
        state.daterange = [];
        state.data = [];
        state.dataLoading = false;
      });
    };

    const handleChange = (tag, checked) => {
      const { selectedTags } = state;
      const nextSelectedTags = checked
        ? [...selectedTags, tag]
        : selectedTags.filter(t => t !== tag);
      console.log('You are interested in: ', nextSelectedTags);
      state.selectedTags = nextSelectedTags;
    };
    const showUpload = () => {
      state.files = [];
      state.uploadDrawer = true;
    }
    const cancelUpload = () => {
      state.uploadDrawer = false;
      state.files = [];
    }
    const selectFiles = async (e) => {

    }
    ///刷新数据
    const getUploadFiles = async (e) => {

    }
    const uploadFile = async (e) => {

    }

    const removeFile = (file) => {

    }
    const addFiles = () => {
      uploadRef.value.click();
    }
    return {
      ...toRefs(state),
      uploadRef,
      columns,
      width,
      getData,
      handleChange,
      showUpload,
      addFiles
    };
  }
});
</script>
<style lang="less" scoped>
:deep(.ant-layout-header) {
  display: flex;
  padding: 0 20px;
}

:deep(.ant-table-thead > tr > th) {
  padding: 8px 8px !important;
}

.header {
  .search {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }

  .op {
    display: flex;
    justify-content: space-between;
  }
}

p {
  margin: 0;
  padding: 0;
}
</style>
